<p-dialog [visible]="showDialog" (visibleChange)="closeDialog()" [modal]="true" [blockScroll]="true" [draggable]="false"
  [dismissableMask]="true" appendTo="body" styleClass="dialog-no-footer" [style]="{width: '51%'}"
>
  <div>
    <div class="grid">
      <div class="col-12 mb-2">
        <h5 class="text-center menu-subtitle mb-1">{{'PROJECT.TEMPLATE.POPUP_SUBTITLE' | translate}}</h5>
        <p class="text-center">{{'PROJECT.TEMPLATE.POPUP_DESC' | translate }}</p>
      </div>
    </div>
    <!-- <p-divider /> -->
    <!-- <div class="grid">
      <div class="col-12 px-5">
        <p class="px-4">{{'PROJECT.TEMPLATE.FORM_NAME' | translate }}<sup>*</sup></p>
        <input type="text" pInputText class="w-100 h-3rem px-4" [ngModel]="appNameField()"
          (ngModelChange)="appNameField.set($event)"
          placeholder="{{'PROJECT.TEMPLATE.FORM_NAME_PLACEHOLDER'|translate}}">
        @if (appNameFieldError()) {
        <sub class="text-magenta">field required</sub>
        }
      </div>
    </div>
    <p-divider /> -->
    <div class="grid center mb-5">
      <div class="col-auto center flex-column mx-4 mt-4">
        <p-button [disabled]="isSubmitting()" (click)="submit()" pButton type="button"
          label="{{'PROJECT.TEMPLATE.COPY_BUTTON' | translate}}" icon="pi pi-chevron-right" iconPos="right"
          class="p-button p-button-sm mr-2 mb-2" [loading]="isSubmitting()" />
      </div>
    </div>
  </div>
</p-dialog>
<fibr-block-ui [isBlocked]="isSubmitting()" />
```
