import {CommonModule} from '@angular/common';
import {Component, Input, OnInit, signal, computed, AfterViewInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {MenuItem} from 'primeng/api';
import {OrderListModule} from 'primeng/orderlist';
import {TranslateService} from "@ngx-translate/core";
import {SidebarModule} from 'primeng/sidebar';
import {CardModule} from 'primeng/card';
import {ButtonModule} from 'primeng/button';
import {Page} from '../../model';
import {AccordionModule} from 'primeng/accordion';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {FormsModule} from '@angular/forms';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {DividerModule} from 'primeng/divider';
import {CheckboxModule} from 'primeng/checkbox';
import {EditorAPIService} from '../../api/editor-api.service';
import {ActivatedRoute} from '@angular/router';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {setToast} from '@shared/components/toast/toast.component';
import {EditorStoreService} from '../../store/editor-store.service';
import {NgFlutterComponent, type FlutterState} from './ng-flutter/ng-flutter.component';
import {AuthStoreService} from '../../../auth/store/auth-store.service';
import {BlockUIComponent} from '@shared/components/block-ui/block-ui.component';
import {FibrPageComponentDetailsComponent} from '../page-component-details/page-component-details.component';
import {FibrPageComponentFormsComponent} from '../page-component-forms/page-component-forms.component';
import {PageComponentStore} from '../../store/page-component.store';
import {ProjectStoreService} from '../../../project/store/project-store.service';
import {PickListModule} from 'primeng/picklist';
import {PageParentComponent} from "../page-parent/page-parent.component";
import {escapeClicker} from "@shared/libs/escape-clicker";
import {FibrCustomIconComponent} from "@shared/components/icons/custom-icon/custom-icon.component";
import * as feathers from 'feather-icons';

@Component({
  selector: 'fibr-preview-and-page-list-section',
  standalone: true,
  imports: [
    TranslateModule,
    OrderListModule,
    SidebarModule,
    CardModule,
    ButtonModule,
    AccordionModule,
    OverlayPanelModule,
    CommonModule,
    FormsModule,
    ScrollPanelModule,
    DividerModule,
    CheckboxModule,
    ConfirmDialogModule,
    NgFlutterComponent,
    FibrPageComponentDetailsComponent,
    FibrPageComponentFormsComponent,
    BlockUIComponent,
    PickListModule,
    PageParentComponent,
    FibrCustomIconComponent
  ],
  templateUrl: './preview-and-page-list-section.component.html',
  styleUrl: './preview-and-page-list-section.component.scss'
})
export class PreviewAndPageListSectionComponent implements OnInit, AfterViewInit {
  itemPages: MenuItem[] = [];
  selectedPage = signal<string | null>(null);
  @Input() isSelected = false;
  isBlocked = signal(false);

  projectID = this.route.snapshot.paramMap.get('id');
  displayPropertyPage = signal(false);
  pageProperty: Page = {
    id: "",
    name: "",
    icon: "airplay",
    type: "",
    show_in_nav_bar: false,
    index: 0
  };
  displayPageComponent = signal(false)
  isDebug = signal(false);

  selectedItem: unknown = null;

  pageMenu = this.editorStore.pageList
  pages = this.editorStore.pageList_2
  selectedPageConfig = this.editorStore.selectedPageConfig

  projectDetail = computed(() => this.projectStore.projectDetail())

  mutableFlutterState?: FlutterState;

  constructor(
    private translate: TranslateService,
    private editorAPIService: EditorAPIService,
    private editorStore: EditorStoreService,
    private authStore: AuthStoreService,
    private route: ActivatedRoute,
    private pageComponentStore: PageComponentStore,
    private projectStore: ProjectStoreService,
  ) {
  }

  onFlutterAppLoaded(state: FlutterState) {
    this.mutableFlutterState = state;
    this.mutableFlutterState.setCredentials(this.getCredentials());
  }

  onFlutterPageLoaded(state: FlutterState) {
    this.mutableFlutterState = state;
    this.mutableFlutterState?.onPageChanged(() => {
      this.modifyPageMenuFromApp(this.mutableFlutterState?.getPageId() ?? '')
    });
  }

  async modifyPageMenuFromApp(eventId: string) {
    this.isBlocked.set(true);
    this.selectedPage.set(eventId);
    await this.editorAPIService.getPageAppByID(this.projectID ?? "", eventId);
    escapeClicker();
    this.pageProperty = this.selectedPageConfig() as Page;
    this.displayPropertyPage.set(true);
    this.isBlocked.set(false);
  }

  selectedPageComponent = computed(() => {
    if (!this.selectedPageConfig()) return null;
    return {
      pageProperty: this.pageProperty as Page,
    }
  })

  ngOnInit(): void {
    this.initItemPage();
    this.editorAPIService.getPageApp(this.projectID!);
    this.loadFeatherIcons();

    this.route.queryParams.subscribe(params => {
      if (params['isDebug'] !== undefined) {
        this.isDebug.set(params['isDebug']);
      }
    });
  }

  ngAfterViewInit() {
    feathers.replace();
  }

  private loadFeatherIcons(): void {
    setTimeout(() => feathers.replace(), 0);
  }

  getCredentials() {
    const body = {
      token: this.authStore.userToken(),
      project_name: this.projectDetail()?.display_name,
      project_id: this.projectID,
      user_id: this.authStore.user()?.uid,
    };
    return JSON.stringify(body);
  }

  initItemPage() {
    this.itemPages = [
      {
        id: "_",
        label: this.translate.instant('editor.pages.title')
      },
      {
        id: "",
        label: "Add Page",
        icon: "pi pi-fw pi-plus",
        styleClass: "add-button",
        command: () => {
          this.addPageMenu();
        }
      }
    ];
  }

  initPageProperty() {
    this.pageProperty = {
      id: "",
      name: "",
      icon: "airplay",
      show_in_nav_bar: false,
      type: "basic",
      index: this.pageMenu().length
    };
  }

  addPageMenu() {
    this.initPageProperty();
    this.editorStore.setSelectedPagePropertyComponent(null);
    this.editorStore.setSelectedPageConfig(null);
    this.displayPropertyPage.set(true);
  }

  checkDragNDrop() {
    this.isBlocked.set(true);
    this.pageMenu().forEach((page, index) => {
      this.editorAPIService.orderPage(this.projectID!, page.id, index, true);
    });
    this.isBlocked.set(false);
    this.loadFeatherIcons()
  }

  reOrderPage() {
    this.isBlocked.set(true);
    const pageMenuLenghtIndex = this.pageMenu().length;
    this.pages().forEach((page, index) => {
      this.editorAPIService.orderPage(this.projectID!, page.id, index + pageMenuLenghtIndex, false);
    });
    this.isBlocked.set(false);
  }

  handleMoveToMenu(event: MenuItem) {
    if (this.pageMenu().length > 5) {
      setToast(this.translate.instant('EDITOR.MAXIMUM_PAGE'), 'error', 'top-center');
      this.editorAPIService.handleMoveToMenu(this.projectID!, event.items?.[0]?.id ?? "", false);
    } else {
      this.editorAPIService.handleMoveToMenu(this.projectID!, event.items?.[0]?.id ?? "", true);
      this.checkDragNDrop();
      this.reOrderPage();
    }
  }

  handleMoveToPage(event: MenuItem) {
    this.editorAPIService.handleMoveToMenu(this.projectID!, event.items?.[0]?.id ?? "", false);
    this.reOrderPage();
    this.checkDragNDrop();
  }

  onCancel() {
    this.displayPropertyPage.set(false);
    this.displayPageComponent.set(false);
    this.pageComponentStore.init()
  }

  onApply(pageId: string | null) {
    if (pageId) {
      this.modifyPageMenu({ id: pageId })
    }
  }

  async modifyPageMenu(event: { id: string }) {
    if (typeof this.mutableFlutterState?.setPageId === 'function') {
      this.mutableFlutterState?.setPageId(event.id);
    }

    this.selectedPage.set(event.id);
    this.isBlocked.set(true);
    await this.editorAPIService.getPageAppByID(this.projectID ?? "", event.id);

    this.isBlocked.set(false);
    this.pageProperty = {
      id: this.selectedPageConfig()!.id,
      name: this.selectedPageConfig()!.name,
      icon: this.selectedPageConfig()!.icon,
      type: this.selectedPageConfig()!.type,
      show_in_nav_bar: <boolean>this.selectedPageConfig()!.show_in_nav_bar,
      index: this.selectedPageConfig()!.index ?? 0
    }
    this.displayPropertyPage.set(true);
  }
}
