<div class="preview-content" [ngClass]="isSelected ? 'd-flex' : 'd-none'">
  <div #mydiv class="page-option"></div>
  <div class="p-vmenu">
    <p-orderList styleClass="static-menu second-element" [value]="itemPages" [dragdrop]="false">
      <ng-template pTemplate="item" let-item let-i="index">
        @if (item.id === '_') {
          <div class="p-menuitem-link">
            <span class="p-menuitem-text">{{ item.label }}</span>
          </div>
        }
        @if (item.id === '') {
          <div class="p-menuitem-link" (click)="addPageMenu()" (keyup.enter)="addPageMenu()" tabindex="">
            <span class="p-menuitem-icon {{item.icon}}"></span>
            <span class="p-menuitem-text">{{ item.label }}</span>
          </div>
        }
      </ng-template>
    </p-orderList>
    <p-pickList
      [source]="pageMenu()"
      [target]="pages()"
      targetHeader="Page"
      [dragdrop]="true"
      [responsive]="true"
      (onMoveToSource)="handleMoveToMenu($event)"
      (onMoveToTarget)="handleMoveToPage($event)"
      (onSourceReorder)="checkDragNDrop()"
      (onTargetReorder)="reOrderPage()"
      [showSourceControls]="false"
      [showTargetControls]="false"
      [disabled]="false"
      [stripedRows]="false"
      [sourceStyle]="{'border-bottom':'1px solid #e0e0e0'}"
      breakpoint="30px">
      <ng-template pTemplate="sourceHeader">
        <div class="p-picklist-header">
          <div class="p-picklist-title">Page Menu</div>
        </div>
        <div class="p-menuitem-link separate-menu mt-2">
          <span class="p-menuitem-text">{{ 'EDITOR.SET_AS_MENU_AREA' | translate }}</span>
        </div>
      </ng-template>
      <ng-template let-item pTemplate="item" let-i="index">
        @if (selectedPage()) {
          <div class="p-menuitem-link" [ngClass]="{'active-page': item.id === selectedPage() }"
               (click)="modifyPageMenu(item)" (keyup.enter)="modifyPageMenu(item)" tabindex="">
            @if (item.icon) {
              <span class="p-menuitem-icon"><i [attr.data-feather]="item.icon"></i></span>
            } @else {
              <span class="p-menuitem-icon pi pi-fw pi-mobile"></span>
            }
            <span class="p-menuitem-text">{{ item.name }}</span>
          </div>
        } @else {
          <div class="p-menuitem-link" (click)="modifyPageMenu(item)" (keyup.enter)="modifyPageMenu(item)" tabindex="">
            @if (item.icon) {
              <span class="p-menuitem-icon"><i [attr.data-feather]="item.icon"></i></span>
            } @else {
              <span class="p-menuitem-icon pi pi-fw pi-mobile"></span>
            }
            <span class="p-menuitem-text">{{ item.name }}</span>
          </div>
        }
      </ng-template>
    </p-pickList>
  </div>

  <div class="preview-app pl-3">
    <div class="sticky-phone">
      <div class="grid">
        <div class="col-12 center"><h3
          class="menu-subtitle text-center mb-3 text-dark-blue">{{ 'EDITOR.APPLICATION_PREVIEW' | translate }}</h3>
        </div>
      </div>
      <div class="grid">
        <div class="col-12">
          <div class="iphone">
            <div class="content">
              <fibr-ng-flutter (appLoaded)="onFlutterAppLoaded($event)"
                               (pageLoaded)="onFlutterPageLoaded($event)"></fibr-ng-flutter>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<fibr-page-parent [component]="selectedPageComponent()" [visible]="displayPropertyPage()"
                  (cancel)="onCancel()" (apply)="onApply($event)"></fibr-page-parent>

<fibr-page-component-details/>
<fibr-page-component-forms/>

<fibr-block-ui [isBlocked]="isBlocked()"/>
