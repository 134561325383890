<form>
  <div class="p-field">
    <div class="flex justify-center mb-1">
      <label class="text-center" for="file_url">
        {{ 'TABLE.UPLOAD.PASTE' | translate }}
      </label>
    </div>

    <div class="flex items-center gap-3">
      <input
        name="file_url"
        (ngModelChange)="onInputTextChange($event)"
        [ngModel]="fileUrl()"
        id="file_url"
        placeholder="{{ 'TABLE.UPLOAD.IMAGE_URL' | translate }}"
        pInputText
      />

      <p-button
        [disabled]="!fileUrl()"
        label="{{ 'COMMON.SAVE' | translate }}"
        icon="pi pi-save"
        iconPos="right"
        (onClick)="submit()"
      ></p-button>
    </div>
  </div>

  <p-divider align="center" type="solid"> {{ 'TABLE.UPLOAD.SELECT' | translate }} </p-divider>

  <div
    #fileUpload
    fibrFileDnd
    (fileChange)="onDropFileChange($event)"
    class="border-1 rounded-xl w-full flex flex-column align-items-center py-6"
  >
    <div class="flex flex-column align-items-center">
      <i class="pi pi-upload block mb-2" style="font-size: 4rem"></i>
      <span>{{ 'TABLE.UPLOAD.DRAG_DROP' | translate }} </span>
    </div>

    <div class="mt-4 flex flex-column align-items-center">
      <input
        #inputFile
        type="file"
        accept=".png,.jpg"
        (change)="onInputFileChange($event)"
        [hidden]="true"
      />

      <p-button
        styleClass="mb-2 block"
        label="{{ 'TABLE.UPLOAD.BROWSE' | translate }}"
        icon="pi pi-plus"
        iconPos="left"
        (onClick)="inputFile.click()"
        [loading]="isLoading"
      ></p-button>

      <span class="text-xs font-italic">(max. size 512KB)</span>
    </div>
  </div>
</form>
