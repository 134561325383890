import { Component } from '@angular/core';

@Component({
  selector: 'fibr-chevron-left-icon',
  standalone: true,
  imports: [],
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15" fill="none">
      <path d="M0.00138597 7.37282C0.000874326 7.12675 0.0920709 6.88828 0.259146 6.69881L5.86262 0.379961C6.05285 0.164892 6.3262 0.0296434 6.62254 0.00396807C6.91888 -0.0217072 7.21394 0.0642943 7.4428 0.243053C7.67167 0.421812 7.81559 0.678685 7.84291 0.957163C7.87024 1.23564 7.77872 1.51291 7.58849 1.72798L2.56778 7.37282L7.40918 13.0177C7.50227 13.1254 7.57179 13.2493 7.61374 13.3824C7.65569 13.5154 7.66924 13.655 7.65362 13.793C7.638 13.9309 7.59351 14.0647 7.52271 14.1865C7.45192 14.3082 7.3562 14.4157 7.24108 14.5026C7.12585 14.599 6.99066 14.6721 6.84399 14.7171C6.69732 14.7622 6.54233 14.7783 6.38872 14.7645C6.23512 14.7506 6.08621 14.7071 5.95132 14.6367C5.81644 14.5663 5.69849 14.4704 5.60486 14.3551L0.191904 8.0363C0.0512393 7.84138 -0.0158398 7.60778 0.00138597 7.37282Z" fill="currentColor"/>
    </svg>
  `,
  styles: ``
})
export class ChevronLeftIconComponent {

}
