import { Component } from '@angular/core';

@Component({
  selector: 'fibr-cms-icon',
  standalone: true,
  imports: [],
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M25.08 11.3735V3.2079C25.08 2.35752 24.7417 1.54096 24.1409 0.939041C23.539 0.338288 22.7225 0 21.8721 0C17.402 0 7.67797 0 3.2079 0C2.35752 0 1.54096 0.338288 0.939041 0.939041C0.338288 1.54096 0 2.35752 0 3.2079V21.8721C0 22.7225 0.338288 23.539 0.939041 24.1409C1.54096 24.7417 2.35752 25.08 3.2079 25.08H11.3735C11.8564 25.08 12.2484 24.688 12.2484 24.2051C12.2484 23.7222 11.8564 23.3302 11.3735 23.3302C11.3735 23.3302 6.30149 23.3302 3.2079 23.3302C2.82062 23.3302 2.45084 23.1762 2.17671 22.9033C1.90375 22.6291 1.74977 22.2594 1.74977 21.8721V3.2079C1.74977 2.82062 1.90375 2.45084 2.17671 2.17671C2.45084 1.90375 2.82062 1.74977 3.2079 1.74977H21.8721C22.2594 1.74977 22.6291 1.90375 22.9033 2.17671C23.1762 2.45084 23.3302 2.82062 23.3302 3.2079V11.3735C23.3302 11.8564 23.7222 12.2484 24.2051 12.2484C24.688 12.2484 25.08 11.8564 25.08 11.3735Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.874883 7.58277H24.2051C24.688 7.58277 25.08 7.19083 25.08 6.70789C25.08 6.22496 24.688 5.83301 24.2051 5.83301H0.874883C0.391948 5.83301 0 6.22496 0 6.70789C0 7.19083 0.391948 7.58277 0.874883 7.58277Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.7909 4.66578H4.95741C5.44035 4.66578 5.83229 4.27383 5.83229 3.7909C5.83229 3.30796 5.44035 2.91602 4.95741 2.91602H3.7909C3.30796 2.91602 2.91602 3.30796 2.91602 3.7909C2.91602 4.27383 3.30796 4.66578 3.7909 4.66578Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.87488 4.66578H9.04139C9.52433 4.66578 9.91628 4.27383 9.91628 3.7909C9.91628 3.30796 9.52433 2.91602 9.04139 2.91602H7.87488C7.39195 2.91602 7 3.30796 7 3.7909C7 4.27383 7.39195 4.66578 7.87488 4.66578Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9569 4.66578H13.1234C13.6064 4.66578 13.9983 4.27383 13.9983 3.7909C13.9983 3.30796 13.6064 2.91602 13.1234 2.91602H11.9569C11.474 2.91602 11.082 3.30796 11.082 3.7909C11.082 4.27383 11.474 4.66578 11.9569 4.66578Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3725 13.998C15.9578 13.998 13.998 15.9578 13.998 18.3725C13.998 20.7871 15.9578 22.7469 18.3725 22.7469C20.7871 22.7469 22.7469 20.7871 22.7469 18.3725C22.7469 15.9578 20.7871 13.998 18.3725 13.998ZM18.3725 15.7478C19.8213 15.7478 20.9971 16.9237 20.9971 18.3725C20.9971 19.8213 19.8213 20.9971 18.3725 20.9971C16.9237 20.9971 15.7478 19.8213 15.7478 18.3725C15.7478 16.9237 16.9237 15.7478 18.3725 15.7478Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.8729 19.2478H24.206C24.6889 19.2478 25.0808 18.8559 25.0808 18.3729C25.0808 17.89 24.6889 17.498 24.206 17.498H21.8729C21.39 17.498 20.998 17.89 20.998 18.3729C20.998 18.8559 21.39 19.2478 21.8729 19.2478Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4667 16.5165L23.1161 14.8671C23.4579 14.5253 23.4579 13.9712 23.1161 13.6294C22.7743 13.2876 22.2202 13.2876 21.8784 13.6294L20.229 15.2788C19.8872 15.6206 19.8872 16.1747 20.229 16.5165C20.5708 16.8583 21.1249 16.8583 21.4667 16.5165Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M19.2478 14.8729V12.5399C19.2478 12.057 18.8559 11.665 18.3729 11.665C17.89 11.665 17.498 12.057 17.498 12.5399V14.8729C17.498 15.3559 17.89 15.7478 18.3729 15.7478C18.8559 15.7478 19.2478 15.3559 19.2478 14.8729Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5165 15.2788L14.8671 13.6294C14.5253 13.2876 13.9712 13.2876 13.6294 13.6294C13.2876 13.9712 13.2876 14.5253 13.6294 14.8671L15.2788 16.5165C15.6206 16.8583 16.1747 16.8583 16.5165 16.5165C16.8583 16.1747 16.8583 15.6206 16.5165 15.2788Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8739 17.498H12.5409C12.058 17.498 11.666 17.89 11.666 18.3729C11.666 18.8559 12.058 19.2478 12.5409 19.2478H14.8739C15.3569 19.2478 15.7488 18.8559 15.7488 18.3729C15.7488 17.89 15.3569 17.498 14.8739 17.498Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2788 20.228L13.6294 21.8775C13.2876 22.2193 13.2876 22.7733 13.6294 23.1151C13.9712 23.4569 14.5253 23.4569 14.8671 23.1151L16.5165 21.4657C16.8583 21.1239 16.8583 20.5698 16.5165 20.228C16.1747 19.8862 15.6206 19.8862 15.2788 20.228Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.498 21.872V24.205C17.498 24.6879 17.89 25.0799 18.3729 25.0799C18.8559 25.0799 19.2478 24.6879 19.2478 24.205V21.872C19.2478 21.389 18.8559 20.9971 18.3729 20.9971C17.89 20.9971 17.498 21.389 17.498 21.872Z" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.229 21.4667L21.8784 23.1161C22.2202 23.4579 22.7743 23.4579 23.1161 23.1161C23.4579 22.7743 23.4579 22.2202 23.1161 21.8784L21.4667 20.229C21.1249 19.8872 20.5708 19.8872 20.229 20.229C19.8872 20.5708 19.8872 21.1249 20.229 21.4667Z" fill="currentColor"/>
    </svg>
  `,
  styles: ``
})
export class CMSIconComponent {

}
