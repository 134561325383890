import { Component, computed, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FibrPageComponent } from '../page-component/page-component.component';
import { FibrPageComponentTextEntryComponent } from './page-component-form-types/page-component-text-entry/page-component-text-entry.component';
import { PageComponentStore } from '../../store/page-component.store';
import { FibrPageComponentTextNumberComponent } from './page-component-form-types/page-component-text-number/page-component-text-number.component';
import { FibrPageComponentFilePickerComponent } from './page-component-form-types/page-component-file-picker/page-component-file-picker.component';
import { FibrPageComponentImagePickerComponent } from './page-component-form-types/page-component-image-picker/page-component-image-picker.component';
import { FibrPageComponentDatePickerComponent } from './page-component-form-types/page-component-date-picker/page-component-date-picker.component';
import { FibrPageComponentCheckboxComponent } from './page-component-form-types/page-component-checkbox/page-component-checkbox.component';
import { FibrPageComponentRadioComponent } from './page-component-form-types/page-component-radio/page-component-checkbox.component';
import { FibrPageComponentDateTimePickerComponent } from './page-component-form-types/page-component-date-time-picker/page-component-date-picker.component';
import { FibrPageComponentDropdownComponent } from './page-component-form-types/page-component-dropdown/page-component-dropdown.component';

@Component({
  selector: 'fibr-page-component-forms',
  templateUrl: './page-component-forms.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FibrPageComponent,
    FibrPageComponentTextEntryComponent,
    FibrPageComponentTextNumberComponent,
    FibrPageComponentFilePickerComponent,
    FibrPageComponentImagePickerComponent,
    FibrPageComponentDatePickerComponent,
    FibrPageComponentDateTimePickerComponent,
    FibrPageComponentCheckboxComponent,
    FibrPageComponentDropdownComponent,
    FibrPageComponentRadioComponent,
  ],
})
export class FibrPageComponentFormsComponent {
  pageComponentStore = inject(PageComponentStore);

  visible = computed(() => this.pageComponentStore.dialogForm().visible);
}
