import type { Timestamp } from "@angular/fire/firestore";
import { minLength, object, string } from "valibot";

export type ProjectsShardFirebaseName = string;
export type ProjectID = string;
type IsoDateString = string;

export type Role = 'owner' | 'editor' | 'end-user'
export type Project = {
  icon: string;
  id: ProjectID;
  display_name: string;
  description: string;
  location?: ProjectsShardFirebaseName;
  updated_at?: IsoDateString;
  role?: Role;
  status?: 'draft' | 'deleted' | 'released';
  plans?: Plan | null;
  active_plans?: [] | null;
  global_config: GlobabConfig 
};

export type Template = {
  id: string;
  display_name: string;
  description: string;
  icon: string;
  updated_date?: Timestamp;
};

export type ProjectStore = {
  projectList: Project[];
  projectDetail: Project | null;
  templateList: Template[];
  selectedTemplate: Template | null;
  activeDialog: 'template' | 'create_project_with_template' | 'create_new_project' | 'google_sheet_lists' | null;
  isReleased: boolean;
  planDetail: Plan | null;
  role: Role | null;
  gSheetFiles: gapi.client.drive.File[]; 
  selectedFile: gapi.client.drive.File[] | null;
  googleSheetAccessToken: string;
};

export const CreateBlankProjectSchema = object({
  projectName: string([minLength(1, 'PROJECT.NAME_REQUIRED')]),
  tableName: string([minLength(1, 'PROJECT.TABLE_REQUIRED')]),
})

export type Plan = {
  status?: string;
  end_date?: string;
  begin_at?: number;
  end_at?: number;
  name?: string;
}

export type GlobabConfig = {
  cache_duration_in_minute: number;
  theme: {
    font_color: string;
    primary_color: string;
    secondary_color: string;
  }
}

export type GsheetFile = {
  id: string;
  name: string;
  owners: string[];
  modifiedTime: string;
}