import { Content } from 'pdfmake/interfaces';
import { formatCurrency, formatDate } from '@angular/common';
import { Invoice } from '../../../../features/billing/model/billing.model';

interface EInvoice extends Invoice {
  tax: number;
  subTotal: number;
}

export default function templateInvoice(
  invoice: EInvoice,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  typography: any
): Content[] {
  const appfibricon = `<svg xmlns="http://www.w3.org/2000/svg" width="883" height="180" viewBox="0 0 883 180" fill="none"><title>editor-dev.appfibr.io/assets/images/logo_appfibr.svg - https://editor-dev.appfibr.io/assets/images/logo_appfibr.svg</title>
  <g clip-path="url(#clip0_7534_140688)">
  <path d="M40.2632 0C45.5506 0 50.7863 1.04144 55.6712 3.06485C60.5562 5.08826 64.9948 8.05403 68.7335 11.7928C72.4723 15.5316 75.4381 19.9702 77.4615 24.8551C79.4849 29.7401 80.5263 34.9757 80.5263 40.2632V80.5263H40.2632C29.5847 80.5263 19.3436 76.2843 11.7928 68.7335C4.24202 61.1827 1.72155e-05 50.9416 1.72155e-05 40.2632C1.72155e-05 29.5847 4.24202 19.3436 11.7928 11.7928C19.3436 4.242 29.5847 0 40.2632 0ZM40.2632 99.4737H80.5263V139.737C80.5263 147.7 78.1649 155.485 73.7408 162.106C69.3166 168.727 63.0284 173.888 55.6712 176.935C48.3141 179.983 40.2185 180.78 32.4082 179.226C24.5979 177.673 17.4237 173.838 11.7928 168.207C6.16192 162.576 2.32723 155.402 0.773663 147.592C-0.779899 139.781 0.0174454 131.686 3.06487 124.329C6.11229 116.972 11.2729 110.683 17.8942 106.259C24.5154 101.835 32.2999 99.4737 40.2632 99.4737ZM139.737 0C150.415 0 160.656 4.242 168.207 11.7928C175.758 19.3436 180 29.5847 180 40.2632C180 50.9416 175.758 61.1827 168.207 68.7335C160.656 76.2843 150.415 80.5263 139.737 80.5263H99.4737V40.2632C99.4737 29.5847 103.716 19.3436 111.267 11.7928C118.817 4.242 129.058 0 139.737 0ZM99.4737 99.4737H139.737C147.7 99.4737 155.485 101.835 162.106 106.259C168.727 110.683 173.888 116.972 176.935 124.329C179.983 131.686 180.78 139.781 179.226 147.592C177.673 155.402 173.838 162.576 168.207 168.207C162.576 173.838 155.402 177.673 147.592 179.226C139.782 180.78 131.686 179.983 124.329 176.935C116.972 173.888 110.683 168.727 106.259 162.106C101.835 155.485 99.4737 147.7 99.4737 139.737V99.4737Z" fill="#4394D8"/>
  <path d="M214.117 90.9786C214.117 82.9494 215.487 75.6212 218.227 68.9939C220.967 62.3666 224.695 56.6633 229.411 51.884C234.126 47.1685 239.67 43.5044 246.043 40.8917C252.351 38.279 259.138 36.9727 266.403 36.9727C273.603 36.9727 280.39 38.3109 286.762 40.9873C293.071 43.6637 298.615 47.3915 303.394 52.1708C308.736 52.1708 315.205 52.1708 318.688 52.1708C318.688 59.3651 318.688 83.0769 318.688 90.9786V145.105H292.497V134.183C291.096 135.968 289.407 137.561 287.431 138.963C285.392 140.365 283.226 141.512 280.932 142.404C278.574 143.296 276.152 143.965 273.667 144.411C271.182 144.921 268.76 145.176 266.403 145.176C259.138 145.176 252.351 143.806 246.043 141.066C239.67 138.389 234.126 134.629 229.411 129.786C224.695 124.943 220.967 119.208 218.227 112.581C215.487 106.017 214.117 98.8167 214.117 90.9786ZM240.403 90.9786C240.403 94.9295 241.104 98.5936 242.506 101.971C243.844 105.412 245.692 108.375 248.05 110.86C250.408 113.346 253.18 115.289 256.366 116.691C259.489 118.093 262.834 118.794 266.403 118.794C269.971 118.794 273.348 117.998 276.535 116.404C279.657 114.875 282.397 112.804 284.755 110.191C287.113 107.642 288.961 104.679 290.299 101.302C291.637 97.9883 292.306 94.5472 292.306 90.9786C292.306 87.0278 291.637 83.3636 290.299 79.9863C288.961 76.6089 287.113 73.6776 284.755 71.1924C282.397 68.7072 279.657 66.7317 276.535 65.2661C273.348 63.8641 269.971 63.1632 266.403 63.1632C262.834 63.1632 259.489 63.8641 256.366 65.2661C253.18 66.7317 250.408 68.7072 248.05 71.1924C245.692 73.6776 243.844 76.6089 242.506 79.9863C241.104 83.3636 240.403 87.0278 240.403 90.9786Z" fill="#4394D8"/>
  <path d="M214.117 91.1698C214.117 99.199 215.487 106.527 218.227 113.155C220.967 119.782 224.695 125.485 229.411 130.264C234.126 134.98 239.67 138.644 246.043 141.257C252.351 143.869 259.138 145.176 266.403 145.176C273.603 145.176 280.39 143.838 286.762 141.161C293.071 138.485 298.615 134.757 303.394 129.978C308.736 129.978 315.205 129.978 318.688 129.978C318.688 122.783 318.688 99.0715 318.688 91.1698V37.0439H292.497V47.965C291.096 46.1808 289.407 44.5877 287.431 43.1857C285.392 41.7838 283.226 40.6368 280.932 39.7446C278.574 38.8525 276.152 38.1834 273.667 37.7373C271.182 37.2276 268.76 36.9727 266.403 36.9727C259.138 36.9727 252.351 38.3427 246.043 41.0828C239.67 43.7592 234.126 47.519 229.411 52.362C224.695 57.205 220.967 62.9401 218.227 69.5674C215.487 76.131 214.117 83.3318 214.117 91.1698ZM240.403 91.1698C240.403 87.2189 241.104 83.5548 242.506 80.1774C243.844 76.7363 245.692 73.7732 248.05 71.2879C250.408 68.8027 253.18 66.8591 256.366 65.4572C259.489 64.0553 262.834 63.3543 266.403 63.3543C269.971 63.3543 273.348 64.1509 276.535 65.744C279.657 67.2733 282.397 69.3444 284.755 71.9571C287.113 74.506 288.961 77.4692 290.299 80.8465C291.637 84.1602 292.306 87.6013 292.306 91.1698C292.306 95.1207 291.637 98.7848 290.299 102.162C288.961 105.54 287.113 108.471 284.755 110.956C282.397 113.441 279.657 115.417 276.535 116.882C273.348 118.284 269.971 118.985 266.403 118.985C262.834 118.985 259.489 118.284 256.366 116.882C253.18 115.417 250.408 113.441 248.05 110.956C245.692 108.471 243.844 105.54 242.506 102.162C241.104 98.7848 240.403 95.1207 240.403 91.1698Z" fill="#4394D8"/>
  <path d="M435.216 90.9789C435.216 82.9497 433.846 75.6215 431.106 68.9942C428.366 62.3669 424.638 56.6636 419.922 51.8843C415.207 47.1688 409.663 43.5046 403.29 40.892C396.982 38.2793 390.195 36.9729 382.93 36.9729C375.73 36.9729 368.943 38.3111 362.571 40.9875C356.262 43.6639 350.718 47.3918 345.939 52.1711C340.597 52.1711 334.128 52.1711 330.645 52.1711C330.645 59.3653 330.645 83.0772 330.645 90.9789V179.044H356.836V134.184C358.237 135.968 359.926 137.561 361.902 138.963C363.941 140.365 366.107 141.512 368.401 142.404C370.759 143.296 373.181 143.965 375.666 144.411C378.151 144.921 380.573 145.176 382.93 145.176C390.195 145.176 396.982 143.806 403.29 141.066C409.663 138.389 415.207 134.63 419.922 129.787C424.638 124.944 428.366 119.209 431.106 112.581C433.846 106.018 435.216 98.8169 435.216 90.9789ZM408.93 90.9789C408.93 94.9298 408.229 98.5939 406.827 101.971C405.489 105.412 403.641 108.376 401.283 110.861C398.925 113.346 396.153 115.29 392.967 116.691C389.845 118.093 386.499 118.794 382.93 118.794C379.362 118.794 375.985 117.998 372.798 116.405C369.676 114.875 366.936 112.804 364.578 110.192C362.22 107.643 360.372 104.68 359.034 101.302C357.696 97.9885 357.027 94.5474 357.027 90.9789C357.027 87.028 357.696 83.3639 359.034 79.9865C360.372 76.6092 362.22 73.6779 364.578 71.1927C366.936 68.7074 369.676 66.732 372.798 65.2663C375.985 63.8644 379.362 63.1635 382.93 63.1635C386.499 63.1635 389.845 63.8644 392.967 65.2663C396.153 66.732 398.925 68.7074 401.283 71.1927C403.641 73.6779 405.489 76.6092 406.827 79.9865C408.229 83.3639 408.93 87.028 408.93 90.9789Z" fill="#4394D8"/>
  <path d="M435.216 91.1698C435.216 99.199 433.846 106.527 431.106 113.155C428.366 119.782 424.638 125.485 419.922 130.264C415.207 134.98 409.663 138.644 403.29 141.257C396.982 143.869 390.195 145.176 382.93 145.176C375.73 145.176 368.943 143.838 362.571 141.161C356.262 138.485 350.718 134.757 345.939 129.978C340.597 129.978 334.128 129.978 330.645 129.978C330.645 122.783 330.645 99.0715 330.645 91.1698V37.0439H356.836V47.965C358.237 46.1808 359.926 44.5877 361.902 43.1857C363.941 41.7838 366.107 40.6368 368.401 39.7446C370.759 38.8525 373.181 38.1834 375.666 37.7373C378.151 37.2276 380.573 36.9727 382.93 36.9727C390.195 36.9727 396.982 38.3427 403.29 41.0828C409.663 43.7592 415.207 47.519 419.922 52.362C424.638 57.205 428.366 62.9401 431.106 69.5674C433.846 76.131 435.216 83.3318 435.216 91.1698ZM408.93 91.1698C408.93 87.2189 408.229 83.5548 406.827 80.1774C405.489 76.7363 403.641 73.7732 401.283 71.2879C398.925 68.8027 396.153 66.8591 392.967 65.4572C389.844 64.0553 386.499 63.3543 382.93 63.3543C379.362 63.3543 375.985 64.1509 372.798 65.744C369.676 67.2733 366.936 69.3444 364.578 71.9571C362.22 74.506 360.372 77.4692 359.034 80.8465C357.696 84.1602 357.027 87.6013 357.027 91.1698C357.027 95.1207 357.696 98.7848 359.034 102.162C360.372 105.54 362.22 108.471 364.578 110.956C366.936 113.441 369.676 115.417 372.798 116.882C375.985 118.284 379.362 118.985 382.93 118.985C386.499 118.985 389.844 118.284 392.967 116.882C396.153 115.417 398.925 113.441 401.283 110.956C403.641 108.471 405.489 105.54 406.827 102.162C408.229 98.7848 408.93 95.1207 408.93 91.1698Z" fill="#4394D8"/>
  <path d="M551.585 90.979C551.585 82.9498 550.215 75.6216 547.475 68.9943C544.735 62.367 541.007 56.6637 536.291 51.8844C531.576 47.1689 526.032 43.5048 519.659 40.8921C513.351 38.2794 506.564 36.9731 499.3 36.9731C492.099 36.9731 485.312 38.3112 478.94 40.9877C472.631 43.6641 467.087 47.3919 462.308 52.1712C456.966 52.1712 450.497 52.1712 447.014 52.1712C447.014 59.3655 447.014 83.0773 447.014 90.979L447.014 179.044H473.204L473.205 134.184C474.607 135.968 476.295 137.561 478.271 138.963C480.31 140.365 482.476 141.512 484.77 142.404C487.128 143.296 489.55 143.965 492.035 144.411C494.52 144.921 496.942 145.176 499.3 145.176C506.564 145.176 513.351 143.806 519.659 141.066C526.032 138.39 531.576 134.63 536.291 129.787C541.007 124.944 544.735 119.209 547.475 112.581C550.215 106.018 551.585 98.8171 551.585 90.979ZM525.299 90.979C525.299 94.9299 524.598 98.594 523.196 101.971C521.858 105.412 520.01 108.376 517.652 110.861C515.294 113.346 512.522 115.29 509.336 116.692C506.214 118.094 502.868 118.795 499.3 118.795C495.731 118.795 492.354 117.998 489.167 116.405C486.045 114.875 483.305 112.804 480.947 110.192C478.589 107.643 476.741 104.68 475.403 101.302C474.065 97.9887 473.396 94.5476 473.396 90.979C473.396 87.0282 474.065 83.364 475.403 79.9867C476.741 76.6093 478.589 73.678 480.947 71.1928C483.305 68.7076 486.045 66.7321 489.167 65.2665C492.354 63.8645 495.731 63.1636 499.3 63.1636C502.868 63.1636 506.214 63.8645 509.336 65.2665C512.522 66.7321 515.294 68.7076 517.652 71.1928C520.01 73.678 521.858 76.6093 523.196 79.9867C524.598 83.364 525.299 87.0282 525.299 90.979Z" fill="#4394D8"/>
  <path d="M551.585 91.1693C551.585 99.1985 550.215 106.527 547.475 113.154C544.734 119.781 541.007 125.485 536.291 130.264C531.576 134.979 526.032 138.644 519.659 141.256C513.35 143.869 506.564 145.175 499.299 145.175C492.099 145.175 485.312 143.837 478.94 141.161C472.631 138.484 467.087 134.756 462.308 129.977C456.966 129.977 450.497 129.977 447.014 129.977C447.014 122.783 447.014 99.0711 447.014 91.1693V37.0434H473.204V47.9645C474.606 46.1803 476.295 44.5872 478.27 43.1852C480.31 41.7833 482.476 40.6363 484.77 39.7442C487.128 38.852 489.55 38.1829 492.035 37.7369C494.52 37.2271 496.942 36.9722 499.299 36.9722C506.564 36.9722 513.35 38.3422 519.659 41.0824C526.032 43.7588 531.576 47.5185 536.291 52.3615C541.007 57.2045 544.734 62.9396 547.475 69.5669C550.215 76.1305 551.585 83.3313 551.585 91.1693ZM525.299 91.1693C525.299 87.2184 524.598 83.5543 523.196 80.1769C521.858 76.7359 520.01 73.7727 517.652 71.2875C515.294 68.8022 512.522 66.8587 509.336 65.4567C506.213 64.0548 502.868 63.3538 499.299 63.3538C495.731 63.3538 492.353 64.1504 489.167 65.7435C486.045 67.2729 483.305 69.3439 480.947 71.9566C478.589 74.5055 476.741 77.4687 475.403 80.846C474.065 84.1597 473.396 87.6008 473.396 91.1693C473.396 95.1202 474.065 98.7843 475.403 102.162C476.741 105.539 478.589 108.47 480.947 110.956C483.305 113.441 486.045 115.416 489.167 116.882C492.353 118.284 495.731 118.985 499.299 118.985C502.868 118.985 506.213 118.284 509.336 116.882C512.522 115.416 515.294 113.441 517.652 110.956C520.01 108.47 521.858 105.539 523.196 102.162C524.598 98.7843 525.299 95.1202 525.299 91.1693Z" fill="#4394D8"/>
  <path d="M675.798 145.145H649.427V46.5339H675.798V145.145Z" fill="#084B83"/>
  <path d="M798.638 94.9282C798.638 102.983 797.263 110.335 794.514 116.984C791.765 123.632 788.026 129.354 783.295 134.149C778.564 138.879 773.002 142.555 766.609 145.176C760.28 147.798 753.472 149.108 746.184 149.108C738.96 149.108 732.152 147.766 725.759 145.081C719.43 142.396 713.868 138.656 709.073 133.861C704.342 129.066 700.603 123.377 697.854 116.792C695.105 110.143 693.73 102.855 693.73 94.9282V5.68327H720.005V51.5843C721.411 49.7943 723.106 48.1961 725.087 46.7897C727.133 45.3832 729.307 44.2325 731.608 43.3375C733.973 42.4425 736.403 41.7712 738.896 41.3237C741.389 40.8123 743.819 40.5566 746.184 40.5566C753.472 40.5566 760.28 41.931 766.609 44.68C773.002 47.365 778.564 51.1368 783.295 55.9954C788.026 60.8541 791.765 66.6077 794.514 73.2563C797.263 79.841 798.638 87.065 798.638 94.9282ZM772.267 94.9282C772.267 90.9646 771.564 87.2887 770.157 83.9005C768.815 80.4483 766.961 77.4756 764.596 74.9824C762.23 72.4891 759.449 70.5393 756.253 69.1329C753.12 67.7264 749.764 67.0232 746.184 67.0232C742.604 67.0232 739.216 67.8223 736.019 69.4205C732.887 70.9548 730.138 73.0325 727.772 75.6536C725.407 78.2108 723.553 81.1835 722.211 84.5717C720.868 87.896 720.197 91.3482 720.197 94.9282C720.197 98.8918 720.868 102.568 722.211 105.956C723.553 109.344 725.407 112.285 727.772 114.778C730.138 117.271 732.887 119.253 736.019 120.724C739.216 122.13 742.604 122.833 746.184 122.833C749.764 122.833 753.12 122.13 756.253 120.724C759.449 119.253 762.23 117.271 764.596 114.778C766.961 112.285 768.815 109.344 770.157 105.956C771.564 102.568 772.267 98.8918 772.267 94.9282Z" fill="#084B83"/>
  <path d="M839.393 145.145H813.214V40.3968H819.543H830.73C830.73 44.9038 837.89 44.9037 841.982 42.858C846.073 40.8123 853.489 40.5885 859.243 40.5885H882.353V66.6716H859.243C856.494 66.6716 853.904 67.183 851.475 68.2059C849.046 69.2288 846.936 70.6352 845.146 72.4252C843.356 74.2152 841.95 76.3249 840.927 78.7542C839.904 81.1835 839.393 83.7726 839.393 86.5216V145.145Z" fill="#084B83"/>
  <path d="M594.736 145.049H568.557L568.557 77.8912L568.557 51.8081L568.557 46.9175C568.557 40.5885 569.74 34.6431 572.105 29.0813C574.535 23.5195 577.827 18.6928 581.982 14.6014C586.202 10.446 591.092 7.1856 596.654 4.82023C602.216 2.39092 608.161 1.17627 614.49 1.17627H634.148V27.2593H614.49C611.677 27.2593 609.056 27.7708 606.627 28.7936C604.262 29.7526 602.184 31.127 600.394 32.9171C598.668 34.6431 597.293 36.7208 596.27 39.1501C595.248 41.5155 594.736 44.1046 594.736 46.9175V51.8081H626.861V77.8912H594.736L594.736 145.049Z" fill="#084B83"/>
  <path d="M679.027 25.4373C679.986 23.3277 680.465 21.0582 680.465 18.6289C680.465 16.2635 679.986 14.026 679.027 11.9164C678.132 9.74276 676.885 7.88882 675.287 6.35452C673.689 4.7563 671.803 3.50968 669.629 2.61467C667.52 1.65574 665.25 1.17627 662.821 1.17627C660.392 1.17627 658.09 1.65574 655.917 2.61467C653.807 3.50968 651.953 4.7563 650.355 6.35452C648.821 7.88882 647.574 9.74276 646.615 11.9164C645.72 14.026 645.272 16.2635 645.272 18.6289C645.272 21.0582 645.72 23.3277 646.615 25.4373C647.574 27.547 648.821 29.401 650.355 30.9992C651.953 32.5335 653.807 33.7801 655.917 34.739C658.09 35.634 660.392 36.0815 662.821 36.0815C665.25 36.0815 667.52 35.634 669.629 34.739C671.803 33.7801 673.689 32.5335 675.287 30.9992C676.885 29.401 678.132 27.547 679.027 25.4373Z" fill="#F34819"/>
  </g>
  <defs>
  <clipPath id="clip0_7534_140688">
  <rect width="883" height="180" fill="white"/>
  </clipPath>
  </defs>
  <script xmlns=""/></svg>`;

  function getTextSize(txt: string, font: string) {
    const element = document.createElement('canvas');
    const context = element.getContext('2d');
    context!.font = font;
    return context!.measureText(txt).width;
  }

  const label = (text: string) => {
    const textLength = getTextSize(text, '10px Helvetica');

    const margin = [4, 4];
    const backgroundWidth = textLength + margin[0] + margin[0];
    const marginTextLeft = backgroundWidth - margin[0];

    const colors: { [x: string]: string } = {
      PAID: '#2EC1AC',
      PENDING: '#CB444A',
      EXPIRED: '#808080',
    };

    return {
      columns: [
        {
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: backgroundWidth,
              h: 16,
              r: 2,
              color: colors[invoice.status],
            },
          ],
        },
        {
          text: text,
          color: '#ffffff',
          fontSize: 10,
          margin: [-marginTextLeft, 2, 0, 0],
        },
      ],
    };
  };

  return [
    {
      columns: [
        [
          {
            svg: appfibricon,
            height: 44,
            width: 200,
            margin: [0, 8, 0, 0],
          },
        ],
        [
          {
            text: typography['title'],
            alignment: 'right',
            color: '#444444',
          },
          {
            text: invoice.id,
            alignment: 'right',
            color: '#0B5EFF',
          },
          [
            {
              margin: [0, 12, 0, 0],
              columns: [
                {
                  text: typography['invoiceDate'],
                  alignment: 'right',
                  color: '#444444',
                },
                {
                  width: 75,
                  text: formatDate(
                    invoice.created_at,
                    'dd MMM yyyy',
                    'en-US'
                  ),
                  alignment: 'right',
                  color: '#8A8A8A',
                },
              ],
            },
            {
              columns: [
                {
                  text: typography['payBefore'],
                  alignment: 'right',
                  color: '#444444',
                },
                {
                  width: 110,
                  text: invoice.expired_at ? formatDate(
                    invoice.expired_at,
                    'dd MMM yyyy HH:mm',
                    'en-US'
                  ): '',
                  alignment: 'right',
                  color: '#8A8A8A',
                },
              ],
            },
          ],
        ],
      ],
    },
    {
      margin: [0, 18, 0, 0],
      layout: 'noBorders',
      table: {
        widths: ['*', '*'],
        body: [
          [
            {
              text: typography['billingTo'],
              fillColor: '#E5EFF9',
              margin: [18, 10, 18, 4],
            },
            {
              text: typography['status'],
              fillColor: '#E5EFF9',
              margin: [18, 10, 18, 4],
            },
          ],
          [
            {
              text: invoice.user,
              fillColor: '#E5EFF9',
              margin: [18, 6, 18, 4],
            },
            {
              fillColor: '#E5EFF9',
              margin: [18, 4, 18, 8],
              columns: [
                {
                  layout: 'noBorders',
                  table: {
                    body: [[label(typography['invoice']['status'])]],
                  },
                },
              ],
            },
          ],
        ],
      },
    },
    {
      margin: [0, 24, 0, 0],
      table: {
        widths: ['*', 80, 80, 80],
        headerRows: 1,
        body: [
          [
            {
              border: [0, 0.4, 0, 0.4],
              borderColor: ['#EAECEF', '#EAECEF', '#EAECEF', '#EAECEF'],
              margin: [0, 8, 0, 8],
              text: typography['invoice']['item'],
              bold: true,
            },
            {
              border: [0, 0.4, 0, 0.4],
              borderColor: ['#EAECEF', '#EAECEF', '#EAECEF', '#EAECEF'],
              margin: [0, 8, 0, 8],
              text: typography['invoice']['cost'],
              bold: true,
            },
            {
              border: [0, 0.4, 0, 0.4],
              borderColor: ['#EAECEF', '#EAECEF', '#EAECEF', '#EAECEF'],
              margin: [0, 8, 0, 8],
              text: typography['invoice']['qty'],
              bold: true,
            },
            {
              border: [0, 0.4, 0, 0.4],
              borderColor: ['#EAECEF', '#EAECEF', '#EAECEF', '#EAECEF'],
              margin: [0, 8, 0, 8],
              text: typography['invoice']['price'],
              bold: true,
            },
          ],
          [
            {
              border: [0, 0.4, 0, 0.4],
              borderColor: ['#EAECEF', '#EAECEF', '#EAECEF', '#EAECEF'],
              margin: [0, 8, 0, 8],
              columns: [
                [
                  {
                    text: typography['project'],
                  },
                  {
                    margin: [12, 2, 0, 0],
                    columns: [
                      [
                        {
                          text: typography['selected'],
                          fontSize: 11,
                          color: '#444444',
                        },
                        {
                          margin: [0, 4, 0, 0],
                          text: invoice.status === 'PAID' ? `${typography['prountil']}: ${formatDate(invoice.pro_plan_until, "dd/MM/yyyy", "en-US")}` :  `${typography['prountil']}:-`,
                          fontSize: 10,
                          color: '#888888',
                        },
                      ],
                    ],
                  },
                ],
              ],
            },
            {
              border: [0, 0.4, 0, 0.4],
              borderColor: ['#EAECEF', '#EAECEF', '#EAECEF', '#EAECEF'],
              margin: [0, 24, 0, 8],
              text: formatCurrency(
                invoice.subTotal,
                'en-US',
                'Rp ',
                'IDR',
                '0.0-0'
              ).toString(),
              color: '#444444',
            },
            {
              border: [0, 0.4, 0, 0.4],
              borderColor: ['#EAECEF', '#EAECEF', '#EAECEF', '#EAECEF'],
              margin: [0, 24, 0, 8],
              text: '1',
              color: '#444444',
            },
            {
              border: [0, 0.4, 0, 0.4],
              borderColor: ['#EAECEF', '#EAECEF', '#EAECEF', '#EAECEF'],
              margin: [0, 24, 0, 8],
              alignment: 'right',
              text: formatCurrency(
                invoice.subTotal,
                'en-US',
                'Rp ',
                'IDR',
                '0.0-0'
              ).toString(),
              color: '#444444',
            },
          ],
          [
            {
              border: [0, 0, 0, 0],
              text: '',
            },
            {
              border: [0, 0, 0, 0],
              margin: [8, 24, 0, 2],
              text: typography['subTotal'],
              color: '#444444',
            },
            {
              border: [0, 0, 0, 0],
              text: '',
            },
            {
              border: [0, 0, 0, 0],
              margin: [0, 24, 0, 2],
              alignment: 'right',
              text: formatCurrency(
                invoice.subTotal,
                'en-US',
                'Rp ',
                'IDR',
                '0.0-0'
              ).toString(),
              color: '#444444',
            },
          ],
          [
            {
              border: [0, 0, 0, 0],
              text: '',
            },
            {
              border: [0, 0, 0, 0],
              margin: [8, 2, 0, 2],
              text: typography['tax'],
              color: '#444444',
            },
            {
              border: [0, 0, 0, 0],
              text: '',
            },
            {
              border: [0, 0, 0, 0],
              margin: [0, 2, 0, 2],
              alignment: 'right',
              text: formatCurrency(
                invoice.tax,
                'en-US',
                'Rp ',
                'IDR',
                '0.0-0'
              ).toString(),
              color: '#444444',
            },
          ],
          [
            {
              border: [0, 0, 0, 0],
              text: '',
            },
            {
              border: [0, 0, 0, 0],
              margin: [8, 2, 0, 14],
              text: typography['discount'],
              color: '#444444',
            },
            {
              border: [0, 0, 0, 0],
              text: '',
            },
            {
              border: [0, 0, 0, 0],
              margin: [0, 2, 0, 14],
              alignment: 'right',
              text: formatCurrency(
                invoice.discount,
                'en-US',
                'Rp ',
                'IDR',
                '0.0-0'
              ).toString(),
              color: '#444444',
            },
          ],
          [
            {
              border: [0, 0, 0, 0],
              text: '',
            },
            {
              fillColor: '#E5EFF9',
              border: [0, 0, 0, 0],
              margin: [8, 10, 0, 10],
              text: typography['total'],
              color: '#444444',
            },
            {
              border: [0, 0, 0, 0],
              text: '',
              fillColor: '#E5EFF9',
            },
            {
              fillColor: '#E5EFF9',
              border: [0, 0, 0, 0],
              margin: [0, 10, 0, 10],
              alignment: 'right',
              text: formatCurrency(
                invoice.amount,
                'en-US',
                'Rp ',
                'IDR',
                '0.0-0'
              ).toString(),
              color: '#444444',
            },
          ],
        ],
      },
    },
  ];
}
