import {Component, model, signal} from "@angular/core";
import {CommonModule} from "@angular/common";
import {OrderListModule} from "primeng/orderlist";
import {CardModule} from "primeng/card";
import {ButtonModule} from "primeng/button";
import {FibrPageComponent} from "../../../../page-component/page-component.component";
import {
  FibrComponentFormTypesModule
} from "../../../../page-component-forms/page-component-form-types/page-component-form-type.module";
import {SectionItem} from "@shared/libs/generators/widgets/widget-builder.model";
import {FibrSectionPageComponent} from "../section-page/section-page.component";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'fibr-section-list',
  templateUrl: './section-list.component.html',
  standalone: true,
  imports: [
    CommonModule,
    OrderListModule,
    CardModule,
    ButtonModule,
    FibrPageComponent,
    FibrComponentFormTypesModule,
    FibrSectionPageComponent,
    TranslateModule
  ]
})
export class FibrSectionListComponent {
  utils = signal<{ visible: boolean; selected: SectionItem | null }>({visible: false, selected: null})
  sections = model<SectionItem[]>([])

  addSection() {
    this.utils.set({visible: true, selected: null})
  }

  openSection(item: SectionItem) {
    this.utils.set({visible: true, selected: item});
  }

  deleteSection(item: SectionItem) {
    this.sections.update((val) => {
      return val.filter((x) => x.id !== item.id);
    })
  }

  editOrAddFormItem(section: SectionItem) {
    const exist = this.sections().findIndex((x) => x.id === section.id);
    this.sections.update((val) => {
      if (exist !== -1) {
        val[exist] = section;
        return val;
      }
      return val.concat([section]);
    })
    this.utils.set({visible: false, selected: null});
  }

  cancelAddOrEditFormItem() {
    this.utils.set({visible: false, selected: null});
  }
}
