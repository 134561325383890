import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  forwardRef,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MissingImageDirective } from '@shared/directive/image-missing/image-missing.directive';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'fibr-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss'],
  standalone: true,
  imports: [ButtonModule, MissingImageDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FibrImagePickerComponent),
      multi: true,
    },
  ],
})
export class FibrImagePickerComponent implements ControlValueAccessor {
  @Input() defaultImageUrl: string = '/assets/images/image-error.png';
  @Input() accept = 'image/*';

  @Output() didRemove = new EventEmitter();

  currentFiles = signal<FileList | null>(null);
  previewImageUrl = signal<string | ArrayBuffer | null>(null);

  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention, no-unused-vars
  private _onChange = (_fileList: FileList) => {};
  private _onTouched = () => {};

  writeValue(obj: FileList | string): void {
    if (typeof obj === 'string') {
      this.previewImageUrl.set(obj);
    } else {
      this.currentFiles.set(obj);
    }
  }

  registerOnChange(fn: () => void): void {
    // eslint-disable-next-line functional/immutable-data
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    // eslint-disable-next-line functional/immutable-data
    this._onTouched = fn;
  }

  private previewImage(file: File): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    // eslint-disable-next-line functional/immutable-data
    reader.onload = () => {
      this.previewImageUrl.set(reader.result);
    };
  }

  onChange(event: Event) {
    const currentTarget = event.currentTarget as HTMLInputElement;
    this.currentFiles.set(currentTarget.files);

    if (currentTarget.files?.length) {
      this._onChange(currentTarget.files);
      this.previewImage(currentTarget.files[0]);
    }

    // eslint-disable-next-line functional/immutable-data
    this.input.nativeElement.value = '';
  }

  click() {
    this.input.nativeElement.click();
  }

  remove() {
    this.currentFiles.set(null);
    this.previewImageUrl.set(null);
    this.didRemove.emit();
  }
}
