<div class="grid">
  <div class="lg:col-8 col-12">
    <div>
      <h4 class="text-primary font-light">
        {{ "PROJECT.app_settings.menus.app_identity" | translate }}
      </h4>
    </div>

    <form class="mt-3 flex flex-column gap-3" (ngSubmit)="onSubmit()">
      <div>
        <label class="text-base text-gray-primary" for="app_name">
          {{ "PROJECT.app_settings.form.app_name" | translate }}
        </label>
        <input
          class="mt-1"
          id="app_name"
          name="app_name"
          placeholder="Type app name"
          pInputText
          [(ngModel)]="identity().app_name"
        />
      </div>

      <div>
        <label class="text-base text-gray-primary" for="app_description">
          {{ "PROJECT.app_settings.form.app_description" | translate }}
        </label>
        <textarea
          class="mt-1"
          pInputTextarea
          id="app_description"
          name="app_description"
          placeholder="Type description app"
          [(ngModel)]="identity().app_description"
        ></textarea>
      </div>

      <div>
        <label class="text-base text-gray-primary" for="">Icon</label>
        <fibr-image-picker
          class="mt-1"
          [defaultImageUrl]="'/assets/images/icon/favicon.png'"
          name="app_icon"
          accept=".png,.jpg"
          [ngModel]="identity().app_icon"
          (ngModelChange)="chooseIcon($event)"
          (didRemove)="removeIcon()"
        ></fibr-image-picker>
      </div>

      <div class="mt-4">
        <div class="mb-2">
          <h4 class="font-medium text-[#F34819]">
            {{ "PROJECT.app_settings.form.delete_title" | translate }}
          </h4>
          <p>
            {{ "PROJECT.app_settings.form.delete_description" | translate }}
          </p>
        </div>

        <p-button
          icon="pi pi-trash"
          severity="danger"
          label="{{ 'PROJECT.app_settings.form.delete_button' | translate }}"
          (onClick)="confirmDeleteProject()"
        ></p-button>
      </div>

      <p-divider></p-divider>

      <div class="flex justify-content-end gap-2">
        <p-button
          label="{{ 'COMMON.CANCEL' | translate }}"
          severity="secondary"
          type="button"
          [routerLink]="['/', 'projects', projectId]"
        >
        </p-button>
        <p-button
          label="{{ 'COMMON.SAVE' | translate }}"
          iconPos="right"
          icon="pi pi-save"
          type="submit"
          [loading]="isLoading"
        ></p-button>
      </div>
    </form>
  </div>

  <div class="lg:col-4 col-12 flex flex-column align-items-center">
    <h4 class="text-primary text-center">Preview</h4>

    <div class="iphone">
      <div class="content relative">
        <img src="/assets/images/mobile_preview.png" alt="mobile-preview" />

        <div
          class="absolute inset-0 flex align-items-center justify-content-center"
          style="background: rgba(0, 0, 0, 0.4)"
        >
          <div class="w-10/12 bg-white rounded-xl">
            <div
              class="flex align-items-center justify-content-between gap-3 pl-3 pr-3 py-3 border-b border-[#D4DBEA]"
            >
              <div class="flex align-items-center gap-2">
                <i class="text-lg pi pi-exclamation-circle"></i>
                <span class="text-lg font-semibold">App Info</span>
              </div>

              <i class="text-lg text-primary pi pi-times"></i>
            </div>

            <div class="px-3 py-4">
              <div
                class="w-full flex flex-row align-items-start gap-3 px-2 py-2 border border-[#D4DBEA] rounded-lg"
              >
                <img
                  class="w-14 h-14 object-contain rounded-md"
                  [src]="appIconUrl()"
                  alt="logo"
                  fibrMissingImage
                  errorImageUrl="/assets/images/icon/favicon.png"
                />

                <div>
                  <h4 class="text-base font-semibold my-0">
                    {{ this.identity().app_name }}
                  </h4>
                  <p class="text-sm font-light my-0 text-[#9C9C9C]">
                    {{ this.identity().app_description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<fibr-block-ui [isBlocked]="isLoading"></fibr-block-ui>
