import { Component, OnInit } from '@angular/core';
import { AuthStoreService } from '../../../../features/auth/store/auth-store.service';

@Component({
  selector: 'fibr-welcome',
  standalone: true,
  imports: [],
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss',
})
export class WelcomeComponent implements OnInit {
  partnerName = '';

  constructor(private authStore: AuthStoreService) {}

  ngOnInit(): void {
    this.partnerName = this.authStore.user()?.displayName ?? '';
  }
}
