import {FormType} from "@shared/libs/generators/widgets/widget-builder.model";

export const convertTimePicker = (x: FormType): FormType => {
  if (x.type === 'date_picker') {
    if (x.config.date_format?.includes('hh:mm')) {
      return {
        ...x,
        type: "time_picker" as FormType['type']
      }
    }
    return x;
  }
  return x;
}

export const revertTimePicker = (x: FormType): FormType => {
  if (x.type === 'time_picker') {
    if (x.config.date_format?.includes('hh:mm')) {
      return {
        ...x,
        type: "date_picker" as FormType['type']
      }
    }
    return x;
  }
  return x;
}
