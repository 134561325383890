import { Component } from '@angular/core';

@Component({
  selector: 'fibr-custom-page-icon',
  standalone: true,
  imports: [],
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="36"
      viewBox="0 0 34 36"
      fill="none"
    >
      <path
        d="M24.5786 24.6587C24.2798 24.6587 23.9932 24.7774 23.782 24.9886C23.5707 25.1999 23.452 25.4865 23.452 25.7853V31.0634C23.4514 31.4561 23.2951 31.8326 23.0174 32.1103C22.7397 32.388 22.3632 32.5443 21.9705 32.5449H4.65461C4.26188 32.5443 3.88541 32.388 3.6077 32.1103C3.33 31.8326 3.17372 31.4561 3.17313 31.0634V4.73469C3.17372 4.34196 3.33 3.96548 3.6077 3.68778C3.88541 3.41008 4.26188 3.2538 4.65461 3.25321H21.9705C22.3632 3.2538 22.7397 3.41008 23.0174 3.68778C23.2951 3.96548 23.4514 4.34196 23.452 4.73469V7.75962C23.452 8.05841 23.5707 8.34497 23.782 8.55625C23.9932 8.76753 24.2798 8.88622 24.5786 8.88622C24.8774 8.88622 25.1639 8.76753 25.3752 8.55625C25.5865 8.34497 25.7052 8.05841 25.7052 7.75962V4.73469C25.704 3.74455 25.3101 2.79531 24.61 2.09518C23.9099 1.39505 22.9606 1.00119 21.9705 1H4.65461C3.66448 1.00119 2.71524 1.39505 2.0151 2.09518C1.31497 2.79531 0.921114 3.74455 0.919922 4.73469V31.0634C0.921114 32.0535 1.31497 33.0028 2.0151 33.7029C2.71524 34.403 3.66448 34.7969 4.65461 34.7981H21.9705C22.9606 34.7969 23.9099 34.403 24.61 33.7029C25.3101 33.0028 25.704 32.0535 25.7052 31.0634V25.7853C25.7052 25.4865 25.5865 25.1999 25.3752 24.9886C25.1639 24.7774 24.8774 24.6587 24.5786 24.6587Z"
        fill="#8A8A8A"
        stroke="#8A8A8A"
        stroke-width="0.5"
      />
      <path
        d="M11.0602 4.37891C10.7614 4.37891 10.4748 4.4976 10.2636 4.70888C10.0523 4.92016 9.93359 5.20672 9.93359 5.50551C9.93359 5.8043 10.0523 6.09086 10.2636 6.30214C10.4748 6.51342 10.7614 6.63211 11.0602 6.63211H15.5666C15.8654 6.63211 16.152 6.51342 16.3632 6.30214C16.5745 6.09086 16.6932 5.8043 16.6932 5.50551C16.6932 5.20672 16.5745 4.92016 16.3632 4.70888C16.152 4.4976 15.8654 4.37891 15.5666 4.37891H11.0602Z"
        fill="#8A8A8A"
        stroke="#8A8A8A"
        stroke-width="0.5"
      />
      <path
        d="M33.2613 12.596L28.7548 8.08962C28.5436 7.87841 28.2571 7.75977 27.9583 7.75977C27.6596 7.75977 27.3731 7.87841 27.1618 8.08962L13.6426 21.6089C13.4313 21.8201 13.3126 22.1066 13.3125 22.4054V26.9118C13.3125 27.2106 13.4312 27.4971 13.6425 27.7084C13.8538 27.9197 14.1403 28.0384 14.4391 28.0384H18.9455C19.2443 28.0383 19.5308 27.9196 19.742 27.7083L33.2613 14.189C33.4725 13.9778 33.5911 13.6913 33.5911 13.3925C33.5911 13.0938 33.4725 12.8073 33.2613 12.596ZM18.4791 25.7852H15.5657V22.8718L25.1418 13.2957L28.0552 16.209L18.4791 25.7852ZM29.6482 14.616L26.7348 11.7026L27.9583 10.4791L30.8717 13.3925L29.6482 14.616Z"
        fill="#8A8A8A"
        stroke="#8A8A8A"
        stroke-width="0.5"
      />
    </svg>
  `,
  styles: ``,
})
export class CustomPageIconComponent {}
