import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  signal,
} from '@angular/core';
import {
  DragDropModule,
  CdkDragDrop,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { Nullable } from 'primeng/ts-helpers';
import { PrimeTemplate } from 'primeng/api';
import { ObjectUtils } from 'primeng/utils';

@Component({
  selector: 'fibr-order-list',
  standalone: true,
  imports: [CommonModule, DragDropModule],
  templateUrl: './order-list.component.html',
  styleUrl: './order-list.component.scss',
})
export class OrderListComponent implements AfterContentInit {
  @Input()
  set value(value: unknown[]) {
    this.values.set(value);
  }

  @Output() didReorder = new EventEmitter();

  @ContentChildren(PrimeTemplate) templates: Nullable<QueryList<PrimeTemplate>>;

  itemTemplate = signal<TemplateRef<unknown> | null>(null);
  emptyTemplate = signal<TemplateRef<unknown> | null>(null);

  public visibleOptions: Nullable<unknown[]>;
  public filterValue: Nullable<string>;
  public values = signal<unknown[]>([]);

  ngAfterContentInit(): void {
    (this.templates as QueryList<PrimeTemplate>).forEach((item) => {
      switch (item.getType()) {
        case 'item':
          this.itemTemplate.set(item.template);
          break;
        case 'empty':
          this.emptyTemplate.set(item.template);
          break;
        default:
          this.itemTemplate.set(item.template);
          break;
      }
    });
  }

  onDrop(event: CdkDragDrop<string[]>) {
    let previousIndex = event.previousIndex;
    let currentIndex = event.currentIndex;

    if (previousIndex !== currentIndex) {
      if (this.visibleOptions) {
        if (this.filterValue) {
          previousIndex = ObjectUtils.findIndexInList(
            event.item.data,
            this.values()
          );
          currentIndex = ObjectUtils.findIndexInList(
            this.visibleOptions[currentIndex],
            this.values()
          );
        }

        moveItemInArray(
          this.visibleOptions,
          event.previousIndex,
          event.currentIndex
        );
      }

      moveItemInArray(this.values() as unknown[], previousIndex, currentIndex);
      this.didReorder.emit(this.values());
    }
  }
}
