import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { HeaderV1Component } from '../../../../core/components/header-v1/header-v1.component';
import { FooterComponent } from '../../../../core/components/footer/footer.component';
import { ToolbarModule } from 'primeng/toolbar';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseComponent } from '../../../../core/base/base-component';
import { AvatarModule } from 'primeng/avatar';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { AuthStoreService } from '../../store/auth-store.service';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { takeUntil } from 'rxjs';
import { SettingComponent } from './settings/settings.component';

@Component({
  selector: 'fibr-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderV1Component,
    FooterComponent,
    DividerModule,
    ButtonModule,
    AvatarModule,
    RouterModule,
    UpdateProfileComponent,
    ChangePasswordComponent,
    SettingComponent,
  ],
})
export class AccountPageComponent extends BaseComponent {
  private route = inject(ActivatedRoute);
  private authStore = inject(AuthStoreService);

  // prettier-ignore
  fullName = computed(() => this.authStore.user()?.full_name || this.authStore.user()?.displayName);
  email = computed(() => this.authStore.user()?.email);
  activatePage = 'account';
  listMenus = [
    {
      label: 'Account',
      icon: 'pi pi-user',
      href: '/profile',
      name: 'account',
    },
    {
      label: 'Password',
      icon: 'pi pi-shield',
      href: '/profile',
      name: 'change-password',
    },
    // {
    //   label: 'Settings',
    //   icon: 'pi pi-cog',
    //   href: '/profile',
    //   name: 'settings',
    // },
  ]

  constructor() {
    super('account-page');

    this.route.queryParams.pipe(takeUntil(this.destroy)).subscribe((params) => {
      if (params['menu']) {
        this.activatePage = params['menu'];
      }
    });
  }
}
