<div class="mb-4">
  <h2 class="font-light">Account</h2>
</div>

<form class="flex flex-column gap-3" (ngSubmit)="onSubmit()">
  <div class="flex flex-column gap-4 lg:px-4">
    <div class="grid">
      <div class="col-12 lg:col-8">
        <label for="oldPassword">Old Password</label>
      </div>

      <div class="col-12 lg:col-4">
        <input
          id="oldPassword"
          name="oldPassword"
          type="password"
          pInputText
          placeholder="Type your old password"
          aria-describedby="oldPassword-help"
          [(ngModel)]="formGroup().oldPassword"
        />
        @if (errors['oldPassword']) {
        <small id="oldPassword-help" class="p-error">
          {{ errors["oldPassword"][0] | translate }}
        </small>
        }
      </div>
    </div>

    <div class="grid">
      <div class="col-12 lg:col-8">
        <label for="newPassword">New Password</label>
      </div>

      <div class="col-12 lg:col-4">
        <input
          id="newPassword"
          name="newPassword"
          type="password"
          pInputText
          placeholder="Type your new password"
          aria-describedby="newPassword-help"
          [(ngModel)]="formGroup().newPassword"
        />
        @if (errors['newPassword']) {
        <small id="newPassword-help" class="p-error">
          {{ errors["newPassword"][0] | translate }}
        </small>
        }
      </div>
    </div>

    <div class="grid">
      <div class="col-12 lg:col-8">
        <label for="newPasswordConfirmation">New Password Confirmation</label>
      </div>

      <div class="col-12 lg:col-4">
        <input
          id="newPasswordConfirmation"
          name="newPasswordConfirmation"
          type="password"
          pInputText
          placeholder="Type your confirm new password"
          aria-describedby="newPasswordConfirmation-help"
          [(ngModel)]="formGroup().newPasswordConfirmation"
        />
        @if (errors['newPasswordConfirmation']) {
        <small id="newPasswordConfirmation-help" class="p-error">
          {{ errors["newPasswordConfirmation"][0] | translate }}
        </small>
        }
      </div>
    </div>

    <p-divider></p-divider>

    <div class="flex justify-content-end">
      <p-button
        label="Save"
        iconPos="right"
        icon="pi pi-save"
        type="submit"
        [loading]="isLoading"
      ></p-button>
    </div>
  </div>
</form>
