import { Injectable, inject } from '@angular/core';
import { Timestamp, collection, deleteField, doc, getDoc, updateDoc } from '@angular/fire/firestore';
import { Result, failure, success } from '../../../shared/libs/error';
import { protectedColumnNames, type BuilderColType, type TableColumnFieldType, type TableField, type ColumnField } from '../model';
import { addDocument, deleteAllDocuments, deleteDocument, getAllDocuments, getAllRealtimeDocuments } from '../../../shared/libs/storage/firestore';
import { getLastIndexColumns, columnColTypeToBuilderColType } from '../tools';
import { ProjectsShardService } from '../../../core/projects-shard/projects-shard.service';
import { TableStoreService } from '../store/table-store.service';
import { toSnakeCase } from '@shared/libs/text';
import type { Row } from '@shared/components/table/table.component';
import { AuthStoreService } from '../../auth/store/auth-store.service';

const getInitialTableDoc = (id: string, label: string): TableField => {
  return {
    id,
    label,
    created_at: Timestamp.now(),
    columns: {
      id: {
        index: 0,
        name: 'id',
        label: 'ID',
        type: 'string',
        format: 'text',
      },
      'name': {
        index: 1,
        name: 'name',
        label: 'Name',
        type: 'string',
        format: 'text',
      },
      'created_at': {
        index: 2,
        name: 'created_at',
        label: 'Created At',
        type: 'timestamp',
        format: 'date_time',
      },
      'updated_at': {
        index: 3,
        name: 'updated_at',
        label: 'Updated At',
        type: 'timestamp',
        format: 'date_time',
      },
    }
  }
}

type ModifyRowParams = {
  projectID: string;
  tableID: string;
  row: Row;
}

type ModifyTableParams = {
  projectID: string;
  tableID: string;
  data: Partial<TableField>;
}

type CreateColumnParams = {
  projectID: string;
  tableID: string;
  columnName: string;
  columnType: TableColumnFieldType
}

type DeleteColumnParams = {
  projectID: string;
  tableID: string;
  columnName: keyof TableField['columns'];
}

type EditLabelAndTypeColumnParams = DeleteColumnParams & {
  columnLabel: string;
  columnType: BuilderColType;
}

type SetDisplayColumnsParams = {
  projectID: string;
  tableID: string;
  columns: ColumnField[];
}

@Injectable({
  providedIn: 'root'
})
export class TableAPIService {
  private projectsShard = inject(ProjectsShardService)
  private tableStore = inject(TableStoreService);
  private authStore = inject(AuthStoreService);

  async fetchTableList(projectID: string) {
    const tables = await getAllDocuments<TableField>(this.projectsShard.fireStore, this.getTablePath(projectID))
    const tablesExternal = await getAllDocuments<TableField>(this.projectsShard.fireStore, `/projects/${projectID}/tables_external`)

    const unionTables = [...tables, ...tablesExternal]
    this.tableStore.setTableList({
      isLoading: false,
      data: unionTables
    });
  }

  async fetchTableData(projectID: string, tableID: string, tableExternal: boolean = false) {
    const tablePath = tableExternal ? String(this.getTablePath(projectID, true)) : String(this.getTablePath(projectID));

    return getAllRealtimeDocuments<Row>(this.projectsShard.fireStore, tablePath, tableID+'/data', (tableData) => {
      this.tableStore.setTableDataByTableID(tableID, tableData);
    });
  }

  async createTable(projectID: string, tableName: string): Promise<Result<null, string>> {
    const tableRef = collection(this.projectsShard.fireStore, this.getTablePath(projectID));
    const tableDocRef = doc(tableRef);
    const tableID = tableDocRef.id;
    try {
      await addDocument(this.projectsShard.fireStore, {
        data: getInitialTableDoc(tableID, tableName),
        customID: tableID,
      }, this.getTablePath(projectID));
      await addDocument(this.projectsShard.fireStore, {
        data: {
          created_at: Timestamp.now(),
          updated_at: Timestamp.now(),
        },
      }, this.getTablePath(projectID), tableID, 'data')
      await this.fetchTableList(projectID);
      return success(null);
    } catch (error) {
      return failure('Failed to create table');
    }
  }

  async deleteTable(projectID: string, tableID: string): Promise<Result<unknown, string>> {
    try {
      await deleteDocument(this.projectsShard.fireStore, this.getTablePath(projectID), tableID);
      await deleteAllDocuments(this.projectsShard.fireStore, this.getTablePath(projectID), tableID, 'data');
      await this.fetchTableList(projectID);
      return success(null)
    } catch (error) {
      return failure('Failed to delete table');
    }
  }

  async updateTable({ projectID, tableID, data }: ModifyTableParams): Promise<Result<unknown, string>> {
    try {
      const tableRef = doc(this.projectsShard.fireStore, this.getTablePath(projectID), tableID);
      await updateDoc(tableRef, data);
      return success(null)
    } catch (error) {
      return failure('Failed to update table');
    }
  }

  async addRow({ projectID, tableID, row }: ModifyRowParams) {
    try {
      const id = await addDocument(this.projectsShard.fireStore, {
        data: {
          ...row,
          created_at: Timestamp.now(),
          created_by: this.authStore.user()?.uid,
          updated_at: Timestamp.now(),
          updated_by: this.authStore.user()?.uid,
        },
      }, this.getTablePath(projectID), tableID, 'data');
      return success(id);
    } catch (error) {
      return failure('TABLE.FAILED_ADD_ROW');
    }
  }

  async editRow({ projectID, tableID, row }: ModifyRowParams) {
    try {
      const tableDataCollectionRef = collection(this.projectsShard.fireStore, this.getTablePath(projectID), tableID, 'data')
      const rowRef = doc(tableDataCollectionRef, `${row['id']}`);
      const columnNameList = (Object.values(this.tableStore.tableList().data
        .find((table) => table.id === tableID)?.columns ?? {})).map((column) => column.name);
      delete row['id'];

      const sanitazedRow = Object.keys(row).reduce((acc, key: string) => {
        if (columnNameList.includes(key)) {
          if(row[key] == null){
            acc[key] = deleteField();
          } else {
            acc[key] = row[key];
          }
        }
        return acc;
      }, {} as Row);

      await updateDoc(rowRef, {
        ...sanitazedRow,
        updated_at: Timestamp.now(),
        updated_by: this.authStore.user()?.uid,
      });

      return success(null);
    } catch (error) {
      return failure('TABLE.FAILED_EDIT_ROW');
    }
  }

  async createColumn({ projectID, tableID, columnName, columnType }: CreateColumnParams) {
    const tableDocRef = doc(this.projectsShard.fireStore, `/projects/${projectID}/tables/${tableID}`)
    const tableDocSnap = await getDoc(tableDocRef);
    const columns = Object.keys(tableDocSnap.data()?.['columns']);
    const newColumnName = toSnakeCase(columnName);
    if (columns.includes(newColumnName)) return failure('Column already exists');
    const totalColumns = columns.length;
    const newColumnPath = `columns.${newColumnName}`;

    try {
      await updateDoc(tableDocRef, {
        [newColumnPath]: {
          index: getLastIndexColumns(totalColumns),
          name: newColumnName,
          label: columnName,
          display: true,
          ...columnColTypeToBuilderColType(columnType),
        },
      });
      return success(null);
    } catch (error) {
      return failure('Failed to create column');
    }
  }

  async deleteColumn({ projectID, tableID, columnName }: DeleteColumnParams) {
    if (protectedColumnNames.includes(columnName)) return failure('TABLE.FAILED_DELETE_PROTECTED_COLUMN');
    const tableDocRef = doc(this.projectsShard.fireStore, `/projects/${projectID}/tables/${tableID}`)
    const deletedColumnPath = `columns.${columnName}`;
    try {
      await updateDoc(tableDocRef, {
        [deletedColumnPath]: deleteField(),
      });
      const docSnap = await getAllDocuments<Row>(this.projectsShard.fireStore, this.getTablePath(projectID)+"/"+tableID, 'data')
      docSnap.map((item) => {
        updateDoc(doc(this.projectsShard.fireStore, `/projects/${projectID}/tables/${tableID}/data/${item.id}`), {
          [columnName]: deleteField(),
        });
      });
      return success(null);
    } catch (error) {
      return failure('TABLE.FAILED_DELETE_COLUMN');
    }
  }

  async editLabelAndTypeColumn({ projectID, tableID, columnName, columnLabel, columnType }: EditLabelAndTypeColumnParams) {
    if (protectedColumnNames.includes(columnName)) return failure('TABLE.FAILED_EDIT_PROTECTED_COLUMN');
    const tableDocRef = doc(this.projectsShard.fireStore, this.getTablePath(projectID), tableID)
    const tableDocSnap = await getDoc(tableDocRef);
    const newColumnName = toSnakeCase(columnLabel);
    if (tableDocSnap.data()?.['columns'][newColumnName]){
      if (columnName !== newColumnName){
        if (tableDocSnap.data()?.['columns'][newColumnName].label.toLowerCase() === columnLabel.toLowerCase()) return failure('Column label same with other column label');
      }
    }
    const columnLabelPath = `columns.${columnName}.label`;
    const columnTypelPath = `columns.${columnName}.type`;
    const columnFormatPath = `columns.${columnName}.format`;
    try {
      await updateDoc(tableDocRef, {
        [columnLabelPath]: columnLabel,
        [columnTypelPath]: columnType.type,
        [columnFormatPath]: columnType.format,
      });
      return success(null);
    } catch (error) {
      return failure('TABLE.FAILED_DELETE_COLUMN');
    }
  }

  async setDisplayColumns({ projectID, tableID, columns }: SetDisplayColumnsParams) {
    const tableDocRef = doc(this.projectsShard.fireStore, this.getTablePath(projectID), tableID)
    type ColumnNameDisplayPath = string;
    const columnsDipslayPathAndValue = columns.reduce((acc, column) => ({
      ...acc,
      [`columns.${column.name}.display`]: column.display ?? true,
    }), {} as Record<ColumnNameDisplayPath, boolean>);
    try {
      await updateDoc(tableDocRef, columnsDipslayPathAndValue);
      return success(null);
    } catch (error) {
      return failure('TABLE.FAILED_SET_DISPLAY_COLUMN');
    }
  }

  async deleteRow({ projectID, tableID, row }: ModifyRowParams) {
    deleteDocument(this.projectsShard.fireStore, this.getTablePath(projectID), tableID, 'data', row['id'] as string);
  }

  getTablePath(projectID: string, tableExternal : boolean = false) {
    // disable until use draft mode
    // return `/projects/${projectID}/drafts/default/tables`;

    if (tableExternal) {
      return `/projects/${projectID}/tables_external`;
    }
    
    return `/projects/${projectID}/tables`;
  }
}
