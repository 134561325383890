import {BodyItem} from "@shared/libs/generators/widgets/widget-builder.model";

export * from '../../../shared/libs/generators/widgets/widget-builder.model'
import { array, email, minLength, object, string, } from 'valibot';
type IsoDateString = string;

export type Page = {
  id: string;
  name: string;
  type: string;
  index: number;
  icon: string;
  show_in_nav_bar: boolean;
};

export type EditorStore = {
  selectedPageConfig: Page | null;
  selectedPagePropertyComponent : PagePropertyComponent | null;
  pageList: Page[];
  usersInvited: Invitation[];
  userAccess: UserAccess[];
}

export type PageProperty = {
  pageID?: string;
  pageTitle?: string;
  pageIcon?: string;
  pageType?: string;
  pagePadding?: string;
  accessedRoles?: unknown;
  index: number;
  show_in_nav_bar?: boolean;
}

export type PageType = {
  pageID?: string;
  layoutTemplate?: string;
}

export type SelectionModel = {
  value?: string | undefined;
  label?: string;
  name?: string;
  url?: string;
  type?: string;
  field?: string;
  validations?: string;
  data_type?: string;
  source?: string;

}

export type PagePropertyComponent = {
  data: BodyItem[];
  id: string;
  style?: [];
  table_config?: [];
  title?: string;
  type?: string;
  view_type?: string;
}

export type Invitation = {
  id: string;
  email: string;
  status: string;
  expiration_date?: IsoDateString;
  role: string;
}

export type ActionInvitation = {
  name: string;
  code: string;
}

export type Role = {
  name: string;
  value: string;
}

export type UserAccess = {
  id: string;
  email?: string;
  role?: string;
}

export const PageConfigSchema = object({
  pageTitle: string([minLength(1, 'EDITOR.ACCORDION.FORM.PAGE_TITLE_REQUIRED')]),
  pageType: string([minLength(1, 'EDITOR.ACCORDION.FORM.PAGE_TYPE_REQUIRED')]),
})

export const PageIconConfigSchema = object({
  pageIcon: string([minLength(1, 'EDITOR.ACCORDION.FORM.PAGE_ICON_REQUIRED')]),
})

export const InvitationConfigSchema = object({
  email: array(string([email('EDITOR.FORM.ERROR_MESSAGES.INVALID_EMAIL')]),[
    minLength(1, 'EDITOR.FORM.ERROR_MESSAGES.EMAIL_REQUIRED'),
  ]),
  role : string([minLength(1, 'EDITOR.FORM.ERROR_MESSAGES.ROLE_REQUIRED')]),
})
