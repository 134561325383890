import { CommonModule } from '@angular/common';
import { Component, computed, effect, inject, signal } from '@angular/core';
import { FibrBaseComponentTypeComponent } from '../../../page-component/page-component-types/base-component-type.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { FibrCollapseComponent } from '@shared/components/collapse/collapse.component';
import { TranslateModule } from '@ngx-translate/core';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FibrImagePickerComponent } from '@shared/components/image-picker/image-picker.component';
import { icons } from '@shared/libs/feather-icons/feather-icon';
import { v4 } from 'uuid';
import { EditorStoreService } from '../../../../store/editor-store.service';
import { Button } from '../../../../model';

@Component({
  selector: 'fibr-component-button',
  templateUrl: './page-component-button.component.html',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    TranslateModule,
    InputTextModule,
    CheckboxModule,
    SelectButtonModule,
    FibrImagePickerComponent,
    FibrCollapseComponent,
  ],
})
export class FibrPageComponentButtonComponent extends FibrBaseComponentTypeComponent {
  private editorStore: EditorStoreService = inject(EditorStoreService);

  override label: string = 'Button';
  override icon: string = 'assets/images/icon/details/icon_button.svg';
  override category: string = 'content';
  override type: string = 'button';

  readonly actionTypes = [
    { label: 'Direct to page', value: 'go_to_page' },
    { label: 'Open Link', value: 'open_link' },
    { label: 'Open File', value: 'open_file', disabled: true },
  ];

  readonly contentTypes = [
    { label: 'Static', value: 'static' },
    { label: 'Dynamic', value: 'table', disabled: true },
  ];

  readonly fileActions = [
    { label: 'Download', value: 'download' },
    { label: 'View File', value: 'view_file' },
  ];

  readonly iconPositions = [
    { label: 'Left', value: 'left' },
    { label: 'Right', value: 'right' },
  ];

  readonly buttonTypes = [
    { label: 'Button', value: 'button' },
    { label: 'Link', value: 'link' },
  ];

  readonly buttonWidth = [
    { label: 'Full', value: 'full' },
    { label: 'Auto', value: 'auto' },
  ];

  readonly icons: Array<{ label: string; value: string }>;

  readonly defaultConfig = {
    style: {
      icon: '',
      icon_position: 'left',
      label: '',
      hide_label: false,
      button_type: 'button',
      button_width: 'full',
      with_outline: false,
    },
    config: {
      action: {
        type: '',
        target: '',
      },
    },
  };

  pageList = computed(() => {
    return this.editorStore
      .rawPageList()
      .filter((i) => i.index !== -1)
      .filter((i) => this.editorStore.selectedPageConfig()?.id !== i.id);
  });

  style = signal(this.defaultConfig.style);
  config = signal(this.defaultConfig.config);
  options = signal({
    content_type: 'static',
  });

  constructor() {
    super('fibr-component-button');

    this.icons = icons.map((icon) => ({
      label: icon.replaceAll('-', ' '),
      value: icon,
    }));

    effect(
      () => {
        const selected = this.pageComponentStore.dialogDetail().selected;
        if (selected) {
          if (selected.type !== 'button') {
            this.style.set(this.defaultConfig.style);
            this.config.set(this.defaultConfig.config);
            return;
          }
          const button = selected as Button;
          this.style.set(button.style);
          this.config.set(button.config);
        }
      },
      { allowSignalWrites: true }
    );
  }

  onActionChange() {
    this.config.update((val) => ({
      ...val,
      action: {
        ...val.action,
        target: '',
      },
    }));
  }

  reset() {
    setTimeout(() => {
      this.style.set(this.defaultConfig.style);
      this.config.set(this.defaultConfig.config);
    }, 200);
  }

  override apply(): void {
    const componentId =
      this.pageComponentStore.dialogDetail().selected?.id || v4();
    const output = {
      id: componentId,
      type: 'button',
      name: 'button_' + componentId,
      style: this.style(),
      config: this.config(),
    };

    this.pageComponentStore.addOrEditDetail(output);
    this.reset();
  }

  override cancel(): void {
    this.reset();
  }

  protected override onDestroy(): void {
    this.reset();
  }
}
