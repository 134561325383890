import { v4 } from "uuid";
import { Collection, CollectionStyle, FormType, TableConfig, View } from "../widget-builder.model";
import { convertTimePicker } from "@shared/libs/generators/forms/utils";

type GridBuilderParams = {
  id: string;
  style: CollectionStyle;
  headerTitle: string;
  config: Collection['config'];
  columns: {
    title: string;
    image: string;
    description: string;
    label: string;
  };
  detailView: View[];
  formView: FormType[]
  tableConfig: TableConfig;
}

export default function grid(params: GridBuilderParams): Collection {
  const columns = params.columns;
  const detailView = params.detailView;
  const formView = params.formView.map(convertTimePicker);
  const tableConfig = params.tableConfig;

  return {
    id: params.id,
    type: 'collection',
    view_type: 'grid',
    title: params.headerTitle,
    style: {
      grid_count: params.style.grid_count,
      grid_horizontal_spacing: 10,
      grid_vertical_spacing: 10,
      padding: {
        left: 16,
        right: 16,
        top: 16,
        bottom: 16,
      }
    },
    config: {
      ...params.config,
      with_search_bar: true,
    },
    data: {
      id: v4(),
      type: 'collection_item',
      "style": {
        "spacer": 8,
        "padding": {
          "left": 8,
          "right": 8,
          "top": 8,
          "bottom": 8
        }
      },
      data: {
        image: {
          id: v4(),
          type: 'image',
          "style": {
            "image_style": "square",
            "corner_radius": 8,
            "fit": "fit_width",
            "aspect_ratio": "1:1"
          },
          "data_source": {
            "type": "table",
            value: columns.image,
          }
        },
        title: {
          id: v4(),
          type: 'label',
          "style": {
            "font_size": 14,
            "font_weight": "bold",
            "max_lines": 1,
            "overflow": "ellipsis"
          },
          "data_source": {
            "type": "table",
            value: columns.title,
          }
        },
        "description": {
          id: v4(),
          type: 'label',
          "style": {
            "font_size": 12,
            "max_lines": 3,
            "overflow": "ellipsis"
          },
          "data_source": {
            "type": "table",
            value: columns.description,
          }
        },
        "label": {
          id: v4(),
          type: 'label',
          "style": {
            "font_size": 12,
            "font_weight": "bold",
            "max_lines": 1,
            "overflow": "ellipsis",
            with_border: true,
            padding: {
              left: 12,
              right: 12,
              top: 8,
              bottom: 8,
            }
          },
          config: {
            is_hidden: false,
          },
          "data_source": {
            "type": "table",
            value: columns.label,
          }
        }
      }
    },
    detail_view: {
      app_bar: {
        id: v4(),
        style: {
          "use_divider": true,
          "brightness": "dark"
        },
        title: 'Halaman Detail',
      },
      body: {
        id: v4(),
        data: detailView,
        style: {
          spacer: 16,
          padding: {
            left: 16,
            right: 16,
            top: 16,
            bottom: 16,
          }
        }
      }
    },
    form_view: {
      app_bar: {
        id: v4(),
        style: {
          "use_divider": true,
          "brightness": "dark"
        },
        title: 'Halaman Tambah',
      },
      body: {
        id: v4(),
        data: formView
      }
    },
    update_view: {
      app_bar: {
        id: v4(),
        style: {
          "use_divider": true,
          "brightness": "dark"
        },
        title: 'Halaman Ubah',
      },
      body: {
        id: v4(),
        data: formView
      }
    },
    table_config: {
      ...tableConfig,
      searchable_columns: [columns.title, columns.description]
    },
  }
}
