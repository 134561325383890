export const generatePrefixedString = (prefix: string): string => {
  const randomSuffix = Math.random().toString(36).substring(2, 15);
  return `${prefix}-${randomSuffix}`;
}

// https://stackoverflow.com/questions/52963900/convert-different-strings-to-snake-case-in-javascript
export const toSnakeCase = (str: string): string => {
  return str.replace(/\W+/g, ' ')
    .split(' ')
    .map(word => word.toLowerCase())
    .join('_');
}
