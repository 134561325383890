import { Component, EventEmitter, Output, inject, input, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import type { TableColumnFieldType } from '../../model';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { TableAPIService } from '../../api/table-api.service';
import { setToast } from '@shared/components/toast/toast.component';
import { InputTextModule } from 'primeng/inputtext';
import { columnColTypeToBuilderColType } from '../../tools';

type Option = {
  value: TableColumnFieldType;
  name: string;
}

@Component({
  selector: 'fibr-edit-column-dialog',
  standalone: true,
  imports: [TranslateModule, FormsModule, DropdownModule, ButtonModule, InputTextModule],
  templateUrl: './edit-column-dialog.component.html',
  styleUrl: './edit-column-dialog.component.scss'
})
export class EditColumnDialogComponent {
  projectID = input.required<string>();
  tableID = input.required<string>();
  columnName = input.required<string>();
  columnLabel = input.required<string>();
  columnType = input.required<TableColumnFieldType>();
  @Output() closeDialogEvent = new EventEmitter<null>();

  columnNameField = signal<string | null>(null);
  columnTypeField = signal<TableColumnFieldType | null>(null);

  translate = inject(TranslateService)
  tableAPI = inject(TableAPIService);

  readonly options: Option[] = [
    { value: 'text', name: this.translate.instant('editor.grid.column.type.text') },
    { value: 'number', name: this.translate.instant('editor.grid.column.type.number') },
    { value: 'date', name: this.translate.instant('editor.grid.column.type.date') },
    { value: 'datetime', name: this.translate.instant('editor.grid.column.type.datetime') },
    { value: 'image', name: this.translate.instant('editor.grid.column.type.image') },
    { value: 'currency', name: this.translate.instant('editor.grid.column.type.currency')},
    { value: 'link', name: this.translate.instant('editor.grid.column.type.link')}
  ]

  onChangeType(value: TableColumnFieldType) {
    this.columnTypeField.set(value)
  }

  // TODO for delete and edit column too
  // 1. handle edit column if has published
  // 2. handle cart_transaction / payment cannot edit column
  // 3. handle column order,
  // 4. handle chack column has been used in app ?
  onDelete() {
    this.tableAPI.deleteColumn({ projectID: this.projectID()!, tableID: this.tableID()!, columnName: this.columnName() })
      .then((res) => {
        if (res.isSuccess) {
          this.tableAPI.fetchTableList(this.projectID()!)
          this.closeDialogEvent.emit()
        } else {
          setToast(this.translate.instant(res.error), 'error', 'top-center')
        }
      })
  }

  onCancel() {
    this.closeDialogEvent.emit()
  }

  onUpdate() {
    this.tableAPI.editLabelAndTypeColumn({
      projectID: this.projectID()!,
      tableID: this.tableID()!,
      columnName: this.columnName(),
      columnLabel: this.columnNameField() ?? this.columnLabel(),
      columnType: columnColTypeToBuilderColType(this.columnTypeField() ?? this.columnType())
    }).then((res) => {
      if (res.isSuccess) {
        this.tableAPI.fetchTableList(this.projectID()!)
        this.closeDialogEvent.emit()
      } else {
        setToast(this.translate.instant(res.error), 'error', 'top-center')
      }
    })
  }

}
