<ng-template #dataTemplate let-tableColumns="columns">
  <div class="flex flex-col">
    <fibr-collapse label="editor.pages.source">
      <div class="flex flex-col space-y-3">
        <div>
          <label for="table_source" class="capitalize"> Description Source </label>

          <p-dropdown
            name="table_source"
            [options]="tableSource"
            [(ngModel)]="dataSource().type"
            (ngModelChange)="onSourceChange($event)"
          >
            <ng-template let-item pTemplate="item">
              <span>{{ item }}</span>
            </ng-template>
          </p-dropdown>
        </div>

        <div>
          <label for="table_column" class="capitalize"> Description Column </label>

          @switch (dataSource().type) { @case ('table') {
          <p-dropdown
            [options]="tableColumns"
            [ngModel]="value()"
            (ngModelChange)="changeValue($event)"
            name="table_column"
            optionLabel="label"
            optionValue="name"
          ></p-dropdown>
          } @case ('custom') {
          <input
            name="table_column"
            pInputText
            [ngModel]="value()"
            (ngModelChange)="changeValue($event)"
          />
          } }
        </div>
      </div>
    </fibr-collapse>
  </div>
</ng-template>
