<fibr-header-v1
  [items]="[]"
  [srcImg]="'/assets/images/logo_appfibr.svg'"
/>
<div class="wrapper change-password">
  <div class="px-4 lg:px-9 py-[1.875rem]">
    <div
      class="lg:px-[.375rem] pt-[.375rem] pb-[1.125rem] space-x-3 flex items-center"
    >
      <i data-feather="settings" class="size-5 text-[#084b83]"></i>
      <h3 class="heading-6 font-normal text-[#084b83]">
        {{ "AUTH.CHANGE_PASSWORD" | translate }}
      </h3>
    </div>
    <div class="w-full lg:w-6 m-auto p-2 flex flex-col">
      <p-card class="w-full">
        <div class="space-y-4">
          @for (field of fields; track field.label) {
          <div class="flex 2 w-full items-center">
            <div class="w-[45%] lg:w-[40%] mb-2">
              {{ field.label | translate }}
              <span class="text-[#D8439C]">*</span>
            </div>
            <div class="flex-grow">
              <div class="relative">
                <input
                  [type]="field.type()"
                  [ngModel]="field.value()"
                  (ngModelChange)="field.value.set($event)"
                  class="p-inputtext p-component w-full"
                />
                <i
                  [class]="field.type() === 'text' ? 'pi pi-eye' : 'pi pi-eye-slash'"
                  [ngClass]="{
                    'absolute right-4 top-3 w': true
                  }"
                  (click)="field.type.update(handleFieldTextType)"
                  (keyup.enter)="field.type.update(handleFieldTextType)"
                  tabindex="0"
                ></i>
              </div>
            </div>
          </div>
          }
        </div>
      </p-card>
      <p-button
        pbutton=""
        [disabled]="isSaveDisabled()"
        type="button"
        label="{{ 'COMMON.SAVE' | translate }}"
        tabindex="0"
        class="ml-auto mr-4 self-end"
        icon="pi pi-save"
        iconPos="right"
        [loading]="isProgressSpinner()"
        (click)="save()"
      />
    </div>
  </div>
</div>

<fibr-block-ui [isBlocked]="isProgressSpinner()" />
