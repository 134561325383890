<div class="grid">
  <div class="col-12">
    <p class="">{{'TABLE.COLUMN_NAME_LABEL' | translate}}</p>
    <input type="text" pInputText class="w-100" placeholder="{{'TABLE.COLUMN_NAME_PLACEHOLDER' | translate}}"
      [ngModel]="columnNameField()" (ngModelChange)="columnNameField.set($event)">
  </div>
  <div class="col-12">
    <p class="">{{'TABLE.COLUMN_TYPE_LABEL' | translate}}</p>
    <p-dropdown styleClass="mb-3" [options]="options" optionLabel="name" optionValue="value"
      [ngModel]="columnTypeField()" (onChange)="onChangeType($event.value)" appendTo="body"></p-dropdown>
  </div>
  <!-- TODO handle calculation field type -->
  <div class="col-12 text-right">
    <hr class="mb-4">
    <button (click)="onClickCancel()" pButton type="button"
      class="p-button p-button-secondary p-component p-ripple mr-2 mb-2">{{'COMMON.CANCEL' | translate}}</button>
    <button (click)="onClickCreate()" pButton type="button" class="p-button p-component p-ripple mb-2">{{'COMMON.CREATE'
      | translate}}</button>
  </div>
</div>
