import { P, match } from "ts-pattern";
import type { BuilderColType, ClientColType, ColumnField, TableColumnFieldType } from "../model";

// Maybe will deprecated
export const convertToBuilderColType = (type: ClientColType): BuilderColType => {
  return match<ClientColType, BuilderColType>(type)
    .with('text', () => ({ type: 'string', format: 'text' }))
    .with('float', () => ({ type: 'number', format: 'number' }))
    .with('currency', () => ({ type: 'number', format: 'currency' }))
    .with('image', () => ({ type: 'string', format: 'url' }))
    .exhaustive();
}

export const columnColTypeToBuilderColType = (type: TableColumnFieldType): BuilderColType => {
  return match<TableColumnFieldType, BuilderColType>(type)
    .with('text', () => ({ type: 'string', format: 'text' }))
    .with('number', () => ({ type: 'number', format: 'number' }))
    .with('date', () => ({ type: 'timestamp', format: 'date' }))
    .with('datetime', () => ({ type: 'timestamp', format: 'date_time' }))
    .with('image', () => ({ type: 'string', format: 'url' }))
    .with('link', () => ({ type: 'string', format: 'link' }))
    .with('currency', () => ({ type: 'number', format: 'currency', symbol: 'Rp'}))
    .exhaustive();
}

export const builderColTypeToColumnColType = (type: ColumnField): TableColumnFieldType => {
  return match<BuilderColType, TableColumnFieldType>(type)
    .with({ type: 'string', format: 'text' }, () => 'text')
    .with({ type: 'number', format: 'number' }, () => 'number')
    .with({ type: 'string', format: 'timestamp' }, () => 'datetime')
    .with({ type: 'string', format: 'url' }, () => 'image')
    .with({ type: 'string', format: 'link' }, () => 'link')
    .with({ type: 'number', format: 'currency' }, () => 'currency')
    .with({ type: 'timestamp', format: 'date' }, () => 'date')
    .with({ type: 'timestamp', format: 'date_time' }, () => 'datetime')
    // for temporary for another type and format convert to text
    .with(P._, () => 'text')
    .exhaustive();
}

export const convertToClientColType = (type: string, format: string) => {
  return match<{ type: string, format: string }, ClientColType>({ type, format })
    .with({ type: 'string', format: 'url' }, () => 'image')
    .with({ type: 'number', format: 'number' }, () => 'float')
    .with({ type: 'number', format: 'text' }, () => 'float')
    .with({ type: 'number', format: 'currency' }, () => 'float')
    .with({ type: 'string', format: P.string }, () => 'text')
    .with({ type: P.string, format: P.string }, () => 'text')
    .exhaustive();
}

export const getLastIndexColumns = (totalColumns: number) => totalColumns;
// createdAt and updatedAt columns will disable for temporary until use created_at and updated_at column
// const TOTAL_DEFAULT_COLUMNS = 2;// created_at, updated_at
// export const getLastIndexColumns = (totalColumns: number) => totalColumns - TOTAL_DEFAULT_COLUMNS;
// export const getCreatedAtIndexColumns = (totalColumns: number) => getLastIndexColumns(totalColumns) + 1;
// export const getUpdatedAtIndexColumns = (totalColumns: number) => getLastIndexColumns(totalColumns) + 2;
