<ng-template #dataTemplate let-tableColumns="columns">
  <fibr-collapse label="Action Type">
    <div class="flex flex-col space-y-3">
      <div>
        <span class="text-lg capitalize text-[#8A8A8A] mb-2">
          {{ "Action Type" | translate }}
        </span>

        <p-dropdown
          name="action_type"
          styleClass="mt-1"
          [options]="actionTypes"
          placeholder="{{ 'Action Type' | translate }}"
          [(ngModel)]="config().action.type"
          (ngModelChange)="onActionChange()"
          optionLabel="label"
          optionValue="value"
          optionDisabled="disabled"
        ></p-dropdown>
      </div>

      @switch (config().action.type) { @case('go_to_page') {
      <div>
        <span class="text-lg capitalize text-[#8A8A8A] mb-2">
          {{ "Navigate to Page" | translate }}
        </span>

        <p-dropdown
          name="action_type"
          styleClass="mt-1"
          placeholder="{{ 'Select Page' | translate }}"
          [(ngModel)]="config().action.target"
          [options]="pageList()"
          optionLabel="name"
          optionValue="id"
        ></p-dropdown>
      </div>
      } }
    </div>
  </fibr-collapse>

  @switch(config().action.type){ @case('open_link') {
  <fibr-collapse label="Content">
    <div class="flex flex-col space-y-3">
      <div>
        <span class="text-lg capitalize text-[#8A8A8A] mb-2">
          {{ "Content Type" | translate }}
        </span>

        <p-selectButton
          styleClass="mt-1 fibr-selectbutton"
          [options]="contentTypes"
          [(ngModel)]="options().content_type"
          placeholder="Content type"
          optionDisabled="disabled"
        ></p-selectButton>
      </div>

      @switch (options().content_type) { @case('static') {
      <div>
        <span class="text-lg capitalize text-[#8A8A8A] mb-2">
          {{ "Link" | translate }}
        </span>

        <input
          name="content_link"
          pInputText
          [(ngModel)]="config().action.target"
          placeholder="{{ 'Input Link' | translate }}"
        />
      </div>
      } @case('table') {
      <div>
        <span class="text-lg capitalize text-[#8A8A8A] mb-2">
          {{ "Link Source" | translate }}
        </span>

        <p-dropdown
          name="content_link"
          styleClass="mt-1"
          [(ngModel)]="config().action.target"
          placeholder="{{ 'Link Source' | translate }}"
          optionLabel="label"
          optionValue="value"
        ></p-dropdown>
      </div>
      }}
    </div>
  </fibr-collapse>
  } @case('open_file') {
  <fibr-collapse label="Content">
    <div class="flex flex-col space-y-3">
      <div>
        <span class="text-lg capitalize text-[#8A8A8A] mb-2">
          {{ "Content Type" | translate }}
        </span>

        <p-selectButton
          styleClass="mt-1 fibr-selectbutton"
          [options]="contentTypes"
          [(ngModel)]="options().content_type"
          placeholder="Content type"
        ></p-selectButton>
      </div>

      @switch (options().content_type) { @case('static') {
      <div class="flex flex-col space-y-3">
        <div>
          <span class="text-lg capitalize text-[#8A8A8A] mb-2">
            {{ "Upload File" | translate }}
          </span>

          <fibr-image-picker></fibr-image-picker>
        </div>

        <div>
          <span class="text-lg capitalize text-[#8A8A8A] mb-2">
            {{ "File Action" | translate }}
          </span>

          <p-selectButton
            styleClass="mt-1 fibr-selectbutton"
            [options]="fileActions"
          >
          </p-selectButton>
        </div>
      </div>
      } @case('table') {
      <div class="flex flex-col space-y-3">
        <div>
          <span class="text-lg capitalize text-[#8A8A8A] mb-2">
            {{ "File Source" | translate }}
          </span>

          <p-dropdown
            name="content_link"
            styleClass="mt-1"
            placeholder="{{ 'Link Source' | translate }}"
            optionLabel="label"
            optionValue="value"
          ></p-dropdown>
        </div>

        <div>
          <span class="text-lg capitalize text-[#8A8A8A] mb-2">
            {{ "File Action" | translate }}
          </span>

          <p-selectButton
            styleClass="mt-1 fibr-selectbutton"
            [options]="fileActions"
          >
          </p-selectButton>
        </div>
      </div>
      }}
    </div>
  </fibr-collapse>
  } }

  <fibr-collapse label="editor.pages.form.field_list.label">
    <span class="text-lg capitalize text-[#8A8A8A] mb-2">
      {{ "editor.pages.form.field_list.label" | translate }}
    </span>

    <input
      name="form_label"
      pInputText
      [(ngModel)]="style().label"
      placeholder="{{ 'editor.pages.form.field_list.label' | translate }}"
    />

    <div class="mt-2">
      <p-checkbox
        styleClass="fibr-checkbox"
        [binary]="true"
        label="Hide Label"
        [(ngModel)]="style().hide_label"
      ></p-checkbox>
    </div>
  </fibr-collapse>

  <fibr-collapse label="Icon">
    <div class="flex flex-col space-y-3">
      <div>
        <span class="text-lg capitalize text-[#8A8A8A] mb-2">
          {{ "Icon" | translate }}
        </span>

        <p-dropdown
          name="action_type"
          styleClass="mt-1"
          placeholder="{{ 'Icon' | translate }}"
          [options]="icons"
          [(ngModel)]="style().icon"
          [filter]="true"
          filterBy="label"
          [showClear]="true"
          optionLabel="label"
          optionValue="value"
        >
          <ng-template pTemplate="selectedItem" let-item>
            <div class="flex items-center space-x-3">
              <img
                [class]="'feather md-10 md-light ' + item.value"
                [src]="'/feather-icons/' + item.value + '.svg'"
                [alt]="item.label"
              />

              <span class="capitalize">
                {{ item.label }}
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="item" let-item>
            <div class="flex items-center space-x-3">
              <img
                [class]="'feather md-10 md-light ' + item.value"
                [src]="'/feather-icons/' + item.value + '.svg'"
                [alt]="item.label"
              />

              <span class="capitalize">
                {{ item.label }}
              </span>
            </div>
          </ng-template>
        </p-dropdown>
      </div>

      <div>
        <span class="text-lg capitalize text-[#8A8A8A] mb-2">
          {{ "Icon Position" | translate }}
        </span>

        <p-selectButton
          styleClass="mt-1 fibr-selectbutton"
          [(ngModel)]="style().icon_position"
          [options]="iconPositions"
        >
        </p-selectButton>
      </div>
    </div>
  </fibr-collapse>

  <fibr-collapse label="Design">
    <div class="flex flex-col space-y-3">
      <div>
        <span class="text-lg capitalize text-[#8A8A8A] mb-2">
          {{ "Button Type" | translate }}
        </span>

        <p-selectButton
          styleClass="mt-1 fibr-selectbutton"
          [(ngModel)]="style().button_type"
          [options]="buttonTypes"
        >
        </p-selectButton>
      </div>

      <div>
        <span class="text-lg capitalize text-[#8A8A8A] mb-2">
          {{ "Button Width" | translate }}
        </span>

        <p-selectButton
          styleClass="mt-1 fibr-selectbutton"
          [(ngModel)]="style().button_width"
          [options]="buttonWidth"
        >
        </p-selectButton>
      </div>
    </div>
  </fibr-collapse>
</ng-template>
