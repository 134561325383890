<fibr-header-v1 [srcImg]="'/assets/images/logo_appfibr.svg'"></fibr-header-v1>
<p-toolbar class="sub-header">
  <div class="p-toolbar-group-start">
    <span>Profile</span>
  </div>
</p-toolbar>

<div class="px-4 py-24 fibr-portal">
  <div class="px-1 flex align-items-center gap-3">
    <img
      alt="logo"
      src="/assets/images/icon/icon_billing-lg.svg"
      class="w-auto h-7"
    />
    <h3 class="text-primary font-light">Profile</h3>
  </div>

  <div class="mt-4">
    <div class="grid">
      <div class="col-12 lg:col-3">
        <div
          class="px-4 py-4"
          [ngStyle]="{
            background: 'url(/assets/images/icon/profile_header.png)',
            backgroundSize: '100% auto',
            minHeight: 150,
          }"
        >
          <div class="flex align-items-center gap-2">
            <p-avatar
              icon="pi pi-user"
              styleClass="mr-2"
              size="xlarge"
              shape="circle"
            ></p-avatar>

            <div>
              <h4 class="text-2xl mb-0">
                <span class="text-white">Hi,</span>
                <span class="text-[#FBE416] ml-1">
                  {{ fullName() }}
                </span>
              </h4>
              <p class="text-base text-white">
                {{ email() }}
              </p>
            </div>
          </div>
        </div>
        <ul class="border rounded-b-lg">
          @for (menu of listMenus; track $index;) {
          <a [routerLink]="[menu.href]" [queryParams]="{ menu: menu.name }">
            <li
              class="flex align-items-center gap-4 mx-4 px-2 py-4"
              [ngClass]="{
                'text-[#8A8A8A]': activatePage !== menu.name,
                'text-primary': activatePage === menu.name,
                'border-bottom-1': listMenus.length !== $index
              }"
            >
              <i [class]="menu.icon + ' text-xl'"></i>
              <span class="text-xl">{{ menu.label }}</span>
            </li>
          </a>
          }
        </ul>
      </div>

      <div class="col-12 lg:col-9">
        <div class="lg:px-4">
          @switch (activatePage) { @case ('account') {
          <fibr-update-profile></fibr-update-profile>
          } @case ('change-password') {
          <fibr-change-password></fibr-change-password>
          } @case ('settings') {
          <fibr-settings></fibr-settings>
          } }
        </div>
      </div>
    </div>
  </div>
</div>
<fibr-footer></fibr-footer>
