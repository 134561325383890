import { Component, effect, inject, signal } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { BlockUIComponent } from '@shared/components/block-ui/block-ui.component';
import { ButtonModule } from 'primeng/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ProjectStoreService } from '../../store/project-store.service';
import { GsheetFile } from '../../model';
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProjectAPIService } from '../../api/project-api.service';
import { handleErrorTranslationToast } from '@shared/libs/error';
import { setToast } from '@shared/components/toast/toast.component';
import { Router } from '@angular/router';


@Component({
  selector: 'fibr-google-sheet-lists-dialog',
  standalone: true,
  imports: [DialogModule, DividerModule, BlockUIComponent, ButtonModule, TranslateModule, TableModule, CommonModule],
  templateUrl: './google-sheet-lists-dialog.component.html',
  styleUrl: './google-sheet-lists-dialog.component.scss'
})
export class GoogleSheetListsDialogComponent {

  isSubmitting = signal(false);

  gSheetFiles = signal<gapi.client.drive.FileList[]>([]);

  selectedFile = signal('');

  scriptDeployed: boolean = false;
  deploymentUrl: string = '';
  translate = inject(TranslateService)

  constructor(
    private projectStore: ProjectStoreService, 
    private http: HttpClient, 
    private projectAPI: ProjectAPIService,
    private router: Router) { 
    effect(() => {
      this.gSheetFiles.set(this.projectStore.gSheetFiles());
    }, { allowSignalWrites: true });
  }

  closeDialog() {
    this.projectStore.setActiveDialog(null);
  }

  get showDialog() {
    return this.projectStore.activeDialog() === 'google_sheet_lists';
  }

  onCloseDialog() {
    this.projectStore.setActiveDialog(null)
  }

  async submit() {
    this.isSubmitting.set(true);
    let projectName = 'New App';
    let counter = 1;

    const existingProjectNames = this.projectStore.projectList().map(project => project.display_name);

    while(existingProjectNames.includes(projectName)){
      projectName = `New App (${counter})`;
      counter++;
    }

    this.projectAPI.createProjectWithGoogleSheet(projectName).then((response) => {
      if (response.isSuccess) {
        const projectID = response.value;
        this.projectAPI.importGoogleSheet(projectID, this.selectedFile(), this.projectStore.googleSheetAccessToken())
        .then((response) => {
          if(response.isSuccess){
            setToast(this.translate.instant('PROJECT.SUCCESS_CREATE_PROJECT'), 'success', 'top-center');
            this.router.navigate(['/projects', projectID]);
          } else {
            handleErrorTranslationToast(this.translate, 'top-center')(response.error);
          }
        })
      }
    })
    .catch(handleErrorTranslationToast(this.translate, 'top-center'))
    .finally(() => this.isSubmitting.set(false));
    this.onCloseDialog();
  }

  async attachScript(sheetID: string){
    try {

      // Create the script project
      const createScriptResponse = await this.http.post('https://script.googleapis.com/v1/projects', {
        title: 'appsscript',
        parentId: sheetID,
      }, {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${this.projectStore.googleSheetAccessToken()}`,
          'Content-Type': 'application/json'
        })
      }).toPromise();

      const createScriptData = createScriptResponse as unknown as { scriptId: string };
      const scriptId = createScriptData.scriptId;

      console.log('Script ID: ' + scriptId);
      console.log(`exec API  : https://script.googleapis.com/v1/projects/${scriptId}/content`)

      const appsScriptContent = `
        function doPost(e) {
          var result;
          var data = JSON.parse(e.postData.contents);
          var spreadsheet = SpreadsheetApp.getActiveSpreadsheet();
          Logger.log('Received data: ' + JSON.stringify(data));

          if (data['function'] === "updateRow" && data.parameters) {
            Logger.log('Parameters: ' + JSON.stringify(data.parameters));
            
            var sheet = spreadsheet.getSheetByName(data.parameters.sheet_name);
            if (sheet !== null) {
              Logger.log('Found sheet: ' + data.parameters.sheet_name);
              var headers = sheet.getRange(1, 1, 1, sheet.getLastColumn()).getValues()[0];
              var rowsIdColumn = headers.indexOf("🔒 Rows ID") + 1;
              var sheetData = sheet.getRange(2, 1, sheet.getLastRow() - 1, sheet.getLastColumn()).getValues();
              var rowToUpdate = -1;
              for (var i = 0; i < sheetData.length; i++) {
                if (sheetData[i][rowsIdColumn - 1] === data.parameters.rows_id) {
                  rowToUpdate = i + 2;
                  Logger.log('Found row to update: ' + rowToUpdate);
                  break;
                }
              }
              if (rowToUpdate === -1) {
                rowToUpdate = sheet.getLastRow() + 1;
                sheet.getRange(rowToUpdate, rowsIdColumn).setValue(data.parameters.rows_id);
                Logger.log('Added new row: ' + rowToUpdate);
              }
              for (var key in data.parameters.details) {
                var columnToUpdate = headers.indexOf(key) + 1;
                if (columnToUpdate !== 0) {
                  sheet.getRange(rowToUpdate, columnToUpdate).setValue(data.parameters.details[key]);
                  Logger.log('Updated column ' + key + ' with value ' + data.parameters.details[key]);
                }
              }
              result = {"result": "Successfully updating row."};
              Logger.log('Update successful');
            } else {
              result = {"result": "Sheet not found."};
              Logger.log('Sheet not found');
            }
          } else if (data['function'] === "updateColumn" && data.parameters) {
            var sheet = spreadsheet.getSheetByName(data.parameters.sheet_name);
            if (sheet !== null) {
              var headers = sheet.getRange(1, 1, 1, sheet.getLastColumn()).getValues()[0];
              var columnToUpdate = headers.indexOf(data.parameters.prev_column_name) + 1;
              if (columnToUpdate !== 0) {
                sheet.getRange(1, columnToUpdate).setValue(data.parameters.update_column_name);
                result = {"result": "Successfully updating column."};
                Logger.log('Update successful');
              } else {
                result = {"result": "Failed updating column."};
                Logger.log('Failed updating column')
              }
            } else {
              result = {"result": "Sheet not found."};
              Logger.log('Sheet not found');
            }
          } else if (data['function'] === "deleteRow" && data.parameters) {
            Logger.log('Parameters: ' + JSON.stringify(data.parameters));
            
            var sheet = spreadsheet.getSheetByName(data.parameters.sheet_name);
            if (sheet !== null) {
              Logger.log('Found sheet: ' + data.parameters.sheet_name);
              var headers = sheet.getRange(1, 1, 1, sheet.getLastColumn()).getValues()[0];
              var rowsIdColumn = headers.indexOf("🔒 Rows ID") + 1;
              var sheetData = sheet.getRange(2, 1, sheet.getLastRow() - 1, sheet.getLastColumn()).getValues();
              var rowToDelete = -1;
              for (var i = 0; i < sheetData.length; i++) {
                if (sheetData[i][rowsIdColumn - 1] === data.parameters.rows_id) {
                  rowToDelete = i + 2;
                  Logger.log('Found row to delete: ' + rowToDelete);
                  break;
                }
              }
              if (rowToDelete !== -1) {
                sheet.deleteRow(rowToDelete);
                result = {"result": "Successfully deleted row."};
                Logger.log('Delete successful');
              } else {
                result = {"result": "Row not found."};
                Logger.log('Row not found');
              }
            } else {
              result = {"result": "Sheet not found."};
              Logger.log('Sheet not found');
            }
          } else {
            result = {"result": "Failed handling request."};
            Logger.log('Failed handling request.');
          }
          return ContentService.createTextOutput(JSON.stringify(result))
            .setMimeType(ContentService.MimeType.JSON);
        }

        function doGet() {
          var res = {"error": "cannot provide GET request."};
          return ContentService.createTextOutput(JSON.stringify(res)).setMimeType(ContentService.MimeType.JSON);
        }
      `;

      await this.http.put(`https://script.googleapis.com/v1/projects/${scriptId}/content`, {
        files: [
          {
            name: 'Code',
            type: 'SERVER_JS',
            source: appsScriptContent.replace(/\s+/g, ' ').trim()
          }, {
            name: 'appsscript',
            type: 'JSON',
            source: JSON.stringify({
              "timeZone": "Asia/Jakarta",
              "dependencies": {},
              "exceptionLogging": "STACKDRIVER",
              "sheets": { "macros": [] },
            })
          }
        ]
      }, {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${this.projectStore.googleSheetAccessToken()}`,
          'Content-Type': 'application/json'
        })
      }).toPromise();

      console.log('deploying');

      const deployResponse = await this.http.post(`https://script.googleapis.com/v1/projects/${scriptId}/deployments`, {
        scriptId: scriptId,
        versionNumber: 1,
        manifestFileName: 'appsscript',
        description: 'Initial deployment'
      }, {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${this.projectStore.googleSheetAccessToken()}`,
          'Content-Type': 'application/json'
        })
      }).toPromise();

      const deployData = deployResponse as unknown as { deploymentId: string };
      const deploymentId = deployData.deploymentId;
      console.log('Deployment ID: ' + deploymentId);

      // Make the deployment public
      await this.http.patch(`https://script.googleapis.com/v1/projects/${scriptId}/deployments/${deploymentId}`, {
        deploymentConfig: {
          scriptId: scriptId,
          versionNumber: 2,
          manifestFileName: 'appsscript',
          description: 'Initial deployment'
        },
        entryPoints: [
          {
            entryPointType: 'WEB_APP',
            webApp: {
              entryPointConfig: {
                access: 'ANYONE',
                executeAs: 'USER_DEPLOYING'
              }
            }
          }
        ]
      }, {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${this.projectStore.googleSheetAccessToken()}`,
          'Content-Type': 'application/json'
        })
      }).toPromise();

      // Get the deployment URL
      const getDeploymentResponse = await this.http.get(`https://script.googleapis.com/v1/projects/${scriptId}/deployments/${deploymentId}`, {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${this.projectStore.googleSheetAccessToken()}`
        })
      }).toPromise();

      const getDeploymentData = getDeploymentResponse as unknown as { entryPoints: { webApp: { url: string } }[] };
      this.deploymentUrl = getDeploymentData.entryPoints[0].webApp.url;
      this.scriptDeployed = true;

      console.log('Deployment URL: ' + this.deploymentUrl);

    } catch (error) {
      console.log(error);
    }
  }

  selectFile(file: GsheetFile){
    this.selectedFile.set(file.id);
  }

}
