<p-dialog [visible]="showDialog" (visibleChange)="closeDialog()" [modal]="true" [blockScroll]="true" [draggable]="false"
  [dismissableMask]="true" appendTo="body" [style]="{width: '51%'}" styleClass="p-dialog-google-sheet"
>
<ng-template pTemplate="header">
    <span class="p-dialog-title">Select a file</span>
</ng-template>
<ng-template pTemplate="content">
    <p-table [value]="gSheetFiles()" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th>Owner</th>
                <th>Last Modified</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-i="index">
            <tr (click)="selectFile(rowData)" class="cursor-pointer" [ngClass]="{'selected': rowData.id === selectedFile()}">
                <td>
                    <div class="flex flex-row gap-2">
                        <img class="align-self-center mr-3 w-[10px]" alt="icon" src="/assets/images/icon/app-google-sheet.svg"> 
                        {{ rowData.name }}
                    </div>
                </td>
                <td>{{ rowData.owners[0].displayName }}</td>
                <td>{{ rowData.modifiedTime | date : 'dd/MM/yyyy' }}</td>
            </tr>
        </ng-template>
    </p-table>
</ng-template>
<ng-template pTemplate="footer" >
    <div class="grid mb-5 p-justify-between">
        <div class="col-12 text-left">
            <div class="flex flex-row gap-2">
                <p-button
                    label="Select"
                    styleClass="p-button p-component p-button-label"
                    [disabled]="isSubmitting()" 
                    (click)="submit()"
                    [disabled]="!selectedFile()"
                ></p-button>
                <p-button
                    label="Cancel"
                    styleClass="p-button p-component p-button-secondary"
                    (click)="closeDialog()"
                ></p-button>
            </div>
        </div>
    </div>
</ng-template>
</p-dialog>
<fibr-block-ui [isBlocked]="isSubmitting()" />
