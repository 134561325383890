import { Component } from '@angular/core';

@Component({
  selector: 'fibr-cart-icon',
  standalone: true,
  imports: [],
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="36"
      viewBox="0 0 41 36"
      fill="none"
    >
      <path
        d="M14.0195 34.3183C14.8339 34.3183 15.494 33.6581 15.494 32.8437C15.494 32.0293 14.8339 31.3691 14.0195 31.3691C13.2051 31.3691 12.5449 32.0293 12.5449 32.8437C12.5449 33.6581 13.2051 34.3183 14.0195 34.3183Z"
        stroke="#8A8A8A"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M34.664 34.3183C35.4784 34.3183 36.1386 33.6581 36.1386 32.8437C36.1386 32.0293 35.4784 31.3691 34.664 31.3691C33.8496 31.3691 33.1895 32.0293 33.1895 32.8437C33.1895 33.6581 33.8496 34.3183 34.664 34.3183Z"
        stroke="#8A8A8A"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.22266 1.87793H8.12089L12.5446 26.9454H36.1375"
        stroke="#8A8A8A"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5448 21.0473H35.5332C35.7037 21.0475 35.869 20.9885 36.0009 20.8804C36.1328 20.7724 36.2232 20.6219 36.2566 20.4548L38.9108 7.18373C38.9323 7.07672 38.9297 6.96628 38.9032 6.8604C38.8768 6.75452 38.8271 6.65583 38.7579 6.57145C38.6887 6.48707 38.6016 6.41911 38.503 6.37247C38.4043 6.32583 38.2965 6.30168 38.1874 6.30176H9.5957"
        stroke="#8A8A8A"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: ``,
})
export class CartIconComponent {}
