<fibr-page-component
  [children]="true"
  [component]="pageComponentStore.dialogDetail().selected"
  [visible]="visible()"
  [right]="410 * 3"
>
  <fibr-component-title #componentType></fibr-component-title>
  <fibr-component-content #componentType></fibr-component-content>
  <fibr-component-image #componentType></fibr-component-image>
  <fibr-component-separator #componentType></fibr-component-separator>
  <fibr-component-button #componentType></fibr-component-button>
</fibr-page-component>
