<fibr-header-v1 srcImg="/assets/images/logo_appfibr.svg" />
<div class="p-content fibr-portal content-nosubheader">
  <div class="center-content w-100">
    <div class="box h-100">
      <div class="layout-main full">
        <div class="layout-content">
          <fibr-banner></fibr-banner>
          <fibr-project-list (isBlockedEvent)="this.onSetIsBlocked($event)" />
          <p-divider class="full-divider"></p-divider>
          <fibr-project-template-list />
        </div>
      </div>
    </div>
  </div>
</div>
<fibr-footer></fibr-footer>

<!-- Modal / Dialog -->
<fibr-template-dialog [useTemplateWithDataField]="useTemplateWithDataField()"
  (toggleUseTemplateWithDataEvent)="onToggleUseTemplateWithData()" />
<fibr-create-project-with-template-dialog [useTemplateWithDataField]="useTemplateWithDataField()" />
<fibr-create-new-project-dialog />
<fibr-google-sheet-lists-dialog />

<!-- Loader -->
<fibr-block-ui [isBlocked]="isBlocked()" />

