import {Component, inject, EventEmitter, Input, Output, signal} from "@angular/core";
import {SidebarModule} from "primeng/sidebar";
import {CardModule} from "primeng/card";
import {ButtonModule} from "primeng/button";
import {TooltipModule} from "primeng/tooltip";
import {AccordionModule} from "primeng/accordion";
import {TranslateModule} from "@ngx-translate/core";
import {InputTextModule} from "primeng/inputtext";
import {OrderListModule} from "primeng/orderlist";
import {DividerModule} from "primeng/divider";
import {FibrPageComponent} from "../../../../page-component/page-component.component";
import {FormType, SectionItem} from "@shared/libs/generators/widgets/widget-builder.model";
import {
  FibrComponentFormTypesModule
} from "../../../../page-component-forms/page-component-form-types/page-component-form-type.module";
import {FormsModule} from "@angular/forms";
import {PageComponentStore} from "../../../../../store/page-component.store";

@Component({
  selector: 'fibr-section-page',
  templateUrl: './section-page.component.html',
  standalone: true,
  imports: [
    SidebarModule,
    CardModule,
    ButtonModule,
    TooltipModule,
    AccordionModule,
    TranslateModule,
    InputTextModule,
    OrderListModule,
    DividerModule,
    FormsModule,
    FibrPageComponent,
    FibrComponentFormTypesModule,
  ]
})
export class FibrSectionPageComponent {
  private pageComponentStore: PageComponentStore = inject(PageComponentStore);

  @Input() visible: boolean;
  @Input()
  set component(component: SectionItem | null) {
    if (!component) {
      this.options.set({ title: '' });
      return;
    }
    this.components.set(component.data);
    this.options.set({ title: component.title });
  }

  @Output() cancel = new EventEmitter();
  @Output() apply = new EventEmitter();

  utils = signal<{ visible: boolean; selected: FormType | null }>({visible: false, selected: null});
  components = signal<FormType[]>([]);
  options = signal<{ title: string }>({ title: '' });

  columns = this.pageComponentStore.tableSelectedColumn;

  getLabelColumn(name: string) {
    return this.columns()?.[name]?.label || '';
  }

  onSectionCancel() {
    this.cancel.emit();
  }

  onSectionApply() {
    const output = {
      ...this.options(),
      data: this.components()
    }

    this.apply.emit(output);
    this.components.set([]);
    this.options.set({ title: '' });
  }

  openComponent(event: FormType) {
    this.utils.set({visible: true, selected: event});
    this.pageComponentStore.changeSelectedForm(event);
  }

  addComponent() {
    this.utils.set({visible: true, selected: null});
  }

  deleteComponent(event: FormType) {
    this.components.update((val) => {
      return val.filter((x) => x.id !== event.id);
    })
  }

  onCancel() {
    this.utils.set({visible: false, selected: null});
    this.pageComponentStore.changeSelectedForm(null);
  }

  onApply(event: FormType) {
    const exist = this.components().findIndex((x) => x.id === event.id);
    this.components.update((val) => {
      if (exist !== -1) {
        val[exist] = event;
        return val;
      }
      return val.concat([event]);
    })
    this.pageComponentStore.changeSelectedForm(null);
    this.utils.set({visible: false, selected: null});
  }
}
