import { Component, OnInit, signal } from '@angular/core';
import { GoogleSignInComponent } from '../google-sign-in/google-sign-in.component';
import { AuthAPIService } from '../../api/auth-api.service';
import { DropdownModule } from 'primeng/dropdown';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoginSchema, type Lang } from '../../model';
import { fieldSignal, getZodRegulerError } from '../../../../shared/libs/form';
import { setToast } from '../../../../shared/components/toast/toast.component';
import { BlockUIComponent } from '@shared/components/block-ui/block-ui.component';
import { GlobalConfigService } from '../../../../core/global-config/global-config.service';
import mixpanel from 'mixpanel-browser';

mixpanel.init(environment.mixpanel.token, {debug: true, track_pageview:true, persistence: 'localStorage'});


@Component({
  selector: 'fibr-login-page',
  standalone: true,
  imports: [
    GoogleSignInComponent,
    DropdownModule,
    TranslateModule,
    CommonModule,
    FormsModule,
    RouterModule,
    BlockUIComponent
  ],
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss',
})
export class LoginPageComponent implements OnInit {
  emailField = fieldSignal('');
  passwordField = fieldSignal('');
  selectedLanguage = signal<Lang | undefined>(undefined);
  returnUrl = signal('');
  isBlocked = signal(false);
  fieldTextType = signal<'text' | 'password'>('password');
  isProgressSpinner = signal(false);

  languageList: Lang[] = [
    { name: 'English', code: 'en' },
    { name: 'Indonesia', code: 'id' },
  ];

  logo = environment.logo;
  labelAlt = environment.app_name;
  imageLogin = environment.image_login;

  selectedValues = false;

  constructor(
    private router: Router,
    private authService: AuthAPIService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private globalConfig: GlobalConfigService
  ) { }

  ngOnInit() {
    this.selectedLanguage.set(this.languageList.find(
      (x: Lang) => x.code === this.globalConfig.language()
    ));
    this.route.queryParams.subscribe((params) => {
      this.returnUrl.set(params['return_url'] ?? "/projects");
    });
  }
  handleClickGoogle() {
    this.authService.googleSignIn().then((result) => {
      if (result.isSuccess) {
        this.authService.fetchLoggedInUser();
        mixpanel.track('Login Attempt', {
          distinct_id: this.emailField.value(),
          time: new Date().toISOString(),
        });
        this.router.navigateByUrl(this.returnUrl());
      } else {
        this.translate.get(result.error).subscribe((errorMessage) => {
          setToast(errorMessage, 'error');
          mixpanel.track('Login Failed', {
            distinct_id: this.emailField.value(),
            error_message: errorMessage,
            time: new Date().toISOString(),
          });
        })
      }
    });
  }

  handleClickLogin() {
    const validatedFields = LoginSchema.safeParse({
      email: this.emailField.value(),
      password: this.passwordField.value()
    })
    if (validatedFields.success) {
      this.isBlocked.set(true);
      this.authService.signInByEmail(this.emailField.value(), this.passwordField.value()).then((result) => {
        if (result.isSuccess) {
          this.authService.fetchLoggedInUser();
          mixpanel.track('Login Attempt', {
            distinct_id: this.emailField.value(),
            time: new Date().toISOString(),
          });
          this.router.navigateByUrl(this.returnUrl());
        } else this.translate.get(result.error).subscribe((errorMessage) => {
          setToast(errorMessage, 'error');
          mixpanel.track('Login Failed', {
            distinct_id: this.emailField.value(),
            error_message: errorMessage,
            time: new Date().toISOString(),
          });
        })
      })
        .catch((error) => {
          setToast(error.message, 'error');
          mixpanel.track('Login Failed', {
            distinct_id: this.emailField.value(),
            error_message: error.message,
            time: new Date().toISOString(),
          });
        })
        .finally(() => {
          this.isBlocked.set(false);
        });
    } else {
      const errorField = ['email', 'password'].find((field) => {
        return getZodRegulerError(validatedFields, field)
      }) as string;
      this.translate.get(getZodRegulerError(validatedFields, errorField)).subscribe((errorMessage) => {
        setToast(errorMessage, 'error');
      });
    }
  }

  toggleFieldTextType() {
    this.fieldTextType() === 'text' ? this.fieldTextType.set('password') : this.fieldTextType.set('text');
  }

  onLanguageChange() {
    const selectedLangCode = this.selectedLanguage()?.code ?? 'en';
    this.globalConfig.setLanguage(selectedLangCode);
  }
}
