<div class="login-body layout-light">
  <div class="login-content">
    <div class="login-panel">
      <div class="login-panel-content text-left">
        <div class="col login-header">
          <div class="logo">
            <img [src]="logo" alt="{{ labelAlt }}" />
          </div>
          <h4 class="mb-2">{{ "ForgotPasswordMessage" | translate }}</h4>
          <span>{{ "ForgotPasswordSubMessage" | translate }}</span>
        </div>
        <div class="col">
          <div class="forms mt-0">
            <div class="login-input-wrapper mb-2">
              <label for="password">{{
                "inputEmailForgotPassword" | translate
              }}</label>
              <input
                [type]="'email'"
                [ngModel]="emailField()"
                (ngModelChange)="emailField.set($event)"
                id="password"
                placeholder="youremail@emaildomain.com"
                class="p-inputtext p-component line-input py-3"
                required
              />
            </div>

            <div class="buttons">
              <button
                pbutton=""
                [disabled]="isProgressSpinner()"
                type="button"
                label="Login"
                tabindex="0"
                class="col-12 py-3 p-button text-lg bg-primary-500"
                (click)="onSubmit()"
              >
                <span class="p-button-label">{{
                  "AUTH.SEND_LINK" | translate
                }}</span>
                @if(isProgressSpinner()) {
                <p-progressSpinner
                  strokeWidth="3"
                  styleClass="progress-spinner-btn"
                  [style]="{ width: '20px', height: '20px' }"
                />
                }
              </button>
            </div>
            <div class="col-12 center">
              <a
                href="javascript:;"
                [routerLink]="['/sign-in']"
                pbutton=""
                type="button"
                label="Register"
                tabindex="0"
                class="p-button-text mt-3 text-primary-500"
              >
                <span class="p-button-label">{{
                  "AUTH.BACK_TO_LOGIN" | translate
                }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="login-panel right"
      [ngStyle]="{
        'background-image': 'url(' + imageForgotPassword + ')',
        'background-size': 'cover',
        'background-position': 'center',
        'background-repeat': 'no-repeat'
      }"
    ></div>
  </div>
</div>
