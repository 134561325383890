<div class="grid">
  <div class="col-12">
    <p class="">{{'editor.add.table.name' | translate}}</p>
    <input type="text" pInputText class="w-100 mb-3" placeholder="{{'editor.add.table.name' | translate}}"
      [ngModel]="tableNameField()" (ngModelChange)="tableNameField.set($event)">
  </div>
</div>
<div class="grid">
  <div class="col-12 text-right">
    <button (click)="cancel()" pButton type="button" label="{{'PROJECT.POPUP_CANCEL' | translate}}" icon=""
      iconPos="right" class="mr-2 mb-2 p-button-secondary" >
    </button>
    <button (click)="createTable()" pButton type="button" label="{{'COMMON.CREATE' | translate}}" icon="pi pi-plus"
      iconPos="right" class="p-button-primary mb-2">
    </button>
  </div>
</div>
