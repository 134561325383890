import { Injectable, computed, signal } from '@angular/core';
import type { EditorStore } from '../model';


@Injectable({
  providedIn: 'root'
})
export class EditorStoreService {
  private _store = signal<EditorStore>({
    pageList: [],
    selectedPageConfig: null,
    selectedPagePropertyComponent: null,
    usersInvited: [],
    userAccess:[]
  })

  //sore page list by index
  rawPageList = computed(() => this._store().pageList.sort((a, b) => a.index - b.index));
  pageList = computed(() => this._store().pageList.filter(page => page.index !== -1 && page.show_in_nav_bar === true).sort((a,b) => a.index - b.index));
  pageList_2 = computed(() => this._store().pageList.filter(page => page.index !== -1 && page.show_in_nav_bar === false).sort((a,b) => a.index - b.index));
  // pageList = computed(() => this._store().pageList);
  selectedPageConfig = computed(() => this._store().selectedPageConfig);
  selectedPagePropertyComponent = computed(() => this._store().selectedPagePropertyComponent);
  usersInvited = computed(() => {
    const acceptedEmails = this._store().usersInvited
      .filter(user => user.status === 'accepted' || user.status === 'failed')
      .map(user => user.email);
  
    return this._store().usersInvited.filter((user, index, self) => !acceptedEmails.includes(user.email) && index === self.findIndex((t) => t.email === user.email));
  });
  userAccess = computed(() => this._store().userAccess);

  constructor() { }

  setPageList(pageList: EditorStore['pageList']) {
    this._store.set({ ...this._store(), pageList });
  }
  
  setSelectedPageConfig(selectedPageConfig: EditorStore['selectedPageConfig']) {
    this._store.set({ ...this._store(), selectedPageConfig });
  }

  setSelectedPagePropertyComponent(selectedPagePropertyComponent: EditorStore['selectedPagePropertyComponent']) {
    this._store.set({ ...this._store(), selectedPagePropertyComponent });
  }

  setUsersInvited(usersInvited: EditorStore['usersInvited']) {
    this._store.set({ ...this._store(), usersInvited });
  }

  setUserAccess(userAccess: EditorStore['userAccess']) {
    this._store.set({ ...this._store(), userAccess });
  }
}
