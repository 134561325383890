import { Component, inject } from '@angular/core';
import { BaseComponent } from '../../../../../core/base/base-component';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'fibr-settings',
  templateUrl: './settings.component.html',
  standalone: true,
  imports: [CommonModule, ButtonModule],
})
export class SettingComponent extends BaseComponent {
  confirmationService = inject(ConfirmationService);

  constructor() {
    super('fibr-settings');
  }

  clickDelete() {
    this.confirmationService.confirm({
      header: 'Delete account',
      message:
        'Are you sure you want to delete this account? This action cannot be undone.',
      rejectButtonStyleClass: 'p-button-secondary',
      dismissableMask: true,
      accept: () => {
        alert('[Under-Development] accepted');
      },
      reject: () => {
        alert('[Under-Development] reject');
      },
    });
  }
}
