<div class="w-full flex flex-col gap-5 justify-center items-center pt-0 payment-container">
  <div class="logo w-1/5">
    <a href="https://appfibr.io">
      <img [src]="logo" alt="logo-fibr"
      />
    </a>
  </div>

  <div class="p-6 flex flex-column align-items-center border border-[#CCCCCC] rounded-lg w-[500px] mt-4">
    <p class="text-xl font-medium text-[#444444] text-center">
      {{ 'BILLING.FINISH_PAYMENT.TITLE' | translate }}
    </p>

    <p-button label="{{ 'BILLING.FINISH_PAYMENT.BUTTON' | translate }}" styleClass="mt-4 h-[52px]" (onClick)="automatedRedirect()"></p-button>
  </div>
</div>
