import { CommonModule } from '@angular/common';
import { Component, computed, inject } from '@angular/core';
import { FibrPageComponent } from '../page-component/page-component.component';
import { FibrPageComponentTitleComponent } from './page-component-detail-types/page-component-title/page-component-title.component';
import { FibrPageComponentContentComponent } from './page-component-detail-types/page-component-content/page-component-content.component';
import { PageComponentStore } from '../../store/page-component.store';
import { FibrPageComponentImageComponent } from './page-component-detail-types/page-component-image/page-component-image.component';
import { FibrPageComponentSeparatorComponent } from './page-component-detail-types/page-component-separator/page-component-separator.component';
import { FibrPageComponentButtonComponent } from './page-component-detail-types/page-component-button/page-component-button.component';

@Component({
  selector: 'fibr-page-component-details',
  templateUrl: './page-component-details.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FibrPageComponent,
    FibrPageComponentTitleComponent,
    FibrPageComponentContentComponent,
    FibrPageComponentImageComponent,
    FibrPageComponentSeparatorComponent,
    FibrPageComponentButtonComponent,
  ],
})
export class FibrPageComponentDetailsComponent {
  pageComponentStore = inject(PageComponentStore);

  visible = computed(() => this.pageComponentStore.dialogDetail().visible);
}
