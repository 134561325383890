import { Injectable, inject } from '@angular/core';
import { initializeApp, type FirebaseOptions } from '@angular/fire/app';
import { UserCredential, getAuth, type Auth } from '@angular/fire/auth';
import { Firestore, doc, getDoc, type DocumentReference, getFirestore } from '@angular/fire/firestore';
import { environment } from '../../../environments/environment';
import { AuthStoreService } from '../../features/auth/store/auth-store.service';
import type { ProjectsShardFirebaseName } from '../../features/project/model';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { errorToFailure, failure, success } from '@shared/libs/error';
import { PROJECTS_SHARD } from '@shared/libs/storage/firebase';
import { isTokenExpired } from '@shared/libs/auth';
import { ProjectShardToken } from '../../features/auth/model';
import { FirebaseStorage, getStorage } from '@angular/fire/storage';

type CreateCustomTokenResponse = {
  custom_token: string;
  location: ProjectsShardFirebaseName;
};

type CreateCustomTokenPayload = {
  id_token: string;
  target_project_id: string;
};

type ProjectFirebase = {
  service_config: FirebaseOptions;
}

@Injectable({
  providedIn: 'root'
})
export class ProjectsShardService {
  private authFireStore = inject(Firestore);
  public auth: Auth;
  public fireStore: Firestore;
  public storage: FirebaseStorage;

  constructor(private authStore: AuthStoreService, private http: HttpClient) { }

  // TODO: remove this method, this is a temporary method to initialize firebase,
  // because in project list currently we still dont get the project shard config
  // because depends on project id
  tempInitializeFirebase() {
    const app = initializeApp(environment.firebase2, `${PROJECTS_SHARD}_${environment.buildConfiguration}`);
    this.auth = getAuth(app);
    this.fireStore = getFirestore(app);
    this.storage = getStorage(app);
  }

  async initializeFirebase(projectID: string) {
    try {
      const config = await this.getConfig(projectID);
      if (!config.isSuccess) return errorToFailure(config.error);
      const app = initializeApp(config.value, `${PROJECTS_SHARD}_${environment.buildConfiguration}_${config.value.projectId}`);
      this.auth = getAuth(app);
      this.fireStore = getFirestore(app);
      this.storage = getStorage(app);
      return success(null);
    } catch (error) {
      return errorToFailure(error);
    }
  }

  async getProjectsShardConfig(projectID: string) {
    const savedProjectsShardConfig = this.authStore.projectsShardConfigByID()[projectID];
    if (savedProjectsShardConfig && !isTokenExpired(savedProjectsShardConfig.customToken)) return success(savedProjectsShardConfig);
    try {
      const { custom_token: customToken, location: projectsShardFirebaseName } = await lastValueFrom(this.http
        .post<CreateCustomTokenResponse>(
          `${environment.apiUrl}/createCustomToken`,
          {
            id_token: this.authStore.userToken(),
            target_project_id: projectID,
          } as CreateCustomTokenPayload
        ));
      this.authStore.setProjectsShardConfigByProjectID(projectID, { customToken, firebaseName: projectsShardFirebaseName });
      return success({ customToken, firebaseName: projectsShardFirebaseName });
    } catch (error) {
      return failure(error);
    }
  }

  async getConfig(projectID: string) {
    try {
      const projectsShard = await this.getProjectsShardConfig(projectID);
      if (!projectsShard.isSuccess) return failure(projectsShard.error);
      const projectsShardRef = doc(
        this.authFireStore, 'project_firebase', projectsShard.value.firebaseName
      ) as DocumentReference<ProjectFirebase>;
      const projectsShardDoc = (await getDoc(projectsShardRef)).data();
      return projectsShardDoc ? success(projectsShardDoc.service_config) : failure("FAILED_GET_PROJECT_SHARD");
    } catch (error) {
      return failure(error);
    }
  }

  async getTokenResult(userCredential: UserCredential) {

    const tokenResultRef = (await userCredential.user.getIdTokenResult())

    const projectShardTokenRef = {
      token : tokenResultRef.token,
      authTime : tokenResultRef.authTime,
      expirationTime: tokenResultRef.expirationTime,
    } as unknown as ProjectShardToken;

    this.authStore.setprojectsShardToken(projectShardTokenRef);

    return projectShardTokenRef;
  }
}
