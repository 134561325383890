import { Injectable, computed, signal } from '@angular/core';
import type { Project, ProjectStore } from '../model';

const sortByUpdatedAt = (a: Project, b: Project) => {
  if (a.updated_at && b.updated_at) {
    return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
  } else if (!a.updated_at && b.updated_at) {
    return 1;
  } else if (a.updated_at && !b.updated_at) {
    return -1;
  }
  return a.display_name.localeCompare(b.display_name);
}

@Injectable({
  providedIn: 'root'
})
export class ProjectStoreService {

  private _store = signal<ProjectStore>({
    projectList: [],
    templateList: [],
    selectedTemplate: null,
    activeDialog: null,
    projectDetail: null,
    isReleased: false,
    planDetail: null,
    role: null,
    gSheetFiles: [],
    selectedFile: null,
    googleSheetAccessToken: ''
  })

  projectList = computed(() => this._store().projectList.filter(project => project.role !== 'end-user').sort(sortByUpdatedAt));
  templateList = computed(() => this._store().templateList);
  selectedTemplate = computed(() => this._store().selectedTemplate);
  activeDialog = computed(() => this._store().activeDialog);
  projectDetail = computed(() => this._store().projectDetail);
  isReleased = computed(() => this._store().isReleased);
  planDetail = computed(() => this._store().planDetail);
  role = computed(() => this._store().role);
  gSheetFiles = computed(() => this._store().gSheetFiles);
  selectedFile = computed(() => this._store().selectedFile);
  googleSheetAccessToken = computed(() => this._store().googleSheetAccessToken);

  constructor() { }

  setProjectList(projectList: ProjectStore['projectList']) {
    this._store.update((store) => ({ ...store, projectList }))
  }

  setProjectDetail(projectDetail: ProjectStore['projectDetail']) {
    this._store.update((store) => ({ ...store, projectDetail }))
  }

  setTemplateList(templateList: ProjectStore['templateList']) {
    this._store.update((store) => ({ ...store, templateList }))
  }

  setSelectedTemplate(selectedTemplate: ProjectStore['selectedTemplate']) {
    this._store.update((store) => ({ ...store, selectedTemplate }))
  }

  setActiveDialog(activeDialog: ProjectStore['activeDialog']) {
    this._store.update((store) => ({ ...store, activeDialog }))
  }

  setStatusProject(isReleased: ProjectStore['isReleased']) {
    this._store.update((store) => ({ ...store, isReleased }))
  }

  setPlanDetail(planDetail: ProjectStore['planDetail']) {
    this._store.update((store) => ({ ...store, planDetail }))
  }

  setRole(role: ProjectStore['role']) {
    this._store.update((store) => ({ ...store, role }))
  }

  setGSheetFiles(gSheetFiles: ProjectStore['gSheetFiles']) {
    this._store.update((store) => ({ ...store, gSheetFiles }))
  }

  setselectedFile(selectedFile: ProjectStore['selectedFile']) {
    this._store.update((store) => ({ ...store, selectedFile }))
  }

  setGoogleSheetAccessToken(googleSheetAccessToken: ProjectStore['googleSheetAccessToken']) {
    this._store.update((store) => ({ ...store, googleSheetAccessToken }))
  }
}
