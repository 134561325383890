import { Component, computed, effect, signal } from '@angular/core';
import { FibrBaseComponentTypeComponent } from '../../../page-component/page-component-types/base-component-type.component';
import { FormDataSource, FormType } from '../../../../model';
import { ColumnField } from '../../../../../table/model';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import {
  SelectButtonChangeEvent,
  SelectButtonModule,
} from 'primeng/selectbutton';
import { FibrCollapseComponent } from '@shared/components/collapse/collapse.component';
import { ChipsModule } from 'primeng/chips';
import { v4 } from 'uuid';

@Component({
  selector: 'fibr-page-component-dropdown',
  template: `
    <ng-template #dataTemplate let-tableColumns="columns">
      <div class="flex flex-col">
        <fibr-collapse label="Data">
          <span class="text-lg capitalize text-[#8A8A8A] mb-2">
            {{ 'editor.pages.column' | translate }}
          </span>

          <p-dropdown
            name="form_column"
            [options]="tableColumns"
            [(ngModel)]="config().column"
            placeholder="{{ 'editor.pages.column' | translate }}"
            optionLabel="label"
            optionValue="name"
          ></p-dropdown>
        </fibr-collapse>

        <fibr-collapse label="Content">
          <div class="flex flex-col space-y-3">
            <div>
              <span class="text-lg capitalize text-[#8A8A8A] mb-2">
                {{ 'Content Type' | translate }}
              </span>

              <p-selectButton
                name="content_type"
                styleClass="fibr-selectbutton mt-1"
                [options]="dataTypes"
                [(ngModel)]="config().data_source!.type"
              ></p-selectButton>
            </div>

            @switch (config().data_source!.type) { @case('static') {
            <div>
              <span class="text-lg capitalize text-[#8A8A8A] mb-2">
                {{ 'editor.pages.form.value_list' | translate }}
              </span>

              <p-chips
                name="data_source_values"
                [(ngModel)]="config().data_source!.value"
              ></p-chips>
            </div>
            } @case('table') {
            <div class="flex flex-col space-y-3">
              <div>
                <span class="text-lg capitalize text-[#8A8A8A] mb-2">
                  {{ 'editor.pages.data_source' | translate }}
                </span>

                <p-dropdown
                  name="data_source_table"
                  placeholder="{{ 'editor.pages.data_source' | translate }}"
                  [options]="tables()"
                  optionLabel="label"
                  optionValue="id"
                  [(ngModel)]="config().data_source!.table_id"
                  (ngModelChange)="changeSource($event)"
                ></p-dropdown>
              </div>

              <div>
                <span class="text-lg capitalize text-[#8A8A8A] mb-2">
                  {{ 'editor.pages.column' | translate }}
                </span>

                <p-dropdown
                  name="data_source_column"
                  placeholder="{{ 'editor.pages.column' | translate }}"
                  [options]="columns()"
                  [(ngModel)]="config().data_source!.value"
                  optionLabel="label"
                  optionValue="name"
                ></p-dropdown>
              </div>
            </div>
            } }
          </div>
        </fibr-collapse>

        <fibr-collapse label="editor.pages.form.field_list.label">
          <span class="text-lg capitalize text-[#8A8A8A] mb-2">
            {{ 'editor.pages.form.field_list.label' | translate }}
          </span>

          <input
            name="form_label"
            pInputText
            [(ngModel)]="config().label"
            placeholder="{{ 'editor.pages.form.field_list.label' | translate }}"
          />
        </fibr-collapse>

        <fibr-collapse label="editor.pages.form.field_list.validation">
          <span class="text-lg capitalize text-[#8A8A8A] mb-2">
            {{ 'editor.pages.form.field_list.validation' | translate }}
          </span>

          <p-selectButton
            name="form_validation"
            styleClass="fibr-selectbutton mt-1"
            [options]="['required', 'optional']"
            [ngModel]="required()"
            (onChange)="changeValidation($event)"
          >
            <ng-template let-item pTemplate>
              {{ 'editor.pages.form.' + item | translate }}
            </ng-template>
          </p-selectButton>
        </fibr-collapse>
      </div>
    </ng-template>
  `,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    DropdownModule,
    ChipsModule,
    FormsModule,
    SelectButtonModule,
    FibrCollapseComponent,
  ],
})
export class FibrPageComponentDropdownComponent extends FibrBaseComponentTypeComponent {
  override label: string = 'EDITOR.COMPONENT_FORM_TYPES.choice.LABEL';
  override icon: string = 'assets/images/icon/form/icon_choice.svg';
  override category: string = 'form';
  override type: string = 'choice';

  readonly dataTypes = [
    {
      label: 'Static',
      value: 'static',
    },
    {
      label: 'Dynamic',
      value: 'table',
    },
  ];

  config = signal<FormType['config']>({
    label: '',
    column: '',
    is_disabled: false,
    is_required: false,
    placeholder: '',
    data_source: {
      type: 'static',
      value: [],
    },
  });
  columns = signal<ColumnField[]>([]);

  // prettier-ignore
  required = computed(() => this.config().is_required === true ? 'required' : 'optional');
  tables = computed(() =>
    this.pageComponentStore
      .tableSource()
      .filter((x) => x.id !== this.pageComponentStore.tableSelected())
  );

  changeValidation(event: SelectButtonChangeEvent) {
    this.config.update((val) => ({
      ...val,
      is_required: event.value === 'required',
    }));
  }

  selectSource(sourceId: string) {
    const tables = this.tables();
    const selectedColumns = tables
      .filter((i) => i.id === sourceId)
      .at(0)?.columns;

    if (!selectedColumns) return;

    const columns = Object.keys(selectedColumns)
      .map((o) => selectedColumns[o])
      .filter((x) => x.name !== 'id')
      .sort((a, b) => a.index - b.index);

    this.columns.set(columns);
  }

  changeSource(sourceId: string) {
    this.selectSource(sourceId);
    this.config.update((val) => ({
      ...val,
      data_source: {
        type: 'table',
        table_id: sourceId,
        value: '',
      },
    }));
  }

  constructor() {
    super('fibr-page-component-dropdown');

    effect(
      () => {
        const selected = this.pageComponentStore.dialogForm().selected;
        if (selected) {
          const dataSource = <FormDataSource>selected.config.data_source;
          this.config.update((val) => ({
            ...val,
            ...selected.config,
          }));

          if (dataSource) {
            this.config.update((val) => ({
              ...val,
              data_source: {
                ...dataSource,
                value:
                  dataSource.type === 'static'
                    ? JSON.parse(<string>dataSource.value)
                    : dataSource.value,
              },
            }));
          }
        }
      },
      { allowSignalWrites: true }
    );
  }

  reset() {
    setTimeout(() => {
      this.config.set({
        label: '',
        column: '',
        is_disabled: false,
        is_required: false,
        placeholder: '',
        data_source: {
          type: 'static',
          value: [],
        },
      });
      this.pageComponentStore.closeForm();
    }, 200);
  }

  override apply() {
    const dataSource = {};

    switch (this.config().data_source!.type) {
      case 'table':
        // eslint-disable-next-line functional/immutable-data
        Object.assign(dataSource, {
          type: 'table',
          table_id: this.config().data_source?.table_id,
          value: this.config().data_source?.value,
        });
        break;
      case 'static':
        // eslint-disable-next-line functional/immutable-data
        Object.assign(dataSource, {
          type: 'static',
          value: JSON.stringify(this.config().data_source!.value),
        });
        break;
    }

    const output: FormType = {
      id: this.pageComponentStore.dialogForm().selected?.id || v4(),
      type: 'choice',
      config: {
        label: this.config().label,
        column: this.config().column,
        is_disabled: this.config().is_disabled,
        is_required: this.config().is_required,
        placeholder: this.config().label,
        data_source: <FormDataSource>dataSource,
      },
    };

    this.pageComponentStore.addOrEditForm(output);
    this.reset();

    return output;
  }

  override cancel(): void {
    this.reset();
  }

  protected override onDestroy(): void {
    this.reset();
  }
}
