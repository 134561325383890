<div class="grid pt-5">
  <div class="col-12 md:col-6">
    <div  class="flex p-flex-column p-flex-md-row">
      <img
        alt="icon"
        src="/assets/images/icon/icon_project-lg.svg"
        height="20"
        class="align-self-center mr-3"
      />
      <h3 class="menu-title">
        {{ "PROJECT.TITLE_LIST" | translate }}
      </h3>
    </div>
  </div>
  <div class="col-12 md:col-6 text-right">
    <p-selectButton
      [options]="projectOptions"
      [ngModel]="this.selectedFilter()"
      (ngModelChange)="selectedFilter.set($event)"
      optionLabel="name"
      optionValue="value"
      styleClass="p-button-lg multi-select-button"
    ></p-selectButton>
  </div>
</div>
@if (projects().length === 0){
<div class="grid project-dataview">
  <div class="col-12">
    <div class="grid center h-100">
      <div class="col-12 center flex-column text-center">
        <img src="/assets/images/ani_empty.gif" alt="Icon Support" />
        <h5 class="menu-subtitle mt-0 mb-3">
          {{ "PROJECT.LIST_EMPTY" | translate }}
        </h5>
        <p class="menu-subtitle mb-3">
          {{ "PROJECT.LIST_EMPTY_DESC" | translate }}
        </p>
        <div class="grid center w-100">
          <div class="col-12 md:col-6 lg:col-4">
            <p-accordion
              styleClass="acc-usage"
              (click)="onClickCreateNewProject()"
            >
              <p-accordionTab class="acc-btn-new">
                <ng-template pTemplate="header">
                  <div class="list-item p-0">
                    <span
                      class="p-menuitem-icon pi pi-fw pi-plus-circle text-7xl"
                    ></span>
                    <div class="list-detail text-left">
                      <h5 class="list-title m-0">
                        {{ "PROJECT.CREATE_NEW" | translate }}
                      </h5>
                    </div>
                    <div class="product-list-action">
                      <span
                        class="p-menuitem-icon pi pi-fw pi-chevron-right p-5"
                      ></span>
                    </div>
                  </div>
                </ng-template>
              </p-accordionTab>
            </p-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
} @else {
<div class="grid">
  <div class="col-12">
    <p-dataView #dv [value]="projects()" [paginator]="true" [rows]="11">
      <ng-template pTemplate="list" let-projects>
        <div class="grid grid-nogutter">
          <div class="col-12 md:col-6 lg:col-4">
            <p-accordion
              styleClass="acc-usage"
              (click)="onClickCreateNewProject()"
            >
              <p-accordionTab class="acc-btn-new">
                <ng-template pTemplate="header">
                  <div class="list-item p-0">
                    <span
                      class="p-menuitem-icon pi pi-fw pi-plus-circle text-7xl mr-3"
                    ></span>
                    <div class="list-detail">
                      <h5 class="list-title m-0">
                        {{ "PROJECT.CREATE_NEW" | translate }}
                      </h5>
                    </div>
                    <div class="product-list-action">
                      <span
                        class="p-menuitem-icon pi pi-fw pi-chevron-right p-5"
                      ></span>
                    </div>
                  </div>
                </ng-template>
              </p-accordionTab>
            </p-accordion>
          </div>
          <div class="col-12 md:col-6 lg:col-4" *ngFor="let item of projects; let first = first">
            <p-accordion styleClass="acc-usage relative overflow-hidden">
              <p-accordionTab>
                <ng-template pTemplate="header">
                  
                  <!-- //ribbon pro -->
                  <!-- <div class="absolute right-0 top-0 h-[32px] w-[32px]">
                    <div
                      class="absolute transform rotate-45 bg-gradient-to-r from-sky-500 to-sky-300 text-center text-white font-semibold py-1 diagonal">
                      PRO
                    </div>
                  </div> -->
                  <div class="list-item p-0">
                    <img
                      [src]="item.icon"
                      [alt]="item.display_name"
                      onerror="this.src='/assets/images/icon/iconic_logo.png'"
                    />
                    <div
                      class="list-detail"
                      (click)="showConfirmationToEditor(item)"
                      (keyup.enter)="showConfirmationToEditor(item)"
                      tabindex="0"
                    >
                      @if (item?.updated_at) {
                        <span class="text-xs mb-0">
                          {{ item?.updated_at | date:'dd MMM yyyy' }}
                        </span>
                      }
                      <h5 class="list-title mt-0 mb-1">
                        {{ item?.display_name }}
                      </h5>
                      <div class="flex flex-row gap-2">
                        @if(item?.status === 'released'){
                          <span class="p-tag p-component p-tag-published">
                            <span class="p-tag-value">Published</span>
                          </span>
                        } @else {
                          <span class="p-tag p-component p-tag-secondary">
                            <span class="p-tag-value">Draft</span>
                          </span>
                        }

                        @if(item?.plans){

                          @if (getDaysFromToday(item.plans.end_date) <= 7 && getDaysFromToday(item.plans.end_date) >= 0){
                            <span class="p-tag p-component p-tag-expired">
                              <span class="p-tag-value">{{item.plans.status | uppercase}} {{item.plans.end_date | date:'dd/MM/yyyy'}} </span>
                            </span>
                          } 
                          
                          @if (getDaysFromToday(item.plans.end_date) > 7 && getDaysFromToday(item.plans.end_date) >= 0) {
                            <span class="p-tag p-component p-tag-pro">
                              <span class="p-tag-value">{{item.plans.status | uppercase}} {{item.plans.end_date | date:'dd/MM/yyyy'}} </span>
                            </span>
                          }
                        }
                      </div>
                    </div>
                    <div class="product-list-action">
                      <p-button
                        id="dropdown-project-list"
                        styleClass="p-button p-button-text"
                        icon="pi pi-ellipsis-v"
                        (click)="more.toggle($event); setProject(item)"
                      >
                      </p-button>
                      <p-menu
                        #more
                        [popup]="true"
                        [model]="itemsProject"
                        appendTo="body"
                      ></p-menu>
                    </div>
                  </div>
                </ng-template>
              </p-accordionTab>
            </p-accordion>
          </div>
        </div>
      </ng-template>
    </p-dataView>
  </div>
</div>
}
