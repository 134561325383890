import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { HorizontalmenuComponent } from '../../../shared/components/topbar/horizontalmenu/horizontalmenu.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'fibr-header-v1',
  standalone: true,
  imports: [HorizontalmenuComponent, CommonModule],
  templateUrl: './header-v1.component.html',
  styleUrl: './header-v1.component.scss',
})
export class HeaderV1Component {
  isShowTopMenu: boolean = true;
  @Input() items: MenuItem[] = [];
  @Input() srcImg!: string;
  @Input() userProfilePath = '/portal/config/user-profile';
  @Input() aksesFrom!: string;
}
