import { Injectable, inject } from '@angular/core';
import type { BodyItem, Page, Invitation, UserAccess } from '../model';
import { v4 as uuidv4 } from 'uuid';
import { collection, collectionData, doc, getDoc, setDoc, updateDoc } from '@angular/fire/firestore';
import { EditorStoreService } from '../store/editor-store.service';
import { ProjectsShardService } from '../../../core/projects-shard/projects-shard.service';
import { addDocument, deleteAllDocuments, deleteDocument } from '@shared/libs/storage/firestore';
import { failure, success } from '@shared/libs/error';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthStoreService } from '../../auth/store/auth-store.service';
import { environment } from '../../../../environments/environment';
import { lastValueFrom } from 'rxjs';
import { APIResponse } from '@shared/libs/http/response';

@Injectable({
  providedIn: 'root'
})
export class EditorAPIService {
  private projectShard = inject(ProjectsShardService);

  constructor(private editorStore: EditorStoreService, private authStore: AuthStoreService, private http: HttpClient) { }


  async getPageApp(projectID: string) {
    const projectRef = collection(
      this.projectShard.fireStore,
      `/projects/${projectID}/app_config`
    );
    return collectionData(projectRef, { idField: 'id' }).subscribe((pages) => {
      this.editorStore.setPageList(pages as Page[]);
    }).unsubscribe;
  }

  async getPageAppByID(projectID: string, pageID: string) {
    try {
      const appConfigPageRef = doc(
        this.projectShard.fireStore,
        `/projects/${projectID}/app_config/${pageID}`
      );

      const selectedPageConfig = (await getDoc(appConfigPageRef)).data() as Page;

      this.editorStore.setSelectedPageConfig(selectedPageConfig)
      return success(null)
    }
    catch (error) {
      return failure('PROJECT.FAILED_TO_FETCH_PROJECT_DETAIL')
    }
  }

  async getPagePropertyComponent(projectID: string, pageID: string) {

    try {
      const projectRef = doc(
        this.projectShard.fireStore,
        `/projects/${projectID}/app_config/${pageID}/pages/main`
      );
      const pagePropertyComponentRef = (await getDoc(projectRef)).data();

      if (pagePropertyComponentRef) {
        const pagePropertyComponentConfig = JSON.parse(pagePropertyComponentRef['body']);
        this.editorStore.setSelectedPagePropertyComponent(pagePropertyComponentConfig);
        return success(null);
      } else {
        this.editorStore.setSelectedPagePropertyComponent(null);
        return failure('PROJECT.FAILED_TO_FETCH_PROJECT_DETAIL');
      }
    } catch (error) {
      return failure('PROJECT.FAILED_TO_FETCH_PROJECT_DETAIL');
    }
  }

  async getPageConfig(projectID: string, pageID: string) {
    const projectRef = doc(
      this.projectShard.fireStore,
      `/projects/${projectID}/app_config/${pageID}/pages/main`
    );
    const pageConfig = await getDoc(projectRef);
    const appBar = pageConfig.data()!;
    // TODO handle error using success and failure type
    return appBar;
  }

  async createInitialPage(projectID: string, pageProperty: Page, pagePropertyComponent: BodyItem[], index: number) {
    let showInNavbar: boolean = false;

    switch(pageProperty.type) {
      case 'basic':
        showInNavbar = index < 5 ? true : false;
        break;
      case 'form':
        showInNavbar = false;
    }

    try {
        const pageIDRef = await addDocument(this.projectShard.fireStore, {
          data : {
            index: index,
            name: pageProperty.name,
            type: pageProperty.type,
            icon: pageProperty.icon ?? "airplay",
            show_in_nav_bar: showInNavbar
          },
        },`/projects/${projectID}/app_config`);

        const docApConfig = doc(
          this.projectShard.fireStore,
          `/projects/${projectID}/app_config/${pageIDRef}/pages/main`
        );
        await setDoc(docApConfig, this.editorToBuilderFormat(pageProperty, pagePropertyComponent));
      return success(pageIDRef);
    } catch (error) {
      return failure('Failed to create page');
    }
  }

  async updatePageProperty(projectID: string, pageID: string, pageProperty: Page, pagePropertyComponent: BodyItem[]) {
    const data = {
      id : pageID,
      index: pageProperty.index,
      name: pageProperty.name,
      type: pageProperty.type,
      icon: pageProperty.icon ?? "airplay",
    }
    try {
      const pageRef = doc(this.projectShard.fireStore, `/projects/${projectID}/app_config/${pageID}`);
      await updateDoc(pageRef, data);

      const docApConfig = doc(
        this.projectShard.fireStore,
        `/projects/${projectID}/app_config/${pageID}/pages/main`
      );
      await updateDoc(docApConfig, this.editorToBuilderFormat(pageProperty, pagePropertyComponent));

      return success(null)
    } catch (error) {
      return failure('Failed to update data');
    }
  }

  editorToBuilderFormat(pageProperty : Page, widgets: BodyItem[]) {
    return {
      app_bar: JSON.stringify({
        id: uuidv4(),
        title: pageProperty.name,
        style: {
          use_divider: true,
          brightness: "dark"
        },
        actions: null,
      }),
      body: JSON.stringify({
        id: uuidv4(),
        data: widgets
      })
    }
  }

  async deletePage(projectID: string, pageID: string) {
    try{
      await deleteDocument(this.projectShard.fireStore, `/projects/${projectID}/app_config/`, pageID);
      await deleteAllDocuments(this.projectShard.fireStore, `/projects/${projectID}/app_config/${pageID}/pages`,);
      return success(null)
    } catch (error) {
      return failure('Failed to delete page');
    }

  }

  async publishApp(projectID: string) {
    const headers = new HttpHeaders().set('Authorization', this.authStore.projectsShardToken()?.token ?? '');
    try {
      await lastValueFrom(this.http.post(`${environment.apiProjectUrl}/publishProject`,
      { project_id: projectID } , { headers }));
      return success(null) ;
    } catch (error) {
      return failure('EDITOR.FAILED_PUBLISH_APP');
    }
  }

  async orderPage(projectID: string, pageID: string, index: number, showInNavBar: boolean) {
    const data = {
      index: index,
      show_in_nav_bar: showInNavBar
    }
    const pageRef = doc(this.projectShard.fireStore, `/projects/${projectID}/app_config/${pageID}`);
    await updateDoc(pageRef, data);
  }

  async handleMoveToMenu(projectID: string, pageID: string, showInNavBar: boolean) {
    const data = {
      show_in_nav_bar: showInNavBar
    }
    const pageRef = doc(this.projectShard.fireStore, `/projects/${projectID}/app_config/${pageID}`);
    await updateDoc(pageRef, data);
  }

  async sendInvitations(emails: string[], projectID: string, role: string) {
    try {
      const res = await Promise.all(emails.map((email) => this.sendInvitation(email, projectID, role)));

      if (res.every((r) => r.isSuccess)) {
        return success(null);
      } else {
        return failure('Too many requests, please try again later');
      }
    } catch (error) {
      return failure(error);
    }
  }

  async sendInvitation(email: string, projectID: string, role: string){
    const headers = new HttpHeaders().set('Authorization', this.authStore.projectsShardToken()?.token ?? '');
    try {
      await lastValueFrom(this.http.post(`${environment.apiProjectUrl}/sendInvitationAppToUser`,
      { email: email, project_id: projectID, role:role } , { headers }));
      return success(null);
    } catch (error) {
      return failure(error);
    }
  }

  getUserInvited(projectID: string) {
    const userRef = collection(
      this.projectShard.fireStore,
      `/projects/${projectID}/invitations/logs/data`
    );
    return collectionData(userRef, { idField: 'id' }).subscribe((users) => {
      this.editorStore.setUsersInvited(users as Invitation[]);
    }).unsubscribe;
  }

  async validateInvitation<T>(token: string) {
    const headers = new HttpHeaders().set('Authorization', this.authStore.userToken());

    try {
      const result = await lastValueFrom(this.http.post<APIResponse<T>>(`${environment.apiUrl}/validateInvitation`,
      { invitation_token: token }, { headers, observe: 'response'}));
      return success(result);
    } catch (error) {
      return failure(error);
    }

  }

  async getUserAccess(projectID: string) {
    const headers = new HttpHeaders().set('Authorization', this.authStore.projectsShardToken()?.token ?? '');
    try {
      const usersRefAccess = await lastValueFrom(this.http.post<APIResponse<UserAccess[]>>(`${environment.apiProjectUrl}/listAccess`,
      { project_id: projectID }, { headers }));
      this.editorStore.setUserAccess(usersRefAccess.data);
      return success(null);
    } catch (error) {
      return failure(error);
    }

  }

  async removeUserAccess(projectID: string, userID: string) {
    const headers = new HttpHeaders().set('Authorization', this.authStore.projectsShardToken()?.token ?? '');
    try {
      await lastValueFrom(this.http.post(`${environment.apiProjectUrl}/removeAccess`,
      { project_id: projectID, user_id: userID }, { headers }));
      return success(null);
    } catch (error) {
      return failure(error);
    }
  }

  async setAsOwner(projectID: string, userID: string) {

    const data = {
      role: 'owner'
    }

    const pageRef = doc(this.projectShard.fireStore, `/projects/${projectID}/access/${userID}`);
    const docSnap = await getDoc(pageRef);
    if (docSnap.exists()) {
      await updateDoc(pageRef, data);
      return success(null);
    } else {
      return failure('User not found');
    }

  }

  async updateBodyConfig(projectID: string, pageID: string, body: string) {
    const data = {
      body: body
    }

    try {
      const pageRef = doc(this.projectShard.fireStore, `/projects/${projectID}/app_config/${pageID}/pages/main`);
      await updateDoc(pageRef, data);
      return success(null);
    }
    catch (error) {
      return failure('Failed to update data');
    }

  }
}
