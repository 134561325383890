import { Injectable, inject } from '@angular/core';
import {
  Auth,
  GoogleAuthProvider,
  authState,
  createUserWithEmailAndPassword,
  signInWithPopup,
  type User,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  updateProfile as fireUpdateProfile,
} from '@angular/fire/auth';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from '@angular/fire/storage';
import { Router } from '@angular/router';
import { FirebaseApp, FirebaseError } from '@angular/fire/app';
import type {
  FormAccountPageSchema,
  RegisterParams,
  UserAccount,
} from '../model';
import { DocumentData, Firestore, doc, getDoc, updateDoc } from '@angular/fire/firestore';
import { error_constants, failure, success } from '../../../shared/libs/error';
import { AuthStoreService } from '../store/auth-store.service';
import { ProjectsShardService } from '../../../core/projects-shard/projects-shard.service';
import { Input } from 'valibot';

@Injectable({
  providedIn: 'root',
})
export class AuthAPIService {
  private auth: Auth = inject(Auth);
  private authFireStore = inject(Firestore);
  private firebase = inject(FirebaseApp);

  constructor(
    private router: Router,
    private authStore: AuthStoreService,
    private projectsShard: ProjectsShardService
  ) {}

  fetchLoggedInUser() {
    const authState$ = authState(this.auth).subscribe(async (user) => {
      if (!user) {
        this.authStore.setUser(null);
        this.authStore.setUserToken('');
      } else {
        const token = (await user?.getIdToken()) ?? '';
        this.authStore.setUser(user);
        this.authStore.setUserToken(token);
      }
    });
    return authState$.unsubscribe;
  }

  async register({ email, password, fullName }: RegisterParams) {
    try {
      const credential = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      await this.handleCheckUserDocCreated(credential.user, fullName);
      this.auth.signOut();
      return success(null);
    } catch (error) {
      if (
        error instanceof FirebaseError &&
        error.code === error_constants.FIREBASE_AUTH_EMAIL_ALREADY_IN_USE
      ) {
        return failure('AUTH.EMAIL_ALREADY_IN_USE');
      }
      return failure('AUTH.USER_REGISTRATION_FAILED');
    }
  }

  async signInByEmail(email: string, password: string) {
    try {
      const result = await signInWithEmailAndPassword(
        this.auth,
        email,
        password
      );
      if (!result.user.emailVerified) return failure('AUTH.EMAIL_NOT_VERIFIED');
      return success(null);
    } catch (error) {
      return failure('AUTH.USER_SIGN_IN_FAILED');
    }
  }

  async googleSignIn() {
    const provider = new GoogleAuthProvider();
    try {
      provider.setCustomParameters({ prompt: 'select_account' });
      await signInWithPopup(this.auth, provider);
      return success(null);
    } catch (error) {
      return failure('AUTH.USER_SIGN_IN_FAILED');
    }
  }

  async signOut() {
    await this.auth.signOut();
    if (this.projectsShard.auth.currentUser) {
      await this.projectsShard.auth.signOut();
    }
    this.authStore.resetProjectsShardConfigByProjectID();
    this.router.navigate(['/']);
  }

  async sendLinkForgotPassword(userEmail: string) {
    try {
      await sendPasswordResetEmail(this.auth, userEmail);
      return success(null);
    } catch (error) {
      return failure('FAILED_SEND_FORGOT_PASSWORD');
    }
  }

  async changePassword(oldPassword: string, newPassword: string) {
    const user = this.authStore.user();
    if (!user?.email) return failure('AUTH.FAILED_TO_CHANGE_PASSWORD');
    try {
      const credential = EmailAuthProvider.credential(user.email, oldPassword);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      return success(null);
    } catch (error) {
      return failure('AUTH.FAILED_TO_CHANGE_PASSWORD');
    }
  }

  private handleCheckUserDocCreated(user: User, fullName: string) {
    let checkedTimes = 0;
    return new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        checkedTimes++;
        if (checkedTimes > 5) {
          clearInterval(interval);
          reject('There was an error while creating user data');
        }
        const userRef = doc(this.authFireStore, '/users', user?.uid);
        try {
          const userDoc = await getDoc(userRef);
          if (!userDoc.exists()) return;
          await updateDoc(userRef, {
            full_name: fullName,
          });
          await sendEmailVerification(user);
          clearInterval(interval);
          resolve(null);
        } catch (error) {
          reject(error);
        }
      }, 2000);
    });
  }

  async getProfile(uid?: string) {
    const user = this.authStore.user();
    const userRef = doc(this.authFireStore, '/users', <string>uid || <string>user?.uid);
    return {
      ...(await getDoc(userRef)).data() as UserAccount,
      display_name: <string>user?.displayName,
    };
  }

  async uploadPicture(image: File) {
    const user = this.authStore.user();
    const storage = getStorage();
    const storageRef = ref(storage, `users/${user!.uid}/profile-picture/${user!.uid}`);

    return uploadBytesResumable(storageRef, image);
  }

  async updateProfile(params: Input<typeof FormAccountPageSchema>) {
    const user = this.authStore.user();
    const userRef = doc(this.authFireStore, '/users', user!.uid);

    if (params.photoPicture instanceof File) {
      const picture = await this.uploadPicture(params.photoPicture as File);

      picture.task.on(
        'state_changed',
        () => {},
        () => {},
        async () => {
          const pictureUrl = await getDownloadURL(picture.task.snapshot.ref);

          // update firestore
          updateDoc(userRef, {
            photo_url: pictureUrl,
          });

          // update firebase auth
          fireUpdateProfile(user as User, {
            photoURL: pictureUrl,
          });
        }
      );
    }

    fireUpdateProfile(user as User, {
      displayName: params.name,
    })

    return updateDoc(userRef, {
      full_name: params.name,
      display_name: params.name,
      email: params.email,
      phone_number: params.phoneNumber,
      gender: params.gender,
      birthdate: params.birthdate,
      address: params.address,
      interest: params.interest,
    });
  }

  async getOptionsBoarding(docPath: string) {
    const docRef = doc(this.authFireStore, `/global_config`, 'profilling');
    const docs = await getDoc(docRef);
    const selectedDoc = <DocumentData>docs.data();
    return selectedDoc[docPath];
  }

  async onboarding(params: Record<string, string | string[]>) {
    const user = this.authStore.user();
    const userRef = doc(this.authFireStore, `/users`, user!.uid);

    return updateDoc(userRef, {
      ...params,
      is_onboard: true,
    })
  }
}
