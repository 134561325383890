<form class="flex flex-column gap-3" (ngSubmit)="onSubmit()">
  <div class="d-none">
    <h4 class="text-primary font-light">Theme</h4>
  </div>

  <div>
    <div class="grid">
      <div class="lg:col-8 col-12">
        <div class="grid">
          @for (theme of listThemes; track $index) {
          <div class="lg:col-4 col-12 p-0 d-none">
            <button class="text-left" (click)="changeTheme(theme.value)">
              <div
                class="border-2 rounded-xl mb-2 mx-2"
                [ngClass]="{
                  'border-[#D9D9D9]': themeColor().theme !== theme.value,
                  'border-primary': themeColor().theme === theme.value,
                }"
              >
                <img [src]="theme.icon" alt="minimal" />
              </div>
              <span
                class="text-lg font-semibold"
                [ngClass]="{
                  'text-gray-primary': themeColor().theme === theme.value
                }"
              >
                {{ theme.label }}
              </span>
            </button>
          </div>
          }
          <div class="col-12 p-0 d-none">
            <div class="my-3">
              <label class="text-lg" for="mode">Mode</label>
              <p-selectButton
                name="theme_mode"
                styleClass="mt-2 p-button-lg button-select"
                [options]="['light', 'dark']"
                [(ngModel)]="themeColor().mode"
              >
                <ng-template let-item pTemplate>
                  <span class="capitalize">{{ item }}</span>
                </ng-template>
              </p-selectButton>
            </div>
            <p-divider></p-divider>
          </div>
          <div class="col-12 p-0">
            <div>
              <h4 class="text-primary font-light">Color</h4>
            </div>

            <div class="grid">
              <div class="lg:col-4 col-12">
                <label class="block text-lg font-medium" for="primary_color">
                  {{ 'PROJECT.app_settings.color.primary_color' | translate }}
                </label>

                <div class="mt-2 flex align-items-center gap-2">
                  <ngx-colors ngx-colors-trigger id="primary_color" format="hex" colorPickerControls="no-alpha" name="primary_color" [(ngModel)]="themeColor().primary_color"></ngx-colors>
                  <!-- <p-colorPicker
                    id="primary_color"
                    name="primary_color"
                    [(ngModel)]="themeColor().primary_color"
                  ></p-colorPicker> -->
                  <span class="text-lg uppercase">{{ themeColor().primary_color }}</span>
                </div>
              </div>

              <div class="lg:col-4 col-12">
                <label class="block text-lg font-medium" for="secondary_color">
                  {{ 'PROJECT.app_settings.color.secondary_color' | translate }}
                </label>

                <div class="mt-2 flex align-items-center gap-2">
                  <ngx-colors ngx-colors-trigger id="secondary_color" format="hex" colorPickerControls="no-alpha" name="secondary_color" [(ngModel)]="themeColor().secondary_color"></ngx-colors>
                  <!-- <p-colorPicker
                    id="secondary_color"
                    name="secondary_color"
                    [(ngModel)]="themeColor().secondary_color"
                  ></p-colorPicker> -->

                  <span class="text-lg uppercase">{{
                    themeColor().secondary_color
                  }}</span>
                </div>
              </div>

              <div class="lg:col-4 col-12">
                <label class="block text-lg font-medium" for="font_color">
                  {{ 'PROJECT.app_settings.color.font_color' | translate }}
                </label>
                <div class="mt-2 flex align-items-center gap-2">
                  <ngx-colors ngx-colors-trigger id="font_color" format="hex" colorPickerControls="no-alpha" name="font_color" [(ngModel)]="themeColor().font_color"></ngx-colors>
                  <!-- <p-colorPicker
                    id="font_color"
                    name="font_color"
                    [(ngModel)]="themeColor().font_color"
                  ></p-colorPicker> -->

                  <span class="text-lg uppercase">{{ themeColor().font_color }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:col-4 col-12 flex flex-column align-items-center">
        <h4 class="text-primary text-center ml-5 pl-5">Preview</h4>

        <div class="iphone mr-1">
          <div class="content relative">
            <div class="header-preview py-3" [ngStyle]="{'background-color': themeColor().primary_color, 'width': '100%', 'height': 'auto'}">
              <span class="ml-3" [ngStyle]="{'font-weight': '800','color': themeColor().font_color,'cursor': 'pointer'}"><i class="pi pi-angle-left"></i> Collection Title</span>
            </div>
            <div [ngStyle]="{'margin':'0.7em'}">
              <div class="grid">
                <div class="col-8 pl-0" [ngStyle]="{'align-self': 'center'}">
                  <span [ngStyle]="{'font-weight': '800'}"> Collection Title</span>
                </div>
                <div class="col-4 pr-0" [ngStyle]="{'text-align': 'center'}">
                  <span [ngStyle]="{'background-color':themeColor().primary_color, 'display': 'block','padding': '5px','border-radius': '5px','cursor': 'pointer', 'font-weight' : '800', 'color': themeColor().font_color}">Add <i class="pi pi-plus"></i></span>
                </div>
              </div>
              <div class="grid">
                <div class="col-8 pl-0">
                  <div class="p-inputgroup">
                      <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                      <input type="text" pInputText class="p-inputtext p-component p-element ng-untouched ng-pristine ng-valid" placeholder="Search" icon="" [ngStyle]="{'border-left' : '0'}">
                  </div>
                </div>
                <div class="col-2" [ngStyle]="{'align-self': 'center','text-align': 'center'}">
                  <span [ngStyle]="{'background-color': themeColor().secondary_color, 'color': themeColor().primary_color ,'display': 'grid','padding': '5px','border-radius': '5px','font-size': '10px','cursor': 'pointer'}"><i class="pi pi-list"></i></span>
                </div>
                <div class="col-2 pr-0" [ngStyle]="{'align-self': 'center','text-align': 'center'}">
                  <span [ngStyle]="{'background-color': themeColor().secondary_color, 'color': themeColor().primary_color ,'display': 'grid','padding': '5px','border-radius': '5px','font-size': '10px','cursor': 'pointer'}"><i class="pi pi-filter"></i></span>
                </div>
              </div>
              <div class="grid my-2" *ngFor="let item of itemsHtml" [ngStyle]="{'background-color':'#fff', 'border':'1px solid #ccc','border-radius':'10px'}">
                <div class="col-3">
                  <div class="py-3" [ngStyle]="{'text-align':'center','align-content':'center','width': '100%','height': '100%','background-color': themeColor().secondary_color,'border-radius':'20%'}">
                    <span class="text-sm text-muted">IMG</span>
                  </div>
                </div>
                <div class="col-6">
                  <h6 [ngStyle]="{'margin':'0'}">List Title</h6>
                  <span class="text-muted text-sm">Description</span>
                </div>
                <div class="col-3" [ngStyle]="{'align-self': 'center'}">
                  <span class="p-tag p-component" [ngStyle]="{'background-color': themeColor().secondary_color,'color': themeColor().primary_color,'border-radius': '5px','border': '1px solid #ccc'}">
                      <span class="p-tag-value">Label</span>
                  </span>
                  <div class="mr-1" [ngStyle]="{'display': 'flex','justify-content': 'flex-end','position': 'relative','bottom': '-10px', 'cursor': 'pointer'}"><i class="pi pi-ellipsis-h"></i></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <p-divider></p-divider>

  <div class="flex justify-content-end gap-2">
    <p-button
      label="{{ 'COMMON.CANCEL' | translate }}"
      severity="secondary"
      type="button"
      [routerLink]="['/', 'projects', projectId]"
    >
    </p-button>
    <p-button
      label="{{ 'COMMON.SAVE' | translate }}"
      iconPos="right"
      icon="pi pi-save"
      type="submit"
      [loading]="isLoading"
    ></p-button>
  </div>
</form>

<fibr-block-ui [isBlocked]="isLoading"></fibr-block-ui>
