import { DataSource, Image, ImageStyle } from '../widget-builder.model';
import { v4 as uuid } from 'uuid';

type ImageBuilderParams = {
  style: ImageStyle;
  tableConfig: DataSource;
};

export default function image(params: ImageBuilderParams): Image {
  return {
    id: uuid(),
    type: 'image',
    style: params.style,
    data_source: params.tableConfig,
  };
}
