import { CommonModule } from '@angular/common';
import { Component, computed, effect, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { FibrBaseComponentTypeComponent } from '../base-component-type.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DividerModule } from 'primeng/divider';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { configDesignOptions } from '@shared/libs/editor-config/config-design-options';
import { contentContentOptions } from '@shared/libs/editor-config/config-content-options';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { OrderListComponent } from '@shared/components/order-list/order-list.component';
import { CardModule } from 'primeng/card';
import { FibrPageComponent } from '../../page-component.component';
import list, { ListBuilderParams } from '@shared/libs/generators/widgets/list';
import { v4 } from 'uuid';
import { CollectionStyle, View } from '../../../../model';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { FibrCollapseComponent } from '@shared/components/collapse/collapse.component';

@Component({
  selector: 'fibr-page-component-list',
  standalone: true,
  templateUrl: './page-component-list.component.html',
  imports: [
    CommonModule,
    TranslateModule,
    DropdownModule,
    ReactiveFormsModule,
    FormsModule,
    DividerModule,
    OverlayPanelModule,
    ButtonModule,
    RippleModule,
    CardModule,
    OrderListComponent,
    InputTextModule,
    CheckboxModule,
    FibrCollapseComponent,
  ],
})
export class FibrPageComponentListComponent extends FibrBaseComponentTypeComponent {
  override label: string = 'List';
  public icon = '/assets/images/icon/editor/icon_list.svg';
  public type = 'list';
  public category = 'widget';

  contentOptions = computed(() =>
    contentContentOptions.filter((o) => o.for.includes(this.type))
  );
  designOptions = computed(() =>
    configDesignOptions.filter((o) => o.for.includes(this.type))
  );

  columns = signal<{ [x: string]: string }>({
    header: '',
    title: '',
    label: '',
    image: '',
    description: '',
  });

  styles = signal<CollectionStyle>({
    list_direction: 'horizontal',
  });

  config = signal({
    with_sort: false,
    with_add_button: true,
    with_edit_button: true,
    with_delete_button: true,
    with_filter: false,
    with_search_bar: true,
  })

  filterConfig = signal<{id: number; value: string}[]>([]);

  constructor(private pageComponent: FibrPageComponent) {
    super('fibr-page-component-list');

    effect(
      () => {
        const component = this.pageComponentStore.configSelected();
        if (component) {
          this.columns.set({
            header: <string>component.title,
            title: component.data.data.title.data_source.value,
            label: component.data.data.label.data_source.value,
            description: component.data.data.description.data_source.value,
            image: component.data.data.image.data_source.value,
          });
          this.styles.set({
            list_direction: component.style?.list_direction || 'vertical'
          })
          this.config.set({
            with_add_button: <boolean>component.config?.with_add_button,
            with_edit_button: <boolean>component.config?.with_edit_button,
            with_delete_button: <boolean>component.config?.with_delete_button,
            with_filter: <boolean>component.config?.with_filter,
            with_search_bar: <boolean>component.config?.with_search_bar,
            with_sort: <boolean>component.config?.with_sort,
          })
          this.filterConfig.set(component.table_config?.filterable_columns?.map((item,index) => {
            return {
              id : index + 1,
              value : item
            }
          }) || [])
        }
      },
      { allowSignalWrites: true }
    );

    effect(() => {
      const component = this.pageComponentStore.configSelected();
      const columns = this.pageComponentStore.tableColumns();
      if (columns && !component) {
        const filteredColumns = columns.filter((x) => x.name !== 'id');

          this.columns.set({
            header: 'Title',
            title: filteredColumns?.[0]?.name || '',
            description: filteredColumns?.[1]?.name || '',
            label: filteredColumns?.[2]?.name || '',
            image: filteredColumns?.[3]?.name || '',
          });
      }
    }, { allowSignalWrites: true });
  }

  changeSource(tableName: string) {
    const columns = this.pageComponentStore.changeSource(tableName);
    this.columns.set({
      title: columns?.[0]?.name || "",
      label: columns?.[1]?.name || "",
      description: columns?.[2]?.name || "",
      image: columns?.[3]?.name || ""
    })
  }

  changeStyle(config: string, value: unknown) {
    this.styles.update((val) => ({
      ...val,
      [config]: value,
    }));
  }

  addNewFilter(){
    this.filterConfig.update((prev) => {
      return prev.concat({
        id : prev.length + 1,
        value : ""
      })
    });
  }

  deleteFilter(item : {id : number}){
    this.filterConfig.update((prev) => {
      return prev.filter((a : {id : number}) => a.id !== item.id  );
    });
  }

  isActive(config: keyof CollectionStyle, item: unknown) {
    return this.styles()[config] === item;
  }

  reset() {
    setTimeout(() => {
      this.columns.set({
        header: 'Collection',
        title: '',
        label: '',
        image: '',
        description: '',
      });

      this.styles.set({
        list_direction: 'horizontal',
      });

      this.config.set({
        with_add_button: true,
        with_edit_button: true,
        with_delete_button: true,
        with_filter: false,
        with_search_bar: true,
        with_sort: false,
      })

      this.pageComponentStore.resetDetail();
      this.pageComponentStore.resetForm();
    }, 200);
  }

  override apply(): void {
    const values = this.columns() as ListBuilderParams['columns'];
    const styles = this.styles();

    this.config.update((val) => ({
        ...val,
        with_filter: this.filterConfig().length > 0,
      })
    )

    const output = list({
      id: this.pageComponentStore.configSelected()?.id || v4(),
      columns: values,
      headerTitle: this.columns()['header'],
      config: this.config(),
      detailView: this.pageComponentStore.configDetail() as View[],
      formView: this.pageComponentStore.configForm(),
      style: styles,
      tableConfig: {
        table_name: this.pageComponentStore.tableSelected(),
        filterable_columns: this.filterConfig().map((item) => item.value),
      },
    });

    this.pageComponent.apply.emit(output);
    this.reset();
  }

  override cancel(): void {
    this.pageComponent.cancel.emit();
    this.reset();
  }
}
