import { FormType } from '../widget-builder.model';
import { v4 as uuid } from 'uuid';

type InputBuilderParams = {
  type: 'text_entry' | 'number_entry' | 'image_picker' | 'date_picker';
  config: FormType['config'];
};

export default function input(params: InputBuilderParams): FormType {
  return {
    id: uuid(),
    type: params.type,
    config: params.config,
  };
}
