import { Component, OnInit, computed, inject, signal } from '@angular/core';
import { BaseComponent } from '../../../../core/base/base-component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { BillingApi } from '../../api/billing.api';
import { BillingStore } from '../../store/billing.store';
import { LabelInvoiceComponent } from '../label-invoice/label-invoice.component';
import { Invoice } from '../../model/billing.model';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import templateInvoice from '@shared/libs/generators/invoice';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BlockableComponent } from '@shared/components/block-ui/block-ui-div.component';
import { PackageApi } from '../../api/packages.api';
// eslint-disable-next-line functional/immutable-data
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  template: `
    <div class="w-full flex align-items-center justify-content-between">
      <div class="flex align-items-center gap-2">
        <i class="text-3xl pi pi-file"></i>
        <span class="text-[#084B83] text-3xl">
          {{ 'Invoice' | translate }}
        </span>
      </div>

      <div class="absolute right-3 top-3">
        <p-button
          styleClass="p-button-secondary2"
          icon="pi pi-times"
          (onClick)="close()"
        ></p-button>
      </div>
    </div>
  `,
  standalone: true,
  imports: [TranslateModule, ButtonModule],
})
export class TitlePopupComponent {
  private dialog: DynamicDialogRef = inject(DynamicDialogRef);

  close() {
    this.dialog.close();
  }
}

@Component({
  selector: 'fibr-invoice-subscription',
  templateUrl: './popup-invoice-subscription.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TranslateModule,
    LabelInvoiceComponent,
    BlockUIModule,
    ProgressSpinnerModule,
    BlockableComponent,
  ],
  providers: [BillingApi, PackageApi],
  styleUrls: ['./popup-invoice-subscription.component.scss'],
})
export class PopupInvoiceSubscriptionComponent
  extends BaseComponent
  implements OnInit
{
  private config: DynamicDialogConfig = inject(DynamicDialogConfig);
  private dialog: DynamicDialogRef = inject(DynamicDialogRef);
  private packageApi: PackageApi = inject(PackageApi);
  private billingApi: BillingApi = inject(BillingApi);
  private billingStore: BillingStore = inject(BillingStore);

  constructor() {
    super('fibr-invoice-subscription');
    this.config.width = '800px';
    this.config.templates = {
      header: TitlePopupComponent,
    };
  }

  invoice = computed(() => this.billingStore.invoice());

  calculate = signal({
    subTotal: 0,
    tax: 0,
  });

  ngOnInit(): void {
    const projectId = this.config.data.project_id;
    const invoiceId = this.config.data.invoice_id;

    this.setStateLoading();
    this.billingApi
      .getInvoice({
        projectId,
        invoiceId,
      })
      .then((invoice) => {
        this.packageApi.getPackages().then((packages) => {
          const packageId = packages
            .filter((x) => x.duration_days === invoice?.duration_days)
            .at(0);
          if (packageId) {
            this.calculate.update((val) => ({
              ...val,
              subTotal: packageId.price,
              tax:
                (packageId.price - invoice!.discount) *
                (invoice!.tax_percentage / 100),
            }));
          }

          this.setStateReady();
        });
      })
      .catch(() => {
        this.setStateReady();
      });
  }

  pay() {
    window.location.href = this.invoice()!.invoice_url;
  }

  download() {
    if (this.invoice()) {
      this.invoicePdf('download');
    }
  }

  print() {
    if (this.invoice()) {
      this.invoicePdf('print');
    }
  }

  invoicePdf(mode: 'print' | 'download') {
    this.setStateLoading();
    const invoice = {
      ...(<Invoice>this.invoice()),
      ...this.calculate(),
    };
    const typography = {
      title: this.translate('BILLING.INVOICE.TITLE'),
      invoiceDate: this.translate('BILLING.INVOICE.INVOICE_DATE'),
      payBefore: this.translate('BILLING.INVOICE.PAY_BEFORE'),
      billingTo: this.translate('BILLING.INVOICE.BILLING_TO'),
      status: this.translate('BILLING.STATUS.LABEL'),
      invoice: {
        item: this.translate('BILLING.INVOICE.ITEM'),
        cost: this.translate('BILLING.INVOICE.COST'),
        qty: this.translate('BILLING.INVOICE.QTY'),
        price: this.translate('BILLING.INVOICE.PRICE'),
        status: this.translate('BILLING.STATUS.' + invoice.status),
      },
      project: this.translate('BILLING.INVOICE.PROJECT_PLAN'),
      selected: this.translate('BILLING.CHECKOUT.PRICING.SELECTED', {
        app_name: invoice.project_name,
        month: invoice.duration_days / 30,
      }),
      prountil: this.translate('BILLING.INVOICE.PRO_UNTIL'),
      subTotal: this.translate('BILLING.INVOICE.SUB_TOTAL'),
      tax: this.translate('BILLING.CHECKOUT.TAX', { percent: invoice.tax_percentage }),
      discount: this.translate('BILLING.CHECKOUT.DISCOUNT'),
      total: this.translate('BILLING.CHECKOUT.TOTAL'),
    };

    switch (mode) {
      case 'print':
        pdfMake
          .createPdf({
            content: templateInvoice(invoice, typography),
          })
          .print();
        this.setStateReady();
        break;
      case 'download':
        pdfMake
          .createPdf({
            content: templateInvoice(invoice, typography),
          })
          .download(invoice.id);
        this.setStateReady();
        break;
    }
  }

  close() {
    this.dialog.close();
  }
}
