import { Injectable, computed, signal } from '@angular/core';
import { PackageSubscription } from '../model/package.model';

interface IPackageStore {
  packages: PackageSubscription[];
}

@Injectable({
  providedIn: 'root',
})
export class PackageStore {
  private _store = signal<IPackageStore>({
    packages: [],
  });

  packages = computed(() => this._store().packages);

  setPackages(packages: PackageSubscription[]) {
    this._store.update((val) => ({
      ...val,
      packages,
    }));
  }
}
