<fibr-header-v1 [srcImg]="'/assets/images/logo_appfibr.svg'"></fibr-header-v1>
<fibr-subheader [minimal]="true" [projectDetail]="projectDetail()"></fibr-subheader>

<div class="p-content fibr-portal min-h-screen">
  <div class="grid px-4 pt-24 pb-16 h-full">
    <div class="lg:col-3 col-12 border-right-1 pr-6 h-full">
      <p-button
        icon="pi pi-arrow-left"
        label="Back to Editor"
        severity="secondary"
        [routerLink]="['/', 'projects', projectId()]"
      ></p-button>

      <div class="flex align-items-center gap-2 text-[#084B83] mt-3">
        <i class="pi pi-cog text-3xl"></i>
        <span class="text-3xl">{{ "PROJECT.app_settings.title" | translate }}</span>
      </div>

      <ul class="mt-4 pl-4">
        @for (nav of navigation; track $index) {
        <a [routerLink]="currentPath()" [queryParams]="{ menu: nav.name }">
          <li
            class="flex align-items-center gap-3 px-4 py-3"
            [ngClass]="{
              'bg-[#D9EAF7] text-primary': nav.name === activatePage(),
              'text-gray-primary': nav.name !== activatePage(),
            }"
          >
            <fibr-custom-icon
              [src]="nav.icon"
              [styleClass]="nav.name === activatePage() ? 'bg-primary' : 'bg-black'"
            ></fibr-custom-icon>
            <span class="text-lg">
              {{ nav.label | translate }}
            </span>
          </li>
        </a>
        }
      </ul>
    </div>
    <div class="lg:col-9 col-12 px-6">
      @switch (activatePage()) { @case ('app-identity') {
      <fibr-app-identity></fibr-app-identity>
      } @case ('theme-color') {
      <fibr-theme-color></fibr-theme-color>
      } }
    </div>
  </div>
</div>

<fibr-footer></fibr-footer>
