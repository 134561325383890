<p-blockUI [target]="invoiceEl" [blocked]="isLoading">
  <p-progressSpinner
    strokeWidth="4"
    [style]="{ width: '6rem', height: '6rem' }"
    styleClass="progress-spinner center-screen"
  ></p-progressSpinner>
</p-blockUI>

<fibr-blockable #invoiceEl>
  <div class="flex align-items-center justify-content-end gap-2">
    <p-button
      styleClass="p-button p-button-primary bg-softblue text-blue"
      label="{{ 'BILLING.INVOICE.PRINT' | translate }}"
      icon="pi pi-print"
      iconPos="right"
      (onClick)="print()"
    ></p-button>
    <p-button
      styleClass="p-button p-button-primary bg-softblue text-blue"
      label="{{ 'BILLING.INVOICE.DOWNLOAD' | translate }}"
      icon="pi pi-download"
      iconPos="right"
      (onClick)="download()"
    ></p-button>
  </div>

  <div class="w-full p-4 rounded-xl border-2 border-[#EAECEF] mt-4">
    <div class="flex flex-row align-items-center justify-content-between">
      <img
        src="/assets/images/logo_appfibr.svg"
        [style]="{ maxHeight: '44px' }"
        alt="logo-fibr"
      />

      <div class="text-right flex flex-column align-items-end">
        <div class="flex flex-column text-right text-lg">
          <span class="font-medium text-[#444444]">
            {{ "BILLING.INVOICE.TITLE" | translate }}
          </span>

          <span class="text-primary font-semibold"> {{ invoice()?.id }} </span>
        </div>

        <div class="flex flex-row gap-2 text-right text-lg mt-4">
          <span class="font-semibold text-[#444444]">
            {{ "BILLING.INVOICE.INVOICE_DATE" | translate }}
          </span>
          <span>
            {{ invoice()?.created_at | date : "dd MMM yyyy" }}</span
          >
        </div>

        <div class="flex flex-row gap-2 text-right text-lg">
          <span class="font-semibold text-[#444444]">
            {{ "BILLING.INVOICE.PAY_BEFORE" | translate }}
          </span>
          <span>
            {{ invoice()?.expired_at| date : "dd MMM yyyy HH:mm" }}</span
          >
        </div>
      </div>
    </div>

    <div class="my-6">
      <table class="w-full">
        <tr>
          <td class="bg-[#E5EFF9] px-4 pt-3 pb-1 text-lg font-semibold">
            {{ "BILLING.INVOICE.BILLING_TO" | translate }}
          </td>
          <td class="bg-[#E5EFF9] px-4 pt-3 pb-1 text-lg font-semibold">
            {{ "BILLING.STATUS.LABEL" | translate }}
          </td>
        </tr>
        <tr>
          <td class="bg-[#E5EFF9] px-4 py-3">
            {{ invoice()?.user }}
          </td>
          <td class="bg-[#E5EFF9] px-4 py-3">
            <fibr-label-invoice
              status="{{ invoice()?.status }}"
            ></fibr-label-invoice>
          </td>
        </tr>
      </table>
    </div>

    <div>
      <table class="w-full">
        <tr>
          <td class="invoice-cell font-semibold">
            {{ "BILLING.INVOICE.ITEM" | translate }}
          </td>
          <td class="w-[100px] invoice-cell font-semibold">
            {{ "BILLING.INVOICE.COST" | translate }}
          </td>
          <td class="w-[100px] invoice-cell font-semibold">
            {{ "BILLING.INVOICE.QTY" | translate }}
          </td>
          <td class="w-[120px] invoice-cell font-semibold text-left px-4">
            {{ "BILLING.INVOICE.PRICE" | translate }}
          </td>
        </tr>
        <tr>
          <td class="invoice-cell">
            <div>
              <span class="font-semibold">
                {{ "BILLING.INVOICE.PROJECT_PLAN" | translate }}
              </span>

              <div class="pl-4 mt-2">
                <p class="font-medium text-[#444444]">
                  {{
                    "BILLING.CHECKOUT.PRICING.SELECTED"
                      | translate
                        : {
                            app_name: invoice()?.project_name,
                            month: invoice()?.duration_days
                              ? invoice()!.duration_days / 30
                              : 0
                          }
                  }}
                </p>
                @switch (invoice()?.status) {
                  @case ('PENDING') {
                    <p class="text-base text-[#888888]">
                      {{ "BILLING.INVOICE.PRO_UNTIL" | translate }}: -
                    </p>
                  }
                  @case ('PAID') {
                    <p class="text-base text-[#888888]">
                      {{ "BILLING.INVOICE.PRO_UNTIL" | translate }}: {{ invoice()?.pro_plan_until | date: "dd/MM/yyyy" }}
                    </p>
                  }
                  @case ('EXPIRED') {
                    <p class="text-base text-[#888888]">
                      {{ "BILLING.INVOICE.PRO_UNTIL" | translate }}: -
                    </p>
                  }
                }
              </div>
            </div>
          </td>
          <td class="invoice-cell">
            {{ calculate().subTotal | currency : "Rp " : "code" : "0.0-0" }}
          </td>
          <td class="invoice-cell">1</td>
          <td class="invoice-cell text-right pr-4">
            {{ calculate().subTotal | currency : "Rp " : "code" : "0.0-0" }}
          </td>
        </tr>
        <tr>
          <td colspan="4" [style]="{ height: '20px' }"></td>
        </tr>
        <tr>
          <td></td>
          <td colspan="2" class="invoice-cell text-left border-none py-1">
            {{ "BILLING.INVOICE.SUB_TOTAL" | translate }}
          </td>
          <td class="invoice-cell py-2 border-none text-right px-4">
            {{ calculate().subTotal | currency : "Rp " : "code" : "0.0-0" }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td colspan="2" class="invoice-cell text-left border-none py-1">
            {{ "BILLING.CHECKOUT.TAX" | translate : { percent: invoice()?.tax_percentage } }}
          </td>
          <td class="invoice-cell py-2 border-none text-right px-4">
            {{ calculate().tax | currency : "Rp " : "code" : "0.0-0" }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td colspan="2" class="invoice-cell text-left border-none pt-1 pb-3">
            {{ "BILLING.CHECKOUT.DISCOUNT" | translate }}
          </td>
          <td class="invoice-cell py-2 border-none text-right px-4">
            {{ invoice()?.discount | currency : "Rp " : "code" : "0.0-0" }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td
            colspan="2"
            class="invoice-cell text-left border-none py-3 bg-[#E5EFF9]"
          >
            {{ "BILLING.CHECKOUT.TOTAL" | translate }}
          </td>
          <td
            class="invoice-cell text-right border-none py-3 px-4 bg-[#E5EFF9] text-[#5893D2]"
          >
            {{ invoice()?.amount | currency : "Rp " : "code" : "0.0-0" }}
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div class="mt-4 flex align-items-center justify-content-end gap-3">
    <p-button
      icon="pi pi-angle-right"
      iconPos="right"
      label="{{ 'BILLING.INVOICE.PAY' | translate }}"
      (onClick)="pay()"
    ></p-button>
    <p-button
      styleClass="p-button-secondary"
      label="{{ 'COMMON.CLOSE' | translate }}"
      (onClick)="close()"
    ></p-button>
  </div>
</fibr-blockable>
