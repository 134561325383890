import { collection, getDocs, type Firestore, writeBatch, doc, setDoc, type WithFieldValue, type DocumentReference, type CollectionReference, getDoc, deleteDoc, onSnapshot, QuerySnapshot, DocumentData } from "@angular/fire/firestore";

// https://firebase.google.com/docs/firestore/manage-data/transactions#batched-writes
const BATCH_LIMIT = 500;

export const deleteDocument = async (firestore: Firestore, collectionName: string, ...pathSegments: string[]) => {
  const docRef = doc(firestore, collectionName, ...pathSegments);
  await deleteDoc(docRef);
}

export const deleteAllDocuments = async (firestore: Firestore, collectionPath: string, ...pathSegments: string[]) => {
  const collectionRef = collection(firestore, collectionPath, ...pathSegments);
  const querySnapshot = await getDocs(collectionRef);

  let batch = writeBatch(firestore);
  let i = 0;
  querySnapshot.forEach(async (doc) => {
    batch.delete(doc.ref);
    i++;

    // we use half of limit to avoid hitting the limit and resource exhaustion
    if (i === BATCH_LIMIT / 2) {
      await batch.commit();
      batch = writeBatch(firestore);
      i = 0;
    }
  });

  // Commit any remaining operations
  if (i > 0) {
    await batch.commit();
  }
}

export const getAllDocumentIDs = async (firestore: Firestore, collectionPath: string, ...pathSegments: string[]) => {
  const collectionRef = collection(firestore, collectionPath, ...pathSegments);
  const querySnapshot = await getDocs(collectionRef);
  return querySnapshot.docs.map(({ id }) => id);
}

export const getAllDocuments = async <T>(firestore: Firestore, collectionPath: string, ...pathSegments: string[]) => {
  const tablesCollectionRef = collection(firestore, collectionPath, ...pathSegments) as CollectionReference<T>;
  const snapshot = await getDocs(tablesCollectionRef);
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}

export const getAllRealtimeDocuments = <T>(firestore: Firestore, collectionPath: string, pathSegments: string, onUpdate: (data: T[]) => void) => {
  const tablesCollectionReferences = collection(firestore, collectionPath, pathSegments);
  return onSnapshot(tablesCollectionReferences, (snapshot: QuerySnapshot<DocumentData>) => {
    const documents = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() as T }));
    onUpdate(documents);
  });
}

export const addDocument = async <T>(firestore: Firestore, {
  data,
  customID,
}: {
  data?: WithFieldValue<T>, customID?: string
}, collectionName: string, ...collectionSegments: string[]) => {
  if (customID) {
    const docRef = doc(firestore, collectionName, ...collectionSegments, customID) as DocumentReference<T>;
    await handleDocIDExists(docRef);
    await setDoc(docRef, data ?? {} as WithFieldValue<T>, { merge: true });
    return customID
  } else {
    const collectionRef = collection(firestore, collectionName, ...collectionSegments) as CollectionReference<T>;
    const docRef = doc(collectionRef);
    const docID = docRef.id;
    await handleDocIDExists(doc(collectionRef, docID))
    await setDoc(doc(collectionRef, docID), (data ? {id: docID, ...data as object} : {id: docID}) as WithFieldValue<T>);
    return docID;
  }
}

const handleDocIDExists = async <T>(docRef: DocumentReference<T>) => {
  const snapshot = await getDoc(docRef);
  if (snapshot.exists()) {
    throw new Error('Document ID already exists, please try again');
  }
}
