<header
  class="flex border border-[#D9D9D9] h-[4.375rem] items-center px-[1.625rem] justify-between shadow-1"
>
  <fibr-logo-icon></fibr-logo-icon>
  <div class="flex items-center">
    <div class="rounded-lg overflow-hidden">
      @if (DUMMY_DATA.app_icon) {
      <img src="{{ DUMMY_DATA.app_icon }}" alt="{{ DUMMY_DATA.app_title }}" />
      } @else {
      <img
        src="assets/images/default_app_icon.png"
        alt="{{ DUMMY_DATA.app_title }}"
      />
      }
    </div>
    <div class="ml-[.625rem]">
      <h2 class="body-2 font-bold">
        {{ DUMMY_DATA.app_title }}
      </h2>
      <div class="flex space-x-2">
        @for (platform of DUMMY_DATA.published_platforms; track platform) {
        @switch (platform) { @case ('web') {
        <fibr-web-icon></fibr-web-icon>
        } @case ('android') {
        <fibr-android-icon></fibr-android-icon>
        } @default { } } }
        <div
          class="rounded-[.25rem] border border-[#8A8A8A] bg-[#D9D9D9]/[.3] h-[1.125rem] px-2 overline-2 font-medium text-[#8A8A8A]"
        >
          {{ DUMMY_DATA.package }}
        </div>
      </div>
    </div>
    <button
      class="rounded-[3.75rem] bg-[#C9529A] flex h-9 px-[1.125rem] items-center text-gray-50 body-1 ml-[4.375rem]"
    >
      {{ "EDITOR.UPGRADE_BUTTON" | translate }}
      <fibr-upgrade-to-pro-icon class="ml-2"></fibr-upgrade-to-pro-icon>
    </button>
  </div>
  <div class="flex">
    <button
      class="rounded-[.625rem] h-9 flex items-center bg-[#E5EFF9] px-[1.625rem] text-primary-500 caption-1"
    >
      {{ "EDITOR.INVITE" | translate }}
    </button>
    <button
      class="rounded-[.625rem] h-9 ml-4 flex items-center bg-[#3B9EF2] px-5 text-gray-50 caption-1"
    >
      {{ "EDITOR.PUBLISH" | translate }}
      <fibr-publish-icon class="ml-4"></fibr-publish-icon>
    </button>
  </div>
</header>
