import { Component, effect, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { safeParse, type SchemaIssues } from 'valibot';
import { CreateBlankProjectSchema } from '../../model';
import { ProjectAPIService } from '../../api/project-api.service';
import { setToast } from '@shared/components/toast/toast.component';
import { Router } from '@angular/router';
import { handleErrorTranslationToast } from '@shared/libs/error';
import { BlockUIComponent } from '@shared/components/block-ui/block-ui.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'fibr-create-new-blank-project-dialog',
  standalone: true,
  imports: [DividerModule, FormsModule, TranslateModule, InputTextModule, BlockUIComponent],
  templateUrl: './create-new-blank-project-dialog.component.html',
  styleUrl: './create-new-blank-project-dialog.component.scss'
})
export class CreateNewBlankProjectDialogComponent {
  translate = inject(TranslateService);
  projectApi = inject(ProjectAPIService);
  router = inject(Router);
  dynamicDialogRef = inject(DynamicDialogRef);

  appNameField = signal('');
  appNameFieldError = signal('');
  tableNameField = signal('');
  tableNameFieldError = signal('');
  fieldIssues = signal<SchemaIssues | null>(null);
  isSubmitting = signal(false);

  constructor() {
    effect(() => {
      (this.fieldIssues() ?? []).forEach((issue) => {
        switch (issue?.path?.[0].key) {
          case 'projectName':
            this.appNameFieldError.set(this.translate.instant(issue.message));
            break;
          case 'tableName':
            this.tableNameFieldError.set(this.translate.instant(issue.message));
            break;
          default:
            break;
        }
      })
    }, { allowSignalWrites: true })
  }

  submit() {
    this.resetErrors();
    const validatedFields = safeParse(
      CreateBlankProjectSchema,
      { projectName: this.appNameField(), tableName: this.tableNameField() },
    );
    if (validatedFields.success) {
      this.isSubmitting.set(true);
      this.projectApi.createProject(this.appNameField(), this.tableNameField())
      .then((res) => {
        if (res.isSuccess) {
          setToast(this.translate.instant('PROJECT.SUCCESS_CREATE_PROJECT'), 'success');
          this.router.navigate(['/projects', res.value]);
        } else {
          setToast(this.translate.instant(res.error), 'error');
        }
      })
      .catch(handleErrorTranslationToast(this.translate))
      .finally(() => {
        this.dynamicDialogRef.close();
        this.isSubmitting.set(false);
      });
    } else {
      this.fieldIssues.set(validatedFields.issues);
    }
  }

  private resetErrors() {
    this.appNameFieldError.set('');
    this.tableNameFieldError.set('');
  }
}
