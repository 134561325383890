import { Component } from '@angular/core';

@Component({
  selector: 'fibr-analytic-icon',
  standalone: true,
  imports: [],
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" viewBox="0 0 30 25" fill="none">
      <path d="M29.1211 23.2818H28.7544V0.859099C28.7544 0.384581 28.361 0 27.8755 0H21.3277C20.8422 0 20.4488 0.384581 20.4488 0.859099V23.2818H19.1528V9.1975C19.1528 8.72299 18.7594 8.3384 18.2739 8.3384H11.7261C11.2406 8.3384 10.8472 8.72299 10.8472 9.1975V23.2818H9.55124V17.5357C9.55124 17.0614 9.15779 16.6766 8.67233 16.6766H2.12448C1.63902 16.6766 1.24557 17.0614 1.24557 17.5357V23.2818H0.878906C0.393448 23.2818 0 23.6664 0 24.1409C0 24.6154 0.393448 25 0.878906 25H29.1211C29.6066 25 30 24.6154 30 24.1409C30 23.6664 29.6066 23.2818 29.1211 23.2818ZM3.00339 23.2818V18.3948H7.79343V23.2818H3.00339ZM12.605 23.2818V10.0566H17.395V23.2818H12.605ZM22.2066 23.2818V1.7182H26.9966V23.2818H22.2066Z" fill="currentColor"/>
    </svg>
  `,
  styles: ``
})
export class AnalyticIconComponent {

}
