import {AfterViewInit, Component, ElementRef, forwardRef, Input, ViewChild} from "@angular/core";
import {CommonModule} from "@angular/common";
import Quill from "quill";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'fibr-text-editor-quill',
  template: '<div class="bg-white" #editor></div>',
  standalone: true,
  imports: [CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FibrTextEditorQuillComponent),
      multi: true,
    }
  ],
})
export class FibrTextEditorQuillComponent implements AfterViewInit, ControlValueAccessor {
  private instanceQuill: Quill;

  @Input() theme: string = 'snow';

  @ViewChild('editor') editor: ElementRef;

  private _value: string = '';

  ngAfterViewInit() {
    this.init();
  }

  private init() {
    this.instanceQuill = new Quill(this.editor.nativeElement, {
      theme: this.theme
    })

    this.instanceQuill.on('text-change', () => {
      const value = this.instanceQuill.getText()
      this._value = value;
      this.onChange(value);
    })
  }

  onChange = (source: string) => {
    this._value = source;
  }
  onTouch = () => {}

  writeValue(value: string) {
    this._value = value;
    this.instanceQuill?.setText(value);
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouch = fn
  }
}
