import { Component, Input, OnInit, AfterContentInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { AuthAPIService } from '../../../../features/auth/api/auth-api.service';
import { TooltipModule } from 'primeng/tooltip';
import { MenuModule } from 'primeng/menu';
import { AuthStoreService } from '../../../../features/auth/store/auth-store.service';
import { Router } from '@angular/router';

@Component({
  selector: 'fibr-profile',
  standalone: true,
  imports: [TooltipModule, MenuModule],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent implements OnInit, AfterContentInit {
  items: MenuItem[] = [];
  @Input() userProfilePath = '/';
  @Input() userChangePasswordPath = '/';
  @Input() aksesFrom!: string;
  partnerName = '';

  constructor(
    private translate: TranslateService,
    private authAPI: AuthAPIService,
    private authStore: AuthStoreService,
    private router: Router
  ) {}

  ngAfterContentInit(): void {
    this.translate
      .get(['profile.text', 'profile.change_password', 'profile.logout'])
      .subscribe((trx) => {
        this.items = [
          {
            label: trx['profile.text'],
            icon: 'pi pi-fw pi-user',
            command: () => {
              this.profile();
            },
          },
          // {
          //   label: trx['profile.change_password'],
          //   icon: 'pi pi-fw pi-lock',
          //   command: () => {
          //     this.changePassword();
          //   },
          // },
          {
            label: trx['profile.logout'],
            icon: 'pi pi-fw pi-sign-out',
            command: () => {
              this.logout();
            },
          },
        ];
      });
  }
  logout() {
    this.authAPI.signOut();
  }
  changePassword() {
    this.router.navigate(['/config','change-password']);
  }
  profile() {
    this.router.navigate(['profile'], { queryParams: { menu: 'account' } });
  }
  ngOnInit(): void {
    this.partnerName = this.authStore.user()?.displayName ?? '';
  }
}
