import { Component, EventEmitter, Input, OnInit, Output, effect, signal } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ChipsModule } from 'primeng/chips';
import { EditorAPIService } from '../../api/editor-api.service';
import { ActivatedRoute } from '@angular/router';
import { SchemaIssues, safeParse } from 'valibot';
import { Invitation, InvitationConfigSchema, Role, UserAccess } from '../../model';
import { setToast } from '@shared/components/toast/toast.component';
import { BlockUIComponent } from '@shared/components/block-ui/block-ui.component';
import { EditorStoreService } from '../../store/editor-store.service';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api/menuitem';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { AuthStoreService } from '../../../auth/store/auth-store.service';
import { Confirmation } from '../../../../core/components/subheader/model/confirmation';
import { ConfirmationService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectStoreService } from '../../../project/store/project-store.service';

@Component({
  selector: 'fibr-dialog-invite-user',
  standalone: true,
  imports: [DialogModule, TranslateModule, FormsModule, InputTextModule, ButtonModule, ChipsModule, BlockUIComponent, DropdownModule, MenuModule, InputGroupModule, InputGroupAddonModule],
  templateUrl: './dialog-invite-user.component.html',
  styleUrl: './dialog-invite-user.component.scss'
})
export class DialogInviteUserComponent implements OnInit{

  @Input() dialogInviteUser: boolean ;
  @Output() didClose = new EventEmitter();
  role = this.projectStore.role
  userID = this.authStore.user()?.uid ?? "";

  isBlocked = signal(false);
  inputEmail = signal([]);
  fieldIssues = signal<SchemaIssues | null>(null);
  emailFieldError = signal('');
  roleFieldError = signal('');

  projectID = this.route.snapshot.paramMap.get('id');
  userInvited = signal<Invitation[]>([])
  userAccess= signal<UserAccess[]>([])
  selectedUser = signal<UserAccess | null>(null);

  confirmationTemplate: Confirmation | undefined;

  roles: Role[] | undefined;
  selectedRole: Role = {
    name: '',
    value: ''
  }

  action: MenuItem[] = [
    {
      label: 'Resend Email',
      command: () => {
        this.onclick('resend', this.selectedUser());
      },
    }
  ];

  actionAccessUserOwner: MenuItem[] = [
    {
      label: 'Remove from App',
      command: () => {
        this.onclick('remove', this.selectedUser());
      }
    },
    {
      label: 'Set as Owner',
      command: () => {
        this.onclick('set-owner', this.selectedUser());
      }
    },
  ];

  actionAccessUserEditor: MenuItem[] = [
    {
      label: 'Remove from App',
      command: () => {
        this.onclick('remove', this.selectedUser());
      }
    },
  ];

  projectsShardToken = this.authStore.projectsShardToken

  constructor(
    private editorAPI: EditorAPIService,
    private editorStore: EditorStoreService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private authStore: AuthStoreService,
    private cs : ConfirmationService,
    private projectStore: ProjectStoreService
  ) { effect(() => {
    this.userInvited.set(this.editorStore.usersInvited());
    this.userAccess.set(this.editorStore.userAccess());
    
    (this.fieldIssues() ?? []).forEach((issue) => {
      switch (issue?.path?.[0].key) {
        case 'email':
          this.emailFieldError.set(this.translate.instant(issue.message));
          break;
        case 'role':
          this.roleFieldError.set(this.translate.instant(issue.message));
          break;
        default:
          break;
      }
    });
  }, { allowSignalWrites: true }) }

  ngOnInit(): void {
    this.roles = [
      {name: 'Editor', value: 'editor'},
      {name: 'Member', value: 'end-user'}
    ]
    this.editorAPI.getUserInvited(this.projectID!);
    this.editorAPI.getUserAccess(this.projectID!);
  }

  submit() {
    this.isBlocked.set(true);

    this.resetErrors();
    const emails = this.inputEmail();

    const validatedInvitation = safeParse(
      InvitationConfigSchema,
      {email: this.inputEmail(), role: this.selectedRole.value}
    );

    if (validatedInvitation.success) {
      this.editorAPI.sendInvitations(emails, this.projectID!, this.selectedRole.value)
      .then((res) => {
        if(res.isSuccess) {
          this.isBlocked.set(false);
          setToast(this.translate.instant('EDITOR.SUCCESS_SENT_EMAIL'), 'success', 'top-center');
        } else{
          this.isBlocked.set(false);
          setToast('Too many requests, please try again later.', 'error', 'top-center');
        }
      })
    } else {
      this.isBlocked.set(false);
      this.fieldIssues.set(validatedInvitation.issues);
      setToast(this.translate.instant("EDITOR.CHECK_FIELD_REQUIRED"), 'error', 'top-center');
    }
  }

  private resetErrors() {
    this.fieldIssues.set(null);
    this.emailFieldError.set('');
    this.roleFieldError.set('');
  }

  onCloseDialog() {
    this.didClose.emit();
  }

  setUser(user: UserAccess) {
    this.selectedUser.set(user);
  }

  onclick(action: 'resend' | 'remove' | 'set-owner', user: UserAccess | null) {
    switch (action) {
      case 'resend':
        if (user) {
          this.sendInvitation(user);
        }
        return;
      case 'remove':
        if (user) {
          this.removeUser(user);
        }
        return;
      case 'set-owner':
        if (user) {
          this.setAsOwner(user);
        }
        return;
      default:
        return
    }
  }

  sendInvitation(user: UserAccess) {
    this.isBlocked.set(true);
    this.editorAPI.sendInvitation(user.email!, this.projectID!, user.role!)
    .then((res) => {
      if(res.isSuccess) {
        this.isBlocked.set(false);
        setToast(this.translate.instant('EDITOR.SUCCESS_SENT_EMAIL'), 'success', 'top-center');
      } else {
        this.isBlocked.set(false);
        setToast((res.error as HttpErrorResponse).error.message, 'error', 'top-center');
      }
    
    });
  }

  removeUser(user: UserAccess) {
    this.confirmationTemplate = {
      header: this.translate.instant("EDITOR.CONFIRMATION_REMOVE_USER.HEADER"),
      content: this.translate.instant("EDITOR.CONFIRMATION_REMOVE_USER.CONTENT") + '"' + user.email + '"' + this.translate.instant("EDITOR.CONFIRMATION_REMOVE_USER.CONTENT_END"),
      rejectButtonStyleClass: "p-button-secondary",
      dismissableMask: true
    };

    this.cs.confirm({
      message: this.confirmationTemplate.content,
      header: this.confirmationTemplate.header,
      rejectButtonStyleClass: this.confirmationTemplate.rejectButtonStyleClass,
      dismissableMask: this.confirmationTemplate.dismissableMask,
      accept:  () => {
        this.isBlocked.set(true);
       const result =  this.editorAPI.removeUserAccess(this.projectID!, user.id)
       result.then((res) => {
        if (res.isSuccess) {
          this.editorAPI.getUserAccess(this.projectID!);
          this.isBlocked.set(false);
          setToast(this.translate.instant('EDITOR.SUCCESS_REMOVE_USER'), 'success', 'top-center');
        } else {
          this.isBlocked.set(false);
          setToast((res.error as HttpErrorResponse).error.message, 'error', 'top-center');
        }
       })
      },
      reject: () => {
      }
    });
  }

  setAsOwner(user: UserAccess) {
    this.confirmationTemplate = {
      header: this.translate.instant("EDITOR.CONFIRMATION_SET_AS_OWNER.HEADER"),
      content: this.translate.instant("EDITOR.CONFIRMATION_SET_AS_OWNER.CONTENT") + '"' + user.email + '"' + this.translate.instant("EDITOR.CONFIRMATION_REMOVE_USER.CONTENT_END"),
      rejectButtonStyleClass: "p-button-secondary",
      dismissableMask: true
    };

    this.cs.confirm({
      message: this.confirmationTemplate.content,
      header: this.confirmationTemplate.header,
      rejectButtonStyleClass: this.confirmationTemplate.rejectButtonStyleClass,
      dismissableMask: this.confirmationTemplate.dismissableMask,
      accept:  () => {
        this.isBlocked.set(true);
       const result =  this.editorAPI.setAsOwner(this.projectID!, user.id)
       result.then((res) => {
        if (res.isSuccess) {
          this.editorAPI.getUserAccess(this.projectID!);
          this.isBlocked.set(false);
          setToast(this.translate.instant('EDITOR.SUCCESS_SET_AS_OWNER'), 'success', 'top-center');
        } else {
          this.isBlocked.set(false);
          setToast(res.error, 'error', 'top-center');
        }
       })
      },
      reject: () => {
      }
    });
  }


  getColor(user: Invitation) {
    return user.status === 'sent' ? 'grey' : 'red';
  }

  getColorUserAccess(user: UserAccess) {
    if (user.role === 'editor') {
      return '#B1E4B9';
    } else if (user.role === 'end-user') {
      return '#ECC1A2';
    } else {
      return '#9FCCF1';
    }
  }

  getRoleName(role: string) {
    if(role === 'editor') {
      return 'Editor';
    } else if (role === 'end-user') {
      return 'Member';
    } else {  
      return 'Owner';
    }
  }


}
