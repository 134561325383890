<div class="login-body layout-light">
  <div class="login-content">
    <div class="night-switch">
      <div class="language-menu">
        <p-dropdown
          (onChange)="onLanguageChange()"
          [options]="languageList"
          [ngModel]="selectedLanguage()"
          (ngModelChange)="selectedLanguage.set($event)"
          placeholder="{{ 'COMMON.SELECT_LANGUAGE' | translate }}"
          optionLabel="name"
          [showClear]="false"
        ></p-dropdown>
      </div>
    </div>
    <div class="login-panel">
      <div class="login-panel-content text-left">
        <div class="col login-header">
          <div class="logo"><img [src]="logo" alt="fibr-logo" /></div>
          <h4 class="mb-2">{{ "AUTH.PORTAL_REGISTER" | translate }}</h4>
          <span>{{ "AUTH.WELCOME_MESSAGE_REGISTRATION" | translate }}</span>
        </div>

        <div class="col">
          <div id="my-signin2">
            <div
              class="abcRioButton"
              (click)="onClickGoogle()"
              (keydown.enter)="(onClickGoogle)"
              tabindex="0"
            >
              <div class="abcRioButtonContentWrapper">
                <div class="abcRioButtonIcon">
                  <div
                    style="width: 18px; height: 18px"
                    class="abcRioButtonSvgImageWithFallback abcRioButtonIconImage abcRioButtonIconImage18"
                  >
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18px"
                      height="18px"
                      viewBox="0 0 48 48"
                      class="abcRioButtonSvg"
                    >
                      <g>
                        <path
                          fill="#EA4335"
                          d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                        ></path>
                        <path
                          fill="#4285F4"
                          d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                        ></path>
                        <path
                          fill="#FBBC05"
                          d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                        ></path>
                        <path
                          fill="#34A853"
                          d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                        ></path>
                        <path fill="none" d="M0 0h48v48H0z"></path>
                      </g>
                    </svg>
                  </div>
                </div>
                <span class="abcRioButtonContents">
                  <span
                    id="not_signed_inqxa7b2xmul7l"
                    style="font-family: 'Roboto', sans-serif"
                    >{{ "AUTH.GOOGLE_REGISTER" | translate }}</span
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="p-divider p-component p-divider-horizontal">
            <div class="p-divider-content bg-softgrey">
              {{ "COMMON.OR" | translate }}
            </div>
          </div>
        </div>

        <div class="col">
          <div class="forms mt-0">
            <div class="login-input-wrapper mb-3">
              <label for="fullname">{{ "AUTH.FULL_NAME" | translate }}</label
              ><input
                type="text"
                [ngModel]="fullName.value()"
                (ngModelChange)="fullName.value.set($event)"
                id="fullname"
                placeholder="{{ 'AUTH.PLACEHOLDER_FULL_NAME' | translate }}"
                class="p-inputtext p-component line-input py-3"
              />
            </div>
            <div class="login-input-wrapper mb-3">
              <label for="email">Email</label
              ><input
                id="email"
                pinputtext=""
                [ngModel]="email.value()"
                (ngModelChange)="email.value.set($event)"
                placeholder="{{ 'AUTH.PLACEHOLDER_EMAIL' | translate }}"
                class="p-inputtext p-component line-input py-3"
              />
            </div>
            <div class="login-input-wrapper mb-3">
              <label for="password">{{ "AUTH.PASSWORD" | translate }}</label
              ><input
                type="password"
                [ngModel]="password.value()"
                (ngModelChange)="password.value.set($event)"
                id="password"
                placeholder="{{ 'AUTH.PLACEHOLDER_PASSWORD' | translate }}"
                class="p-inputtext p-component line-input py-3"
              />
            </div>
            <div class="login-input-wrapper mb-3">
              <label for="passwordConfirmation">{{
                "AUTH.PASSWORD_CONFIRMATION" | translate
              }}</label
              ><input
                type="password"
                [ngModel]="passwordConfirmation.value()"
                (ngModelChange)="passwordConfirmation.value.set($event)"
                id="passwordConfirmation"
                placeholder="{{
                  'AUTH.PLACEHOLDER_PASSWORD_CONFIRMATION' | translate
                }}"
                class="p-inputtext p-component line-input py-3"
              />
            </div>
            <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [ngModel]="this.captcha.value()"
            (ngModelChange)="this.captcha.value.set($event)" required></re-captcha>
            <div class="col-12">
              <p-checkbox
                [binary]="true"
                [ngModel]="termAndCondition.value()"
                (ngModelChange)="termAndCondition.value.set($event)"
              />
              <span class="p-checkbox-label">
                {{ "AUTH.CHECKBOX_REGISTER" | translate }}
                <a
                  [ngStyle]="{ color: 'var(--primary-color)' }"
                  href="https://appfibr.io/syarat-ketentuan/"
                  target="_blank"
                  >{{ "AUTH.TERM_OF_SERVICE" | translate }}</a
                >
                {{ "and" | translate }}
                <a
                  [ngStyle]="{ color: 'var(--primary-color)' }"
                  href="https://appfibr.io/privacy-policy/"
                  target="_blank"
                  >{{ "AUTH.PRIVACY_POLICY" | translate }}</a
                ></span
              >
            </div>
            <div class="buttons">
              <button
                id="registermail-appfibr"
                pbutton=""
                [disabled]="isProgressSpinner()"
                type="button"
                label="Login"
                tabindex="0"
                class="col-12 py-3 p-button text-lg bg-primary-500"
                (click)="onRegister()"
              >
                <span class="p-button-label">{{ "Register" | translate }}</span>
                @if (isProgressSpinner()) {
                <p-progressSpinner
                  strokeWidth="3"
                  styleClass="progress-spinner-btn"
                  [style]="{ width: '20px', height: '20px' }"
                />
                }
              </button>
            </div>
            <div class="col-12 center">
              <a
                href="javascript:;"
                [ngClass]="{ disabled: isProgressSpinner() }"
                [routerLink]="['/sign-in']"
                pbutton=""
                type="button"
                label="Register"
                tabindex="0"
                class="p-button-text mt-4 text-primary-500"
                ><span class="text-primary-500 p-button-label">{{
                  "AUTH.BACK_TO_LOGIN" | translate
                }}</span></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="login-panel right"
      [ngStyle]="{
        'background-image': 'url(' + image_register + ')',
        'background-size': 'cover',
        'background-position': 'center',
        'background-repeat': 'no-repeat'
      }"
    ></div>
  </div>
</div>
