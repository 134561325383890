<div class="login-body layout-light">
  <div class="login-content">
    <div class="night-switch">
      <div class="language-menu">
        <p-dropdown
          (onChange)="onLanguageChange()"
          [options]="languageList"
          [ngModel]="selectedLanguage()"
          (ngModelChange)="selectedLanguage.set($event)"
          placeholder="{{ 'COMMON.SELECT_LANGUAGE' | translate }}"
          optionLabel="name"
          [showClear]="false"
        ></p-dropdown>
      </div>
    </div>
    <div class="login-panel">
      <div class="login-panel-content text-left">
        <div class="col login-header">
          <div class="logo">
            <a href="https://appfibr.io"
              ><img [src]="logo" alt="{{ labelAlt }}"
            /></a>
          </div>
          <h4 class="mb-2">{{ "AUTH.LOGIN" | translate }}</h4>
          <span>{{ "AUTH.WELCOME_MESSAGE" | translate }}</span>
        </div>
        <div class="col">
          <div id="my-signin2">
            <div
              class="abcRioButton"
              (click)="handleClickGoogle()"
              (keyup.enter)="handleClickGoogle()"
              tabindex=""
            >
              <div class="abcRioButtonContentWrapper">
                <div class="abcRioButtonIcon">
                  <div
                    style="width: 18px; height: 18px"
                    class="abcRioButtonSvgImageWithFallback abcRioButtonIconImage abcRioButtonIconImage18"
                  >
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18px"
                      height="18px"
                      viewBox="0 0 48 48"
                      class="abcRioButtonSvg"
                    >
                      <g>
                        <path
                          fill="#EA4335"
                          d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                        ></path>
                        <path
                          fill="#4285F4"
                          d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                        ></path>
                        <path
                          fill="#FBBC05"
                          d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                        ></path>
                        <path
                          fill="#34A853"
                          d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                        ></path>
                        <path fill="none" d="M0 0h48v48H0z"></path>
                      </g>
                    </svg>
                  </div>
                </div>
                <span class="abcRioButtonContents">
                  <span
                    id="not_signed_inqxa7b2xmul7l"
                    style="font-family: 'Roboto', sans-serif"
                    >{{ "AUTH.GOOGLE_LOGIN" | translate }}</span
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="p-divider p-component p-divider-horizontal">
            <div class="p-divider-content bg-softgrey">
              {{ "COMMON.OR" | translate }}
            </div>
          </div>
        </div>
        <div class="col">
          <div class="forms mt-0">
            <div class="login-input-wrapper mb-3">
              <label for="username">Email</label>
              <input
                id="username"
                pinputtext=""
                [ngModel]="emailField.value()"
                (ngModelChange)="emailField.value.set($event)"
                placeholder="{{ 'AUTH.PLACEHOLDER_EMAIL' | translate }}"
                class="p-inputtext p-component line-input py-3"
              />
              <i class="pi pi-user"></i>
            </div>
            <div class="login-input-wrapper mb-3">
              <label for="password">{{ "AUTH.PASSWORD" | translate }}</label>
              <input
                [type]="fieldTextType()"
                [ngModel]="passwordField.value()"
                (ngModelChange)="passwordField.value.set($event)"
                id="password"
                placeholder="{{ 'AUTH.PLACEHOLDER_PASSWORD' | translate }}"
                ppassword=""
                class="p-inputtext p-component line-input py-3"
              />
              <i
                [class]="
                  fieldTextType() === 'text' ? 'pi pi-eye' : 'pi pi-eye-slash'
                "
                (click)="toggleFieldTextType()"
                (keyup.enter)="toggleFieldTextType()"
                tabindex="0"
              ></i>
            </div>
            <div class="grid center">
              <div class="col-6 px-0 text-right ml-auto">
                <a
                  href="javascript:;"
                  [routerLink]="['/forgot-password']"
                  class="secondary-button text-primary-500"
                  >{{ "AUTH.FORGOT_PASSWORD" | translate }}</a
                >
              </div>
            </div>

            <div class="buttons">
              <button
                id="login-appfibr"
                pbutton=""
                [disabled]="isProgressSpinner()"
                type="button"
                label="Login"
                tabindex="0"
                class="col-12 py-3 p-button text-lg bg-primary-500"
                (click)="handleClickLogin()"
              >
                <span class="p-button-label">{{
                  "AUTH.LOGIN" | translate
                }}</span>
              </button>
            </div>
            <div class="col-12 center">
              <a
                href="javascript:;"
                [routerLink]="['/register']"
                pbutton=""
                type="button"
                label="Register"
                tabindex="0"
                class="p-button-text mt-3 text-primary-500"
              >
                <span class="p-button-label">{{
                  "AUTH.REGISTER_LINK" | translate
                }}</span>
              </a>
            </div>

            <div class="col-12 center">
              <a
                href="https://appfibr.io"
                pbutton=""
                type="button"
                label="Back to AppFibr website"
                tabindex="1"
                class="p-button-text mt-3 text-primary-500"
              >
                <span class="p-button-label">{{
                  "AUTH.BACK_TO_FIBR_IO" | translate
                }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="login-panel right"
      [ngStyle]="{
        'background-image': 'url(' + imageLogin + ')',
        'background-size': 'cover',
        'background-position': 'center',
        'background-repeat': 'no-repeat'
      }"
    ></div>
  </div>
</div>
<fibr-block-ui [isBlocked]="isBlocked()" />
```
