import { Component, Input } from '@angular/core';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { Confirmation } from '../../../core/components/subheader/model/confirmation';

@Component({
  selector: 'fibr-comfirmation',
  standalone: true,
  imports: [ConfirmDialogModule],
  templateUrl: './confirmation.component.html',
  styleUrl: './confirmation.component.scss'
})
export class ConfirmationComponent {

  @Input() configConfirmation: Confirmation| undefined;

}
