import { Component, OnInit, signal } from '@angular/core';
import { ToastComponent } from '../../../../shared/components/toast/toast.component';
import { ButtonModule } from 'primeng/button';
import { HeaderV1Component } from '../../../../core/components/header-v1/header-v1.component';
import { CommonModule } from '@angular/common';
import { ProjectListComponent } from '../project-list/project-list.component';
import { FooterComponent } from '../../../../core/components/footer/footer.component';
import { DividerModule } from 'primeng/divider';
import { ProjectTemplateListComponent } from '../project-template-list/project-template-list.component';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { BannerComponent } from '../banner/banner.component';
import { DialogModule } from 'primeng/dialog';
import { TemplateDialogComponent } from '../template-dialog/template-dialog.component';
import { CreateProjectWithTemplateDialogComponent } from '../create-project-with-template-dialog/create-project-with-template-dialog.component';
import { ProjectsShardService } from '../../../../core/projects-shard/projects-shard.service';
import { CreateNewProjectDialogComponent } from '../create-new-project-dialog/create-new-project-dialog.component';
import { BlockUIComponent } from '@shared/components/block-ui/block-ui.component';
import { GoogleSheetListsDialogComponent } from '../google-sheet-lists-dialog/google-sheet-lists-dialog.component';

@Component({
  selector: 'fibr-project-list-page',
  standalone: true,
  imports: [
    ToastComponent,
    ButtonModule,
    HeaderV1Component,
    CommonModule,
    ProjectListComponent,
    FooterComponent,
    DividerModule,
    ProjectTemplateListComponent,
    TranslateModule,
    BannerComponent,
    DialogModule,
    TemplateDialogComponent,
    CreateProjectWithTemplateDialogComponent,
    CreateNewProjectDialogComponent,
    BlockUIComponent,
    GoogleSheetListsDialogComponent
  ],
  templateUrl: './project-list-page.component.html',
  styleUrl: './project-list-page.component.scss',
})
export class ProjectListPageComponent implements OnInit {
  useTemplateWithDataField = signal(true);
  isBlocked = signal(false);
  bannerDesktop: unknown[] = [];
  bannerMobile: unknown[] = [];

  constructor(private translate: TranslateService, private projectsShard: ProjectsShardService) {}

  ngOnInit(): void {
    this.projectsShard.tempInitializeFirebase();
    this.translate
      .get(['list_banners.desktop', 'list_banners.mobile'])
      .subscribe((next: Record<string, unknown[]>) => {
        this.bannerDesktop = next['list_banners.desktop'];
        this.bannerMobile = next['list_banners.mobile'];
      });
  }

  onToggleUseTemplateWithData() {
    this.useTemplateWithDataField.update((prev) => !prev);
  }

  onSetIsBlocked(isBlocked: boolean) {
    this.isBlocked.set(isBlocked);
  }
}
