<ng-template #dataTemplate let-tableColumns="columns">
  <div class="flex flex-col">
    <fibr-collapse label="editor.pages.source">
      <span class="text-lg capitalize text-[#8A8A8A] mb-2">
        {{ "editor.pages.column" | translate }}
      </span>

      <p-dropdown
        name="form_column"
        [options]="tableColumns"
        [(ngModel)]="config().column"
        optionLabel="label"
        optionValue="name"
      ></p-dropdown>
    </fibr-collapse>

    <fibr-collapse label="editor.pages.form.field_list.label">
      <span class="text-lg capitalize text-[#8A8A8A] mb-2">
        {{ "editor.pages.form.field_list.label" | translate }}
      </span>

      <input
        name="form_label"
        pInputText
        [(ngModel)]="config().label"
        placeholder="{{ 'editor.pages.form.field_list.label' | translate }}"
      />
    </fibr-collapse>

    <fibr-collapse label="editor.pages.form.field_list.validation">
      <label for="" class="capitalize">
        {{ "editor.pages.form.field_list.validation" | translate }}
      </label>

      <p-dropdown
        name="form_validation"
        placeholder="{{
          'editor.pages.form.field_list.validation.placeholder' | translate
        }}"
        [ngModel]="required()"
        (onChange)="changeValidation($event)"
        [options]="['required', 'optional']"
      >
        <ng-template let-selected pTemplate="selectedItem">
          <span>
            {{ "editor.pages.form." + selected | translate }}
          </span>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <span>{{ "editor.pages.form." + item | translate }}</span>
        </ng-template>
      </p-dropdown>
    </fibr-collapse>
  </div>
</ng-template>
