<!-- eslint-disable @angular-eslint/template/elements-content -->

<p-sidebar
  #sidebar
  styleClass="page-option"
  [visible]="visible"
  appendTo="body"
  [blockScroll]="true"
  [modal]="false"
  position="right"
  [style]="{ right: sidebarPositionStyle }"
>
  <p-card>
    <ng-template pTemplate="header">
      <div class="page-header">
        <p-card styleClass="card-details">
          <div class="grid align-items-center">
            <img alt="icon-details" [src]="selected().icon" />
            <b class="capitalize">Type : {{ labelType() | translate }}</b>
          </div>
        </p-card>
      </div>
      <div class="right-action">
        <p-button
          type="button"
          label=""
          icon="pi pi-times"
          styleClass="p-button-secondary p-button-text"
          pTooltip="Cancel"
          tooltipPosition="bottom"
          (onClick)="onCancel()"
        ></p-button>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <p-accordion [multiple]="true" [activeIndex]="[0, 1]">
        <p-accordionTab>
          <ng-template pTemplate="header">
            <div class="label-info">
              <img
                class="accordion-icon"
                alt="icon"
                src="/assets/images/icon/accordion-icon/icon_page_type.svg"
              />
              <span>Type</span>
            </div>
          </ng-template>

          <ng-template pTemplate="content">
            <div class="py-4">
              <div class="flex align-items-center gap-3 px-4">
                <button
                  class="capitalize flex-1"
                  pButton
                  pRipple
                  label="All ({{ componentTypes().length }})"
                  (click)="categorySelected.set('all')"
                  [ngClass]="{
                    'p-button-primary': categorySelected() === 'all',
                    'p-button-secondary2': categorySelected() !== 'all'
                  }"
                ></button>
                @for (category of componentCategories(); track $index) {
                <button
                  class="capitalize flex-1"
                  pButton
                  pRipple
                  label="{{ category.name }} ({{ category.length }})"
                  (click)="categorySelected.set(category.name)"
                  [ngClass]="{
                    'p-button-primary': categorySelected() === category.name,
                    'p-button-secondary2': categorySelected() !== category.name
                  }"
                ></button>
                }
              </div>

              <div class="grid comp-temp mt-3">
                @for(component of componentTypes(); track component) {
                <div
                  class="col-3 text-center comp-list flex align-items-center justify-content-center"
                >
                  <a href="javascript:;" (click)="select(component)">
                    <div
                      class="flex flex-column align-items-center justify-content-center"
                    >
                      <img
                        class="comp-icon"
                        [ngClass]="{
                          filtered: selected().type !== component.type
                        }"
                        alt="icon-{{ component.type }}"
                        [src]="component.icon"
                      />
                      <p
                        class="capitalize"
                        [ngClass]="{
                          filtered: selected().type !== component.type
                        }"
                      >
                        {{ component.label | translate }}
                      </p>
                    </div>
                  </a>
                </div>
                }
              </div>
            </div>
          </ng-template>
        </p-accordionTab>

        <p-accordionTab #config>
          <ng-template pTemplate="header">
            <div class="label-info">
              <img
                class="accordion-icon"
                alt="icon"
                src="/assets/images/icon/accordion-icon/icon_config.svg"
              />
              <span>Config</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            @if(!children){
            <p-tabView styleClass="tabview-standard">
              <p-tabPanel header="Data">
                <ng-container
                  *ngTemplateOutlet="selected().dataTemplate; context: {
                    tables: pageComponentStore.tableSource(),
                    selected: pageComponentStore.tableSelected(),
                    columns: pageComponentStore.tableColumns(),
                  }"
                >
                </ng-container>
              </p-tabPanel>

              @if (selected().detailTemplate) {
              <p-tabPanel header="Detail">
                <ng-container
                  *ngTemplateOutlet="
                    selected().detailTemplate;
                    context: {
                      components: pageComponentStore.configDetail(),
                      columns: pageComponentStore.tableSelectedColumn()
                    }
                  "
                >
                </ng-container>
              </p-tabPanel>
              } @if (selected().formTemplate) {
              <p-tabPanel header="Form">
                <ng-container
                  *ngTemplateOutlet="
                    selected().formTemplate;
                    context: {
                      components: pageComponentStore.configForm(),
                      columns: pageComponentStore.tableSelectedColumn()
                    }
                  "
                >
                </ng-container>
              </p-tabPanel>
              }
            </p-tabView>
            } @if (children) {
            <ng-container
              *ngTemplateOutlet="selected().dataTemplate; context: {
                    tables: pageComponentStore.tableSource(),
                    selected: pageComponentStore.tableSelected(),
                    columns: pageComponentStore.tableColumns(),
                  }"
            >
            </ng-container>
            }
          </ng-template>
        </p-accordionTab>

        @if(selected().optionTemplate){
          <p-accordionTab>
            <ng-template pTemplate="header">
              <div class="label-info">
                <img
                  class="accordion-icon"
                  alt="icon"
                  src="/assets/images/icon/accordion-icon/icon_options.svg"
                />
                <span>Options</span>
              </div>
            </ng-template>

            <ng-template pTemplate="content">
              <ng-container
                *ngTemplateOutlet="selected().optionTemplate; context: {
                  tables: pageComponentStore.tableSource(),
                  selected: pageComponentStore.tableSelected(),
                  columns: pageComponentStore.tableColumns(),
                }"
              >
              </ng-container>
            </ng-template>
          </p-accordionTab>
        }
      </p-accordion>
    </ng-template>
    <ng-template pTemplate="footer">
      <button
        pButton
        pRipple
        class="p-button-secondary2 p-button-text col-5 mr-5"
        type="button"
        label="{{ 'EDITOR.ACCORDION.POPUP.CANCEL' | translate }}"
        (click)="onCancel()"
      ></button>
      <button
        pButton
        pRipple
        class="col-5"
        type="button"
        label="OK"
        (click)="onApply()"
      ></button>
    </ng-template>
  </p-card>
</p-sidebar>
