import { Component, OnInit } from '@angular/core';
import { GalleriaModule } from 'primeng/galleria';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'fibr-banner',
  standalone: true,
  imports: [GalleriaModule, TranslateModule],
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.scss',
})
export class BannerComponent implements OnInit {
  bannerDesktop: unknown[] = [];
  bannerMobile: unknown[] = [];

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.translate
      .get(['list_banners.desktop', 'list_banners.mobile'])
      .subscribe((next) => {
        this.bannerDesktop = next['list_banners.desktop'];
        this.bannerMobile = next['list_banners.mobile'];
      });
  }
}
