import { Collection, CollectionStyle, FormType, TableConfig, View } from '../widget-builder.model';
import { v4 as uuidv4 } from 'uuid';
import { revertTimePicker } from "@shared/libs/generators/forms/utils";

export type ListBuilderParams = {
  id: string;
  style: CollectionStyle;
  headerTitle: string;
  columns: {
    title: string;
    image: string;
    description: string;
    label: string;
  };
  config?: Collection['config'];
  detailView: View[];
  formView: FormType[]
  tableConfig: TableConfig;
};

export default function list(params: ListBuilderParams): Collection {
  return {
    id: params.id,
    type: 'collection',
    view_type: 'list',
    title: params.headerTitle,
    style: {
      list_direction: params.style.list_direction,
      list_separator: {
        type: 'divider',
        height: 0.6,
        thickness: 0.6,
      },
      padding: {
        left: 16,
        right: 16,
        top: 16,
        bottom: 16,
      }
    },
    config: {
      ...params.config,
      with_search_bar: true,
    },
    data: {
      id: uuidv4(),
      type: 'collection_item',
      style: {
        spacer: 12,
        padding: {
          left: 8,
          right: 8,
          top: 8,
          bottom: 8,
        },
      },
      data: {
        image: {
          id: uuidv4(),
          type: 'image',
          style: {
            image_style: 'circle',
            corner_radius: 8,
            fit: 'none',
            aspect_ratio: '1:1',
          },
          data_source: {
            type: 'table',
            value: params.columns.image,
          },
        },
        title: {
          id: uuidv4(),
          type: 'label',
          style: {
            font_size: 12,
            font_weight: 'bold',
            max_lines: 1,
            overflow: 'ellipsis',
          },
          data_source: {
            type: 'table',
            value: params.columns.title,
          },
        },
        label: {
          id: uuidv4(),
          type: 'label',
          style: {
            font_size: 12,
            max_lines: 1,
            overflow: 'ellipsis',
            padding: {
              left: 12,
              right: 12,
              top: 8,
              bottom: 8,
            }
          },
          data_source: {
            type: 'table',
            value: params.columns.label,
          },
        },
        description: {
          id: uuidv4(),
          type: 'label',
          style: {
            font_size: 12,
            max_lines: 1,
            overflow: 'ellipsis',
          },
          data_source: {
            type: 'table',
            value: params.columns.description,
          },
        },
      },
    },
    detail_view: {
      app_bar: {
        id: uuidv4(),
        style: {
          "use_divider": true,
          "brightness": "dark"
        },
        title: 'Halaman Detail',
      },
      body: {
        id: uuidv4(),
        data: params.detailView,
        style: {
          spacer: 16,
          padding: {
            left: 16,
            right: 16,
            top: 16,
            bottom: 16,
          }
        }
      }
    },
    form_view: {
      app_bar: {
        id: uuidv4(),
        style: {
          "use_divider": true,
          "brightness": "dark"
        },
        title: 'Halaman Tambah',
      },
      body: {
        id: uuidv4(),
        data: params.formView.map(revertTimePicker)
      }
    },
    update_view: {
      app_bar: {
        id: uuidv4(),
        style: {
          "use_divider": true,
          "brightness": "dark"
        },
        title: 'Halaman Ubah',
      },
      body: {
        id: uuidv4(),
        data: params.formView
      }
    },
    table_config: {
      ...params.tableConfig,
      searchable_columns: [params.columns.title, params.columns.description]
    },
  }
}
