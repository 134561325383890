import { Injectable, computed, effect, signal } from '@angular/core';
import type { AuthStore, ProjectsShardConfig, User } from '../model';
import { setLocalStorage } from '@shared/libs/storage/localeStorage';

@Injectable({
  providedIn: 'root'
})
export class AuthStoreService {

  private _store = signal<AuthStore>({
    user: null,
    userToken: '',
    projectsShardConfigByProjectID: {},
    isLoggedIn: false,
    projectsShardToken: null
  })

  user = computed(() => this._store().user)
  userToken = computed(() => this._store().userToken)
  projectsShardConfigByID = computed(() => this._store().projectsShardConfigByProjectID)
  isLoggedIn = computed(() => !!this.user && this.user()?.emailVerified)
  projectsShardToken = computed(() => this._store().projectsShardToken)

  constructor() {
    effect(() => {
      setLocalStorage('PROJECTS_SHARD_CONFIG_BY_PROJECT_ID', JSON.stringify(this.projectsShardConfigByID()));
    });
  }

  setUser(user: User | null) {
    this._store.update((store) => ({ ...store, user }))
  }

  setUserToken(token: string) {
    this._store.update((store) => ({ ...store, userToken: token }))
  }

  setProjectsShardConfigByProjectID(projectID: string, config: ProjectsShardConfig) {
    this._store.update((store) => ({
      ...store,
      projectsShardConfigByProjectID: {
        ...store.projectsShardConfigByProjectID,
        [projectID]: config
      }
    }))
  }

  setprojectsShardToken(projectsShardToken: AuthStore['projectsShardToken']) {
    this._store.update((store) => ({ ...store, projectsShardToken }))
  }

  resetProjectsShardConfigByProjectID() {
    this._store.update((store) => ({ ...store, projectsShardConfigByProjectID: {} }))
  }

  restoreProjectsShardConfigByProjectID(config: AuthStore['projectsShardConfigByProjectID']) {
    this._store.update((store) => ({ ...store, projectsShardConfigByProjectID: config }))
  }

}
