import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FibrBaseComponentTypeComponent } from '../../../page-component/page-component-types/base-component-type.component';
import spacer from '@shared/libs/generators/widgets/spacer';
import { v4 } from 'uuid';

@Component({
  selector: 'fibr-component-separator',
  template: '',
  standalone: true,
  imports: [CommonModule],
})
export class FibrPageComponentSeparatorComponent extends FibrBaseComponentTypeComponent {
  override label: string = 'Spacer';
  override icon: string = '/assets/images/icon/details/icon_spacer.svg';
  override type: string = 'spacer';
  override category: string = 'content';

  constructor() {
    super('fibr-component-separator');
  }

  reset() {
    setTimeout(() => {
      this.pageComponentStore.closeDetail();
    }, 200);
  }

  override apply(): void {
    const output = {
      ...spacer(),
      id: this.pageComponentStore.dialogDetail().selected?.id || v4()
    };

    this.pageComponentStore.addOrEditDetail(output);
    this.reset();
  }

  override cancel(): void {
    this.reset();
  }

  protected override onDestroy(): void {
    this.reset();
  }
}
