<div class="p-4">
  <div>
    <span class="text-lg capitalize text-[#8A8A8A] mb-3">
      {{ "Form Description" | translate }}
    </span>

    <fibr-text-editor-quill
      name="description"
      [(ngModel)]="settings().description"
    />
  </div>

  <div class="mt-4">
    <p-checkbox
      label="Show Title & Description in Every Section"
      [binary]="true"
      name="showAllSection"
      [(ngModel)]="settings().showAllSection"
    />
  </div>

  <div class="mt-4">
    <span class="text-lg capitalize text-[#8A8A8A] mb-2">
        {{ "Table" | translate }}
      </span>

    <p-dropdown
      name="data_source"
      [options]="tables()"
      [ngModel]="tableSelected()"
      (ngModelChange)="changeSource($event)"
      placeholder="Select Table"
      optionValue="id"
      appendTo="body"
    >
      <ng-template let-selected pTemplate="selectedItem">
        <span>{{ selected.label || selected.id }}</span>
      </ng-template>
      <ng-template let-source pTemplate="item">
        <span>{{ source.label || source.id }}</span>
      </ng-template>
    </p-dropdown>
  </div>
</div>
