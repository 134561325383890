<div class="data-content" [ngClass]="isSelected ? 'flex' : 'hidden'">
  <div class="p-vmenu">
    <p-tabMenu class="editor editor-advance first-element" [model]="sidebarItems"
      [activeItem]="sidebarItems[selectedTableIndex]">
      <ng-template pTemplate="item" let-item let-i="index">
        @if (item.icon === 'google') {
        <span class="p-menuitem-img"><img alt="g-sheet" src="/assets/images/icon/icon_g-sheet.svg" height="10"></span>
        }
        @if (item.icon) {
        <span [class]="'p-menuitem-icon ' + item.icon"></span>
        }
        <span class="p-menuitem-text">{{item.label}}</span>
      </ng-template>
    </p-tabMenu>
  </div>
  <div class="data-table p-5">
    <div id="table-el" class="editor table-data">
      @if (selectedTable()?.label) {
      <div class="flex p-flex-column p-flex-md-row">
        <img class="align-self-center mr-3" alt="" src="/assets/images/icon-lg/table.svg" height="20">
        <h3 class="menu-title">{{selectedTable()?.label}}</h3>
      </div>
      }
      @if(!isGoogleFiles) {
      <div class="flex flex-column justify-content-between flex-wrap md:flex-row p-jc-md-between mt-3">
        <div class="p-inputgroup md:col-4 col-12 my-0 md:my-3 p-0">
          <input type="text" pInputText placeholder="{{'TABLE.SEARCH' | translate}}" [ngModel]="searchField()"
            (ngModelChange)="searchField.set($event)" (keyup)="onSearchPressEnter()">
          <button class="p-inputgroup-addon" (click)="onClickSearch()"><i class="pi pi-search"></i></button>
        </div>
        <div class="col-auto ml-auto p-0 mt-3">
          @if (!isGoogleFiles) {
          <button type="button" pButton pRipple (click)="onAddRow()" icon="pi pi-plus" iconPos="left"
            label="{{'TABLE.ADD_ROW' | translate}}" class="p-button-secondary mr-2 mb-2"> </button>
          <button type="button" pButton pRipple (click)="addNewColumn()" icon="pi pi-plus" iconPos="left"
            label="{{'TABLE.ADD_COLUMN' | translate}}" class="p-button-secondary mr-2 mb-2"> </button>
          <button type="button" pButton pRipple (click)="removeDataColumn()" icon="pi pi-trash" iconPos="left"
            label="{{'TABLE.REMOVE_DATA' | translate}}" class="p-button-secondary text-red mr-2 mb-2"> </button>
          }
          <button type="button" pButton pRipple (click)="actionTable.toggle($event)"
            class="p-button-secondary mr-2 mb-2" label="" icon="pi pi-ellipsis-v" iconPos="right"> </button>
          <p-menu #actionTable appendTo="body" [popup]="true" [model]="itemTable"></p-menu>
        </div>
      </div>
      }
      <!-- TODO multiple instance table -->
      @if (selectedTable()) {
      <fibr-table class="flex-1" [fields]="fields()" [initialData]="initialData()" (editRowEvent)="onEditRow($event)" (clickCellEvent)="onClickCell($event)"
        (deleteRowEvent)="onDeleteRow($event)" (dataSelected)="onSelectionDataRow($event)" [tableID]="selectedTable()!.id"
        (clickColumnHeaderEvent)="onShowUpdateColumnDialog($event)" />
      }
    </div>
  </div>
</div>

<!-- dialog / modal -->
@if (selectedColumnNameToUpdate() && selectedTable()?.columns?.[selectedColumnNameToUpdate()!]?.label) {
<p-dialog [visible]="showUpdateColumnDialog()" (visibleChange)="onCloseUpdateColumnDialog()" [modal]="true"
  [blockScroll]="true" [draggable]="false" [dismissableMask]="false" appendTo="body"
  styleClass="dialog-no-footer create-new" [style]="{width: '500px'}" header="Update Columns">
  <fibr-edit-column-dialog (closeDialogEvent)="onCloseUpdateColumnDialog()" [columnName]="selectedColumnNameToUpdate()!"
    [projectID]="projectID()!" [tableID]="selectedTable()!.id"
    [columnLabel]="selectedTable()!.columns[selectedColumnNameToUpdate()!].label"
    [columnType]="getColumnType(selectedTable()!.columns[selectedColumnNameToUpdate()!])" />
</p-dialog>
}

<p-dialog [visible]="showColumnsSettingDialog()" (visibleChange)="showColumnsSettingDialog.set(false)" [modal]="true"
  [blockScroll]="true" [draggable]="false" [dismissableMask]="true" appendTo="body" styleClass="dialog-no-footer"
  [style]="{width: '400px'}">
  <ng-template pTemplate="header">
    <span class="p-dialog-title capitalize"><i class="pi pi-list"></i>{{"TABLE.SHOW_HIDE_COLUMN" | translate}}</span>
  </ng-template>
  <div class="grid">
    <div class="col-12 text-left">
      <p class="capitalize">Columns</p>
      @for (setting of columnsSetting(); track setting.name) {
      <div class="field-checkbox">
        <p-checkbox [ngModel]="setting.display ?? true" [value]="setting.display ?? true" [binary]="true"
          (ngModelChange)="toggleShowHideColumn(setting)" [inputId]="setting.name"></p-checkbox>
        <label [for]="setting.name" class="capitalize">{{setting.label}}</label>
      </div>
      }
      <div class="p-inputgroup mt-2">
        <button pButton type="button" (click)="saveColumnsSetting()" label="Save" icon="pi pi-save" iconPos="right"
          class="rounded ml-auto">
        </button>
      </div>
    </div>
  </div>
</p-dialog>