<p-sidebar
  styleClass="page-option fourth-element"
  [visible]="visible"
  appendTo="body"
  [blockScroll]="true"
  [modal]="false"
  position="right"
>
  <p-card>
    <ng-template pTemplate="header">
      <div class="page-header">
        @if (pageProperty().icon) {
          <img [src]="selectedIcon()" alt="page-icon"/>
        }

        <div class="ml-2">
          <span class="title-text">{{ pageProperty().name }}</span>
        </div>

        @if (selectedPage()) {
          <p-button
            type="button"
            label=""
            icon="pi pi-copy"
            styleClass="p-button p-button-text"
            class="float-right"
            (onClick)="confirmCopyPage()"
            pTooltip="Copy Page"
            tooltipPosition="bottom"
          >
          </p-button>
          <p-button
            type="button"
            label=""
            icon="pi pi-trash"
            styleClass="p-button-danger p-button-text"
            class="float-right"
            (onClick)="confirmDeletePage()"
            pTooltip="Delete Page"
            tooltipPosition="bottom"
          >
          </p-button>
          <p-button
            type="button"
            [ngStyle]="{ display: isDebug ? 'block' : 'none' }"
            label=""
            icon="pi pi-cog"
            styleClass="p-button-secondary p-button-text"
            class="float-right"
            (onClick)="openConfigJson()"
            pTooltip="Json Config"
            tooltipPosition="bottom"
          >
          </p-button>
        }
      </div>
      <div class="right-action">
        <p-button
          type="button"
          label=""
          icon="pi pi-times"
          styleClass="p-button-secondary p-button-text"
          (onClick)="onCancel()"
          pTooltip="Cancel"
          tooltipPosition="bottom"
        ></p-button>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <p-accordion [multiple]="true">
        <p-accordionTab [selected]="true">
          <ng-template pTemplate="header">
            <div class="label-info">
              <img
                class="accordion-icon"
                alt="icon page"
                src="/assets/images/icon/accordion-icon/icon_page_type.svg"
              />
              {{ "EDITOR.ACCORDION.COMPONENT.PAGE_TYPE" | translate }}
            </div>
          </ng-template>
          <div class="grid p-3">
            <div class="col-12">
              <div class="grid comp-temp">
                @for (item of pageTypes; track item) {
                  <div class="col-3 comp-list">
                    <a
                      (click)="changePageType(item.value)"
                      (keyup.enter)="changePageType(item.value)"
                      tabindex=""
                      class="cursor-pointer"
                    >
                      <div class="flex flex-col items-center justify-center">
                        <img
                          class="comp-icon p-4"
                          [src]="item.icon"
                          [ngClass]="{
                          filtered: selectedPageType() !== item.value
                        }"
                          alt="page type"
                        />
                        <p
                          [ngClass]="{
                          filtered: selectedPageType() !== item.value
                        }"
                          class="w-full text-center pt-3"
                        >
                          {{ item.label }}
                        </p>
                      </div>
                    </a>
                  </div>
                }
              </div>

              @if (errors['pageType']) {
                <sub class="text-magenta">
                  {{ errors['pageType']?.[0] ?? '' | translate }}
                </sub>
              }
            </div>
          </div>
        </p-accordionTab>
        <p-accordionTab [selected]="true">
          <ng-template pTemplate="header">
            <div class="label-info">
              <img
                class="accordion-icon"
                alt="icon"
                src="/assets/images/icon/accordion-icon/icon_page-sm.svg"
              />
              {{ "editor.pages.property" | translate }}
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <div class="p-3">
              <div class="grid">
                <div class="col-12">
                  <p class="label-info">
                    {{ "editor.pages.page.title" | translate }}
                    <i
                      class="pi pi-info-circle"
                      (mouseenter)="ptInfo.toggle($event)"
                      (mouseleave)="ptInfo.toggle($event)"
                    ></i>
                  </p>
                  <p-overlayPanel
                    #ptInfo
                    styleClass="overlay-info"
                    appendTo="body"
                  >
                    <ng-template pTemplate>
                      <div class="p-card-shadow p-card p-component">
                        <div class="p-card-header">
                          <img
                            alt="Image Info"
                            src="/assets/images/overlay-info/oi_pt.jpg"
                          />
                        </div>
                        <div class="p-card-body">
                          <div class="p-card-title">
                            {{ "editor.pages.page.title" | translate }}
                          </div>
                          <div class="p-card-content p-0">
                            <p>{{ "editor.pages.page.tooltip" | translate }}</p>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </p-overlayPanel>
                  <input
                    type="text"
                    pInputText
                    class="col-12"
                    [ngModel]="pageProperty().name"
                    (ngModelChange)="changePageName($event)"
                    placeholder="Page Title"
                  />
                  @if (errors['pageTitle']) {
                    <sub class="text-magenta">
                      {{ errors['pageTitle']?.[0] ?? '' | translate }}
                    </sub>
                  }
                </div>
                <div class="col-12 align-self-center">
                  <p-overlayPanel
                    #samInfo
                    styleClass="overlay-info"
                    appendTo="body"
                  >
                    <ng-template pTemplate>
                      <div class="p-card-shadow p-card p-component">
                        <div class="p-card-header">
                          <img
                            alt="Image Info"
                            src="/assets/images/overlay-info/oi_sam.jpg"
                          />
                        </div>
                        <div class="p-card-body">
                          <div class="p-card-title">
                            {{ "editor.pages.set.as.menu" | translate }}
                          </div>
                          <div class="p-card-content p-0">
                            <p>
                              {{
                                "editor.pages.set.as.menu.tooltip.on"
                                  | translate
                              }}
                            </p>
                            <p>
                              {{
                                "editor.pages.set.as.menu.tooltip.off"
                                  | translate
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </p-overlayPanel>
                </div>
                <div class="col-12 align-self-center">
                  <!--                  <div-->
                  <!--                    class="p-inputgroup"-->
                  <!--                    *ngIf="!enableFeature && pageProperty().show_in_nav_bar"-->
                  <!--                  >-->
                  <!--                    <p-button-->
                  <!--                      icon="pi pi-user"-->
                  <!--                      iconPos="right"-->
                  <!--                      label="Accessible by Role"-->
                  <!--                      pTooltip="Setting Role for Web App and Mobile Apps"-->
                  <!--                      [disabled]="true"-->
                  <!--                    ></p-button>-->
                  <!--                  </div>-->
                  <!--                  <div class="p-inputgroup" *ngIf="enableFeature">-->
                  <!--                    <p-button-->
                  <!--                      styleClass="p-button-secondary2"-->
                  <!--                      [disabled]="true"-->
                  <!--                      icon="pi pi-user"-->
                  <!--                      iconPos="right"-->
                  <!--                      label="Accessible by Role"-->
                  <!--                      pTooltip="This feature only for business plan project"-->
                  <!--                    ></p-button>-->
                  <!--                  </div>-->
                </div>
              </div>
              <div class="grid icon-pack">
                <div class="col-3 p-2">
                  <span class="p-inputgroup-addon icon-preview">
                    @if (pageProperty().icon) {
                      <img [src]="selectedIcon()" alt="page-icon"/>
                    }
                  </span>
                </div>
                <div class="col-9 p-2">
                  <div class="grid">
                    <div class="col-12 px-0 pt-0">
                      <div class="p-inputgroup">
                        <input
                          type="text"
                          pInputText
                          class="p-inputtext p-component p-element ng-untouched ng-pristine ng-valid"
                          placeholder="{{
                            'EDITOR.ACCORDION.COMPONENT.SEARCH_ICON' | translate
                          }}"
                          icon=""
                          (keyup)="searchPageIcon($event)"
                        />

                        <span class="p-inputgroup-addon">
                          <i class="pi pi-search"></i>
                        </span>
                      </div>
                    </div>
                  </div>

                  <p-scrollPanel class="icon-list">
                    <div class="grid">
                      @for (icon of icons(); track icon) {
                        <a
                          (click)="changePageIcon(icon)"
                          (keyup.enter)="changePageIcon(icon)"
                          tabindex=""
                          class="col-auto d-flex align-self-center p-1 cursor-pointer"
                          style="color: #495057"
                        >
                          <img
                            [src]="convertIcon(icon)"
                            alt="page-icon"
                            class="md-10 md-light"
                          />
                        </a>
                      }
                    </div>
                  </p-scrollPanel>
                </div>
              </div>
            </div>
          </ng-template>
        </p-accordionTab>
        @if (this.selectedSettingPage()) {
          @for (settings of
            this.selectedSettingPage()?.settings; track $index) {
            <p-accordionTab [selected]="true">
              <ng-template pTemplate="header">
                <div class="label-info">
                  <img class="accordion-icon" alt="icon" [src]="settings.icon"/>

                  {{ settings.title | translate }}
                </div>
              </ng-template>

              @switch (settings.type) {
                @case ('component-list') {
                  <fibr-component-list [(pageComponents)]="pageComponents" [(utils)]="pageSettings"></fibr-component-list>
                }
                @case ('form-settings') {
                  <fibr-form-settings [(settings)]="formSettings"/>
                }
                @case ('section-list') {
                  <fibr-section-list [(sections)]="formComponents"/>
                }
              }
            </p-accordionTab>
          }
        }
      </p-accordion>
    </ng-template>
    <ng-template pTemplate="footer">
      <p-button
        label="{{ 'EDITOR.ACCORDION.POPUP.CANCEL' | translate }}"
        styleClass="p-button-secondary2 p-button-text col-5 mr-5"
        (click)="onCancel()"
      ></p-button>
      <p-button
        label="{{ 'editor.pages.apply.changes' | translate }}"
        styleClass="col-5"
        (click)="onApply()"
      ></p-button>
    </ng-template>
  </p-card>
</p-sidebar>

<fibr-block-ui [isBlocked]="isLoading"></fibr-block-ui>

<fibr-page-config-json [visible]="displayConfigPage()" (cancel)="displayConfigPage.set(false)"
                       [component]="selectedPageConfig() || {}"
                       [page]="selectedPageId()"></fibr-page-config-json>
