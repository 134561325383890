<div class="mb-4">
  <h2 class="font-light">Account</h2>
</div>

<form class="flex flex-column gap-3" (ngSubmit)="onSubmit()">
  <div class="flex flex-column gap-4 lg:px-4">
    <div class="grid">
      <div class="col-12 lg:col-8">
        <label for="name">Name</label>
      </div>
      <div class="col-12 lg:col-4">
        <input
          id="name"
          type="text"
          pInputText
          name="name"
          placeholder="Type your name"
          [(ngModel)]="formGroup().name"
          aria-describedby="name-help"
        />
        @if (errors['name']) {
        <small id="name-help" class="p-error">
          {{ errors["name"][0] }}
        </small>
        }
      </div>
    </div>

    <div class="grid">
      <div class="col-12 lg:col-8">
        <label for="email">Email</label>
      </div>
      <div class="col-12 lg:col-4">
        <input
          id="email"
          type="email"
          pInputText
          name="email"
          placeholder="Type your email"
          [(ngModel)]="formGroup().email"
          [disabled]="true"
        />
        @if (errors['email']) {
        <small id="name-help" class="p-error">
          {{ errors["email"][0] }}
        </small>
        }
      </div>
    </div>

    <div class="grid">
      <div class="col-12 lg:col-8">
        <label for="profile">Profile Picture</label>
        <p class="font-italic">Recommended image 250*250 px (.jpg format)</p>
      </div>
      <div class="col-12 lg:col-4">
        <fibr-image-picker
          name="photoPicture"
          [ngModel]="formGroup().photoPicture"
          (ngModelChange)="changePicture($event)"
        ></fibr-image-picker>
      </div>
    </div>

    <div class="grid">
      <div class="col-12 lg:col-8">
        <label for="phoneNumber">Phone Number</label>
      </div>
      <div class="col-12 lg:col-4">
        <input
          id="phoneNumber"
          type="tel"
          pInputText
          name="phoneNumber"
          placeholder="Type your phone number"
          [(ngModel)]="formGroup().phoneNumber"
        />
        @if (errors['phoneNumber']) {
        <small id="name-help" class="p-error">
          {{ errors["phoneNumber"][0] }}
        </small>
        }
      </div>
    </div>

    <div class="grid">
      <div class="col-12 lg:col-8">
        <label for="gender">Gender</label>
      </div>
      <div class="col-12 lg:col-4">
        <p-selectButton
          name="gender"
          [options]="['male', 'female']"
          styleClass="p-button-lg button-select"
          [(ngModel)]="formGroup().gender"
        >
          <ng-template let-item pTemplate>
            <span class="capitalize">{{ item }}</span>
          </ng-template>
        </p-selectButton>
        @if (errors['gender']) {
        <small id="name-help" class="p-error">
          {{ errors["gender"][0] }}
        </small>
        }
      </div>
    </div>

    <div class="grid">
      <div class="col-12 lg:col-8">
        <label for="birthdate">Birthdate</label>
      </div>
      <div class="col-12 lg:col-4">
        <input
          id="birthdate"
          type="date"
          pInputText
          name="birthdate"
          placeholder="Select date"
          [(ngModel)]="formGroup().birthdate"
        />
        @if (errors['birthdate']) {
        <small id="name-help" class="p-error">
          {{ errors["birthdate"][0] }}
        </small>
        }
      </div>
    </div>

    <div class="grid">
      <div class="col-12 lg:col-8">
        <label for="type">Type</label>
      </div>
      <div class="col-12 lg:col-4">
        <p-selectButton
          name="type"
          [options]="['personal', 'business']"
          styleClass="p-button-lg button-select"
          [disabled]="true"
          [(ngModel)]="formGroup().type"
        >
          <ng-template let-item pTemplate>
            <span class="capitalize">{{ item }}</span>
          </ng-template>
        </p-selectButton>
        @if (errors['type']) {
        <small id="name-help" class="p-error">
          {{ errors["type"][0] }}
        </small>
        }
      </div>
    </div>

    <div class="grid">
      <div class="col-12 lg:col-8">
        <label for="address">Address</label>
      </div>
      <div class="col-12 lg:col-4">
        <input
          id="address"
          type="text"
          pInputTextarea
          name="address"
          placeholder="Type your address"
          [(ngModel)]="formGroup().address"
        />
        @if (errors['address']) {
        <small id="name-help" class="p-error">
          {{ errors["address"][0] }}
        </small>
        }
      </div>
    </div>

    <div class="grid">
      <div class="col-12 lg:col-8">
        <label for="interest">Interest</label>
      </div>
      <div class="col-12 lg:col-4">
        <textarea
          id="interest"
          type="text"
          pInputText
          name="interest"
          placeholder="Type your interest"
          [(ngModel)]="formGroup().interest"
        ></textarea>
        @if (errors['interest']) {
        <small id="name-help" class="p-error">
          {{ errors["interest"][0] }}
        </small>
        }
      </div>
    </div>
  </div>

  <p-divider></p-divider>

  <div class="flex justify-content-end">
    <p-button
      label="Save"
      iconPos="right"
      icon="pi pi-save"
      type="submit"
      [loading]="isLoading"
    ></p-button>
  </div>
</form>