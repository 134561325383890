import { Injectable, computed, signal } from '@angular/core';
import type { TableID, TableStore } from '../model';
import type { Row } from '@shared/components/table/table.component';

@Injectable({
  providedIn: 'root'
})
export class TableStoreService {
  private _store = signal<TableStore>({
    tableList: {
      isLoading: false,
      data: []
    },
    tableDataByTableID: {
      isLoading: true,
      data: {}
    }
  })

  tableList = computed(() => this._store().tableList);
  tableDataByTableID = computed(() => this._store().tableDataByTableID);

  setTableList(tableList: TableStore['tableList']) {
    this._store.update(prev => ({
      ...prev,
      tableList
    }))
  }

  setTableDataByTableID(tableID: TableID, tableData: Row[]) {
    tableData.sort((a: Row , b: Row) =>{
      if (a['created_at'] < b['created_at']){
        return -1;
      } else if(a['created_at'] > b['created_at']) {
        return 1;
      } else {
        return 0;
      }
    }) 
    this._store.update(prev => ({
      ...prev,
      tableDataByTableID: {
        ...prev.tableDataByTableID,
        data: {
          ...prev.tableDataByTableID.data,
          [tableID]: tableData
        }
      }
    }))
  }
}
