import { Component, Input, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { ProjectStoreService } from '../../store/project-store.service';
import { ProjectAPIService } from '../../api/project-api.service';
import { handleErrorTranslationToast } from '@shared/libs/error';
import { setToast } from '@shared/components/toast/toast.component';
import { Router } from '@angular/router';
import { BlockUIComponent } from '@shared/components/block-ui/block-ui.component';

@Component({
  selector: 'fibr-create-project-with-template-dialog',
  standalone: true,
  imports: [DividerModule, TranslateModule, ButtonModule, FormsModule, DialogModule, BlockUIComponent],
  templateUrl: './create-project-with-template-dialog.component.html',
  styleUrl: './create-project-with-template-dialog.component.scss'
})
export class CreateProjectWithTemplateDialogComponent {
  @Input() useTemplateWithDataField = true;

  appNameField = signal('');
  appNameFieldError = signal('');
  isSubmitting = signal(false);

  constructor(
    private projectStore: ProjectStoreService,
    private projectAPI: ProjectAPIService,
    private translate: TranslateService,
    private router: Router
  ) { }

  get showDialog() {
    return this.projectStore.activeDialog() === 'create_project_with_template';
  }

  closeDialog() {
    this.projectStore.setActiveDialog(null);
    this.projectStore.setSelectedTemplate(null);
  }

  submit() {
    let projectName = this.projectStore.selectedTemplate()?.display_name ?? '';
    let counter = 1;
    const existingProjectNames = this.projectStore.projectList().map(project => project.display_name);

    while(existingProjectNames.includes(projectName)){
      projectName = this.projectStore.selectedTemplate()?.display_name+` (${counter})`;
      counter++;
    }
    this.isSubmitting.set(true);
    this.projectAPI.createProjectUsingTemplate(
      projectName,
      this.projectStore.selectedTemplate()?.id ?? '',
      this.useTemplateWithDataField
    )
      .then((result) => {
        if (result.isSuccess) {
          setToast(this.translate.instant('PROJECT.SUCCESS_CREATE_PROJECT'), 'success', 'top-center');
          this.router.navigate(['/projects', result.value]);
        } else {
          handleErrorTranslationToast(this.translate, 'top-center')(result.error);
        }
      })
      .catch(handleErrorTranslationToast(this.translate, 'top-center'))
      .finally(() => this.isSubmitting.set(false));
    this.closeDialog();
  }
}
