<div class="flex flex-col gap-5 justify-center items-center pt-0 join-app">
    <div class="logo w-1/5">
        <a href="https://appfibr.io"
            ><img [src]="logo" alt="{{ labelAlt }}"
        /></a>
    </div>
    @if (error() !== null){
        <div class="flex flex-col border bg-[#FFFFFF] border-solid border-[#CCCCCC] p-7 text-center mt-5 gap-3">
            @if (error() === true){
                <h2 class="text-[2rem] text-[#F34819] font-medium">{{'PROJECT.INVITATION_INVALID.TITLE' | translate}}</h2>
                <p class="text-[1.125rem] font-medium text-[#444444] w-[30.5625rem]">{{errMessage()}}</p>
            } @else {
                <h2 class="text-[2rem] text-[#2EC1AC] font-medium">{{'PROJECT.INVITATION_SUCCESS.TITLE' | translate}}</h2>
                <p class="text-[1.125rem] font-medium text-[#444444] w-[30.5625rem]">{{'PROJECT.INVITATION_SUCCESS.CONTENT' | translate}}</p>
                <p>You will be redirected to App in {{countdown}} seconds.</p>
            }
        </div>
    }

</div>