import { Injectable, inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  ICheckout,
  IGetInvoice,
  Invoice,
  InvoiceDto,
} from '../model/billing.model';
import { AuthStoreService } from '../../auth/store/auth-store.service';
import { GenericHttpResponse } from '../../../core/types/httpResponse';
import { BillingStore } from '../store/billing.store';
import { groupBy } from '@shared/libs/groupBy';
import { ProjectsShardService } from '../../../core/projects-shard/projects-shard.service';
import { signInWithCustomToken } from '@angular/fire/auth';

@Injectable()
export class BillingApi {
  private billingStore: BillingStore = inject(BillingStore);
  private projectShard: ProjectsShardService = inject(ProjectsShardService);
  private auth = inject(AuthStoreService);
  private http = inject(HttpClient);

  async checkout(params: ICheckout): Promise<GenericHttpResponse<Invoice>> {
    const baseUrl = `${environment.apiProjectUrl}/createInvoice`;
    let tokenId = this.auth.projectsShardToken()?.token;

    if (!tokenId) {
      const project = await this.projectShard.getProjectsShardConfig(params.projectId)
      if (project.isSuccess) {
        const credentials = await signInWithCustomToken(this.projectShard.auth, project.value.customToken);
        const tokens = await this.projectShard.getTokenResult(credentials);
        tokenId = tokens.token;
      }
    }

    const response = (await lastValueFrom(
      this.http.post(baseUrl, {
        id_token: tokenId,
        project_id: params.projectId,
        package_id: params.packageId,
        voucher_id: params.voucherId,
        tax_percentage: 11,
      })
    )) as GenericHttpResponse<Invoice>;

    return response;
  }

  async getInvoices(): Promise<Invoice[]> {
    const baseUrl = `${environment.apiUrl}/listInvoice`;
    const response = (await lastValueFrom(
      this.http.post(baseUrl, {
        id_token: this.auth.userToken(),
      })
    )) as GenericHttpResponse<InvoiceDto[]>;

    const toDate = (seconds: number) => {
      const date = new Date(0);
      date.setSeconds(seconds);
      return date;
    };

    const invoices = response.data
      .map((invoice) => {
        const proPlanUntil = toDate(invoice.created_at._seconds);
        proPlanUntil.setDate(proPlanUntil.getDate() + invoice.duration_days);

        return {
          ...invoice,
          user: invoice.user_display_name,
          created_at: toDate(invoice.created_at._seconds),
          updated_at: invoice?.updated_at ? toDate(invoice.updated_at?._seconds) : null,
          expired_at: invoice?.expired_at ? toDate(invoice.expired_at?._seconds) : null,
          pro_plan_until: proPlanUntil,
          paid_at: invoice?.paid_at?._seconds
            ? toDate(invoice.paid_at?._seconds)
            : null,
        };
      })
      .sort((a, b) => b.created_at.getTime() - a.created_at.getTime());

    return invoices;
  }

  async setupInvoices(): Promise<Invoice[]> {
    const invoices = await this.getInvoices();

    const projects = groupBy<Invoice>(invoices, (i) => i.project_id);
    // prettier-ignore
    const optionProjects = Object.keys(groupBy<Invoice>(invoices, (i) => i.project_id)).map((invoice) => ({
      label: projects[invoice].at(0)?.project_name || '',
      value: invoice,
    }));

    this.billingStore.setBillingOptions({
      projects: optionProjects,
      methods: ['OTP', 'Recurring'],
      status: [
        {
          label: 'Unpaid',
          value: 'PENDING',
        },
        {
          label: 'Paid',
          value: 'PAID',
        },
        {
          label: 'Expired',
          value: 'EXPIRED',
        },
      ],
    });

    this.billingStore.setInvoices(invoices);

    const needPayments = invoices
      .filter((x) => x.status === 'PENDING' && x.expired_at !== null)
      .filter((x) => {
        const date_cycle = new Date();
        date_cycle.setDate(new Date().getDate() - 7);
        return (<Date>x.expired_at).getTime() > date_cycle.getTime();
      })
      .sort((a, b) => (<Date>b.expired_at).getTime() - (<Date>a.expired_at).getTime())
      .slice(0, 2);

    this.billingStore.setNeedPayment(needPayments);

    // prettier-ignore
    this.billingStore.setTotalUnpaidBilling(invoices.filter((x) => x.status === 'PENDING').length);

    return invoices;
  }

  async getInvoice(params: IGetInvoice): Promise<Invoice | null> {
    return new Promise((resolve, reject) => {
      this.getInvoices()
        .then((invoices) => {
          const invoice = invoices
            .filter((i) => i.project_id === params.projectId)
            .filter((i) => i.id === params.invoiceId)
            .at(0);

          if (!invoice) {
            reject({ code: 'INVOICE_NOT_FOUND' });
            return;
          }

          this.billingStore.setInvoice(invoice);

          resolve(invoice);
        })
        .catch((error) => {
          reject({ code: 'ERROR_WHILE_FETCH', detail: error });
        });
    });
  }
}
