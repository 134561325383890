<fibr-header-v1 [srcImg]="'/assets/images/logo_appfibr.svg'"></fibr-header-v1>
<fibr-block-ui [isBlocked]="isProcessing"></fibr-block-ui>

<div class="p-content fibr-portal min-h-screen">
  <div class="grid px-4 pt-4 pb-8 h-full">
    <div class="col-12">
      <div class="grid">
        <div class="lg:col-3 col-12">
          <div
            class="w-full h-full bg-[#084B83] flex flex-column justify-content-between px-3 py-3 rounded-lg"
          >
            <h6 class="text-xl font-medium text-white">
              {{ "BILLING.UNPAID.TITLE" | translate }}
            </h6>

            <div
              class="flex flex-row align-items-center justify-content-between py-4 pr-3"
            >
              <span class="text-2xl font-medium text-white">
                {{ "BILLING.CHECKOUT.TOTAL" | translate }}
              </span>

              <span class="text-4xl font-medium text-[#F4B941]">
                {{ totalUnpaid() }} {{ "BILLING.INVOICE.TITLE" | translate }}
              </span>
            </div>
          </div>
        </div>

        <div class="lg:col-9 col-12">
          <div
            class="w-full bg-[#F7F7F7] border border-[#E1E1E1] px-3 py-3 rounded-lg h-full"
          >
            <h6 class="text-xl font-medium text-[#084B83]">
              {{ "BILLING.NEED_PAYMENT.TITLE" | translate }}
            </h6>

            <p-dataView [value]="needPayment()" [loading]="isLoading" layout="grid" styleClass="mt-2 need-payment">
              <ng-template pTemplate="empty">
                <div class="flex flex-col items-center justify-center">
                  <span>{{ 'COMMON.NO_DATA' | translate }}</span>
                </div>
              </ng-template>

              <ng-template pTemplate="grid" let-listInvoices>
                <div class="grid">
                  @for (invoice of listInvoices; track $index) {
                  <div class="lg:col-6 col-12">
                    <div
                      class="w-full flex flex-row align-items-center justify-content-between bg-white border border-[#E1E1E1] rounded-lg pl-4 pr-6 py-3"
                    >
                      <div>
                        <h6 class="text-lg text-[#5A5A5A] my-0">
                          {{ invoice.project_name }}
                        </h6>
                        <div class="flex flex-column mt-2">
                          <span class="text-lg font-semibold text-[#444444]">
                            {{ invoice.amount | currency : "Rp " : "code" : "0.0-0" }}
                          </span>
                          <span class="text-sm text-[#444444]">
                            {{ "BILLING.INVOICE.EXPIRED_ON" | translate }}
                            {{ invoice.expired_at | date : "EEEE dd/MM/yyyy" }}
                          </span>
                        </div>
                      </div>

                      <a [href]="invoice.invoice_url">
                        <p-button
                          label="{{ 'BILLING.INVOICE.PAY' | translate }}"
                          icon="pi pi-angle-right"
                          iconPos="right"
                        ></p-button>
                      </a>
                    </div>
                  </div>
                  }
                </div>
              </ng-template>
            </p-dataView>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="flex flex-row align-items-center gap-3">
        <img src="/assets/images/icon/icon_billing-lg.svg" alt="logo" />

        <h5 class="text-5xl text-[#084B83] font-medium">
          {{ 'BILLING.TITLE' | translate }}
        </h5>
      </div>
    </div>

    <div class="col-12">
      <div class="grid">
        <div class="lg:col-3 col-12">
          <input
            pInputText
            placeholder="{{ 'BILLING.UTILS.SEARCH' | translate }}"
            class="w-full"
            type="search"
            [(ngModel)]="filters().search"
            (keyup)="searchBilling($event)"
            (search)="searchBilling($event)"
          />
        </div>
        <div class="col-1 lg:block hidden"></div>
        <div class="lg:col-2 col-12">
          <p-dropdown
            [options]="billingProjects()"
            [ngModel]="filters().project_id || null"
            (ngModelChange)="changeProject($event)"
            placeholder="{{ 'BILLING.UTILS.PROJECT_NAME' | translate }}"
            [showClear]="true"
          ></p-dropdown>
        </div>
        <div class="lg:col-2 col-12">
          <p-dropdown
            [options]="billingMethod()"
            [ngModel]="filters().method || null"
            (ngModelChange)="changeMethod($event)"
            placeholder="{{ 'BILLING.INVOICE.METHOD' | translate }}"
            [showClear]="true"
          ></p-dropdown>
        </div>
        <div class="lg:col-2 col-12">
          <p-dropdown
            [options]="billingStatus()"
            [ngModel]="filters().status || null"
            (ngModelChange)="changeStatus($event)"
            placeholder="{{ 'BILLING.STATUS.LABEL' | translate }}"
            [showClear]="true"
          ></p-dropdown>
        </div>
        <div class="lg:col-2 col-12">
          <p-calendar
            placeholder="{{ 'BILLING.UTILS.ORDER_DATE' | translate }}"
            [showClear]="true"
            [ngModel]="filters().orderDate"
            (ngModelChange)="changeDate($event)"
          ></p-calendar>
        </div>
      </div>
    </div>

    <div class="col-12">
      <p-dataView [value]="invoices()" [loading]="isLoading" [paginator]="true" [rows]="5">        
        <ng-template pTemplate="empty">
          <div class="flex flex-col items-center justify-center">
            <span>{{ 'COMMON.NO_DATA' | translate }}</span>
          </div>
        </ng-template>

        <ng-template let-listInvoice pTemplate="list">
          <div class="space-y-6">
            @for (invoice of listInvoice; track $index) {
              <div
                class="bg-[#F7F7F7] w-full grid border border-[#E1E1E1] rounded-lg lg:px-6 px-3 lg:py-4 py-3"
              >
                <div class="lg:col-9 col-7 flex lg:flex-row flex-column">
                  <div
                    class="flex-1 flex flex-column justify-content-center lg:mt-0 mt-3"
                  >
                    <h6 class="text-lg text-[#444444] my-0">
                      {{ invoice.project_name }}
                    </h6>

                    <div class="mt-3">
                      <p class="text-lg font-semibold">
                        {{
                          "BILLING.CHECKOUT.PRICING.PACKAGE"
                            | translate : { month: invoice.duration_days / 30 }
                        }}
                      </p>

                      @switch (invoice.status) { @case ('PENDING') {
                        <p class="text-lg text-[#CB444A] uppercase">
                          {{ "BILLING.INVOICE.PAY_BEFORE" | translate }}
                          {{ invoice.expired_at | date : "dd/MM/yyyy" }}
                        </p>
                      } @case ('PAID') {
                        <p class="text-lg">
                          {{ "BILLING.INVOICE.PRO_UNTIL" | translate }}:
                          {{ invoice.pro_plan_until | date : "dd/MM/yyyy" }}
                        </p>
                      } @case ('EXPIRED'){
                        <p class="text-lg uppercase">
                          {{ "BILLING.INVOICE.PAY_BEFORE" | translate }}
                          {{ invoice.expired_at | date : "dd/MM/yyyy" }}
                        </p>
                      } }
                    </div>
                  </div>

                  <div
                    class="flex-1 flex flex-column justify-content-center lg:mt-0 mt-3"
                  >
                    <p class="text-lg font-semibold">
                      {{ "BILLING.CHECKOUT.TOTAL" | translate }}
                    </p>
                    <p class="text-lg text-[#444444] font-medium">
                      {{ invoice.amount | currency : "Rp " : "code" : "0.0-0" }}
                    </p>
                  </div>

                  <div
                    class="flex-1 flex flex-column justify-content-center lg:mt-0 mt-3"
                  >
                    <p class="text-lg font-semibold">
                      {{ "BILLING.INVOICE.METHOD" | translate }}
                    </p>
                    <p class="text-lg text-[#444444] font-medium">-</p>
                  </div>

                  <div
                    class="flex-1 flex flex-column justify-content-center lg:mt-0 mt-3"
                  >
                    <fibr-label-invoice
                      [status]="invoice.status"
                    ></fibr-label-invoice>
                  </div>
                </div>

                <div class="lg:col-3 col-5 flex flex-row gap-3 align-items-center">
                  <button (click)="openInvoice(invoice)">
                    <i class="icon-receipt"></i>
                  </button>

                  @switch (invoice.status) { @case ('PENDING') {
                    <a [href]="invoice.invoice_url">
                      <p-button
                        label="{{ 'BILLING.INVOICE.PAY' | translate }}"
                        icon="pi pi-angle-right"
                        iconPos="right"
                      ></p-button>
                    </a>
                  } @case ('EXPIRED') {
                    <p-button
                      label="{{ 'BILLING.INVOICE.RENEW_PAYMENT' | translate }}"
                      [outlined]="true"
                      (onClick)="renewPayment(invoice)"
                    ></p-button>
                  } @case ('PAID') {
                    <p-button
                      label="{{ 'BILLING.INVOICE.EXTEND_PAYMENT' | translate }}"
                      [outlined]="true"
                      (onClick)="extendPayment(invoice)"
                    ></p-button>
                  } }
                </div>
              </div>
            }
          </div>
        </ng-template>
      </p-dataView>
    </div>
  </div>
</div>
