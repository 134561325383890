import { Component, Inject, OnDestroy, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { flatten, safeParse, BaseSchema } from 'valibot';
import { environment } from '../../../environments/environment';

@Component({
  template: '',
})
export abstract class BaseComponent implements OnDestroy {
  destroy: Subject<boolean>;
  _translate: TranslateService;

  schema: unknown;
  errors: Partial<Record<string, string[]>>;
  isLoading: boolean;
  isProcessing: boolean;

  readonly appfibr = environment;

  constructor(@Inject(String) public moduleCode: string) {
    this.errors = {};
    this._translate = inject(TranslateService);
    this.isLoading = false;
    this.isProcessing = false;
    this.destroy = new Subject();
  }

  translate(key: string, params?: object | undefined): string {
    return this._translate.instant(key, params);
  }

  validate(value: unknown) {
    const result = safeParse(this.schema as BaseSchema, value);

    if (result.issues) {
      this.errors = flatten(result.issues).nested;
    } else {
      this.errors = {};
    }

    return {
      ...result,
      errors: this.errors
    };
  }

  setStateLoading(): void {
    this.isLoading = true;
  }

  setStateProcessing(): void {
    this.isProcessing = true;
  }

  setStateReady(): void {
    this.isLoading = false;
    this.isProcessing = false;
  }

  resetValidation() {
    this.errors = {};
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }
}
