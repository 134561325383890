import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Confirmation } from './model/confirmation';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToolbarSubheaderRightComponent } from '@shared/components/toolbar-subheader-right/toolbar-subheader-right.component';
import { Plan, Project } from '../../../features/project/model';
import { DialogService } from 'primeng/dynamicdialog';
import { PopupCheckoutSubscriptionComponent } from '../../../features/billing/ui/popup-checkout-subscription/popup-checkout-subscription.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'fibr-subheader',
  standalone: true,
  imports: [ToolbarModule, ButtonModule, ConfirmDialogModule, BreadcrumbModule, CommonModule, TranslateModule, ToolbarSubheaderRightComponent],
  templateUrl: './subheader.component.html',
  styleUrl: './subheader.component.scss'
})
export class SubheaderComponent {

  @Input() projectDetail : Project | null = null
  @Input() planDetail: Plan | null = null;
  @Input() isReleased: boolean = false;
  @Input() minimal: boolean = false;

  @Output() publishApp = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() invite = new EventEmitter();
  @ViewChild(ToolbarSubheaderRightComponent) opPublishAppChild: ToolbarSubheaderRightComponent;

  items: MenuItem[] = [
    { label: "Editor" }
  ]

  confirmationTemplate: Confirmation | undefined;

  constructor(
    private cs : ConfirmationService,
    private translate: TranslateService,
    private dialog: DialogService,
    private route: ActivatedRoute
  ) {}

  backProjectClick(){
    this.confirmationTemplate = {
      header: this.translate.instant("EDITOR.CONFIRMATION_EXIT.HEADER"),
      content: this.translate.instant("EDITOR.CONFIRMATION_EXIT.CONTENT"),
      rejectButtonStyleClass: "p-button-secondary",
      dismissableMask: true
    };
    this.cs.confirm({
      message: this.confirmationTemplate.content,
      header: this.confirmationTemplate.header,
      rejectButtonStyleClass: this.confirmationTemplate.rejectButtonStyleClass,
      dismissableMask: this.confirmationTemplate.dismissableMask,
      accept:  async () => {
        // Currently has memory leak if use router navigate, because there's an error while destroy table component
        // https://github.com/cloudfibr/appfibr-portal-v2/issues/99
        window.location.href = "/projects";
      },
      reject: () => {
        //
      }
    });
  }

  onPublishApp() {
    this.publishApp.emit();
  }

  onSave() {
    this.save.emit();
  }

  callOpPublishApp() {
    this.opPublishAppChild.showOverlayPanel('');
  }

  onInvite() {
    this.invite.emit();
  }

  getDaysFromToday(dateString: number): number {
    const today = new Date();
    const date = new Date(dateString * 1000);
  
    const diffInTime = date.getTime() - today.getTime();
    const diffInDays = Math.round(diffInTime / (1000 * 60 * 60 * 24));
  
    return diffInDays;
  }

  upgradeProject() {
    this.dialog.open(PopupCheckoutSubscriptionComponent, {
      data: { projectId: this.route.snapshot.paramMap.get('id'), from: 'editor' }
    })
  }
}
