import {NgModule} from "@angular/core";
import {FibrPageComponentTextEntryComponent} from "./page-component-text-entry/page-component-text-entry.component";
import {FibrPageComponentTextNumberComponent} from "./page-component-text-number/page-component-text-number.component";
import {FibrPageComponentFilePickerComponent} from "./page-component-file-picker/page-component-file-picker.component";
import {
  FibrPageComponentImagePickerComponent
} from "./page-component-image-picker/page-component-image-picker.component";
import {FibrPageComponentCheckboxComponent} from "./page-component-checkbox/page-component-checkbox.component";
import {FibrPageComponentRadioComponent} from "./page-component-radio/page-component-checkbox.component";
import {FibrPageComponentDropdownComponent} from "./page-component-dropdown/page-component-dropdown.component";
import {FibrPageComponentDatePickerComponent} from "./page-component-date-picker/page-component-date-picker.component";
import {
  FibrPageComponentDateTimePickerComponent
} from "./page-component-date-time-picker/page-component-date-picker.component";

@NgModule({
  imports: [
    FibrPageComponentTextEntryComponent,
    FibrPageComponentTextNumberComponent,
    FibrPageComponentFilePickerComponent,
    FibrPageComponentImagePickerComponent,
    FibrPageComponentCheckboxComponent,
    FibrPageComponentRadioComponent,
    FibrPageComponentDropdownComponent,
    FibrPageComponentDatePickerComponent,
    FibrPageComponentDateTimePickerComponent,
  ],
  exports: [
    FibrPageComponentTextEntryComponent,
    FibrPageComponentTextNumberComponent,
    FibrPageComponentFilePickerComponent,
    FibrPageComponentImagePickerComponent,
    FibrPageComponentCheckboxComponent,
    FibrPageComponentRadioComponent,
    FibrPageComponentDropdownComponent,
    FibrPageComponentDatePickerComponent,
    FibrPageComponentDateTimePickerComponent,
  ]
})
export class FibrComponentFormTypesModule {}
