import { Component } from '@angular/core';

@Component({
  selector: 'fibr-upgrade-to-pro-icon',
  standalone: true,
  imports: [],
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path d="M7.80073 0.795898C3.49265 0.795898 0 4.21735 0 8.43759C0 12.6578 3.49265 16.0793 7.80073 16.0793C12.1088 16.0793 15.6015 12.6578 15.6015 8.43759C15.6015 4.21735 12.1088 0.795898 7.80073 0.795898ZM12.5625 13.1023C11.9378 13.7177 11.1953 14.2062 10.3775 14.54C9.55975 14.8738 8.68278 15.0462 7.79687 15.0474C6.91095 15.0486 6.03349 14.8786 5.21478 14.547C4.39606 14.2155 3.65217 13.7289 3.02573 13.1152C2.39929 12.5016 1.90262 11.7728 1.56416 10.9708C1.2257 10.1688 1.05211 9.30923 1.05333 8.44137C1.05456 7.57351 1.23058 6.71442 1.5713 5.91332C1.91203 5.11221 2.41076 4.38484 3.03893 3.77287C3.66363 3.15751 4.40614 2.66894 5.22392 2.33516C6.0417 2.00139 6.91867 1.82896 7.80459 1.82776C8.6905 1.82655 9.56796 1.9966 10.3867 2.32816C11.2054 2.65972 11.9493 3.14628 12.5757 3.75994C13.2022 4.37361 13.6988 5.10233 14.0373 5.90436C14.3758 6.70639 14.5493 7.56595 14.5481 8.43381C14.5469 9.30166 14.3709 10.1608 14.0302 10.9619C13.6894 11.763 13.1907 12.4903 12.5625 13.1023Z" fill="white"/>
      <path d="M10.8259 11.4888H4.77756C4.47649 11.4888 4.23242 11.7279 4.23242 12.0228V12.0231C4.23242 12.318 4.47649 12.5571 4.77756 12.5571H10.8259C11.1269 12.5571 11.371 12.318 11.371 12.0231V12.0228C11.371 11.7279 11.1269 11.4888 10.8259 11.4888Z" fill="white"/>
      <path d="M7.25217 10.0927V6.16592L6.25331 7.14442C6.15048 7.24494 6.01114 7.3014 5.86587 7.3014C5.72059 7.3014 5.58125 7.24494 5.47842 7.14442L5.4708 7.13695C5.36818 7.03622 5.31055 6.89972 5.31055 6.75741C5.31055 6.61509 5.36818 6.47859 5.4708 6.37786L7.41276 4.4755C7.5156 4.37488 7.655 4.31836 7.80035 4.31836C7.9457 4.31836 8.08511 4.37488 8.18795 4.4755L10.1302 6.37786C10.2328 6.47859 10.2905 6.61509 10.2905 6.75741C10.2905 6.89972 10.2328 7.03622 10.1302 7.13695L10.1226 7.14442C10.0198 7.24494 9.88042 7.3014 9.73515 7.3014C9.58987 7.3014 9.45053 7.24494 9.3477 7.14442L8.34915 6.16592V10.0927C8.34915 10.2352 8.29136 10.3719 8.1885 10.4727C8.08564 10.5734 7.94613 10.63 7.80066 10.63C7.65519 10.63 7.51568 10.5734 7.41282 10.4727C7.30996 10.3719 7.25217 10.2352 7.25217 10.0927Z" fill="white"/>
    </svg>
  `,
  styles: ``
})
export class UpgradeToProIconComponent {

}
