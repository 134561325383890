import { Component, signal } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { setToast } from '../../../../shared/components/toast/toast.component';
import { AuthAPIService } from '../../api/auth-api.service';
import { safeParse } from 'valibot';
import { ForgotPasswordSchema } from '../../model';
import { handleErrorTranslationToast } from '@shared/libs/error';

@Component({
  selector: 'fibr-forgot-page',
  standalone: true,
  imports: [
    TranslateModule,
    ProgressSpinnerModule,
    CommonModule,
    FormsModule,
    RouterModule,
  ],
  templateUrl: './forgot-page.component.html',
  styleUrl: './forgot-page.component.scss',
})
export class ForgotPageComponent {
  emailField = signal('');
  isProgressSpinner = signal(false);

  logo = environment.logo;
  labelAlt = environment.app_name;
  imageForgotPassword = environment.image_forgotPassword;

  constructor(
    public translate: TranslateService,
    private authService: AuthAPIService
  ) { }

  onSubmit() {
    const validatedFields = safeParse(ForgotPasswordSchema, {
      email: this.emailField(),
    }, { abortEarly: true });
    if (!validatedFields.success) {
      this.translate.get(validatedFields.issues[0].message).subscribe((message) => {
        setToast(message, 'error');
      })
    } else {
      this.isProgressSpinner.set(true);
      this.authService.sendLinkForgotPassword(this.emailField())
        .then(result => {
          if (result.isSuccess) {
            this.translate.get('AUTH.SUCCESS_SEND_FORGOT_PASSWORD').subscribe((message) => {
              setToast(message, 'success');
            })
          } else {
            handleErrorTranslationToast(this.translate)(result.error);
          }
        })
        .catch(handleErrorTranslationToast(this.translate))
        .finally(() => {
          this.isProgressSpinner.set(false);
        });
    }
  }
}
