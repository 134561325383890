import { NgClass } from '@angular/common';
import { Component, signal, type AfterViewInit, computed } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BlockUIComponent } from '@shared/components/block-ui/block-ui.component';
import * as feather from 'feather-icons';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ChangePasswordSchema } from '../../model';
import { setToast } from '@shared/components/toast/toast.component';
import { AuthAPIService } from '../../api/auth-api.service';
import { safeParse } from 'valibot';
import { HeaderV1Component } from '../../../../core/components/header-v1/header-v1.component';


@Component({
  selector: 'fibr-change-password-page',
  standalone: true,
  imports: [BlockUIComponent, CardModule, TranslateModule, FormsModule, NgClass, ButtonModule, ProgressSpinnerModule, HeaderV1Component],
  templateUrl: './change-password-page.component.html',
  styleUrl: './change-password-page.component.scss'
})
export class ChangePasswordPageComponent implements AfterViewInit {
  isBlocked = signal(false);
  oldPasswordFieldType = signal<'text' | 'password'>('password');
  newPasswordFieldType = signal<'text' | 'password'>('password');
  newPasswordConfirmationFieldType = signal<'text' | 'password'>('password');

  oldPasswordField = signal('');
  newPasswordField = signal('');
  newPasswordConfirmationField = signal('');

  isSaveDisabled = computed(() => [
    this.oldPasswordField(),
    this.newPasswordField(),
    this.newPasswordConfirmationField()
  ].some((field) => field.length === 0) || this.isProgressSpinner());

  isProgressSpinner = signal(false);

  constructor(private translate: TranslateService, private authAPI: AuthAPIService) { }

  ngAfterViewInit() {
    feather.replace();
  }

  fields = [
    { label: 'AUTH.OLD_PASSWORD', value: this.oldPasswordField, type: this.oldPasswordFieldType },
    { label: 'AUTH.NEW_PASSWORD', value: this.newPasswordField, type: this.newPasswordFieldType },
    { label: 'AUTH.NEW_PASSWORD_CONFIRMATION', value: this.newPasswordConfirmationField, type: this.newPasswordConfirmationFieldType },
  ]

  handleFieldTextType = (type: 'text' | 'password') => {
    if (type === 'text') {
      return 'password';
    }
    return 'text';
  }

  save = () => {
    const validatedFields = safeParse(ChangePasswordSchema, {
      oldPassword: this.oldPasswordField(),
      newPassword: this.newPasswordField(),
      newPasswordConfirmation: this.newPasswordConfirmationField(),
    }, { abortEarly: true });

    if (validatedFields.success) {
      this.isProgressSpinner.set(true);
      this.authAPI.changePassword(this.oldPasswordField(), this.newPasswordField())
        .then((result) => {
          if (result.isSuccess) {
            this.translate.get('AUTH.PASSWORD_CHANGED_SUCCESSFULLY').subscribe((message) => {
              setToast(message, 'success');
              this.authAPI.signOut();
            })
          } else {
            this.translate.get(result.error).subscribe((errorMessage) => {
              this.translate.get(errorMessage).subscribe((message) => {
                setToast(message, 'error');
              })
            });
          }
        })
        .catch((error) => {
          this.translate.get(error).subscribe((errorMessage) => {
            this.translate.get(errorMessage).subscribe((message) => {
              setToast(message, 'error');
            })
          });
        }).finally(() => {
          this.isProgressSpinner.set(false);
        })
    } else {
      this.translate.get(validatedFields.issues[0].message).subscribe((message) => {
        setToast(message, 'error');
      })
    }
  }
}
