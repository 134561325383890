import { Component } from '@angular/core';

@Component({
  selector: 'fibr-video-conference-icon',
  standalone: true,
  imports: [],
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
    >
      <path
        d="M34.4381 5.80816C30.9994 2.37083 26.4182 0.315894 21.5644 0.0335109C16.7105 -0.248872 11.922 1.26096 8.10787 4.27635C4.29378 7.29173 1.71973 11.6027 0.874447 16.3908C0.0291612 21.1789 0.971493 26.1106 3.52253 30.2497L0.677854 37.5626C0.568806 37.8429 0.543693 38.1489 0.605576 38.4433C0.66746 38.7377 0.813666 39.0077 1.02636 39.2204C1.23906 39.4331 1.50906 39.5793 1.80342 39.6412C2.09779 39.703 2.4038 39.6779 2.68414 39.5689L9.99687 36.7242C14.1359 39.2761 19.068 40.2191 23.8565 39.3741C28.6451 38.5291 32.9565 35.9549 35.9721 32.1404C38.9877 28.3259 40.4975 23.5368 40.2147 18.6824C39.9319 13.8281 37.8762 9.24666 34.438 5.80816H34.4381ZM20.4105 36.5746C17.0708 36.5806 13.8065 35.5822 11.0416 33.7091C10.834 33.5685 10.5953 33.4805 10.3462 33.4525C10.097 33.4244 9.84479 33.4573 9.61111 33.5482L4.84377 35.4027L6.69825 30.6353C6.78914 30.4016 6.82199 30.1494 6.79397 29.9002C6.76595 29.651 6.6779 29.4124 6.53737 29.2048C4.66437 26.4398 3.66606 23.1756 3.67209 19.836C3.67209 10.6066 11.1811 3.09744 20.4105 3.09744C29.6398 3.09744 37.1489 10.6066 37.1489 19.836C37.1489 29.0655 29.6398 36.5746 20.4105 36.5746ZM31.1587 13.4069C30.9476 13.2652 30.7048 13.1776 30.4518 13.1518C30.1988 13.126 29.9434 13.1629 29.708 13.2592L26.0606 14.7511C26.0381 13.2354 24.8521 12.0093 23.3966 12.0093H11.641C10.1714 12.0093 8.97583 13.2588 8.97583 14.7948V24.8774C8.97583 26.4132 10.1714 27.6628 11.641 27.6628H23.3966C24.8521 27.6628 26.0381 26.4367 26.0606 24.921L29.708 26.4129C29.9434 26.5092 30.1988 26.5461 30.4518 26.5203C30.7048 26.4945 30.9476 26.4069 31.1587 26.2652C31.3698 26.1234 31.5428 25.9319 31.6624 25.7075C31.7821 25.4831 31.8446 25.2327 31.8446 24.9784V14.6937C31.8447 14.4394 31.7821 14.189 31.6625 13.9646C31.5428 13.7402 31.3698 13.5487 31.1587 13.4069ZM22.9621 24.5631H12.0759V15.1089H22.9621V24.5631ZM28.7451 22.67L26.0618 21.5724V18.0997L28.7451 17.0022V22.67Z"
        fill="#8A8A8A"
      />
    </svg>
  `,
  styles: ``,
})
export class VideoConferenceIconComponent {}
