import { DataSource, Label, LabelStyle } from '../widget-builder.model';
import { v4 as uuid } from 'uuid';

type LabelBuilderParams = {
  variant: 'title' | 'content',
  style: LabelStyle;
  tableConfig: DataSource;
};

export default function label(params: LabelBuilderParams): Label {
  return {
    id: uuid(),
    style: params.style,
    type: 'label',
    variant: params.variant,
    data_source: params.tableConfig,
  };
}
