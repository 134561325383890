import { Component, OnInit, inject, signal } from '@angular/core';
import { BaseComponent } from '../../../../core/base/base-component';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { FieldBoarding } from '../../model';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TranslateModule } from '@ngx-translate/core';
import { AuthAPIService } from '../../api/auth-api.service';
import { Router } from '@angular/router';
import { BlockUIComponent } from '@shared/components/block-ui/block-ui.component';

@Component({
  selector: 'fibr-onboarding',
  templateUrl: './onboarding.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    FormsModule,
    SelectButtonModule,
    TranslateModule,
    BlockUIComponent,
  ],
  styles: [
    `
      ::ng-deep {
        .p-selectbutton {
          .p-button {
            margin-top: 4px;
            margin-right: 8px;
            margin-bottom: 4px;
          }
        }
      }
    `,
  ],
})
export class OnBoardingComponent extends BaseComponent implements OnInit {
  private authApi = inject(AuthAPIService);
  private router = inject(Router);

  fields = signal<FieldBoarding[]>([]);
  records = signal<Record<string, string | string[]>>({});

  currentYear = new Date().getFullYear();

  constructor() {
    super('fibr-onboarding');
  }

  ngOnInit() {
    this.fields.set(
      (<unknown>this.translate('ONBOARDING.FIELDS')) as FieldBoarding[]
    );

    this.checkHasBoarding();
    this.getOptionsBoarding('team_options', 'teams');
    this.getOptionsBoarding('apps_options', 'apps');
  }

  async checkHasBoarding() {
    this.setStateProcessing();
    try {
      const profile = await this.authApi.getProfile();
      this.setStateReady();
      if (profile.is_onboard) {
        this.router.navigate(['projects'], { replaceUrl: true });
      }
    } catch (error) {
      this.setStateReady();
    }
  }

  getOptionsBoarding(path: string, column: string) {
    this.authApi.getOptionsBoarding(`${path}_${this._translate.currentLang}`)
      .then((boarding) => {
        this.fields.update((val) => {
          return val.map((field) => {
            if (field.column === column) {
              return {
                ...field,
                options: {
                  ...field.options,
                  values: boarding
                }
              }
            }

            return field
          })
        })
      })
  }

  canSubmit() {
    const columns = this.fields().map((i) => i.column);
    const haveValues = columns
      .map((i) => Object.keys(this.records()).includes(i))
      .filter((x) => x === false).length;
    return haveValues > 0;
  }

  async submit() {
    this.setStateLoading();

    try {
      await this.authApi.onboarding(this.records());
      this.setStateReady();
      this.router.navigate(['projects'], { replaceUrl: true });
    } catch (error) {
      this.setStateReady();
    }
  }
}
