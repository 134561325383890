<p-dialog [visible]="showDialog" (visibleChange)="closeTemplateDialog()" [modal]="true" [blockScroll]="true"
  [draggable]="false" [dismissableMask]="true" appendTo="body" styleClass="dialog-no-footer" [style]="{width: '90%'}">
  <ng-template pTemplate="header">
    <span class="p-dialog-title"><i class="pi pi-mobile"></i>{{"PROJECT.TEMPLATE.DIALOG_TITLE" | translate}}</span>
  </ng-template>
  <div class="grid md:p-5">
    <div class="col-12 lg:col-5 align-self-center">
      <div class="list-item p-0 mb-3">
        <div class="list-detail d-flex flex-row align-items-center">
          <img alt="Project Template Image" [src]="selectedTemplate()?.icon" class="img-template"
            onerror="this.src='/assets/images/icon/iconic_logo.png'">
          <div class="d-block">
            @if(selectedTemplate()?.updated_date?.['seconds']) {
            <p>{{(selectedTemplate()?.updated_date?.['seconds'] ?? 0 * 1000) | date : 'dd MMM yyyy'}}</p>
            }
            <h3 class="list-title m-0">{{selectedTemplate()?.display_name}}</h3>
          </div>
        </div>
      </div>
      <p class="list-desc mb-5">{{selectedTemplate()?.description}}</p>
      <p-checkbox [ngModel]="useTemplateWithDataField" (ngModelChange)="onToggleUseTemplateWithData()"
        [binary]="true" [label]="('PROJECT.TEMPLATE.USE_TEMPLATE_WITH_DATA' | translate)" />
      <p-button styleClass="p-button p-button-primary mt-5 w-100" label="{{'PROJECT.TEMPLATE.COPY_BUTTON' | translate}}"
        icon="pi pi-copy" iconPos="right" (click)="openCreateProjectWithTemplateDialog()" />
    </div>
    <div class="col-12 lg:col-1"></div>
    <div class="col-12 lg:col-6">
      <div class="card box inline-flex bg-softgrey w-100 justify-content-center">
        <div class="grid">
          <div class="col-12">
            <div class="iphone">
              <div class="content overflow-hidden">
                <iframe class="border-none w-100 h-100" title="App Preview"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
