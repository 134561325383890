import { Component, OnInit, inject, signal } from '@angular/core';
import { BaseComponent } from '../../../../../core/base/base-component';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Input as InputValibot } from 'valibot';
import { FormAccountPageSchema, UserAccount } from '../../../model';
import { setToast } from '@shared/components/toast/toast.component';
import { AuthAPIService } from '../../../api/auth-api.service';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FibrImagePickerComponent } from '@shared/components/image-picker/image-picker.component';

@Component({
  selector: 'fibr-update-profile',
  templateUrl: './update-profile.component.html',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    DividerModule,
    ButtonModule,
    ReactiveFormsModule,
    FormsModule,
    SelectButtonModule,
    InputTextareaModule,
    FibrImagePickerComponent,
  ],
})
export class UpdateProfileComponent extends BaseComponent implements OnInit {
  private authService = inject(AuthAPIService);

  constructor() {
    super('fibr-update-profile');

    this.schema = FormAccountPageSchema;
  }

  profile = signal<UserAccount | null>(null);
  formGroup = signal<InputValibot<typeof FormAccountPageSchema>>({
    name: '',
    email: '',
    photoPicture: null,
    gender: 'male',
    address: '',
    birthdate: '',
    phoneNumber: '',
    interest: '',
    type: 'personal',
  });

  ngOnInit(): void {
    this.getProfile();
  }

  async getProfile() {
    const user = await this.authService.getProfile();

    this.profile.set(user);
    this.formGroup.update((val) => ({
      ...val,
      name: user.display_name || '',
      email: user.email,
      phoneNumber: user.phone_number || '',
      photoPicture: user.photo_url || '',
      gender: user.gender || '',
      address: user.address || '',
      birthdate: user.birthdate || '',
      interest: user.interest || '',
      type: 'personal',
    }));
  }

  changePicture(event: FileList) {
    this.formGroup.update((val) => ({
      ...val,
      photoPicture: event.item(0),
    }));
  }

  async onSubmit() {
    const valid = this.validate(this.formGroup());

    if (valid.success) {
      this.setStateLoading();
      try {
        await this.authService.updateProfile(this.formGroup());
        setToast('Update Profile Success', 'success');
        this.getProfile();
        window.location.reload();
        this.setStateReady();
      } catch (erorr) {
        this.setStateReady();
        setToast('Update Profile Failed', 'error');
      }
    }
  }
}
