<div class="grid">
  <div class="col-12">
    <div class="flex p-flex-column p-flex-md-row mb-3">
      <img
        alt="icon"
        src="/assets/images/icon/icon_billing-lg.svg"
        height="20"
        class="align-self-center mr-3"
      />
      <h3 class="menu-title">
        {{ "PROJECT.TEMPLATE.TITLE" | translate }}
      </h3>
    </div>
  </div>
</div>
<div class="grid">
  @for (template of templateList(); track template.id) {
    <div
      class="col-12 md:col-4 lg:col-3"
      (click)="openTemplateDialog(template)"
      (keyup.enter)="openTemplateDialog(template)"
      tabindex="0"
    >
      <p-accordion styleClass="acc-usage">
        <p-accordionTab>
          <ng-template pTemplate="header">
            <div class="list-item p-0 align-items-start">
              <img
                alt="Project Template Image"
                [src]="getSafeUrl(template.icon)"
                class="img-template"
                onerror="this.src='/assets/images/icon/iconic_logo.png'"
              />

              <div class="list-detail">
                <h5
                  class="list-title mt-0 mb-1"
                  pTooltip="{{template.display_name}}"
                  tooltipPosition="bottom"
                >
                  {{ template.display_name }}
                </h5>
                <p
                  class="list-desc"
                  pTooltip="{{template.description}}"
                  tooltipPosition="bottom"
                >
                  {{ template.description }}
                </p>
              </div>
            </div>
          </ng-template>
        </p-accordionTab>
      </p-accordion>
    </div>
  }
</div>
