<div class="mb-4">
  <h2 class="font-light">Settings</h2>
</div>

<div>
  <div>
    <h4 class="font-medium text-[#F34819]">Delete Account</h4>
  </div>

  <div class="px-4 py-4">
    <div class="grid">
      <div class="col-12 lg:col-8">
        <h6 class="text-base">Delete Account</h6>
        <p class="font-italic">
          Once you delete your account, there is no going back. Please be
          certain.
        </p>
      </div>
      <div class="col-12 lg:col-4">
        <p-button
          label="Delete Your Account"
          icon="pi pi-times-circle"
          iconPos="right"
          severity="danger"
          (onClick)="clickDelete()"
        ></p-button>
      </div>
    </div>
  </div>
</div>
