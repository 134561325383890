export const icons = [
    "activity",
    "airplay",
    "alert-circle",
    "alert-octagon",
    "alert-triangle",
    "align-center",
    "align-justify",
    "align-left",
    "align-right",
    "anchor",
    "at-sign",
    "award",
    "bar-chart-2",
    "bar-chart",
    "battery-charging",
    "battery",
    "bell-off",
    "bell",
    "bluetooth",
    "book",
    "bookmark",
    "box",
    "briefcase",
    "calendar",
    "cast",
    "check-circle",
    "check-square",
    "check",
    "circle",
    "clipboard",
    "clock",
    "cloud-off",
    "cloud",
    "command",
    "copy",
    "cpu",
    "delete",
    "disc",
    "download-cloud",
    "download",
    "edit-2",
    "edit-3",
    "edit",
    "external-link",
    "eye-off",
    "eye",
    "feather",
    "file",
    "filter",
    "flag",
    "folder",
    "grid",
    "hash",
    "headphones",
    "heart",
    "home",
    "inbox",
    "info",
    "layers",
    "layout",
    "life-buoy",
    "link-2",
    "link",
    "list",
    "loader",
    "lock",
    "log-in",
    "log-out",
    "maximize-2",
    "maximize",
    "menu",
    "mic-off",
    "mic",
    "minimize-2",
    "minimize",
    "minus-circle",
    "minus-square",
    "minus",
    "monitor",
    "more-horizontal",
    "more-vertical",
    "move",
    "music",
    "octagon",
    "package",
    "pie-chart",
    "plus-circle",
    "plus-square",
    "plus",
    "power",
    "printer",
    "radio",
    "refresh-ccw",
    "refresh-cw",
    "rotate-ccw",
    "rotate-cw",
    "scissors",
    "search",
    "settings",
    "share-2",
    "share",
    "slash",
    "speaker",
    "square",
    "star",
    "tag",
    "target",
    "thumbs-down",
    "thumbs-up",
    "toggle-left",
    "toggle-right",
    "trash-2",
    "trash",
    "trending-down",
    "trending-up",
    "triangle",
    "type",
    "unlock",
    "upload-cloud",
    "upload",
    "user-check",
    "user-minus",
    "user-plus",
    "user-x",
    "user",
    "watch",
    "wifi",
    "x-circle",
    "x-square",
    "x",
    "zap",
    "zoom-in",
    "zoom-out",
    "fast-forward",
    "pause",
    "play",
    "repeat",
    "rewind",
    "shuffle",
    "skip-back",
    "skip-forward",
    "volume-1",
    "volume-2",
    "volume-x",
    "volume",
    "mail",
    "message-circle",
    "message-square",
    "phone-call",
    "phone-forwarded",
    "phone-incoming",
    "phone-missed",
    "phone-off",
    "phone-outgoing",
    "phone",
    "voicemail",
    "aperture",
    "camera-off",
    "camera",
    "image",
    "video-off",
    "video",
    "compass",
    "crosshair",
    "globe",
    "map-pin",
    "map",
    "navigation-2",
    "navigation",
    "cloud-drizzle",
    "cloud-lightning",
    "cloud-rain",
    "cloud-snow",
    "droplet",
    "moon",
    "sun",
    "sunrise",
    "sunset",
    "thermometer",
    "umbrella",
    "wind",
    "arrow-down-left",
    "arrow-down-right",
    "arrow-down",
    "arrow-left",
    "arrow-right",
    "arrow-up-left",
    "arrow-up-right",
    "arrow-up",
    "chevron-down",
    "chevron-left",
    "chevron-right",
    "chevron-up",
    "chevrons-down",
    "chevrons-left",
    "chevrons-right",
    "chevrons-up",
    "chrome",
    "codepen",
    "facebook",
    "instagram",
    "pocket",
    "slack",
    "twitter"
]