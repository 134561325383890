<fibr-block-ui [isBlocked]="isProcessing"></fibr-block-ui>

<div class="flex align-items-center justify-content-center h-screen">
  <div class="bg-white p-8 w-[600px] min-w-[420px] rounded-[24px] shadow-lg">
    <div>
      <img [src]="appfibr.logo" alt="logo" class="h-[48px] mb-6" />

      <h5 class="text-4xl font-medium text-[#444444]">
        {{ "ONBOARDING.TITLE" | translate }}
      </h5>
      <p class="text-xl font-medium text-[#444444]">
        {{ "ONBOARDING.DESCRIPTION" | translate }}
      </p>
    </div>

    <form (ngSubmit)="submit()">
      <div class="my-6 space-y-5">
        @for(field of fields(); track field){
        <div class="p-field">
          <label [for]="field.column" class="text-lg text-[#444444]">
            {{ field.label }}
          </label>

          @switch (field.type) { @case('text') {
          <input
            [id]="field.column"
            [name]="field.column"
            type="text"
            class="w-full mt-2"
            [placeholder]="field.placeholder"
            pInputText
            [(ngModel)]="records()[field.column]"
          />
          } @case('question') {
          <p-selectButton
            [multiple]="field.options?.multiple"
            [name]="field.column"
            [options]="field.options?.values || []"
            [(ngModel)]="records()[field.column]"
          ></p-selectButton>
          } }
        </div>
        }
      </div>

      <div class="w-full flex flex-col">
        <p-button
          styleClass="w-full h-[42px]"
          label="{{ 'ONBOARDING.BUTTON' | translate }}"
          type="submit"
          [disabled]="canSubmit()"
          [loading]="isLoading"
        ></p-button>
      </div>

      <div class="flex justify-center mt-4">
        <div class="flex flex-row items-center gap-2">
          <span class="text-lg">Copyright © {{ currentYear }} </span>
          <img
            alt="fibr"
            [src]="'/assets/images/logo_fibr_footer.svg'"
            class="h-[14px]"
          />
        </div>
      </div>
    </form>
  </div>
</div>
