import {Injectable, computed, inject, signal} from '@angular/core';
import label from '@shared/libs/generators/widgets/label';
import input from '@shared/libs/generators/widgets/input';
import {ColumnField, TableField} from '../../table/model';
import {Collection, FormType} from '../model';
import {DetailView} from '../model/page-component';
import {TableStoreService} from '../../table/store/table-store.service';
import {FibrBaseComponentTypeComponent} from '../ui/page-component/page-component-types/base-component-type.component';
import {defaultView} from '@shared/libs/generators/default_view';
import {convertTimePicker} from "@shared/libs/generators/forms/utils";

interface PageStoreType {
  table: {
    columns: TableField['columns'][];
    selected: string;
  };
  config: {
    selected: Collection | null;
    form: FormType[];
    detail: DetailView[];
  };
  dialog: {
    form: {
      visible: boolean;
      selected: FormType | null;
    };
    detail: {
      visible: boolean;
      selected: DetailView | null;
    };
  };
}

@Injectable({
  providedIn: 'root',
})
export class PageComponentStore {
  private tableStore = inject(TableStoreService);

  private _store = signal<PageStoreType>({
    table: {
      selected: '',
      columns: [],
    },
    config: {
      selected: null,
      form: [],
      detail: [],
    },
    dialog: {
      detail: {
        visible: false,
        selected: null,
      },
      form: {
        visible: false,
        selected: null,
      },
    },
  });

  tableSource = computed(() => this.tableStore.tableList().data);
  tableSelected = computed(() => this._store().table.selected);
  // prettier-ignore
  tableSelectedColumn = computed(() => this.tableSource().filter((t) => t.id === this.tableSelected())?.[0]?.columns);
  tableColumns = computed(() => {
    // prettier-ignore
    const table = this.tableSource().filter((t) => t.id === this.tableSelected());
    if (table.length === 0) return [];
    // prettier-ignore
    const columns = Object.keys(table[0].columns).map((column) => {
      const col = table[0].columns[column];
      if (col.label === 'ID') {
        col.display = false;
      }
      return col;
    });

    return columns.filter(col => col.display === true).sort((a, b) => a.index - b.index);
  });

  configSelected = computed(() => this._store().config.selected);
  configDetail = computed(() => this._store().config.detail);
  configForm = computed(() => this._store().config.form);

  dialogDetail = computed(() => this._store().dialog.detail);
  dialogForm = computed(() => this._store().dialog.form);

  changeTable(table: string) {
    this._store.update((val) => ({
      ...val,
      table: {
        ...val.table,
        selected: table,
      }
    }))
  }

  changeSelectedForm(form: FormType | null) {
    this._store.update((val) => ({
      ...val,
      dialog: {
        ...val.dialog,
        form: {
          ...val.dialog.form,
          selected: form,
        }
      }
    }))
  }

  selectTypeDetail(component?: DetailView) {
    if (component && ['column'].includes(<string>component.type)) {
      this._store.update((val) => ({
        ...val,
        dialog: {
          ...val.dialog,
          detail: {
            ...val.dialog.detail,
            visible: true,
            selected: null,
          },
        },
      }));
    } else {
      this._store.update((val) => ({
        ...val,
        dialog: {
          ...val.dialog,
          detail: {
            ...val.dialog.detail,
            visible: true,
            selected: component || null,
          },
        },
      }));
    }
  }

  addOrEditDetail(component: DetailView) {
    this._store.update((val) => {
      const selectedId = val.dialog.detail.selected?.id;
      const hasAdded =
        val.config.detail.filter((x) => x.id === selectedId).length > 0;

      let detail;

      if (!hasAdded) {
        detail = val.config.detail.concat([component]);
      } else {
        detail = val.config.detail.map((o) => {
          if (o.id === selectedId) return component;
          return o;
        });
      }

      return {
        ...val,
        config: {
          ...val.config,
          detail,
        },
        dialog: {
          ...val.dialog,
          detail: {
            ...val.dialog.detail,
            visible: false,
            selected: null,
          },
        },
      };
    });
  }

  removeDetail(component: DetailView) {
    this._store.update((val) => ({
      ...val,
      config: {
        ...val.config,
        detail: val.config.detail.filter((x) => x.id !== component.id),
      },
    }));
  }

  closeDetail() {
    this._store.update((val) => ({
      ...val,
      dialog: {
        ...val.dialog,
        detail: {
          ...val.dialog.detail,
          visible: false,
          selected: null,
        },
      },
    }));
  }

  reOrderDetail(components: DetailView[]) {
    this._store.update((val) => ({
      ...val,
      config: {
        ...val.config,
        detail: components,
      },
    }));
  }

  resetDetail() {
    this._store.update((val) => ({
      ...val,
      config: {
        ...val.config,
        detail: [],
      },
    }));
  }

  selectTypeForm(component?: FormType) {
    this._store.update((val) => ({
      ...val,
      dialog: {
        ...val.dialog,
        form: {
          ...val.dialog.form,
          visible: true,
          selected: component || null,
        },
      },
    }));
  }

  addOrEditForm(component: FormType) {
    this._store.update((val) => {
      const selectedId = val.dialog.form.selected?.id;
      const hasAdded =
        val.config.form.filter((x) => x.id === selectedId).length > 0;

      let form;

      if (!hasAdded) {
        form = val.config.form.concat([component]);
      } else {
        form = val.config.form.map((o) => {
          if (o.id === selectedId) return component;
          return o;
        });
      }

      return {
        ...val,
        config: {
          ...val.config,
          form,
        },
        dialog: {
          ...val.dialog,
          form: {
            ...val.dialog.form,
            visible: false,
            selected: null,
          },
        },
      };
    });
  }

  removeForm(component: FormType) {
    this._store.update((val) => ({
      ...val,
      config: {
        ...val.config,
        form: val.config.form.filter((x) => x.id !== component.id),
      },
    }));
  }

  closeForm() {
    this._store.update((val) => ({
      ...val,
      dialog: {
        ...val.dialog,
        form: {
          ...val.dialog.form,
          visible: false,
          selected: null,
        },
      },
    }));
  }

  reOrderForm(components: FormType[]) {
    this._store.update((val) => ({
      ...val,
      config: {
        ...val.config,
        form: components,
      },
    }));
  }

  resetForm() {
    this._store.update((val) => ({
      ...val,
      config: {
        ...val.config,
        form: [],
      },
    }));
  }

  changeSource(tableName: string) {
    // prettier-ignore
    const table = this.tableSource().filter((t) => t.id === tableName);
    // prettier-ignore
    const columns = Object.keys(table[0].columns).map((column) => table[0].columns[column]);

    this._store.update((val) => ({
      ...val,
      table: {
        ...val.table,
        selected: tableName,
      },
      config: {
        ...val.config,
        detail: this.generateComponentDetails(columns),
        form: this.generateComponentForms(columns),
      },
    }));

    return columns;
  }

  generateComponentDetails(columns: ColumnField[]) {
    return columns
      .filter((c) => c.name !== 'id')
      .map((column, index) => {
        return label({
          style: {
            font_size: index ? 16 : 14,
            font_weight: 'bold',
          },
          variant: index ? 'title' : 'content',
          tableConfig: {
            type: 'table',
            value: column.name,
          },
        });
      });
  }

  generateComponentForms(columns: ColumnField[]) {
    return columns
      .filter((x) => x.name !== 'id')
      .map((column) => {
        let formatType: 'text_entry' | 'number_entry' | 'image_picker' | 'date_picker';
        if (column.format === 'currency' || column.format === 'number'){
          formatType = 'number_entry';
        } else if (column.format === 'timestamp'){
          formatType = 'date_picker';
        } else if (column.format === 'url'){
          formatType = 'image_picker';
        } else{
          formatType = 'text_entry';
        }
        return input({
          type: formatType,
          config: {
            column: column.name,
            format: 'none',
            is_disabled: false,
            is_required: false,
            label: column.label,
            placeholder: column.label,
          },
        });
      });
  }

  select(component: FibrBaseComponentTypeComponent) {
    this._store.update((val) => ({
      ...val,
      selected: component,
    }));
  }

  init(component?: unknown | null) {
    if (!component) {
      this._store.update((val) => ({
        ...val,
        table: {
          ...val.table,
          selected: this.tableSource()?.[0]?.id,
        },
      }));

      this._store.update((val) => ({
        ...val,
        config: {
          selected: null,
          detail: defaultView(this.tableColumns()),
          form: this.generateComponentForms(this.tableColumns()),
        },
        dialog: {
          ...val.dialog,
          form: {
            selected: null,
            visible: false,
          },
          detail: {
            selected: null,
            visible: false,
          },
        },
      }));
    } else {
      // prettier-ignore
      const details = (<Collection>component).detail_view?.body.data as DetailView[];
      const forms = (<Collection>component).form_view?.body.data.map(convertTimePicker);

      this._store.update((val) => ({
        ...val,
        table: {
          ...val.table,
          selected: (<Collection>component).table_config.table_name,
        },
        config: {
          selected: <Collection>component,
          detail: details || [],
          form: forms || [],
        },
      }));
    }
  }
}
