import { AfterViewChecked, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { DividerModule } from 'primeng/divider';
import * as feather from 'feather-icons';

@Component({
  selector: 'fibr-toolbar-subheader-right',
  standalone: true,
  imports: [TranslateModule, ButtonModule, TooltipModule, OverlayPanelModule, DividerModule],
  templateUrl: './toolbar-subheader-right.component.html',
  styleUrl: './toolbar-subheader-right.component.scss'
})
export class ToolbarSubheaderRightComponent implements AfterViewChecked{

  @Output() publishApp = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() invite = new EventEmitter();
  @ViewChild('opPublishApp') opPublishApp: OverlayPanel;

  ngAfterViewChecked(): void {
    feather.replace();
  }

  onPublishApp() {
    this.publishApp.emit();
  }

  onSave() {
    this.save.emit();
  }

  onInvite() {
    this.invite.emit();
  }

  openSetting() {
    window.location.href = window.location.pathname + '/app-settings';
  }

  showOverlayPanel(event: unknown) {
    this.opPublishApp.show(event);
  }

}
