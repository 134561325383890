import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  computed,
  inject,
  signal,
} from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { Nullable } from 'primeng/ts-helpers';
import { FibrBaseComponentTypeComponent } from './page-component-types/base-component-type.component';
import { CardModule } from 'primeng/card';
import { Sidebar, SidebarModule } from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';
import { PageComponentStore } from '../../store/page-component.store';

@Component({
  selector: 'fibr-page-component',
  templateUrl: './page-component.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    AccordionModule,
    CardModule,
    SidebarModule,
    TranslateModule,
    TabViewModule,
  ],
  styles: [
    `
      .p-accordion-content {
        padding: 0 !important;
      }
    `,
  ],
})
export class FibrPageComponent implements AfterContentInit, OnChanges {
  pageComponentStore = inject(PageComponentStore);

  @Input() children: boolean;
  @Input() visible: boolean;
  @Input() component: unknown | null;
  @Input() right: number;

  @Output() apply = new EventEmitter();
  @Output() cancel = new EventEmitter();

  componentTypes = signal<FibrBaseComponentTypeComponent[]>([]);
  componentSelected = signal<FibrBaseComponentTypeComponent | null>(null);
  categorySelected = signal('all');

  selected = computed(() => {
    const component =
      this.componentSelected() as FibrBaseComponentTypeComponent;
    return component;
  });
  labelType = computed(() => this.selected()?.label || '');

  componentCategories = computed(() =>
    this.componentTypes()
      .map((o) => ({
        name: o.category,
        length: this.componentTypes().filter((i) => i.category === o.category)
          .length,
      }))
      .filter(
        (val, index, array) =>
          array.map((o) => o.name).indexOf(val.name) === index
      )
  );

  get sidebarPositionStyle() {
    if (!this.right) return '';
    return `${this.right}px`;
  }

  @ViewChild('sidebar') sidebar: Sidebar;
  // prettier-ignore
  @ContentChildren('componentType') listComponentTypes: Nullable<QueryList<FibrBaseComponentTypeComponent>>;

  constructor() {}

  initComponentTypes() {
    // prettier-ignore
    const listComponents = (<QueryList<FibrBaseComponentTypeComponent>>(this.listComponentTypes)).toArray();
    this.componentTypes.set(listComponents);

    /**
     * set default value
     */
    this.select(listComponents[0]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['component']?.currentValue) {
      if (!this.children) {
        const type = changes['component']?.currentValue.view_type;

        if (
          !this.componentTypes()
            .map((x) => x.type)
            .includes(type)
        ) {
          return this.select(this.componentTypes()[0]);
        }

        // prettier-ignore
        const selectedComponent = this.componentTypes().filter((x) => x.type === type);
        if (selectedComponent.length === 0) {
          this.componentSelected.set(null);
        } else {
          this.select(selectedComponent[0]);
        }
      }

      if (this.children) {
        const type =
          changes['component']?.currentValue.variant ||
          changes['component']?.currentValue.type;

        if (
          !this.componentTypes()
            .map((x) => x.type)
            .includes(type)
        ) {
          return this.select(this.componentTypes()[0]);
        }

        // prettier-ignore
        const selectedComponent = this.componentTypes().filter((x) => x.type === type);
        if (selectedComponent.length === 0) {
          this.componentSelected.set(null);
        } else {
          this.select(selectedComponent[0]);
        }
      }
    }
  }

  ngAfterContentInit(): void {
    this.initComponentTypes();
  }

  select(component: FibrBaseComponentTypeComponent) {
    this.componentSelected.set(component);
  }

  onApply() {
    const selected = this.selected().apply()
    this.apply.emit(selected);
  }

  onCancel() {
    this.initComponentTypes();

    if (!this.children) {
      this.pageComponentStore.closeDetail();
      this.pageComponentStore.closeForm();
      return this.cancel.emit();
    }

    this.selected().cancel();
  }
}
