<div class="grid p-3">
  <div class="col-12">
    <p-orderList styleClass="order-details" class="component" [value]="sections()" [dragdrop]="true">
      <ng-template pTemplate="empty">
        <div class="flex align-items justify-center py-4">
          <span>{{ 'COMMON.NO_DATA' | translate }}</span>
        </div>
      </ng-template>

      <ng-template pTemplate="item" let-item>
        <p-card styleClass="card-details" (click)="openSection(item)">
          <div class="grid align-items-center">
            <i class="pi pi-clone mr-2"></i>

            <div class="w-full">
              <b class="capitalize">
                {{ item.title }}
              </b>
            </div>

            <p-button icon="pi pi-trash"
                      styleClass="p-button-danger p-button-text p-button-sm p-1 bg-transparent"
                      (onClick)="deleteSection(item)"
                      (keyup.enter)="deleteSection(item)">
            </p-button>
          </div>
        </p-card>
      </ng-template>
    </p-orderList>
  </div>

  <div class="col-12">
    <p-button label="Add" icon="pi pi-plus" (onClick)="addSection()"></p-button>
  </div>
</div>

<fibr-section-page [visible]="utils().visible" [component]="utils().selected" (cancel)="cancelAddOrEditFormItem()"
                   (apply)="editOrAddFormItem($event)"></fibr-section-page>
