import { CommonModule } from '@angular/common';
import { Component, computed, effect, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { FibrBaseComponentTypeComponent } from '../../../page-component/page-component-types/base-component-type.component';
import { FormType } from '../../../../model';
import { v4 } from 'uuid';
import { FibrCollapseComponent } from '@shared/components/collapse/collapse.component';

@Component({
  selector: 'fibr-page-component-text-number',
  template: `
    <ng-template #dataTemplate let-tableColumns="columns">
      <div class="flex flex-col">
        <fibr-collapse label="editor.pages.source">
          <span class="text-lg capitalize text-[#8A8A8A] mb-2">
            {{ 'editor.pages.column' | translate }}
          </span>

          <p-dropdown
            name="form_column"
            [options]="tableColumns"
            [(ngModel)]="config().column"
            optionLabel="label"
            optionValue="name"
          ></p-dropdown>
        </fibr-collapse>

        <fibr-collapse label="editor.pages.form.field_list.label">
          <span class="text-lg capitalize text-[#8A8A8A] mb-2">
            {{ 'editor.pages.form.field_list.label' | translate }}
          </span>

          <input
            name="form_label"
            pInputText
            [(ngModel)]="config().label"
            placeholder="{{ 'editor.pages.form.field_list.label' | translate }}"
          />
        </fibr-collapse>

        <fibr-collapse label="Prefix">
          <span class="text-lg capitalize text-[#8A8A8A] mb-2">
            Prefix
          </span>

          <input
            name="form_prefix"
            pInputText
            [(ngModel)]="config().prefix"
            placeholder="Prefix"
          />
        </fibr-collapse>

        <fibr-collapse label="editor.pages.form.field_list.validation">
          <span class="text-lg capitalize text-[#8A8A8A] mb-2">
            {{ 'editor.pages.form.field_list.validation' | translate }}
          </span>

          <p-dropdown
            name="form_validation"
            placeholder="{{
              'editor.pages.form.field_list.validation.placeholder' | translate
            }}"
            [ngModel]="required()"
            (onChange)="changeValidation($event)"
            [options]="['required', 'optional']"
          >
            <ng-template let-selected pTemplate="selectedItem">
              <span>
                {{ 'editor.pages.form.' + selected | translate }}
              </span>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <span>{{ 'editor.pages.form.' + item | translate }}</span>
            </ng-template>
          </p-dropdown>
        </fibr-collapse>
      </div>
    </ng-template>
  `,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    DropdownModule,
    FormsModule,
    InputTextModule,
    FibrCollapseComponent,
  ],
})
export class FibrPageComponentTextNumberComponent extends FibrBaseComponentTypeComponent {
  override label: string = 'Number';
  override icon: string = '/assets/images/icon/form/icon_number_entry.svg';
  override type: string = 'number_entry';
  override category: string = 'form';

  config = signal<FormType['config']>({
    label: '',
    column: '',
    format: 'number',
    is_disabled: false,
    is_required: false,
    placeholder: '',
    prefix: '',
    default_value: 0,
    is_allow_decimal: false,
    is_allow_negative: false,
  });

  required = computed(() =>
    this.config().is_required === true ? 'required' : 'optional'
  );

  constructor() {
    super('fibr-page-component-text-number');

    effect(
      () => {
        const selected = this.pageComponentStore.dialogForm().selected;

        if (selected) {
          this.config.update((val) => ({
            ...val,
            ...selected.config,
          }));
        }
      },
      { allowSignalWrites: true }
    );
  }

  changeValidation(event: DropdownChangeEvent) {
    this.config.update((val) => ({
      ...val,
      is_required: event.value === 'required',
    }));
  }

  reset() {
    setTimeout(() => {
      this.config.set({
        label: '',
        column: '',
        format: 'none',
        is_disabled: false,
        is_required: false,
        placeholder: '',
        prefix: '',
        default_value: 0,
        is_allow_decimal: false,
        is_allow_negative: false,
      });

      this.pageComponentStore.closeForm();
    }, 200);
  }

  override apply() {
    const output: FormType = {
      id: this.pageComponentStore.dialogForm().selected?.id || v4(),
      type: 'number_entry',
      config: {
        label: this.config().label,
        column: this.config().column,
        format: this.config().format,
        is_disabled: this.config().is_disabled,
        is_required: this.config().is_required,
        placeholder: this.config().label,
        prefix: this.config().prefix,
        default_value: this.config().default_value,
        is_allow_decimal: this.config().is_allow_decimal,
        is_allow_negative: this.config().is_allow_negative,
      },
    };

    this.pageComponentStore.addOrEditForm(output);
    this.reset();
    return output;
  }

  override cancel(): void {
    this.reset();
  }

  protected override onDestroy(): void {
    this.reset();
  }
}
