<button
  pButton
  pRipple
  type="button"
  *ngIf="countRead === 0"
  class="p-element p-button-rounded p-button-primary mr-3 p-button-text p-button-icon-only p-button p-component ng-star-inserted"
  pTooltip="{{ 'menuNotification' | translate }}"
  tooltipPosition="bottom"
  (click)="notif.toggle($event)"
>
  <img
    alt="icon"
    src="/assets/images/icon/icon_notifications.svg"
    height="20"
    class="h-[20px]"
  />
</button>

<button
  pButton
  pRipple
  type="button"
  *ngIf="countRead > 0"
  class="p-button-rounded p-button-primary mr-3 p-button-text p-button-icon-only notification-trigger"
  pTooltip="{{ 'menuNotification' | translate }}"
  tooltipPosition="bottom"
  (click)="notif.toggle($event)"
>
  <img
    alt="icon"
    src="/assets/images/icon/icon_notifications.svg"
    height="20"
  />
  <p-badge
    [value]="countRead.toString()"
    severity="danger"
    class="notification-trigger__badge"
  ></p-badge>
</button>

<p-overlayPanel #notif styleClass="notif-overlay-panel">
  <ng-template pTemplate="content">
    <h3 class="menu-title">
      <i class="pi pi-bell text-2xl mr-3"></i
      >{{ "menuNotification" | translate }}
    </h3>
    <p-divider></p-divider>

    <div class="grid" *ngIf="notifUser.length === 0">
      <div class="col-12 text-center flex flex-col">
        <img src="/assets/images/ani_empty.gif" alt="Icon Support" />
        <h5 class="menu-subtitle mt-0 mb-8">
          {{ "notificationEmpty" | translate }}
        </h5>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
