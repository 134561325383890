<div id="default-toolbar" class="p-toolbar-group-right">
    <p-button (onClick)="openSetting()" styleClass="p-button p-button-secondary2 mr-2 seventh-element"  label="" icon="pi pi-cog" iconPos="right" pTooltip="{{'EDITOR.PROPERTIES.TITLE' | translate}}" tooltipPosition="bottom"> </p-button>
    <p-button styleClass="p-button p-button-secondary2 mr-2 seventh-element"  label="" icon="pi pi-user-plus" iconPos="right" pTooltip="{{'EDITOR.INVITE_USER' | translate}}" (click)="onInvite()" tooltipPosition="bottom"> </p-button>
    <p-button styleClass="mr-2 px-5 p-button-secondary2" label="{{'EDITOR.SAVE' | translate}}" icon="pi pi-save" iconPos="right" (click)="onSave()"> </p-button>
    <p-button styleClass="p-button mr-2 px-5 eighth-element" label="{{'EDITOR.PUBLISH' | translate}}" icon="pi pi-cloud-download" iconPos="right" (click)="onPublishApp()" > </p-button>
    
</div>

<p-overlayPanel styleClass="publishApp-overlay-panel" #opPublishApp>
    <ng-template pTemplate="content">
        <div class="flex flex-row justify-between items-center">
            <div class="flex flex-row gap-4">
                <i data-feather="share-2"></i><h3 class="menu-title text-[22px]">{{'EDITOR.PUBLISH' | translate}}</h3>
            </div>
            <div (click)="opPublishApp.hide()" (keyup.enter)="opPublishApp.hide()" tabindex="" class="cursor-pointer"><i data-feather="x"></i></div>
        </div>
        <p-divider></p-divider>
        <div class="flex flex-col text-center p-4">
            <div class="flex flex-col text-black">
                <p>{{'EDITOR.OPEN_APP_LAUNCHER' | translate}}</p>
                <p>{{'EDITOR.DOWNLOAD_NOW' | translate}}</p>
            </div>
            <div class="flex flex-row gap-5 pt-5 justify-center">
                <div class="flex flex-col gap-1">
                    <a href="https://google.com" target="_blank">
                        <img src="/assets/images/overlay-info/google-play.svg" alt="icon" height="40" />
                    <p>Google Play</p>
                    </a>
                </div>
                <div class="flex flex-col gap-1">
                    <a href="https://www.apple.com/id/app-store" target="_blank">
                        <img src="/assets/images/overlay-info/appstore.svg" alt="icon" height="40" />
                    <p>App Store</p>
                    </a>
                </div>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>