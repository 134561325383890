import { Component, EventEmitter, Input, Output, ViewChild, signal } from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormsModule } from '@angular/forms';
import { JsonPipe } from '@angular/common';
import { EditorModule } from 'primeng/editor';
import { CommonModule } from '@angular/common';
import { BlockUIComponent } from '@shared/components/block-ui/block-ui.component';
import { EditorAPIService } from '../../api/editor-api.service';
import { ActivatedRoute } from '@angular/router';
import { setToast } from '@shared/components/toast/toast.component';
import { JsonEditorComponent, JsonEditorOptions, NgJsonEditorModule } from 'ang-jsoneditor' 

@Component({
  selector: 'fibr-page-config-json',
  standalone: true,
  imports: [SidebarModule, CardModule, ButtonModule, InputTextareaModule, FormsModule, JsonPipe, EditorModule, CommonModule, BlockUIComponent, NgJsonEditorModule],
  templateUrl: './page-config-json.component.html',
  styleUrl: './page-config-json.component.scss'
})
export class PageConfigJsonComponent{

  @Input() visible: boolean;
  @Output() cancel = new EventEmitter();
  @Input() component: object ;
  @Input() page: unknown | null = null;
  isBlocked = signal(false);

  hideTextarea = signal(true);
  hideLabel = signal(false);

  projectID = this.route.snapshot.paramMap.get('id');

  public editorOptions: JsonEditorOptions;
  @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;
  editorJson: unknown ;

  constructor(private editorApiService: EditorAPIService, private route: ActivatedRoute,) { 
    this.editorOptions = new JsonEditorOptions()
    this.editorOptions.modes = ['code', 'text', 'tree', 'view'];    
  }

  onCancel() {
    this.cancel.emit();
  }

  changeLog(event = null) {
    this.editorJson = event;
  }

  onSave(){
    this.isBlocked.set(true);

    const result = this.editorApiService.updateBodyConfig(this.projectID ?? "", String(this.page), JSON.stringify(this.editorJson));

    result.then((res) => {
      if (res.isSuccess){
        this.isBlocked.set(false);
      } else {
        this.isBlocked.set(false);
        setToast(res.error, 'error', 'top-center');
      }
    });
  }

  cancelUpdate(){
    this.cancel.emit();
  }

}
