import { CommonModule } from '@angular/common';
import { Component, computed, effect, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { v4 } from 'uuid';
import { FibrBaseComponentTypeComponent } from '../../../page-component/page-component-types/base-component-type.component';
import { FormType } from '../../../../model';
import { FormsModule } from '@angular/forms';
import { FibrCollapseComponent } from '@shared/components/collapse/collapse.component';

@Component({
  selector: 'fibr-page-component-text-entry',
  templateUrl: './page-component-text-entry.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    DropdownModule,
    FormsModule,
    InputTextModule,
    FibrCollapseComponent,
  ],
})
export class FibrPageComponentTextEntryComponent extends FibrBaseComponentTypeComponent {
  override label: string = 'Text';
  override icon: string = '/assets/images/icon/form/icon_text_entry.svg';
  override type: string = 'text_entry';
  override category: string = 'form';

  config = signal<FormType['config']>({
    label: '',
    column: '',
    format: 'none',
    is_disabled: false,
    is_required: false,
    placeholder: '',
  });

  required = computed(() => this.config().is_required === true ? 'required' : 'optional');

  constructor() {
    super('fibr-page-component-text-entry');

    effect(() => {
      const selected = this.pageComponentStore.dialogForm().selected;
      if (selected) {
        this.config.set({
          label: selected.config.label,
          column: selected.config.column,
          format: selected.config.format,
          is_disabled: selected.config.is_disabled,
          is_required: selected.config.is_required,
          placeholder: selected.config.placeholder,
        });
      }
    }, { allowSignalWrites: true });
  }

  changeValidation(event: DropdownChangeEvent) {
    this.config.update((val) => ({
      ...val,
      is_required: event.value === 'required',
    }));
  }

  reset() {
    setTimeout(() => {
      this.config.set({
        label: '',
        column: '',
        format: 'none',
        is_disabled: false,
        is_required: false,
        placeholder: '',
      });
      this.pageComponentStore.closeForm();
    }, 200);
  }

  override apply() {
    const output: FormType = {
      id: this.pageComponentStore.dialogForm().selected?.id || v4(),
      type: 'text_entry',
      config: {
        label: this.config().label,
        column: this.config().column,
        format: this.config().format,
        is_disabled: this.config().is_disabled,
        is_required: this.config().is_required,
        placeholder: this.config().label,
      },
    };

    this.pageComponentStore.addOrEditForm(output);
    this.reset();

    return output;
  }

  override cancel(): void {
    this.reset();
  }

  protected override onDestroy(): void {
    this.reset();
  }
}
