import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[fibrFileDnd]',
  standalone: true,
})
export class FibrFileDndDirective {
  @Output() fileChange = new EventEmitter<FileList>();

  @HostListener('dragover', ['$event']) onDragOver(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    const currentFiles = event.dataTransfer?.files;

    if (currentFiles?.length) {
      this.fileChange.emit(currentFiles);
    }
  }
}
