import { Component, OnInit, inject, signal } from '@angular/core';
import { BaseComponent } from '../../../../core/base/base-component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonModule } from '@angular/common';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { FibrFileDndDirective } from '@shared/directive/file-dnd/file-dnd.directive';
import { instance, maxSize, mimeType } from 'valibot';
import { setToast } from '@shared/components/toast/toast.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableAPIService } from '../../api/table-api.service';
import { ProjectsShardService } from '../../../../core/projects-shard/projects-shard.service';
import { getDownloadURL, ref, uploadBytesResumable } from '@angular/fire/storage';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'fibr-upload-image-dialog',
  templateUrl: './upload-image-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    DividerModule,
    InputTextModule,
    ButtonModule,
    FibrFileDndDirective,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class UploadImageDialogComponent
  extends BaseComponent
  implements OnInit
{
  private _config = inject(DynamicDialogConfig);
  private _dialog = inject(DynamicDialogRef);
  private _tableApi = inject(TableAPIService);
  private _projectShard = inject(ProjectsShardService);

  fileUrl = signal('');

  constructor() {
    super('fibr-upload-image-dialog');

    this.schema = instance(File, [
      mimeType(['image/jpeg', 'image/png']),
      maxSize(512000),
    ]);
  }

  ngOnInit(): void {
    this.fileUrl.set(this._config.data.record[this._config.data.field.name]);
  }

  uploadFile(file: File) {
    const result = this.validate(file);

    if (result.issues) {
      result.issues.forEach((each) => {
        switch (each.context) {
          case 'max_size':
            setToast('file to large', 'error', 'top-center');
            break;
          case 'mime_type':
            setToast('file must be .jpg or .png', 'error', 'top-center');
            break;
        }
      });

      return;
    }
    this.setStateLoading();

    const storage = this._projectShard.storage;
    const fileRef = ref(storage, `/projects/${this._config.data.projectId}/tables/${this._config.data.tableId}/${this._config.data.record.id}/images/${file.name}`);
    const upload = uploadBytesResumable(fileRef, file);

    // prettier-ignore
    upload.on('state_changed', null, (error) => {
      this.setStateReady();
      throw Error(error.message);
    }, async () => {
      const fileDownloadUrl = await getDownloadURL(upload.snapshot.ref);
      await this._tableApi.editRow({
        projectID: this._config.data.projectId,
        tableID: this._config.data.tableId,
        row: {
          ...this._config.data.record,
          [this._config.data.field.name]: fileDownloadUrl,
        },
      });

      this.setStateReady();
      this._dialog.close();
    })
  }

  onDropFileChange(files: FileList) {
    if (files[0]) {
      this.uploadFile(files[0]);
    }
  }

  onInputFileChange(event: Event) {
    const target = event.currentTarget as HTMLInputElement;
    const files = <FileList>target.files;

    if (files[0]) {
      this.uploadFile(files[0]);
    }
  }

  onInputTextChange(text: string) {
    this.fileUrl.set(text);
  }

  async submit() {
    this.setStateLoading();

    try {
      await this._tableApi.editRow({
        projectID: this._config.data.projectId,
        tableID: this._config.data.tableId,
        row: {
          ...this._config.data.record,
          [this._config.data.field.name]: this.fileUrl(),
        },
      });

      this.setStateReady();
      this._dialog.close()
    } catch (error) {
      this.setStateReady();
      console.error(error);
    }
  }
}
