import { Component } from '@angular/core';

@Component({
  selector: 'fibr-maps-icon',
  standalone: true,
  imports: [],
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="38"
      viewBox="0 0 27 38"
      fill="none"
    >
      <path
        d="M13.2622 1.93945C10.1442 1.93945 7.15394 3.13562 4.9492 5.26482C2.74447 7.39401 1.50586 10.2818 1.50586 13.293C1.50586 22.1783 10.4813 32.8706 12.8332 35.4973C12.9595 35.6371 13.1379 35.7228 13.3295 35.7358C13.521 35.7488 13.7101 35.688 13.8553 35.5666L13.9271 35.4973C16.2477 32.8602 25.0176 22.1783 25.0176 13.2921C25.0173 10.2812 23.7788 7.3938 21.5742 5.26482C19.3697 3.13583 16.3798 1.93968 13.2622 1.93945ZM13.2622 16.3738C12.4736 16.3738 11.7028 16.1479 11.0471 15.7249C10.3915 15.3018 9.88045 14.7004 9.57869 13.9969C9.27692 13.2933 9.19797 12.5191 9.35181 11.7722C9.50565 11.0253 9.88537 10.3393 10.443 9.80078C11.0005 9.2623 11.711 8.89559 12.4843 8.74702C13.2577 8.59845 14.0594 8.6747 14.7879 8.96613C15.5164 9.25755 16.1391 9.75106 16.5772 10.3843C17.0153 11.0174 17.2491 11.7619 17.2491 12.5234V12.5329C17.2465 13.5525 16.8253 14.5294 16.0779 15.2494C15.3305 15.9694 14.3179 16.3738 13.2622 16.3738Z"
        stroke="#8A8A8A"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: ``,
})
export class MapsIconComponent {}
