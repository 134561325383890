/**
 * function for solve stuck ui (hanging up)
 */
export function escapeClicker() {
  const event = new KeyboardEvent('keydown', {
    key: 'Escape',
    code: 'Escape',
    keyCode: 27,
    which: 27,
    bubbles: true,
  });

  document.dispatchEvent(event);
}
