import { Component } from '@angular/core';

@Component({
  selector: 'fibr-theme-icon',
  standalone: true,
  imports: [],
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="31" viewBox="0 0 21 31" fill="none">
      <path d="M19.3667 1H1.63333C1.28389 1 1 1.28166 1 1.63043V16.7609C1 18.4989 2.42067 19.913 4.16667 19.913H5.43333C6.48075 19.913 7.33333 20.7617 7.33333 21.8043V26.8478C7.33333 28.5858 8.754 30 10.5 30C12.246 30 13.6667 28.5858 13.6667 26.8478V21.8043C13.6667 20.7617 14.5193 19.913 15.5667 19.913H16.8333C18.5793 19.913 20 18.4989 20 16.7609V1.63043C20 1.28166 19.7161 1 19.3667 1ZM2.26667 11.087H18.7333V13.6087H2.26667V11.087ZM18.7333 2.26087V9.82609H2.26667V2.26087H18.7333ZM18.7333 16.7609C18.7333 17.8035 17.8807 18.6522 16.8333 18.6522H15.5667C13.8207 18.6522 12.4 20.0663 12.4 21.8043V26.8478C12.4 27.8904 11.5474 28.7391 10.5 28.7391C9.45259 28.7391 8.6 27.8904 8.6 26.8478V21.8043C8.6 20.0663 7.17933 18.6522 5.43333 18.6522H4.16667C3.11925 18.6522 2.26667 17.8035 2.26667 16.7609V14.8696H18.7333V16.7609Z" fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
    </svg>
  `,
  styles: ``
})
export class ThemeIconComponent {

}
