import { ColumnField } from '../../../../features/table/model';
import label from '../widgets/label';
import image from '../widgets/image';
import { DetailView } from '../../../../features/editor/model/page-component';

export function defaultView(baseColumns: ColumnField[]): DetailView[] {
  const columns = baseColumns.filter((x) => x.name !== 'id');

  return [
    image({
      style: {
        fit: 'fit_width',
        corner_radius: 16,
        aspect_ratio: '1:1',
        image_style: 'square',
      },
      tableConfig: {
        type: 'table',
        value: '',
      }
    }),
    label({
      style: {
        font_size: 16,
        font_weight: 'bold',
      },
      tableConfig: {
        type: 'table',
        value: columns?.[0]?.name || '',
      },
      variant: 'title',
    }),
    label({
      style: {
        font_size: 14,
      },
      tableConfig: {
        type: 'table',
        value: columns?.[1]?.name || '',
      },
      variant: 'content',
    }),
    label({
      style: {
        font_size: 14,
      },
      tableConfig: {
        type: 'table',
        value: columns?.[2]?.name || '',
      },
      variant: 'content',
    })
  ];
}
