<fibr-page-component
  [children]="true"
  [component]="pageComponentStore.dialogForm().selected"
  [visible]="visible()"
  [right]="410 * 3"
>
  <fibr-page-component-text-entry #componentType></fibr-page-component-text-entry>
  <fibr-page-component-text-number #componentType></fibr-page-component-text-number>
  <fibr-page-component-file-picker #componentType></fibr-page-component-file-picker>
  <fibr-page-component-image-picker #componentType></fibr-page-component-image-picker>
  <fibr-page-component-date-picker #componentType></fibr-page-component-date-picker>
  <fibr-page-component-date-time-picker #componentType></fibr-page-component-date-time-picker>
  <fibr-page-component-checkbox #componentType></fibr-page-component-checkbox>
  <fibr-page-component-dropdown #componentType></fibr-page-component-dropdown>
  <fibr-page-component-radio #componentType></fibr-page-component-radio>
</fibr-page-component>
