import {CommonModule} from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  computed,
  inject,
  signal,
} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {AccordionModule} from 'primeng/accordion';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'primeng/card';
import {DividerModule} from 'primeng/divider';
import {OrderListModule} from 'primeng/orderlist';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {SidebarModule} from 'primeng/sidebar';
import {BodyItem, Page, PageConfigSchema, SectionItem} from '../../model';
import {FormsModule} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {icons} from '@shared/libs/feather-icons/feather-icon';
import {CustomPageListComponent} from './components/custom-page';
import {ConfirmationService} from "primeng/api";
import {BaseComponent} from "../../../../core/base/base-component";
import {EditorAPIService} from "../../api/editor-api.service";
import {setToast} from "@shared/components/toast/toast.component";
import {BlockUIComponent} from "@shared/components/block-ui/block-ui.component";
import {EditorStoreService} from "../../store/editor-store.service";
import {PageComponentStore} from "../../store/page-component.store";
import {FibrPageComponent} from "../page-component/page-component.component";
import {
  FibrPageComponentGridComponent
} from "../page-component/page-component-types/page-component-grid/page-component-grid.component";
import {
  FibrPageComponentListComponent
} from "../page-component/page-component-types/page-component-list/page-component-list.component";
import {PageConfigJsonComponent} from "../page-config-json/page-config-json.component";
import {
  FibrFormSettingsComponent
} from "./components/form-module/form-settings/form-settings.component";
import {FibrSectionListComponent} from "./components/form-module/section-list/section-list.component";
import {v4} from "uuid";

interface IPageComponent {
  pageProperty: Page;
}

@Component({
  selector: 'fibr-page-parent',
  templateUrl: './page-parent.component.html',
  standalone: true,
  imports: [
    CommonModule,
    OrderListModule,
    SidebarModule,
    AccordionModule,
    CardModule,
    ButtonModule,
    OverlayPanelModule,
    ScrollPanelModule,
    DividerModule,
    FormsModule,
    TranslateModule,
    BlockUIComponent,
    FibrPageComponent,
    FibrPageComponentGridComponent,
    FibrPageComponentListComponent,
    CustomPageListComponent,
    PageConfigJsonComponent,
    FibrFormSettingsComponent,
    FibrSectionListComponent,
  ],
})
export class PageParentComponent extends BaseComponent implements OnInit {
  private route: ActivatedRoute = inject(ActivatedRoute);
  private confirmation: ConfirmationService = inject(ConfirmationService);
  private editorApi: EditorAPIService = inject(EditorAPIService);
  private editorStore: EditorStoreService = inject(EditorStoreService);
  private pageComponentStore: PageComponentStore = inject(PageComponentStore);

  @Input() visible: boolean;

  @Input()
  set component(value: IPageComponent | null) {
    if (!value) {
      this.pageComponent.set(this.defaultPage);
      this.pageComponents.set([]);
      return
    }
    this.pageComponent.set(value);
    if (value.pageProperty.id) {
      this.getSelectedComponents(value.pageProperty.id);
    }
  }

  @Output() cancel = new EventEmitter();
  @Output() apply = new EventEmitter<string | null>();

  private readonly defaultPage: IPageComponent = {
    pageProperty: {
      id: '',
      name: '',
      icon: icons[0],
      type: 'basic',
      show_in_nav_bar: false,
      index: 0,
    },
  };

  readonly projectId = this.route.snapshot.paramMap.get('id') ?? '';
  // prettier-ignore
  readonly pageIcons = icons;
  readonly pageTypes = [
    {
      label: 'Custom Page',
      icon: '/assets/images/icon/accordion-icon/icon_custom_page.svg',
      value: 'basic',
      settings: [
        {
          title: 'EDITOR.ACCORDION.COMPONENT.TITLE',
          icon: '/assets/images/icon/accordion-icon/icon_component.svg',
          type: 'component-list',
        },
      ],
    },
    {
      label: 'Form',
      icon: '/assets/images/icon/accordion-icon/icon_custom_page.svg',
      value: 'form',
      settings: [
        {
          title: 'Form Settings',
          icon: '/assets/images/icon/accordion-icon/icon_settings.svg',
          type: 'form-settings',
        },
        {
          title: 'Section List',
          icon: '/assets/images/icon/accordion-icon/icon_section_list.svg',
          type: 'section-list'
        }
      ],
    }
  ];

  private pageComponent = signal(this.defaultPage);
  private pageMenuLength = computed(() => this.editorStore.pageList().length);
  icons = signal<string[]>([]);

  selectedPage = computed(() => this.pageComponent().pageProperty.id !== '');
  selectedPageId = computed(() => this.pageComponent().pageProperty.id);
  pageProperty = computed(() => this.pageComponent().pageProperty);
  selectedPageConfig = computed(() => this.editorStore.selectedPagePropertyComponent());

  selectedPageType = computed(() => this.pageComponent().pageProperty.type);
  selectedPageIcon = computed(() => this.pageComponent().pageProperty.icon);
  // prettier-ignore
  selectedIcon = computed(() => this.convertIcon(this.selectedPageIcon()));
  selectedSettingPage = computed(() =>
    this.pageTypes.find((page) => page.value === this.selectedPageType())
  );
  displayConfigPage = signal(false);

  /**
   * Custom
   */
  pageComponents = signal<BodyItem[]>([]);
  pageSettings = signal({ visible: false, selected: null });
  /**
   * Form
   */
  formSettings = signal({ description: '', showAllSection: false });
  formComponents = signal<SectionItem[]>([]);

  get isDebug(): boolean {
    return this.route.snapshot.queryParamMap.get('isDebug') !== null;
  }

  get enableFeature() {
    return false;
  }

  constructor() {
    super('page-parent');
    this.schema = PageConfigSchema;
  }

  ngOnInit() {
    this.initIcon();
  }

  private initIcon() {
    this.icons.set(this.pageIcons);
  }

  private getSelectedComponents(pageId: string) {
    this.editorApi.getPagePropertyComponent(this.projectId, pageId)
      .then((response) => {
        if (response.isSuccess) {
          const selected = this.editorStore.selectedPagePropertyComponent();
          const isForm = selected?.data?.filter((x) => x.type === 'form');
          if (isForm?.length) {
            this.formComponents.set(selected?.data?.[0]?.sections ?? []);
            this.formSettings.set({
              description: selected?.data?.[0]?.description || '',
              showAllSection: true
            })
            this.pageComponentStore.changeTable(selected?.data?.[0]?.table_config?.table_id ?? '')
            return;
          }
          this.pageComponents.set(selected?.data ?? []);
        }
      })
  }

  convertIcon(icon: string) {
    return '/feather-icons/' + icon + '.svg';
  }

  changePageType(pageType: string) {
    this.pageComponent.update((val) => ({
      ...val,
      pageProperty: {
        ...val.pageProperty,
        type: pageType,
      },
    }));
  }

  changePageName(value: string): void {
    this.pageComponent.update(((val) => ({
      ...val,
      pageProperty: {
        ...val.pageProperty,
        name: value
      }
    })))
  }

  searchPageIcon(event: KeyboardEvent) {
    if (event.keyCode !== 13) return;

    const target = event.target as HTMLInputElement;
    const value = target.value;

    // prettier-ignore
    this.icons.update(() => this.pageIcons.filter((icon) => icon.includes(value)));
  }

  changePageIcon(icon: string) {
    this.pageComponent.update((val) => ({
      ...val,
      pageProperty: {
        ...val.pageProperty,
        icon,
      },
    }));
  }

  openConfigJson() {
    this.displayConfigPage.set(true);
  }

  confirmDeletePage() {
    this.confirmation.confirm({
      header: this.translate("EDITOR.CONFIRMATION_DELETE_PAGE.HEADER"),
      message: this.translate("EDITOR.CONFIRMATION_DELETE_PAGE.CONTENT"),
      rejectButtonStyleClass: "p-button-secondary",
      dismissableMask: true,
      accept: () => this.deletePage(),
    })
  }

  deletePage() {
    this.setStateLoading()
    this.editorApi.deletePage(this.projectId, this.selectedPageId())
      .then((response) => {
        if (response.isSuccess) {
          this.setStateReady();
          this.onCancel();
          setToast(this.translate('EDITOR.SUCCESS_DELETE_PAGE'), 'success');
        } else {
          this.setStateReady();
          setToast(response.error, 'error');
        }
      })
  }

  private addOrEditPage() {
    const fields = this.validate({
      pageTitle: this.pageProperty().name,
      pageType: this.pageProperty().type,
    })

    if (fields.error) {
      setToast(this.translate("EDITOR.CHECK_FIELD_REQUIRED"), 'error');
      return;
    }

    let results;

    this.setStateLoading();

    /**
     * table_id and table_name not consistent
     */
    let params: BodyItem[] = [];

    switch(this.selectedPageType()) {
      case 'basic':
        params = this.pageComponents();
        break;
      case 'form':
        params = [
          {
            id: v4(),
            type: 'form',
            title: this.pageProperty().name,
            description: this.formSettings().description,
            sections: this.formComponents(),
            table_config: {
              table_id: this.pageComponentStore.tableSelected(),
              table_name: this.pageComponentStore.tableSelected(),
            }
          }
        ]
        break;
    }

    if (this.selectedPage()) {
      results = this.editorApi.updatePageProperty(
        this.projectId,
        this.selectedPageId(),
        this.pageProperty(),
        params,
      )
    } else {
      results = this.editorApi.createInitialPage(
        this.projectId,
        this.pageProperty(),
        params,
        this.pageMenuLength()
      )
    }

    results.then((response) => {
      if (response.isSuccess) {
        this.setStateReady()
        this.apply.emit(response.value);
        setToast(this.translate('EDITOR.SUCCESS_CREATE_PAGE'), 'success');
      } else {
        this.setStateReady()
        setToast(response.error, 'error');
      }
    })
  }

  confirmCopyPage() {
    this.confirmation.confirm({
      header: this.translate("EDITOR.CONFIRMATION_DUPLICATE_PAGE.HEADER"),
      message: this.translate("EDITOR.CONFIRMATION_DUPLICATE_PAGE.CONTENT"),
      rejectButtonStyleClass: "p-button-secondary",
      dismissableMask: true,
      accept: () => this.copyPage()
    })
  }

  copyPage() {
    this.setStateLoading();

    this.editorApi.createInitialPage(
      this.projectId,
      this.pageProperty(),
      this.pageComponents(),
      this.pageMenuLength(),
    ).then((response) => {
      if (response.isSuccess) {
        this.setStateReady();
        this.apply.emit(response.value);
        setToast(this.translate('EDITOR.SUCCESS_DUPLICATE_PAGE'), 'success');
      } else {
        this.setStateReady();
        setToast(response.error, 'error');
      }
    })
  }

  private reset() {
    this.pageComponent.set(this.defaultPage);
    this.pageComponents.set([]);
    this.pageSettings.set({ visible: false, selected: null })
    this.formSettings.set({ description: '', showAllSection: false });
    this.formComponents.set([]);
  }

  onCancel() {
    this.reset();
    this.cancel.emit();
  }

  onApply() {
    this.confirmation.confirm({
      header: this.translate("EDITOR.CONFIRMATION_CREATE_PAGE.HEADER"),
      message: this.translate("EDITOR.CONFIRMATION_CREATE_PAGE.CONTENT"),
      rejectButtonStyleClass: "p-button-secondary",
      dismissableMask: true,
      accept: () => this.addOrEditPage()
    })
  }
}
