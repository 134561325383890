// prettier-ignore
export function getPreviewImage(file: File): Promise<string | ArrayBuffer |  null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve(reader.result);
    }

    reader.onerror = () => {
      reject();
    }
  });
}
