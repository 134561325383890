<div class="grid">
  <div class="col-12">
    <div class="card box p-0">
      <p-galleria
        [value]="bannerDesktop"
        [circular]="true"
        [numVisible]="5"
        [showIndicatorsOnItem]="true"
        [showItemNavigators]="true"
        [showThumbnails]="false"
        [containerStyle]="{ 'max-width': '100%' }"
        class="project-banner hidden sm:block"
      >
        <ng-template pTemplate="item" let-item>
          <a [href]="item.itemLinkSrc" target="_blank" class="h-100">
            <img
              [src]="item.itemImageSrc"
              class="img-banner"
              alt="img-banner"
            />
          </a>
        </ng-template>
      </p-galleria>
      <p-galleria
        [value]="bannerMobile"
        [circular]="true"
        [numVisible]="5"
        [showIndicatorsOnItem]="true"
        [showItemNavigators]="true"
        [showThumbnails]="false"
        [containerStyle]="{ 'max-width': '100%' }"
        class="project-banner mobile-galleria md:hidden xl:hidden"
      >
        <ng-template pTemplate="item" let-item>
          <a [href]="item.itemLinkSrc" target="_blank" class="h-100">
            <img
              [src]="item.itemImageSrc"
              class="img-banner"
              alt="img-banner"
            />
          </a>
        </ng-template>
      </p-galleria>
    </div>
  </div>
</div>
