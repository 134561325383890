import type { TranslateService } from '@ngx-translate/core';
import { setToast } from '@shared/components/toast/toast.component';
import type { ToastPositionType } from 'primeng/toast';

export * as error_constants from './constants';

export type Success<T> = { isSuccess: true; value: T };
export type Failure<E> = { isSuccess: false; error: E };
export type Result<T, E> = Success<T> | Failure<E>;

export const success = <T>(value: T): Result<T, never> => {
  return { isSuccess: true, value };
};

export const failure = <E>(error: E): Result<never, E> => {
  return { isSuccess: false, error };
}

export const handleErrorTranslationToast = (translate: TranslateService, position: ToastPositionType = 'bottom-left') => (error: string) => {
  setToast(translate.instant(error), 'error', position);
}

export const errorToFailure = (error: unknown) => {
  if (error instanceof Error) {
    return failure(error.message);
  } else if (typeof error === "string") {
    return failure(error);
  } else {
    // TODO: what to do here?
    return failure('');
  }
}
