<p-toolbar class="sub-header editor" >
    <div class="p-toolbar-group-left">
        <p-button (onClick)="backProjectClick()" styleClass="p-button p-button-secondary2 text-blue mr-2" label="" icon="pi pi-arrow-left" iconPos="right" pTooltip="{{'EDITOR.EXIT' | translate}}" tooltipPosition="bottom"></p-button>
        <!-- <p-breadcrumb class="max-w-full" [model]="items"></p-breadcrumb> -->
        
        <div class="app-detail pl-5">
            <div class="logo bg-slate-200 rounded">
                <img [src]="projectDetail?.icon" alt="fibr-logo" class="logo-apps" onerror="this.src='/assets/images/icon/iconic_logo.png'"/>
            </div>
            <div class="flex flex-col p-4">
                <h5 class="text-[14px] text-[#7F807F] font-semibold leading-[5px] pt-2">{{projectDetail?.display_name}}</h5>
                <div class="flex flex-row gap-2">
                    @if (isReleased){
                        <span class="p-tag p-component p-tag-published">
                            <span class="p-tag-value">Published</span>
                        </span>
                    }@else{
                        <span class="p-tag p-component p-tag-secondary">
                            <span class="p-tag-value">Draft</span>
                        </span>
                    }
                    @if (projectDetail?.active_plans){
                        @if (getDaysFromToday(planDetail?.end_at!) <= 7 && getDaysFromToday(planDetail?.end_at!) >= 0){
                            <span class="p-tag p-component p-tag-expired">
                              <span class="p-tag-value">{{planDetail?.name| uppercase}} {{planDetail?.end_at! * 1000 | date:'dd/MM/yyyy'}} </span>
                            </span>
                        } 
                        
                        @if (getDaysFromToday(planDetail?.end_at!) > 7 && getDaysFromToday(planDetail?.end_at!) >= 0){
                            <span class="p-tag p-component p-tag-pro">
                              <span class="p-tag-value">{{planDetail?.name| uppercase}} {{planDetail?.end_at! * 1000 | date:'dd/MM/yyyy'}} </span>
                            </span>
                        }
                    }
                </div>
            </div>
            @if(!minimal) {
                @if (projectDetail?.active_plans){
                    @if (getDaysFromToday(planDetail?.end_at!) <= 7 && getDaysFromToday(planDetail?.end_at!) >= 0){
                        <p-button styleClass="btn-upgrade-pro ml-3 px-3 hidden lg:inline-flex" label="{{'EDITOR.EXTEND_BUTTON' | translate}}" icon="pi pi-check-circle" iconPos="right" ></p-button>
                    } @if (getDaysFromToday(planDetail?.end_at!) < 0){
                        <p-button styleClass="btn-upgrade-pro ml-3 px-3 hidden lg:inline-flex" label="{{'EDITOR.UPGRADE_BUTTON' | translate}}" icon="pi pi-check-circle" iconPos="right" (onClick)="upgradeProject()"></p-button>
                    }
                } @else {
                    <p-button styleClass="btn-upgrade-pro ml-3 px-3 hidden lg:inline-flex" label="{{'EDITOR.UPGRADE_BUTTON' | translate}}" icon="pi pi-check-circle" iconPos="right" (onClick)="upgradeProject()"></p-button>
                }
            }
        </div>
    </div>
    <div class="p-toolbar-group-right">
        @if(!minimal) {
            <fibr-toolbar-subheader-right (publishApp)="onPublishApp()" (save)="onSave()" (invite)="onInvite()"></fibr-toolbar-subheader-right>
        }
    </div>
    
</p-toolbar>

<p-confirmDialog key="subheader" [style]="{width: '500px'}" [baseZIndex]="10000"></p-confirmDialog>