import { Component, OnInit, signal } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import {
  getLocalStorage,
  setLocalStorage,
} from '../../../libs/storage/localeStorage';
import { MenuModule } from 'primeng/menu';
import { NgIf } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ListboxModule } from 'primeng/listbox';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'fibr-language',
  standalone: true,
  imports: [MenuModule, NgIf, OverlayPanelModule, ListboxModule, FormsModule],
  templateUrl: './language.component.html',
  styleUrl: './language.component.scss',
})
export class LanguageComponent implements OnInit {
  items: MenuItem[];
  selectedLanguage = signal('');

  constructor(private translate: TranslateService) {
    this.items = [
      {
        label: 'ID',
        icon: '/assets/images/icon/icon_flag.svg',
        value: 'id'
      },
      {
        label: 'EN',
        icon: '/assets/images/icon/icon_flag-us.svg',
        value: 'en'
      },
    ];
  }

  ngOnInit(): void {
    this.selectedLanguage.set(getLocalStorage('FIBR-LANGUAGE') ?? '');
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
    this.selectedLanguage.set(lang);
    setLocalStorage('FIBR-LANGUAGE', lang);
    window.location.reload();
  }
}
