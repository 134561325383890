import { Component } from '@angular/core';

@Component({
  selector: 'fibr-chat-icon',
  standalone: true,
  imports: [],
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="39"
      viewBox="0 0 41 39"
      fill="none"
    >
      <path
        d="M14.2058 21.3539L7.89791 27.6619V21.3539H4.74388C3.00198 21.3539 1.58984 19.9417 1.58984 18.1999V5.58372C1.58984 3.84183 3.00198 2.42969 4.74388 2.42969H23.6681C25.41 2.42969 26.8221 3.84183 26.8221 5.58372V18.1999C26.8221 19.9417 25.41 21.3539 23.6681 21.3539H14.2058Z"
        stroke="#8A8A8A"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.0449 27.6627C17.0449 29.4046 18.4571 30.8168 20.199 30.8168H26.5072L32.8151 37.1248V30.8168H35.6537C37.3956 30.8168 38.8077 29.4046 38.8077 27.6627V15.0466C38.8077 13.3047 37.3956 11.8926 35.6537 11.8926H32.4997"
        stroke="#8A8A8A"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="7.89733" cy="11.8915" r="1.57702" fill="#8A8A8A" />
      <circle cx="14.2059" cy="11.8915" r="1.57702" fill="#8A8A8A" />
      <circle cx="20.5126" cy="11.8915" r="1.57702" fill="#8A8A8A" />
    </svg>
  `,
  styles: ``,
})
export class ChatIconComponent {}
