import { Component, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { setToast } from '@shared/components/toast/toast.component';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { TableAPIService } from '../../api/table-api.service';
import { errorToFailure, handleErrorTranslationToast } from '@shared/libs/error';

export type AddTableDialogProps = {
  projectID: string;
};


@Component({
  selector: 'fibr-add-table-dialog',
  standalone: true,
  imports: [ButtonModule, TranslateModule, FormsModule, InputTextModule],
  templateUrl: './add-table-dialog.component.html',
  styleUrl: './add-table-dialog.component.scss'
})
export class AddTableDialogComponent {
  translate = inject(TranslateService);
  dialogRef: DynamicDialogRef = inject(DynamicDialogRef)
  dialogConfig: DynamicDialogConfig<AddTableDialogProps> = inject(DynamicDialogConfig);
  tableAPI = inject(TableAPIService);

  tableNameField = signal('');

  createTable() {
    const projectID = this.dialogConfig.data?.projectID;
    if (!projectID) return;
    if (this.tableNameField() === '') {
      setToast(this.translate.instant('TABLE.NAME_REQUIRED'), "error", "top-center");
    } else {
      this.tableAPI.createTable(projectID, this.tableNameField())
        .then((result) => {
          if (result.isSuccess) {
            setToast(this.translate.instant('TABLE.SUCCESS_CREATE_TABLE'), "success", "top-center");
            this.dialogRef.close();
          } else {
            setToast(result.error, "error", "top-center");
          }
        })
        .catch(error =>
          handleErrorTranslationToast(this.translate, 'top-center')(errorToFailure(error).isSuccess ? '' : error))
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
