<ng-template #dataTemplate let-tableColumns="columns">
  <div class="flex flex-col">
    <fibr-collapse label="editor.pages.source">
      <div class="flex flex-col space-y-3">
        <div>
          <label for="table_source" class="capitalize"> Image Source </label>

          <p-dropdown
            name="table_source"
            [options]="tableSource"
            [(ngModel)]="dataSource().type"
            (ngModelChange)="onSourceChange($event)"
          >
            <ng-template let-item pTemplate="item">
              <span>{{ item }}</span>
            </ng-template>
          </p-dropdown>
        </div>

        <div>
          <label for="table_column" class="capitalize"> Image Column </label>
          <p-dropdown
            [options]="columnImage()"
            [(ngModel)]="dataSource().value"
            name="table_column"
            optionLabel="label"
            optionValue="name"
          ></p-dropdown>
        </div>
      </div>
    </fibr-collapse>
  </div>
</ng-template>
