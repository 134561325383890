<p-menubar [model]="items">
  <ng-template pTemplate="start">
    <fibr-brand class="d-flex" [srcImg]="srcImg"></fibr-brand>
  </ng-template>
  <ng-template pTemplate="end">
    @if (isLanguage){
    <fibr-language></fibr-language>
    } @if (isNotif){
    <fibr-notifications></fibr-notifications>
    } @if(isHelp){
    <fibr-help></fibr-help>
    } @if(isGreeting){
    <fibr-welcome></fibr-welcome>
    } @if(isProfile) {
    <fibr-profile [userProfilePath]="userProfilePath" [aksesFrom]="aksesFrom" />
    }
  </ng-template>
</p-menubar>
