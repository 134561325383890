import {
  Component,
  signal,
  OnInit,
  effect,
  Output,
  EventEmitter,
} from '@angular/core';
import { ToastType } from '../../../../shared/components/toast/toast.component';
import { ProjectAPIService } from '../../api/project-api.service';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { DataViewModule } from 'primeng/dataview';
import { MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api/menuitem';
import { Project } from '../../model';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ProjectStoreService } from '../../store/project-store.service';
import { handleErrorTranslationToast } from '@shared/libs/error';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ProjectsShardService } from '../../../../core/projects-shard/projects-shard.service';
import { PopupCheckoutSubscriptionComponent } from '../../../billing/ui/popup-checkout-subscription/popup-checkout-subscription.component';

@Component({
  selector: 'fibr-project-list',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    FormsModule,
    TranslateModule,
    AccordionModule,
    DataViewModule,
    MenuModule,
    SelectButtonModule,
  ],
  templateUrl: './project-list.component.html',
  styleUrl: './project-list.component.scss',
})
export class ProjectListComponent implements OnInit {
  @Output() isBlockedEvent = new EventEmitter<boolean>();

  showProjectFormModal = signal(false);
  projects = signal<Project[]>([]);
  selectedFilter = signal<'all' | 'owner' | 'editor'>('all');
  projectNameField = signal('');
  descriptionField = signal('');
  selectedProject = signal<Project | null>(null);

  createProjectToast = signal<{
    message: string;
    type: ToastType;
  }>({
    message: '',
    type: 'error',
  });

  showProjectTemplate = false;
  statusProfile!: boolean;
  countProject: number | undefined;
  itemsProject: MenuItem[] = [
    {
      label: 'Upgrade',
      icon: 'pi pi-fw pi-check-circle',
      styleClass: 'menu-green',
      command: () => {
        this.upgradeProject()
      },
    },
    {
      separator: true,
    },
    {
      label: 'Edit',
      icon: 'pi pi-fw pi-pencil',
      styleClass: 'menu-blue',
      command: () => {
        this.onclick('edit', this.selectedProject());
      },
    },
  ];
  projectOptions: unknown[] = [
    { name: 'All', value: 'all' },
    { name: 'Owned', value: 'owner' },
    { name: 'Shared', value: 'editor' },
  ];

  constructor(
    private projectAPI: ProjectAPIService,
    private projectStore: ProjectStoreService,
    private router: Router,
    private translate: TranslateService,
    private cs: ConfirmationService,
    private dialog: DialogService,
    private projectShard: ProjectsShardService
  ) {
    effect(
      () => {
        if (this.selectedFilter() === 'all') {
          this.projects.set(this.projectStore.projectList());
        } else if (this.selectedFilter() === 'owner') {
          this.projects.set(
            this.projectStore
              .projectList()
              .filter((project) => project.role === 'owner')
          );
        } else if (this.selectedFilter() === 'editor') {
          this.projects.set(
            this.projectStore
              .projectList()
              .filter((project) => project.role === 'editor')
          );
        }
      },
      { allowSignalWrites: true }
    );
  }

  ngOnInit(): void {
    this.projectAPI
      .fetchProjectList()
      .then((res) => {
        if (!res.isSuccess) {
          handleErrorTranslationToast(this.translate)(res.error);
        }
      })
      .catch(handleErrorTranslationToast(this.translate));
  }

  handleCreateProjectFailed() {
    this.createProjectToast.set({
      message: 'Project creation failed',
      type: 'error',
    });
  }

  onClickCreateNewProject() {
    this.projectStore.setActiveDialog('create_new_project');
  }

  setProject(project: Project) {
    this.selectedProject.set(project);
  }

  onclick(action: 'update Pro' | 'edit', project: Project | null) {
    switch (action) {
      case 'update Pro':
        return;
      case 'edit':
        if (project) {
          this.showConfirmationToEditor(project);
        }
        return;
      default:
        return;
    }
  }

  showConfirmationToEditor(project: Project) {
    this.cs.confirm({
      header: this.translate.instant('PROJECT.POPUP_TITLE_EDIT'),
      dismissableMask: true,
      message: `Edit ${project.display_name}`,
      acceptLabel: this.translate.instant('PROJECT.POPUP_GO_TO_EDITOR'),
      rejectLabel: this.translate.instant('PROJECT.POPUP_CANCEL'),
      acceptIcon: 'pi pi-chevron-right',
      acceptButtonStyleClass: '',
      rejectButtonStyleClass: 'p-button-secondary',
      accept: async () => {
        this.isBlockedEvent.emit(true);
        await this.router.navigate(['/projects', project.id], {});
        this.isBlockedEvent.emit(false);
      },
      reject: () => {
        //
      },
    });
  }

  getDaysFromToday(dateString: string): number {
    const today = new Date();
    const date = new Date(dateString);

    const diffInTime = date.getTime() - today.getTime();
    const diffInDays = Math.round(diffInTime / (1000 * 60 * 60 * 24));

    return diffInDays;
  }

  upgradeProject() {
    const project = this.selectedProject();

    if (project) {
      this.isBlockedEvent.emit(true);

      this.projectShard
        .initializeFirebase(project.id)
        .then(() => {
          this.isBlockedEvent.emit(false);
          this.dialog.open(PopupCheckoutSubscriptionComponent, {
            data: {
              projectId: project.id,
              from: 'editor',
            },
          });
        })
        .catch(() => {
          this.isBlockedEvent.emit(false);
        });
    }
  }
}
