<div class="grid p-3">
  <div class="col-12">
    <p-orderList styleClass="order-details" class="component" [value]="pageComponents()" [dragdrop]="true">
      <ng-template pTemplate="empty">
        <div class="flex align-items justify-center py-4">
          <span>{{ 'COMMON.NO_DATA' | translate }}</span>
        </div>
      </ng-template>

      <ng-template pTemplate="item" let-item>
        <p-card styleClass="card-details" (click)="openComponent(item)">
          <div class="grid align-items-center">
            <img src="/assets/images/icon/editor/icon_{{ item.view_type }}.svg" alt="component icon"/>

            <b class="capitalize" *ngIf="item.view_type !== 'hidden'">
              {{ 'EDITOR.ACCORDION.COMPONENT.TYPE.' + item.view_type | translate }}
            </b>

            <b class="capitalize" *ngIf="item.view_type === 'hidden'">Auto Fill</b>

            <p-divider styleClass="p-1 mx-2" layout="vertical"></p-divider>

            <span>{{ getTableLabel(item.table_config.table_name) }}</span>

            <button type="button" (click)="deleteComponent(item)" (keyup.enter)="deleteComponent(item)" tabindex=""
                    class="p-button p-button-danger p-button-text p-button-sm p-1 bg-transparent" pButton>
              <img src="/feather-icons/trash-2.svg" alt="trash-2"/>
            </button>
          </div>
        </p-card>
      </ng-template>
    </p-orderList>
  </div>

  <div class="col-12">
    <p-button label="Add" icon="pi pi-plus" (onClick)="addComponent()"></p-button>
  </div>
</div>


<fibr-page-component #fibrPageComponent [children]="false" [visible]="utils().visible"
                     [component]="utils().selected" (apply)="editOrAddComponent($event)"
                     (cancel)="cancelAddOrEditComponent()">
  <fibr-page-component-list #componentType></fibr-page-component-list>
  <fibr-page-component-grid #componentType></fibr-page-component-grid>
</fibr-page-component>
