import { z } from "zod";
import type { User as UserFireAuth } from '@angular/fire/auth'
import { boolean, custom, email, forward, maxLength, minLength, object, string, value, nullable, union } from "valibot";
import type { ProjectID, ProjectsShardFirebaseName } from "../../project/model";
import type { Language } from "../../../core/global-config/global-config.service";

export type ProjectsShardConfig = {
  customToken: string;
  firebaseName: ProjectsShardFirebaseName;
}

export type RegisterParams = {
  email: string;
  password: string;
  fullName: string;
}

export type User = {
  full_name?: string;
} & UserFireAuth;

export type UserAccount = {
  full_name: string;
  display_name: string;
  email: string;
  phone_number: string;
  photo_url: string;
  gender: string;
  birthdate: string;
  address: string;
  interest: string;
  is_onboard: boolean;
}

// TODO Move to global config model
export type Lang = {
  name: string;
  code: Language;
}

export const RegisterSchema = object({
  fullName: string([minLength(1, 'AUTH.FULL_NAME_REQUIRED'), maxLength(50, 'AUTH.FULL_NAME_TOO_LONG')]),
  email:  string([minLength(1, 'AUTH.EMAIL_REQUIRED'), email('AUTH.INVALID_EMAIL')]),
  password: string([minLength(1, 'AUTH.PASSWORD_REQUIRED'), minLength(6, 'AUTH.PASSWORD_TOO_SHORT')]),
  passwordConfirmation: string([minLength(1, 'AUTH.PASSWORD_CONFIRMATION_REQUIRED')]),
  termAndCondition: boolean([value(true, 'AUTH.T&C_REQUIRED')]),
  captcha: string([minLength(1, 'AUTH.CAPTCHA_MISSMATCH')]),
}, [
  forward(
    custom((input) => input.password === input.passwordConfirmation, 'AUTH.PASSWORDS_DO_NOT_MATCH'),
    ['passwordConfirmation']
  )
])

export const LoginSchema = z.object({
  email: z.string().min(1, 'AUTH.EMAIL_REQUIRED').email('AUTH.INVALID_EMAIL'),
  password: z.string().min(1, 'AUTH.PASSWORD_REQUIRED'),
})

export type AuthStore = {
  user: User | null;
  userToken: string;
  projectsShardConfigByProjectID: Record<ProjectID, ProjectsShardConfig>;
  isLoggedIn: boolean;
  projectsShardToken: ProjectShardToken | null
}

export const ChangePasswordSchema = object({
  oldPassword: string([minLength(1, 'AUTH.OLD_PASSWORD_REQUIRED')]),
  newPassword: string([minLength(1, 'AUTH.NEW_PASSWORD_REQUIRED'), minLength(6, 'AUTH.PASSWORD_TOO_SHORT')]),
  newPasswordConfirmation: string([minLength(1, 'AUTH.NEW_PASSWORD_CONFIRMATION_REQUIRED')]),
}, [
  forward(
    custom((input) => input.newPassword === input.newPasswordConfirmation, 'AUTH.PASSWORDS_DO_NOT_MATCH'),
    ['newPasswordConfirmation']
  )
])

export const ForgotPasswordSchema = object({
  email: string([minLength(1, 'AUTH.EMAIL_REQUIRED'), email('AUTH.INVALID_EMAIL')]),
})

export const FormAccountPageSchema = object({
  name: string(),
  email: string(),
  photoPicture: nullable(union([object({}), string()])),
  phoneNumber: string(),
  gender: string(),
  birthdate: string(),
  address: string(),
  interest: string(),
  type: string(),
});

export type ProjectShardToken = {
  authTime: Date;
  expirationTime: Date;
  token: string;
}

export interface FieldBoardingOptions {
  multiple?: boolean;
  values?: {
    label: string;
    value: string;
  }[];
}

export interface FieldBoarding {
  label: string;
  column: string;
  placeholder?: string;
  type: 'text' | 'question';
  defaultValue: string | string[];
  options?: FieldBoardingOptions;
}