import { Component, OnInit, inject, signal } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { ProjectStoreService } from '../../store/project-store.service';
import type { GsheetFile, Template } from '../../model';
import { DatePipe } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { CreateNewBlankProjectDialogComponent } from '../create-new-blank-project-dialog/create-new-blank-project-dialog.component';
import { ProjectAPIService } from '../../api/project-api.service';
import { environment } from '../../../../../environments/environment';
import { setToast } from '@shared/components/toast/toast.component';
import { Router } from '@angular/router';
import { handleErrorTranslationToast } from '@shared/libs/error';
import { BlockUIComponent } from '@shared/components/block-ui/block-ui.component';

@Component({
  selector: 'fibr-create-new-project-dialog',
  standalone: true,
  imports: [DialogModule, TranslateModule, ButtonModule, DividerModule, DatePipe, TooltipModule, DynamicDialogModule, BlockUIComponent],
  templateUrl: './create-new-project-dialog.component.html',
  styleUrl: './create-new-project-dialog.component.scss'
})

export class CreateNewProjectDialogComponent implements OnInit{
  projectStore = inject(ProjectStoreService)
  dialog = inject(DialogService)
  translate = inject(TranslateService)

  SCOPES = 'https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/script.external_request https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/script.scriptapp https://www.googleapis.com/auth/script.deployments';
  DISCOVERY_DOC = [
    'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
    'https://script.googleapis.com/$discovery/rest?version=v1'
  ];
  tokenClient: google.accounts.oauth2.TokenClient;

  isSubmitting = signal(false);

  gSheetFiles = signal<GsheetFile[]>([])

  get templateList() {
    return this.projectStore.templateList()
  }

  get showDialog() {
    return this.projectStore.activeDialog() === 'create_new_project';
  }

  constructor(
    private projectAPI: ProjectAPIService,
    private router: Router
  ) { }

  ngOnInit(): void {
    gapi.load('client', async () => {
      await gapi.client.init({
        apiKey: environment.googleApiKey,
        discoveryDocs: this.DISCOVERY_DOC,
      });
    });

    // eslint-disable-next-line functional/immutable-data
    this.tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: environment.googleClientID,
      scope: this.SCOPES,
      callback:  async (resp) => {
        this.projectStore.setGoogleSheetAccessToken(gapi.client.getToken().access_token);
        if (resp.error !== undefined) {
          throw (resp);
        }
        await this.getAllSheets();
  
        this.projectStore.setActiveDialog('google_sheet_lists');
      }, // defined later
    });
  }

  onClickCreateNewProject() {
    const dialogConfig = {
      header: this.translate.instant('PROJECT.CREATE_NEW'),
      width: "500px",
      contentStyle: { "max-height": "500px", overflow: "auto" },
      styleClass: "dialog-no-footer",
      dismissableMask: true,
      baseZIndex: 10000,
      showHeader: true,
      closable: true,
    };
    this.projectStore.setActiveDialog(null);
    this.dialog.open(CreateNewBlankProjectDialogComponent, dialogConfig)
  }

  onClickTemplate(template: Template) {
    this.projectStore.setSelectedTemplate(template)
    this.projectStore.setActiveDialog('template')
  }

  onCloseDialog() {
    this.projectStore.setActiveDialog(null)
  }

  getTemplateUpdatedAt(template: Template) {
    return template.updated_date?.seconds ?? 0 * 1000
  }

  async createAppWithSampleData(){
    this.isSubmitting.set(true);

    let projectName = 'New App';
    let counter = 1;

    const existingProjectNames = this.projectStore.projectList().map(project => project.display_name);

    while(existingProjectNames.includes(projectName)){
      projectName = `New App (${counter})`;
      counter++;
    }
    this.projectAPI.createProjectUsingTemplate(
      projectName,
      environment.template_sample_data,
      true
    )
    .then((result) => {
      if (result.isSuccess) {
        setToast(this.translate.instant('PROJECT.SUCCESS_CREATE_PROJECT'), 'success', 'top-center');
        this.router.navigate(['/projects', result.value]);
      } else {
        handleErrorTranslationToast(this.translate, 'top-center')(result.error);
      }
    })
    .catch(handleErrorTranslationToast(this.translate, 'top-center'))
    .finally(() => this.isSubmitting.set(false));
    this.onCloseDialog();
  }

  gSheet(){
    if (gapi.client.getToken() === null) {
      this.tokenClient.requestAccessToken({prompt: 'consent'});
    } else {
      this.tokenClient.requestAccessToken({prompt: ''});
    }
  }

  async getAllSheets() {
    let response;

    try {
      response = await gapi.client.drive.files.list({
        q: "mimeType='application/vnd.google-apps.spreadsheet'",
        'pageSize': 100,
        'fields': 'files(id, name, owners, modifiedTime)',
      });
    } catch (err) {
      console.log(err)
      return;
    }
    if (response.result.files) {
      this.projectStore.setGSheetFiles(response.result.files);
    }
    if (!this.gSheetFiles || this.gSheetFiles.length == 0) {
      return;
    }
  }
}
