<a
  type="button"
  href="javascript:void(0)"
  (click)="helpMenu.toggle($event)"
  pButton
  pRipple
  type="button"
  class="p-element p-button-rounded p-button-primary mr-3 p-button-text p-button-icon-only p-button p-component"
  pTooltip="{{ 'menuHelp' | translate }}"
  tooltipPosition="bottom"
  ><img alt="icon" src="/assets/images/icon/icon_help.svg" height="20"
/></a>
<p-menu
  #helpMenu
  [popup]="true"
  [model]="helpMenuItems"
  appendTo="body"
></p-menu>

<p-galleria
  [value]="images()"
  [(visible)]="watchVideo"
  [responsiveOptions]="responsiveOptions"
  [containerStyle]="{ 'max-width': '75%' }"
  [numVisible]="3"
  [circular]="true"
  [fullScreen]="true"
  [showItemNavigators]="true"
  appendTo="body"
>
  <ng-template pTemplate="item" let-item>
    <iframe
      height="500"
      class="w-100"
      [src]="item.itemImageSrc"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
  </ng-template>
  <ng-template pTemplate="thumbnail" let-item>
    <div class="grid grid-nogutter justify-content-center mx-3">
      <img alt="" [src]="item.thumbnailImageSrc" class="block h-100 w-100" />
    </div>
  </ng-template>
</p-galleria>
