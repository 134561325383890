import { CommonModule } from '@angular/common';
import { Component, Input, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'fibr-collapse',
  template: `
    <div class="w-full px-4 py-2 border-b border-[#CDCDCD]">
      <button
        class="w-full flex items-center justify-between focus:outline-none pt-3"
        (click)="toggle()"
      >
        <h6 class="text-[#444444] font-semibold text-lg mb-0">
          {{ label | translate }}
        </h6>

        <i
          class="pi text-[#444444]"
          [ngClass]="{
            'pi-chevron-up': visible(),
            'pi-chevron-down': !visible()
          }"
        ></i>
      </button>

      <div
        class="px-1 pb-3"
        [ngClass]="{ visible: visible(), invisible: !visible() }"
        [ngStyle]="{ 'max-height': visible() ? 'none' : 0 }"
      >
        <div class="pt-3">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  `,
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class FibrCollapseComponent {
  @Input() label: string;

  public visible = signal<boolean>(true);

  toggle() {
    this.visible.update((val) => !val);
  }
}
