import { NgClass } from '@angular/common';
import { Component, signal } from '@angular/core';
import { AddIconComponent } from '../../../shared/components/icons/add/add-icon.component';
import { PageListIconComponent } from '../../../shared/components/icons/page-list/page-list-icon.component';
import { ThemeIconComponent } from '../../../shared/components/icons/theme/theme-icon.component';
import { AnalyticIconComponent } from '../../../shared/components/icons/analytic/analytic-icon.component';
import { CMSIconComponent } from '../../../shared/components/icons/cms/cms-icon.component';
import { SettingsIconComponent } from '../../../shared/components/icons/settings/settings-icon.component';
import { FeaturesComponent } from '../../../features/editor/ui/page-type/features.component';
import { AuthAPIService } from '../../../features/auth/api/auth-api.service';

const mainActionList = [
  'add',
  'page-list',
  'theme',
  'analytics',
  'cms',
  'settings',
] as const;
type MainActionList = (typeof mainActionList)[number];

type InternalState = {
  selectedMainAction: MainActionList;
};

@Component({
  selector: 'fibr-sidebar',
  standalone: true,
  imports: [
    NgClass,
    AddIconComponent,
    PageListIconComponent,
    ThemeIconComponent,
    AnalyticIconComponent,
    CMSIconComponent,
    SettingsIconComponent,
    FeaturesComponent,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
  DUMMY_INTERNAL_STATE: InternalState = {
    selectedMainAction: 'add',
  };

  constructor(private authService: AuthAPIService) {}

  mainActionList = mainActionList;

  featureVisible = false;
  handleVisibleFeature() {
    this.featureVisible = !this.featureVisible;
  }

  showPageList = signal(false);

  onClickShowPageList = () => {
    this.showPageList.update((v) => !v);
  };

  getMainActionClass(action: MainActionList) {
    return this.DUMMY_INTERNAL_STATE.selectedMainAction === action
      ? 'text-gray-50'
      : 'text-[#8A8A8A]';
  }

  signOut() {
    this.authService.signOut();
  }
}
