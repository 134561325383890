import { Injectable, inject } from '@angular/core';
import { ProjectsShardService } from '../../../core/projects-shard/projects-shard.service';
import { doc } from '@angular/fire/firestore';
import { updateDoc } from '@firebase/firestore';
import { UpdateAppSetting, UpdateTheme } from '../model/app-settings';
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from '@angular/fire/storage';
import { getFileExtension } from '@shared/libs/get-file-extension';
import { lastValueFrom } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AuthStoreService } from '../../auth/store/auth-store.service';

@Injectable()
export class AppSettingService {
  private projectShard = inject(ProjectsShardService);
  private http = inject(HttpClient);
  private authStore = inject(AuthStoreService);

  async updateAppIdentity(params: UpdateAppSetting) {
    const storage = this.projectShard.storage;
    // prettier-ignore
    const appConfigRef = doc(this.projectShard.fireStore, `/projects/${params.projectId}`);

    if (params.app_icon instanceof File) {
      const fileExtension = getFileExtension(params.app_icon.name);
      // prettier-ignore
      const iconRef = ref(storage, `/projects/${params.projectId}/app_info/icon/app_icon.${fileExtension}`);

      const upload = uploadBytesResumable(iconRef, params.app_icon);

      upload.on(
        'state_changed',
        null,
        (error) => {
          throw Error(error.message);
        },
        async () => {
          const iconUrl = await getDownloadURL(upload.snapshot.ref);
          updateDoc(appConfigRef, {
            icon: iconUrl,
          });
        }
      );
    }

    await updateDoc(appConfigRef, {
      display_name: params.app_name,
      description: params.app_description,
    });
  }

  deleteProject(projectId: string) {
    // prettier-ignore
    const headers = new HttpHeaders().set(
      'Authorization',
      this.authStore.projectsShardToken()?.token ?? ''
    );

    const body = {
      project_id: projectId,
    };

    // prettier-ignore
    return lastValueFrom(this.http.post(`${environment.apiProjectUrl}/deleteProject`, body, { headers }));
  }

  async updateTheme(params: UpdateTheme) {
    const themeConfigRef = doc(
      this.projectShard.fireStore,
      `/projects/${params.projectId}/global_config/app`
    );

    await updateDoc(themeConfigRef, {
      theme: {
        font_color: params.font_color,
        primary_color: params.primary_color,
        secondary_color: params.secondary_color,
      },
    });
  }
}
