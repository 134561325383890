<main class="flex flex-col w-[23.75vw] bg-[#F7F8F8] text-[#444]">
  <div
    class="flex flex-row h-[4.25rem] bg-grey-50 justify-between items-center border border-tertiary px-4"
  >
    <span class="ml-[1.125rem] font-bold body-2 items-start"> ADD PAGE </span>
    <i data-feather="x" class="feather-24"></i>
  </div>
  <div class="flex w-[313px] p-2 flex-col gap-5">
    <input
      class="mt-[.375rem] appearance-none border rounded-[10px] w-full h-11 px-5 overline-1 text-[#3A3A3A] flex items-center focus:outline-none focus:shadow-outline"
      placeholder="Search"
    />
  </div>
  <div class="grid grid-cols-3 gap-5 p-5 items-center justify-center">
    <div class="flex flex-col justify-center items-center cursor-pointer">
      <fibr-custom-page-icon
        (click)="navigateToEditor()"
      ></fibr-custom-page-icon>
      <p class="overline-1 pt-2 text-center">Custom Page</p>
    </div>
    <div class="flex flex-col justify-center items-center">
      <fibr-chat-icon></fibr-chat-icon>
      <p class="overline-1 pt-2 text-center">Chat</p>
    </div>
    <div class="flex flex-col justify-center items-center">
      <fibr-cart-icon></fibr-cart-icon>
      <p class="overline-1 pt-2 text-center">Cart</p>
    </div>
    <div class="flex flex-col justify-center items-center">
      <fibr-video-conference-icon></fibr-video-conference-icon>
      <p class="overline-1 pt-2 text-center">Video Conference</p>
    </div>
    <div class="flex flex-col justify-center items-center">
      <fibr-booking-icon></fibr-booking-icon>
      <p class="overline-1 pt-2 text-center">Booking</p>
    </div>
    <div class="flex flex-col justify-center items-center">
      <fibr-maps-icon></fibr-maps-icon>
      <p class="overline-1 pt-2 text-center">Maps</p>
    </div>
  </div>
</main>
