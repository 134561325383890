import { Component, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { AddColumnSchema, type TableColumnFieldType } from '../../model';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { safeParse } from 'valibot';
import { setToast } from '@shared/components/toast/toast.component';

type Option = {
  value: TableColumnFieldType;
  name: string;
}

@Component({
  selector: 'fibr-add-column-dialog',
  standalone: true,
  imports: [TranslateModule, FormsModule, DropdownModule, ButtonModule, InputTextModule],
  templateUrl: './add-column-dialog.component.html',
  styleUrl: './add-column-dialog.component.scss'
})
export class AddColumnDialogComponent {
  columnNameField = signal('');
  columnTypeField = signal<TableColumnFieldType>('text');

  translate = inject(TranslateService)
  dialogRef = inject(DynamicDialogRef)

  readonly options: Option[] = [
    { value: 'text', name: this.translate.instant('editor.grid.column.type.text') },
    { value: 'number', name: this.translate.instant('editor.grid.column.type.number') },
    { value: 'date', name: this.translate.instant('editor.grid.column.type.date') },
    { value: 'datetime', name: this.translate.instant('editor.grid.column.type.datetime') },
    { value: 'image', name: this.translate.instant('editor.grid.column.type.image') },
    { value: 'currency', name: this.translate.instant('editor.grid.column.type.currency')},
    { value: 'link', name: this.translate.instant('editor.grid.column.type.link')}
  ]


  onClickCancel() {
    this.dialogRef.close(null);
  }

  // TODO
  // 1. handle calculation type
  // 2. handle validation field
  onClickCreate() {
    const validatedFields = safeParse(AddColumnSchema, {
      columnName: this.columnNameField(),
    }, { abortEarly: true });
    if (!validatedFields.success) {
      setToast(this.translate.instant(validatedFields.issues[0].message), 'error', 'top-center');
      return;
    }
    this.dialogRef.close({
      columnName: this.columnNameField(),
      columnType: this.columnTypeField()
    })
  }

  onChangeType(value: TableColumnFieldType) {
    this.columnTypeField.set(value)
  }
}
