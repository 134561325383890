<div>
  @if (values().length === 0) {
    @if (!emptyTemplate()) {
      <div class="w-full flex items-center justify-center py-2">
        <span class="text-base">No Items</span>
      </div>
    } 

    <ng-container *ngTemplateOutlet="emptyTemplate()"></ng-container>
  }
  <div class="order-list space-y-1" cdkDropList (cdkDropListDropped)="onDrop($event)" role="listbox">
    <ng-template ngFor let-item [ngForOf]="values()" let-i="index">
      <div class="order-list__item" role="option" [attr.aria-selected]="" cdkDrag [cdkDragData]="item">
        <ng-container *ngTemplateOutlet="itemTemplate();context: { $implicit: item, index: i }"></ng-container>
      </div>
    </ng-template>
  </div>
</div>
