/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * any exception for this file.
 */

import {
  AfterViewInit, Component, ElementRef, EventEmitter,
  Input, Output, ViewChild, type OnChanges, type SimpleChanges, type OnDestroy,
} from '@angular/core';
import type { Timestamp, FieldValue } from '@angular/fire/firestore';

type ListGridInstance = {
  startEditingNew: (row?: Row) => void;
  Super: (method: string, args: unknown[]) => void;
  getEditValues: (row: number) => unknown;
  setEditValue: (row: number, col: number, value: unknown) => void;
  getEditRow: () => number;
  removeRecordClick: (ruwNum: number) => void;
  getRecord: (ruwNum: number) => Row;
  getRecordIndex: (row?: Row) => number;
  getFields: () => Field[];
  editorExit: (editCompletionEvent: unknown, record: Record<string, unknown>, newValue: unknown, rowNum: number, colNum: number) => boolean;
  validateCell: (rowNum: number, colNum: number) => boolean;
  startEditingCell: (rowNum: number, colNum: number) => void;
  startEditing: (rowNum: number) => boolean;
  endEditing: () => void;
  getSelection: () => Row[] | null;
  removeData: () => Row[];
  data: any;
  setData: (data: Record<string, unknown>[]) => void;
  setFields: (data: unknown[]) => void;
  destroy: () => void;
  getField: (name: string) => ({ getValue: () => void });
  setDataSource: (dataSource: DataSource) => void;
  invalidateCache: () => void;
  refreshData: () => void;
  fetchData: () => void;
  markForRedraw: () => void;
  cellChanged: (record: Row, newValue: unknown, oldValue: unknown, rowNum: unknown, colNum: unknown, grid: unknown) => void;
  headerClick: (fieldNum: number) => void;
  recordClick?: (
    viewer: unknown,
    record: Row,
    recordNum: number,
    field: Field,
    fieldNum: number,
    value: unknown,
    rawValue: unknown,
    editedRecord: unknown
  ) => void;
};

type DataSource = {
  destroy: () => void;
  updateCaches?: (resp: any, req: any) => void;
  updateData: (data: unknown[]) => void;
  setFields: (data: Field[]) => void;
  getPrimaryKeyFields: () => Field[];
  setCacheData: (data: unknown[]) => void;
  Super: any;
}


declare global {
  interface Window {
    isc: {
      FileLoader: { load: (callback: () => void, options: { target: HTMLElement }) => void }
      DataSource: { create: (options: { clientOnly: boolean, fields: unknown[], cacheData: unknown[] }) => unknown }
      ListGrid: {
        create: (options: {
          ID: string,
          dataSource: unknown,
          autoFetchData: boolean,
          canEdit: boolean,
          showEmptyMessage: boolean,
          canRemoveRecords: boolean,
          zIndex: number,
          autoDraw: boolean,
          showHeaderContextMenu: boolean,
          htmlElement: HTMLElement,
          emptyMessage: string,
          alternateRecordStyles?: boolean,
          matchElement: boolean,
          rowEditorExit?: (editCompletionEvent: unknown, record: unknown, newValues: Record<string, unknown>) => void,
          selectionAppearance?: string,
          autoFitData: unknown,
          width?: string,
          height?: string,
          wrapCells?: boolean,
          fixedRecordHeights?: boolean,
          baseStyle?: string,
          headerBaseStyle?: string
        }) => ListGridInstance
      }
      Button: { create: (options: { title: string, autoFit: boolean, click: () => void }) => unknown }
      VLayout: {
        create: (options: {
          autoDraw: boolean,
          htmlElement: HTMLElement,
          matchElement: boolean,
          members: unknown[],
          membersMargin: number
        }) => unknown
      };
      Canvas: { getById: (id: string) => ListGridInstance };
      warn: (message: string) => void;
    }
    listgrid: ListGridInstance | null;
    dataSource: DataSource;
  }
}

export const fieldTypes = ['text', 'float', 'currency', 'image', 'date', 'datetime'] as const;

export type FieldType = typeof fieldTypes[number];

export type ValidatorType = 'regexp' | 'isInteger' | 'isFloat' | 'custom';

export type FieldName = string;

export type RowValue = boolean | string | number | Timestamp | Date | FieldValue;

export type Row = Record<FieldName, RowValue>;

type BaseValidator = { type: ValidatorType; errorMessage: string }

type Validator = BaseValidator | (Omit<BaseValidator, 'type'> & { type: 'custom', expression: string })

export type Field = {
  name: string;
  type: FieldType;
  primaryKey?: boolean;
  canEdit?: boolean;
  emptyMessage?: string;
  showEmptyMessage?: boolean;
  hidden?: boolean;
  required?: boolean;
  valueMap?: string[];
  validators?: Validator[];
}

@Component({
  selector: 'fibr-table',
  standalone: true,
  imports: [],
  template: `
    <div>
      <div #table style="width:auto;height:450px!important;" [style.height]="height"></div>
    </div>
  `,
  styleUrl: './table.component.scss'
})
export class TableComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() height: string;
  @Input() fields: Field[];
  @Input() initialData: Row[];
  @Input() tableID: string;
  @Output() editRowEvent = new EventEmitter<Row>();
  @Output() dataSelected = new EventEmitter<Row[]>();
  @Output() deleteRowEvent = new EventEmitter<Row>();
  @Output() clickCellEvent = new EventEmitter<{ record: Row; field: Field }>();
  @Output() clickColumnHeaderEvent = new EventEmitter<FieldName>();

  private mutableGrid: ListGridInstance | null = null;
  private mutableDataSource: DataSource

  @ViewChild('table', {static: true}) layoutElementRef: ElementRef;

  ngAfterViewInit() {
    const ele = this.layoutElementRef.nativeElement;
    this.drawCanvasOnElement(this.buildLayout, ele);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['fields']) {
      if (this.mutableDataSource) {
        this.mutableDataSource?.destroy();
        this.mutableDataSource = await this.createDataSource(changes['fields'].currentValue, []);
        this.mutableDataSource.updateCaches = (response, request) => {
          this.mutableDataSource.Super("updateCaches", response, request);
          this.editRowEvent.emit(request.data);
        }
        this.mutableGrid?.setDataSource(this.mutableDataSource);
        this.mutableGrid?.fetchData();
        this.mutableGrid?.refreshData();
      }
    }

    if (changes['initialData']) {
      this.mutableDataSource?.setCacheData(changes['initialData'].currentValue);
      this.mutableGrid?.refreshData();
    }
  }

  ngOnDestroy(): void {
    this.mutableDataSource?.destroy();
    this.mutableGrid?.destroy();
    this.mutableGrid = null;
  }

  public addNewRow(row?: Row) {
    this.mutableGrid?.startEditing(this.mutableGrid?.data.findIndex(row));
  }

  private drawCanvasOnElement(builder: (el: HTMLElement) => void, element: HTMLElement) {
    const isc = window.isc;
    isc.FileLoader.load(() => {
      builder(element);
    }, {target: element});
  }

  private buildLayout = async (element: HTMLElement) => {
    const isc = window.isc as Window['isc'];

    this.mutableDataSource = await this.createDataSource(this.fields, this.initialData)

    this.mutableDataSource.updateCaches = (response, request) => {
      this.mutableDataSource.Super("updateCaches", response, request);
      this.editRowEvent.emit(request.data);
    }

    if (!isc.ListGrid) {
      window.location.reload()
    }

    this.mutableGrid = isc.ListGrid.create({
      autoFitData: true,
      ID: 'list-grid',
      dataSource: this.mutableDataSource,
      autoFetchData: true,
      canEdit: true,
      showEmptyMessage: true,
      emptyMessage: "Data Not Found.",
      canRemoveRecords: true,
      zIndex: 0,
      autoDraw: true,
      showHeaderContextMenu: true,
      htmlElement: element,
      matchElement: true,
      wrapCells: true,
      fixedRecordHeights: false,
      selectionAppearance: "checkbox"
    }) as ListGridInstance;

    this.mutableGrid.removeRecordClick = (rowNum) => {
      const record = this.mutableGrid?.getRecord(rowNum);
      this.deleteRowEvent.emit(record);
    };

    this.mutableGrid.headerClick = (colNum) => {
      const fieldName = this.mutableGrid?.getFields()[colNum].name;
      this.clickColumnHeaderEvent.emit(fieldName);
    };

    this.mutableGrid.recordClick = (viewer, record, recordNum, field) => {
      this.clickCellEvent.emit({record, field});
      const selected = this.mutableGrid?.getSelection();
      if (selected && selected.length > 0) {
        this.dataSelected.emit(selected);
      }
    };
  };

  private createDataSource(fields: Field[], initialData: unknown[], attempts: number = 3): Promise<DataSource> {
    return new Promise((resolve, reject) => {
      if (attempts === 0) {
        reject(new Error('Failed to create DataSource after multiple attempts'));
        return;
      }

      const isc = window.isc;

      if (typeof isc.DataSource !== 'undefined') {
        const redefinedFields = fields.map((field) => {
          if (field.type === 'image') {
            return {
              ...field,
              formatCellValue: function (value: string) {
                if (!value) return `<span class="px-2 py-2 bg-primary rounded cursor-pointer">Upload</span>`;
                return `<img class="cursor-pointer object-cover" alt="preview-small" src="${value}" style="width: 32px;height:32px;" />`
              },
              hoverHTML: (record: Row) => {
                const value = record[field.name];
                if (!value) return '';
                return `<img alt="preview-large" class="object-contain" src="${record[field.name]}" style="width: 250px;height: 250px;" />`
              }
            }
          }

          return field;
        });
        const dataSource = isc.DataSource.create({
          clientOnly: true,
          fields: redefinedFields,
          cacheData: initialData
        }) as DataSource;
        resolve(dataSource);
      } else {
        setTimeout(() => {
          // Try again after 2 seconds
          this.createDataSource(fields, initialData, attempts - 1)
            .then(resolve)
            .catch(reject);
        }, 5000);
      }
    });
  }
}
