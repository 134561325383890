import { CommonModule } from '@angular/common';
import { Component, OnInit, computed, inject, signal } from '@angular/core';
import { HeaderV1Component } from '../../../../core/components/header-v1/header-v1.component';
import { FooterComponent } from '../../../../core/components/footer/footer.component';
import { takeUntil } from 'rxjs';
import { BaseComponent } from '../../../../core/base/base-component';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { AppIdentityComponent } from './app-identity/app-identity.component';
import { ThemeColorComponent } from './theme-color/theme-color.component';
import { ButtonModule } from 'primeng/button';
import { FibrCustomIconComponent } from '@shared/components/icons/custom-icon/custom-icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { SubheaderComponent } from '../../../../core/components/subheader/subheader.component';
import { ProjectStoreService } from '../../../project/store/project-store.service';

@Component({
  selector: 'fibr-app-settings',
  templateUrl: './app-settings.component.html',
  styleUrls: ['./app-settings.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HeaderV1Component,
    FooterComponent,
    RouterModule,
    AppIdentityComponent,
    ThemeColorComponent,
    ButtonModule,
    FibrCustomIconComponent,
    TranslateModule,
    SubheaderComponent,
  ],
})
export class AppSettingsComponent extends BaseComponent implements OnInit {
  route = inject(ActivatedRoute);
  projectStore = inject(ProjectStoreService);

  activatePage = signal('app-identity');
  currentPath = computed(() => window.location.pathname);
  projectId = computed(() => this.route.snapshot.paramMap.get('id'));
  projectDetail = computed(() => this.projectStore.projectDetail());

  navigation = [
    {
      label: 'PROJECT.app_settings.menus.app_identity',
      icon: '/assets/images/icon/icon_card.svg',
      name: 'app-identity',
    },
    {
      label: 'PROJECT.app_settings.menus.color',
      icon: '/assets/images/icon/icon_theme.svg',
      name: 'theme-color',
    },
  ];

  constructor() {
    super('app-settings');
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.destroy)).subscribe((params) => {
      if (params['menu']) {
        this.activatePage.set(params['menu']);
      }
    });
  }
}
