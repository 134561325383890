import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AuthAPIService } from '../../api/auth-api.service';

@Component({
  selector: 'fibr-google-sign-in',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './google-sign-in.component.html',
  styleUrl: './google-sign-in.component.scss'
})
export class GoogleSignInComponent {
  constructor(private authService: AuthAPIService) {

  }

  signIn() {
    this.authService.googleSignIn();
  }
}
