import { Component, Input } from '@angular/core';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'fibr-block-ui',
  standalone: true,
  imports: [BlockUIModule, ProgressSpinnerModule],
  templateUrl: './block-ui.component.html',
  styleUrl: './block-ui.component.scss'
})

export class BlockUIComponent {
  @Input() isBlocked: boolean = false;
}
