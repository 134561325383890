import { CommonModule } from '@angular/common';
import { Component, OnInit, computed, inject, signal } from '@angular/core';
import { BaseComponent } from '../../../../../core/base/base-component';
import { DividerModule } from 'primeng/divider';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ColorPickerModule } from 'primeng/colorpicker';
import { NgxColorsModule } from 'ngx-colors';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectAPIService } from '../../../../project/api/project-api.service';
import { ProjectStoreService } from '../../../../project/store/project-store.service';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Project } from '../../../../project/model';
import { ButtonModule } from 'primeng/button';
import { BlockUIComponent } from '@shared/components/block-ui/block-ui.component';
import { UpdateTheme, schemaUpdateTheme } from '../../../model/app-settings';
import { AppSettingService } from '../../../api/app-settings.service';
import { TranslateModule } from '@ngx-translate/core';
import { setToast } from '@shared/components/toast/toast.component';

@Component({
  selector: 'fibr-theme-color',
  templateUrl: './theme-color.component.html',
  standalone: true,
  imports: [
    CommonModule,
    DividerModule,
    SelectButtonModule,
    ColorPickerModule,
    NgxColorsModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    BlockUIComponent,
    RouterModule,
    TranslateModule,
  ],
  providers: [AppSettingService]
})
export class ThemeColorComponent extends BaseComponent implements OnInit {
  route = inject(ActivatedRoute);
  projectApi = inject(ProjectAPIService);
  projectStore = inject(ProjectStoreService);
  appSetting = inject(AppSettingService);
  itemsHtml = Array(5).fill(0);

  projectId = this.route.snapshot.paramMap.get('id');

  // prettier-ignore
  globalConfig = computed(() => this.projectStore.projectDetail()?.global_config);
  themeColor = signal<UpdateTheme>({
    theme: 'minimalist',
    mode: 'light',
    primary_color: '',
    secondary_color: '',
    font_color: '',
  });

  listThemes = [
    {
      icon: '/assets/images/theme/minimal.png',
      label: 'Minimalist',
      value: 'minimalist',
    },
    {
      icon: '/assets/images/theme/futuristic.png',
      label: 'Futuristict',
      value: 'futuristict',
    },
    {
      icon: '/assets/images/theme/dynamic.png',
      label: 'Dynamic',
      value: 'dynamic',
    },
  ];

  constructor() {
    super('theme-color');

    this.schema = schemaUpdateTheme;
  }

  ngOnInit(): void {
    if (this.projectId) {
      this.setStateLoading();
      this.projectApi.fetchProjectDetail(this.projectId).then(() => {
        const config = <Project['global_config']>this.globalConfig();
        this.themeColor.set({
          theme: 'minimalist',
          mode: 'light',
          font_color: config.theme.font_color,
          primary_color: config.theme.primary_color,
          secondary_color: config.theme.secondary_color,
        });
        this.setStateReady();
      });
    }
  }

  changeTheme(type: string) {
    this.themeColor.update((val) => ({
      ...val,
      theme: type,
    }));
  }

  async onSubmit() {
    this.setStateLoading();
    try {
      await this.appSetting.updateTheme({
        projectId: <string>this.projectId,
        mode: this.themeColor().mode,
        theme: this.themeColor().theme,
        font_color: this.themeColor().font_color,
        primary_color: this.themeColor().primary_color,
        secondary_color: this.themeColor().secondary_color,
      });
      setToast(this.translate('PROJECT.app_settings.color.alert.success'), 'success');
      this.setStateReady();
    } catch (error) {
      setToast(error as string, 'error');
      this.setStateReady();
    }
  }
}
