import {Component, OnInit} from "@angular/core";
import {environment} from "../../../../../environments/environment";
import {ButtonModule} from "primeng/button";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'fibr-redirect-payment-billing',
  templateUrl: './redirect-payment-billing.component.html',
  standalone: true,
  imports: [ButtonModule, TranslateModule],
  styles: [`
    .payment-container {
      height: 100vh;
      width: 100%;
      background-image: url('/assets/images/bg-join-app.png');
      background-size: cover;
    }
  `]
})
export class FibrRedirectPaymentBillingComponent implements OnInit {
  logo = environment.logo;

  ngOnInit() {
    this.automatedRedirect()
  }

  automatedRedirect() {
    setTimeout(() => {
      window.location.replace('/projects');
    }, 500);
  }
}
