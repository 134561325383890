import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ProjectStoreService } from '../../store/project-store.service';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'fibr-template-dialog',
  standalone: true,
  imports: [DialogModule, ButtonModule, TranslateModule, DatePipe, CheckboxModule, FormsModule],
  templateUrl: './template-dialog.component.html',
  styleUrl: './template-dialog.component.scss'
})
export class TemplateDialogComponent {
  @Input() useTemplateWithDataField = false;
  @Output() toggleUseTemplateWithDataEvent: EventEmitter<null> = new EventEmitter();

  selectedTemplate = this.projectStore.selectedTemplate;

  constructor(private projectStore: ProjectStoreService) {
  }

  get showDialog() {
    return this.projectStore.activeDialog() === 'template';
  }

  onToggleUseTemplateWithData() {
    this.toggleUseTemplateWithDataEvent.emit();
  }

  closeTemplateDialog() {
    this.projectStore.setSelectedTemplate(null);
    this.projectStore.setActiveDialog(null);
  }

  openCreateProjectWithTemplateDialog() {
    this.projectStore.setActiveDialog('create_project_with_template');
  }
}
