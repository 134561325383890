<p-dialog [visible]="showDialog" (visibleChange)="onCloseDialog()" [modal]="true" [blockScroll]="true"
  [draggable]="false" [dismissableMask]="true" appendTo="body" styleClass="dialog-no-footer" [style]="{width: '90%'}">
  <ng-template pTemplate="header">
    <span class="p-dialog-title"><img class="align-self-center mr-3" alt="icon"
        src="/assets/images/icon-lg/icon_billing-lg.svg" height="20">{{'PROJECT.CREATE_NEW' | translate}}</span>
  </ng-template>

  <div class="col-12">
    <div class="flex flex-row gap-5 justify-center">
      <div class="wrapper-sample-data">
        <div class="app-with-sample-data text-center flex flex-col gap-4" (click)="createAppWithSampleData()" (keyup.enter)="createAppWithSampleData()"
        tabindex="">
          <img class="align-self-center mr-3 w-[70px]" alt="icon" src="/assets/images/icon/app-sample-data.svg" >
        </div>
        <h5 class="list-title mt-3 mb-1">Sample Data</h5>
      </div>
      <div class="wrapper-google-sheet">
        <div class="app-with-google-sheet flex flex-col gap-4" (click)="gSheet()" (keyup.enter)="gSheet()"
        tabindex="">
          <img class="align-self-center mr-3 w-[70px]" alt="icon" src="/assets/images/icon/app-google-sheet.svg">
        </div>
        <h5 class="list-title mt-3 mb-1">Google Sheet</h5>
      </div>
    </div>
  </div>

  <p-divider styleClass="my-6"></p-divider>

  <div class="grid">
    <div class="col-12">
      <div class="flex p-flex-column p-flex-md-row">
        <img class="align-self-center mr-3" alt="icon" src="/assets/images/icon-lg/icon_billing-lg.svg" height="20">
        <h4 class="menu-title">{{'PROJECT.CREATE_NEW_FROM_TEMPLATE' | translate}}</h4>
      </div>
    </div>
  </div>

  <div class="grid">
    @for (template of templateList; track template.id) {
    <div class="col-6 md:col-4 lg:col-3">
      <div class="p-card-shadow p-card p-component card-project-template" (click)="onClickTemplate(template)"
        (keyup.enter)="onClickTemplate(template)" tabindex="0">
        <div class="p-card-header">
          <img alt="Project Template Image" [src]="template.icon" class="img-template"
            onerror="this.src='/assets/images/icon/iconic_logo.png'">
        </div>
        <div class="p-card-body p-3 pb-0">
          <div class="list-item p-0">
            <div class="list-detail d-flex flex-row align-items-center">
              <div class="d-block">
                @if (template.updated_date) {
                <span class="text-xs mb-0"> {{getTemplateUpdatedAt(template) | date : 'dd MMM yyyy'}} </span>
                }
                <h5 class="list-title mt-0 mb-1" pTooltip="{{template.display_name}}" tooltipPosition="bottom">
                  {{template.display_name}} </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    }
  </div>
</p-dialog>
<fibr-block-ui [isBlocked]="isSubmitting()" />
