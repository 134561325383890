<div>
  <div class="grid">
    <div class="col-12 mb-2">
      <h5 class="text-center menu-subtitle mb-1">{{'PROJECT.CREATE_NEW' | translate}}</h5>
      <p class="text-center">{{'PROJECT.USE_BLANK_DATA' | translate}}</p>
    </div>
  </div>
  <p-divider></p-divider>
  <div class="grid">
    <div class="col-12 px-5">
      <p class="">{{'PROJECT.APP_NAME_LABEL' | translate}}<sup>*</sup></p>
      <input type="text" pInputText class="w-100" [ngModel]="appNameField()" (ngModelChange)="appNameField.set($event)"
        [placeholder]="'PROJECT.APP_NAME_PLACEHOLDER' | translate">
      @if(appNameFieldError()) {
      <sub class="text-magenta">{{appNameFieldError()}}</sub>
      }
    </div>
    <div class="col-12 px-5">
      <p class="">{{'PROJECT.TABLE_NAME_LABEL' | translate}}<sup>*</sup></p>
      <input type="text" pInputText class="w-100" [ngModel]="tableNameField()"
        (ngModelChange)="tableNameField.set($event)" [placeholder]="'PROJECT.TABLE_NAME_LABEL' | translate">
      @if(tableNameFieldError()) {
        <sub class="text-magenta">{{tableNameFieldError()}}</sub>
      }
    </div>
  </div>
  <p-divider></p-divider>
  <div class="grid text-center justify-content-center p-5">
    <div class="col-auto center flex-column mx-4">
      <button (click)="submit()">
        <img [alt]="'PROJECT.BLANK_TABLE' | translate" src="/assets/images/icon/blank-table.svg" height="80px" class="py-3" />
        <p class="text-grey">{{'PROJECT.BLANK_TABLE' | translate}}</p>
      </button>
    </div>
  </div>
</div>
<fibr-block-ui [isBlocked]="isSubmitting()"></fibr-block-ui>
