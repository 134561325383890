<!-- eslint-disable @angular-eslint/template/elements-content -->

<ng-template
  #dataTemplate
  let-tables="tables"
  let-tableSelected="selected"
  let-tableColumns="columns"
>
  <div class="flex flex-col">
    <fibr-collapse label="editor.pages.source">
      <span class="text-lg capitalize text-[#8A8A8A] mb-2">
        {{ "editor.pages.data_source" | translate }}
      </span>

      <p-dropdown
        name="data_source"
        [options]="tables"
        [ngModel]="tableSelected"
        (ngModelChange)="changeSource($event)"
        placeholder="Select Table"
        optionValue="id"
        appendTo="body"
      >
        <ng-template let-selected pTemplate="selectedItem">
          <span>{{ selected.label || selected.id }}</span>
        </ng-template>
        <ng-template let-source pTemplate="item">
          <span>{{ source.label || source.id }}</span>
        </ng-template>
      </p-dropdown>
    </fibr-collapse>

    <fibr-collapse label="editor.pages.content">
      <div class="flex flex-col space-y-3">
        <div>
          <p class="label-info">
            {{ "Header Title" | translate }}
          </p>

          <input
            pInputText
            name="header_title"
            [(ngModel)]="columns()['header']"
          />
        </div>

        @for (item of contentOptions(); track $index) {
        <div>
          <div>
            <p class="label-info">
              {{ item.label | translate }}
              <i
                class="pi pi-info-circle"
                (mouseenter)="overlay.toggle($event)"
                (mouseleave)="overlay.toggle($event)"
              ></i>
            </p>

            <p-overlayPanel #overlay styleClass="overlay-info" appendTo="body">
              <ng-template pTemplate>
                <div class="p-card-shadow p-card p-component">
                  <div class="p-card-header">
                    <img alt="Image Info" [src]="item.guide.image" />
                  </div>
                  <div class="p-card-body">
                    <div class="p-card-title">
                      {{ item.label | translate }}
                    </div>
                    <div class="p-card-content p-0">
                      <p>{{ item.guide.description | translate }}</p>
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-overlayPanel>
          </div>

          <p-dropdown
            [options]="
              [
                {
                  label: translateInstant('editor.pages.content.select_column'),
                  value: ''
                }
              ].concat(tableColumns)
            "
            [(ngModel)]="columns()[item.name]"
            optionLabel="label"
            optionValue="name"
            placeholder="{{ 'editor.pages.content.select_column' | translate }}"
            appendTo="body"
          >
            <ng-template let-selected pTemplate="selectedItem">
              <span>{{ selected.label | translate }}</span>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <span>{{ item.label | translate }}</span>
            </ng-template>
          </p-dropdown>
        </div>
        }
      </div>
    </fibr-collapse>

    <fibr-collapse label="editor.pages.design">
      @for(config of designOptions(); track config) {
      <div>
        <span class="text-lg capitalize text-[#8A8A8A] mb-2">
          {{ config.label | translate }}
        </span>

        <div class="w-full p-inputgroup mt-2">
          @for (item of config.options; track item) {
          <button
            pButton
            pRipple
            type="button"
            class="p-button-secondary2 w-full"
            label="{{ item.label | translate }}"
            [ngClass]="{
              active: styles().list_direction === item.value
            }"
            (click)="changeStyle(config.name, item.value)"
          ></button>
          }
        </div>
      </div>
      }
    </fibr-collapse>
  </div>
</ng-template>

<ng-template #detailTemplate let-components="components" let-columns="columns">
  <div class="p-4">
    <div class="col-12">
      <h6 class="label-info">
        {{ "EDITOR.ACCORDION.COMPONENT.DETAIL_PAGE" | translate }}
      </h6>

      <fibr-order-list [value]="components">
        <ng-template let-item let-index pTemplate="item">
          <p-card
            styleClass="card-details"
            role="button"
            (click)="pageComponentStore.selectTypeDetail(item)"
          >
            <div class="grid align-items-center px-2 py-1">
              <img
                alt="icons-details"
                src="/assets/images/icon/details/icon_{{
                  item.variant || item.type
                }}.svg"
              />
              <b class="capitalize">{{ item.variant || item.type }}</b>
              @if (!['separator'].includes(item.type)) {
              <p-divider styleClass="p-1 mx-2" layout="vertical"></p-divider>
              }
              @switch (item?.type) {
              @case ('button') {
                <span>{{ item?.style?.label }}</span>
              }
              @default {
                @switch (item?.data_source?.type) {
                  @case ('table') {
                    <span class="line-clamp-1 text-ellipsis" style="max-width: 100px">
                      {{ columns?.[item?.data_source?.value]?.label }}
                    </span>
                  }
                  @case ('custom') {
                    <span>{{ item?.data_source?.value }}</span>
                  }
                }
              }
              }
              <button
                type="button"
                class="p-button p-button-danger p-button-text p-button-sm p-1 bg-transparent"
                pButton
                icon="pi pi-trash"
                (click)="pageComponentStore.removeDetail(item)"
              ></button>
            </div>
          </p-card>
        </ng-template>
      </fibr-order-list>
    </div>

    <div class="col-12">
      <button
        pButton
        icon="pi pi-plus"
        label="Add"
        (click)="pageComponentStore.selectTypeDetail()"
      ></button>
    </div>
  </div>
</ng-template>

<ng-template #formTemplate let-components="components" let-columns="columns">
  <div class="p-4">
    <div class="grid">
      <div class="col-4">
        <p-checkbox
          label="{{ 'editor.pages.form.add' | translate }}"
          name="with_add_button"
          [binary]="true"
          [(ngModel)]="config().with_add_button"
        ></p-checkbox>
      </div>
      <div class="col-4">
        <p-checkbox
          label="{{ 'editor.pages.form.edit' | translate }}"
          name="with_edit_button"
          [binary]="true"
          [(ngModel)]="config().with_edit_button"
        ></p-checkbox>
      </div>
      <div class="col-4">
        <p-checkbox
          label="{{ 'editor.pages.form.delete' | translate }}"
          name="with_delete_button"
          [binary]="true"
          [(ngModel)]="config().with_delete_button"
        ></p-checkbox>
      </div>
    </div>
  
    <div class="col-12 mt-2">
      <h6 class="label-info">
        {{ "EDITOR.ACCORDION.COMPONENT.FORM_PAGE" | translate }}
      </h6>
  
      <fibr-order-list
        [value]="components"
        (didReorder)="pageComponentStore.reOrderForm($event)"
      >
        <ng-template let-item let-index pTemplate="item">
          <p-card
            styleClass="card-details"
            role="button"
            (click)="pageComponentStore.selectTypeForm(item)"
          >
            <div class="grid align-items-center px-2 py-1">
              <img
                alt="icons-details"
                src="/assets/images/icon/form/icon_{{ item.type }}.svg"
              />
              <b class="capitalize">{{ item.type.split("_").join(" ") }}</b>
              <p-divider styleClass="p-1 mx-2" layout="vertical"></p-divider>
              <span class="line-clamp-1 text-ellipsis" style="max-width: 100px">
                {{ columns?.[item.config.column]?.label }}
              </span>
              <button
                type="button"
                class="p-button p-button-danger p-button-text p-button-sm p-1 bg-transparent"
                pButton
                icon="pi pi-trash"
                (click)="pageComponentStore.removeForm(item)"
              ></button>
            </div>
          </p-card>
        </ng-template>
      </fibr-order-list>
    </div>
  
    <div class="col-12">
      <button
        pButton
        icon="pi pi-plus"
        label="Add"
        (click)="pageComponentStore.selectTypeForm()"
      ></button>
    </div>
  </div>
</ng-template>

<ng-template #optionTemplate let-components="components" let-tableColumns="columns">
  <div class="p-4">
    <div class="grid">
      @if (filterConfig().length > 0){
        @for (item of filterConfig(); track $index) {
          <div class="col-11">
            <p-dropdown
              [options]="
                [
                  {
                    label: translateInstant('editor.pages.content.select_column'),
                    value: ''
                  }
                ].concat(tableColumns)
              "
              [(ngModel)]="filterConfig()[$index].value"
              optionLabel="label"
              optionValue="name"
              placeholder="{{ 'editor.pages.content.select_column' | translate }}"
              appendTo="body"
            >
              <ng-template let-selected pTemplate="selectedItem">
                <span>{{ selected.label | translate }}</span>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <span>{{ item.label | translate }}</span>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="col-1 flex align-items-center">
            <button type="button" tabindex="" (click)="deleteFilter(item)" class="p-button p-button-danger p-button-text p-button-sm p-1 bg-transparent text-center" pButton ><i data-feather="trash-2" class="size-5"></i></button>
          </div>
        }
      }
      @else{
        <div class="w-full flex items-center justify-center py-2">
          <span class="text-base">No Items</span>
        </div>
      }
        <div class="col-12">
          <p-button label="Add" (click)="addNewFilter()" [disabled]="tableColumns.length === filterConfig().length"><i data-feather="plus"></i></p-button>
        </div>
    </div>
  </div>
</ng-template>