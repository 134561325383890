<div class="grid">
  <div class="col-12">
    <p class="">{{'TABLE.COLUMN_NAME_LABEL' | translate}}</p>
    <input type="text" pInputText class="w-100" placeholder="Field title" [ngModel]="columnLabel()"
      (ngModelChange)="columnNameField.set($event)">
  </div>
  <div class="col-12">
    <p class="">{{'TABLE.COLUMN_TYPE_LABEL' | translate}}</p>
    <p-dropdown [disabled]="true" [options]="options" optionLabel="name" optionValue="value" [ngModel]="columnTypeField() ?? columnType()"
      (onChange)="onChangeType($event.value)" appendTo="body"></p-dropdown>
  </div>

  <!-- TODO handle calculation -->
</div>
<div class="grid">
  <div class="col-6">
    <button (click)="onDelete()" pButton type="button" icon="pi pi-trash" iconPos="right"
      class="p-button p-button-secondary text-magenta mr-2 mb-2" pTooltip="Delete Column" tooltipPosition="bottom">
    </button>
  </div>
  <div class="col-6 text-right">
    <button (click)="onCancel()" pButton type="button" label="Cancel" icon="" iconPos="right"
      class="p-button p-button-secondary p-component p-ripple mr-2 mb-2">
    </button>
    <button (click)="onUpdate()" pButton type="button" label="Update" icon="" iconPos="right"
      class="p-button p-component p-ripple mr-2 mb-2">
    </button>
  </div>
</div>
