<div>
  <div class="flex flex-row">
    <div class="border-2 border-[#D9D9D9] rounded-xl">
      <img
        [src]="previewImageUrl()"
        class="w-[150px] h-auto preview-image"
        alt="preview-image"
        fibrMissingImage
        [errorImageUrl]="defaultImageUrl"
      />
    </div>
  </div>

  <input
    #input
    type="file"
    (change)="onChange($event)"
    [hidden]="true"
    [accept]="accept"
  />

  <div class="flex align-items-center gap-3 mt-3">
    <p-button
      label="Remove"
      severity="secondary"
      (onClick)="remove()"
    ></p-button>
    <p-button icon="pi pi-upload" label="Upload" (onClick)="click()"></p-button>
  </div>
</div>
