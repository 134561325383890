<p-sidebar
  styleClass="page-option fourth-element"
  [visible]="visible"
  appendTo="body"
  [blockScroll]="true"
  [modal]="false"
  position="right"
>
  <p-card>
    <ng-template pTemplate="header">
      <div class="page-header">
        <i class="pi pi-clone text-primary"></i>

        <div class="ml-3">
          <span class="title-text">
            {{ options().title }}
          </span>
        </div>
      </div>

      <div class="right-action">
        <p-button
          type="button"
          label=""
          icon="pi pi-times"
          styleClass="p-button-secondary p-button-text"
          pTooltip="Cancel"
          tooltipPosition="bottom"
          (onClick)="onSectionCancel()"
        ></p-button>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <p-accordion [multiple]="true">
        <p-accordionTab [selected]="true">
          <ng-template pTemplate="header">
            <div class="label-info">
              <i class="pi pi-clone mr-3"></i>

              {{ 'Section Properties' }}
            </div>
          </ng-template>

          <div class="grid p-3">
            <div class="col-12">
              <span class="text-lg capitalize text-[#8A8A8A] mb-3">
                {{ "Section Title" | translate }}
              </span>

              <input placeholder="Section Title" name="section_title" [(ngModel)]="options().title" pInputText/>
            </div>
          </div>
        </p-accordionTab>

        <p-accordionTab [selected]="true">
          <ng-template pTemplate="header">
            <div class="label-info">
              <img src="/assets/images/icon/accordion-icon/icon_component.svg" class="mr-3" alt="form-component" />

              {{ 'Form Component' }}
            </div>
          </ng-template>

          <div class="p-3">
            <p-orderList styleClass="order-details" class="component" [value]="components()" [dragdrop]="true">
              <ng-template pTemplate="empty">
                <div class="flex align-items justify-center py-4">
                  <span>{{ 'COMMON.NO_DATA' | translate }}</span>
                </div>
              </ng-template>

              <ng-template pTemplate="item" let-item>
                <p-card styleClass="card-details" (click)="openComponent(item)">
                  <div class="grid align-items-center">
                    <img alt="icons-form" src="/assets/images/icon/form/icon_{{ item.type }}.svg"/>
                    <b class="capitalize">{{ item.type.split("_").join(" ") }}</b>
                    <p-divider styleClass="p-1 mx-2" layout="vertical"></p-divider>

                    <div class="flex-1">
                      <span class="line-clamp-1 text-ellipsis" style="max-width: 100px">
                        {{ getLabelColumn(item?.config?.column) || item?.config?.label }}
                      </span>
                    </div>

                    <p-button
                      type="button"
                      styleClass="p-button p-button-danger p-button-text p-button-sm p-1 bg-transparent"
                      label=""
                      icon="pi pi-trash"
                      (onClick)="deleteComponent(item)"
                    ></p-button>
                  </div>
                </p-card>
              </ng-template>
            </p-orderList>

            <div class="mt-2">
              <p-button label="Add" icon="pi pi-plus" (onClick)="addComponent()"></p-button>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </ng-template>

    <ng-template pTemplate="footer">
      <p-button
        label="{{ 'EDITOR.ACCORDION.POPUP.CANCEL' | translate }}"
        styleClass="p-button-secondary2 p-button-text col-5 mr-5"
        (onClick)="onSectionCancel()"
      ></p-button>
      <p-button
        label="OK"
        styleClass="col-5"
        (onClick)="onSectionApply()"
      ></p-button>
    </ng-template>
  </p-card>
</p-sidebar>

<fibr-page-component
  [component]="utils().selected"
  [visible]="utils().visible"
  [right]="410 * 3"
  (cancel)="onCancel()"
  (apply)="onApply($event)"
>
  <fibr-page-component-text-entry #componentType></fibr-page-component-text-entry>
  <fibr-page-component-text-number #componentType></fibr-page-component-text-number>
  <fibr-page-component-file-picker #componentType></fibr-page-component-file-picker>
  <fibr-page-component-image-picker #componentType></fibr-page-component-image-picker>
  <fibr-page-component-date-picker #componentType></fibr-page-component-date-picker>
  <fibr-page-component-date-time-picker #componentType></fibr-page-component-date-time-picker>
  <fibr-page-component-checkbox #componentType></fibr-page-component-checkbox>
  <fibr-page-component-dropdown #componentType></fibr-page-component-dropdown>
  <fibr-page-component-radio #componentType></fibr-page-component-radio>
</fibr-page-component>
