import type { Timestamp } from "@angular/fire/firestore";
import type { Row } from "@shared/components/table/table.component";
import type { DataState } from "@shared/libs/http/response";
import { minLength, object, string } from "valibot";

export const clientColTypes = ['text', 'currency', 'image', 'float'] as const;

export type TableID = string;

export type ClientColType = typeof clientColTypes[number];

export type BuilderColType = { type: 'string'; format: 'text'; } | { type: 'number'; format: 'number' } |
  { type: 'number'; format: 'currency' } | { type: 'string'; format: 'url' } | { type: 'string'; format: 'timestamp' } | 
  { type: 'timestamp'; format: 'date' } | { type: 'timestamp'; format: 'date_time' } | { type: 'string'; format: 'link' };

export type ColumnField = {
  index: number;
  name: string;
  label: string;
  display?: boolean;
} & BuilderColType;

type ColumnName = string;

export type TableField = {
  columns: Record<ColumnName, ColumnField>;
  id: string;
  label?: string;
  created_at?: Timestamp;
  is_external?: boolean;
  spreadsheet_id?: string;
}

export type TableDataByTableID = Record<TableID, Array<Row>>;

export type TableStore = {
  tableList: DataState<TableField[]>
  tableDataByTableID: DataState<TableDataByTableID>
}

export type TableColumnFieldType = 'text' | 'number' | 'date' | 'datetime' | 'image' | 'currency' | 'link';

export const AddColumnSchema = object({
  columnName: string([minLength(1, 'TABLE.COLUMN_NAME_REQUIRED')]),
})

export const protectedColumnNames = ['id', 'created_at', 'updated_at'];
