import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'img[fibrMissingImage]',
  standalone: true,
})
export class MissingImageDirective {
  constructor(private el: ElementRef) {}

  @Input() errorImageUrl = '/assets/images/image-error.png';

  @HostListener('error')
  private onError() {
    this.el.nativeElement.src = this.errorImageUrl;
  }
}
