<button
  (click)="signIn()"
  class="justify-center items-stretch self-stretch border bg-white flex gap-2.5 p-4 rounded-lg border-solid border-zinc-300"
>
  <img
    alt="google"
    loading="lazy"
    src="/assets/images/google-icon.svg"
    class="aspect-square object-contain object-center w-6 shrink-0"
  />
  <div
    class="text-neutral-700 text-center text-base grow whitespace-nowrap"
  >
    {{"SIGN_IN.WITH_GOOGLE" | translate}}
  </div>
</button>
