import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'
import { AuthAPIService } from '../../features/auth/api/auth-api.service'
import { AuthStoreService } from '../../features/auth/store/auth-store.service';
import { Auth } from '@angular/fire/auth';

export const onBoardingGuard: CanActivateFn = async () => {
    const auth = inject(Auth);
    const authApi = inject(AuthAPIService);
    const authStore = inject(AuthStoreService);
    const router = inject(Router);

    try {
        const fromLogin = auth.currentUser;
        let user = authStore.user();

        if (fromLogin) {
            user = fromLogin;
        }

        if (!user) {
            return router.parseUrl('/sign-in')
        }

        const profile = await authApi.getProfile(user.uid);

        if (!profile.is_onboard) {
            return router.parseUrl('/onboarding');
        }
        return true;
    } catch (error) {
        console.log(error, 'error');
        return true
    }

}
