import { instance, nullable, object, string, union, Input, optional } from "valibot";

export const schemaUpdateSetting = object({
  projectId: optional(string()),
  app_name: string(),
  app_description: string(),
  app_icon: nullable(union([instance(File), string()])),
});

export type UpdateAppSetting = Input<typeof schemaUpdateSetting>;

export const schemaUpdateTheme = object({
  projectId: optional(string()),
  theme: string(),
  mode: string(),
  primary_color: string(),
  secondary_color: string(),
  font_color: string(),
});

export type UpdateTheme = Input<typeof schemaUpdateTheme>;