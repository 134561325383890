import { Component, Input, AfterViewInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { environment } from '../../../../../environments/environment';
import { LanguageComponent } from '../language/language.component';
import { BrandComponent } from '../brand/brand.component';
import { MenubarModule } from 'primeng/menubar';
import { NotificationsComponent } from '../notifications/notifications.component';
import { HelpComponent } from '../help/help.component';
import { WelcomeComponent } from '../welcome/welcome.component';
import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ProfileComponent } from '../profile/profile.component';

@Component({
  selector: 'fibr-horizontalmenu',
  standalone: true,
  imports: [
    LanguageComponent,
    BrandComponent,
    MenubarModule,
    NotificationsComponent,
    HelpComponent,
    WelcomeComponent,
    CommonModule,
    ProfileComponent,
  ],
  templateUrl: './horizontalmenu.component.html',
  styleUrl: './horizontalmenu.component.scss',
})
export class HorizontalmenuComponent implements AfterViewInit {
  @Input() items: MenuItem[] = [];
  @Input() srcImg!: string;
  @Input() userProfilePath = '/portal/config/user-profile';
  @Input() aksesFrom!: string;

  isNotif = environment.topbar.isNotif;
  isLanguage = environment.topbar.isLanguage;
  isHelp = environment.topbar.isHelp;
  isGreeting = environment.topbar.isGreeting;
  isProfile = environment.topbar.isProfile;

  constructor(private translate: TranslateService) {
    //
  }

  ngAfterViewInit(): void {
    const { menuProject, menuBilling, menuForum, menuSupport } = this.translate
      .instant(['menuProject', 'menuBilling', 'menuForum', 'menuSupport'])
    this.items = [
      {
        label: menuProject,
        routerLink: '/projects',
        routerLinkActiveOptions: { exact: true },
        styleClass: 'p-menuitem-link-active',
      },
      {
        label: menuBilling,
        routerLink: '/billing',
        routerLinkActiveOptions: { exact: true },
      },
      {
        label: menuForum,
        command: () => {
          window.open('https://forum.appfibr.io/');
        },
      },
      {
        label: menuSupport,
        routerLink: 'support',
        routerLinkActiveOptions: { exact: true },
      },
    ];
  }
}
