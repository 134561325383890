import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, signal } from '@angular/core';

@Component({
  selector: 'fibr-custom-icon',
  template: `
    <i
      [class]="this.styleClass"
      [style]="{
        display: 'block',
        width: '22px',
        height: '22px',
        '-webkit-mask': maskIcon(),
        mask: maskIcon()
      }"
    ></i>
  `,
  standalone: true,
  imports: [CommonModule],
})
export class FibrCustomIconComponent implements OnInit {
  @Input() src: string;
  @Input() styleClass: string = 'bg-black';

  public maskIcon = signal<string>('')

  ngOnInit(): void {
    this.maskIcon.set(`url(${this.src}) no-repeat center`);
  }
}
