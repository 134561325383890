import { Injectable, inject } from '@angular/core';
import { ProjectsShardService } from '../../../core/projects-shard/projects-shard.service';
import { collection, getDocs } from '@angular/fire/firestore';
import { PackageSubscription } from '../model/package.model';
import { PackageStore } from '../store/package.store';

@Injectable()
export class PackageApi {
  private project: ProjectsShardService = inject(ProjectsShardService);
  private packageStore: PackageStore = inject(PackageStore);

  private firestore = this.project.fireStore;

  async getPackages(): Promise<PackageSubscription[]> {
    const packageRef = collection(this.firestore, '/packages');
    const packageDoc = await getDocs(packageRef);

    const packages = packageDoc.docs.map((doc) => ({
      id: doc.id,
      ...doc.data() as Omit<PackageSubscription, 'id'>,
    })).sort((a, b) => b.duration_days - a.duration_days);

    this.packageStore.setPackages(packages);

    return packages;
  }
}
