import { Component } from '@angular/core';
import { LogoIconComponent } from '../../../shared/components/icons/logo/logo.component';
import { WebIconComponent } from '../../../shared/components/icons/web/web-icon.component';
import { AndroidIconComponent } from '../../../shared/components/icons/android/android-icon.component';
import { UpgradeToProIconComponent } from '../../../shared/components/icons/upgrade-to-pro/upgrade-to-pro-icon.component';
import { PublishIconComponent } from '../../../shared/components/icons/publish/publish-icon.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'fibr-header',
  standalone: true,
  imports: [LogoIconComponent, WebIconComponent, AndroidIconComponent, UpgradeToProIconComponent, PublishIconComponent, TranslateModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  DUMMY_DATA = {
    app_title: 'Volunteer App',
    app_icon: null,
    published_platforms: ['web', 'android'],
    package: 'Basic'
  }
}
