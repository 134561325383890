import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TagModule } from 'primeng/tag';

@Component({
  selector: 'fibr-label-invoice',
  template: `
    <p-tag [severity]="severity" [styleClass]="severity + ' text-base px-3'">
      {{ 'BILLING.STATUS.' + status | translate }}
    </p-tag>
  `,
  standalone: true,
  imports: [CommonModule, TranslateModule, TagModule],
})
export class LabelInvoiceComponent {
  @Input() status: string;

  get severity(): string {
    const _sevirity: { [x: string]: string } = {
      PAID: 'success',
      PENDING: 'danger',
      EXPIRED: 'p-tag-expired2',
    };

    return _sevirity[this.status];
  }
}
