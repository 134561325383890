<p-dialog [visible]="dialogInviteUser" (visibleChange)="onCloseDialog()" [modal]="true"
  [draggable]="false" [dismissableMask]="true" appendTo="body" styleClass="dialog-no-footer" [style]="{width: '40%'}">
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-center gap-3">
        <span class="pi pi-user-plus" style="font-size: 2rem"></span>
        <span class="p-dialog-title">{{'EDITOR.INVITE' | translate}}</span>
    </div>
  </ng-template>
    <div class="flex flex-row gap-4">
      <p-inputGroup>
        <p-chips [ngModel]="inputEmail()" (ngModelChange)="inputEmail.set($event)" separator="," placeholder="Hint: jhone@example.com, doe@example.com" class="p-inputtext p-component p-element"></p-chips>
        <p-inputGroupAddon>
          <p-dropdown [options]="roles" [(ngModel)]="selectedRole" optionLabel="name" [showClear]="true" placeholder="Role"></p-dropdown>
        </p-inputGroupAddon>
      </p-inputGroup>
      <p-button [label]="'Invite'" styleClass="p-button-lg" class="rounded-md" (click)="submit()"> </p-button>
    </div>
    @if(emailFieldError()) {
      <sub class="text-magenta">{{emailFieldError()}}</sub>
    }
    <br>
    @if(roleFieldError()) {
      <sub class="text-magenta">{{roleFieldError()}}</sub>
    }

    <div class="invited-user overflow-hidden overflow-y-auto max-h-64 mt-5 mb-7">
      @for (user of userInvited(); track user;) {
        <div class="flex flex-row justify-between items-center gap-3 scroll-y">
          <div class="flex flex-row gap-3">
            <div class="rounded-full w-[1.5rem] h-[1.5rem]" [style.backgroundColor]="getColor(user)"></div>
            <span class="text-grey">{{user.email}}</span>
          </div>
          <div class="flex flex-row items-center">
            <span class="text-grey">{{'Waiting'}}</span>
            <div class="product-list-action">
              <p-button
                id="dropdown-project-list"
                styleClass="p-button p-button-text"
                icon="pi pi-angle-down"
                (click)="more.toggle($event); setUser(user)"
              >
              </p-button>
              <p-menu
                #more
                [popup]="true"
                [model]="action"
                appendTo="body"
              ></p-menu>
            </div>
          </div>
        </div>
      }
      @for (userAccess of userAccess(); track userAccess;) {
        <div class="flex flex-row justify-between items-center gap-3 scroll-y">
          <div class="flex flex-row gap-3">
            <div class="rounded-full w-[1.5rem] h-[1.5rem]" [style.backgroundColor]="getColorUserAccess(userAccess)"></div>
            <span class="text-grey">{{userAccess?.email}}</span>
          </div>
          @if (role() === 'owner'){
            @if (userAccess.role === 'owner'){
              <div class="flex flex-row items-center">
                <span class="text-grey">{{getRoleName(userAccess.role!)}}</span>
                <div class="product-list-action">
                  <p-button
                    id="dropdown-project-list"
                    styleClass="p-button p-button-text"
                    icon="pi pi-angle-down"
                    (click)="more.toggle($event); setUser(userAccess)"
                  >
                  </p-button>
                    <p-menu
                    #more
                    [popup]="true"
                    [model]="actionAccessUserEditor"
                    appendTo="body"
                  ></p-menu>
                </div>
              </div>
            } @else {
              <div class="flex flex-row items-center">
                <span class="text-grey">{{getRoleName(userAccess.role!)}}</span>
                <div class="product-list-action">
                  <p-button
                    id="dropdown-project-list"
                    styleClass="p-button p-button-text"
                    icon="pi pi-angle-down"
                    (click)="more.toggle($event); setUser(userAccess)"
                  >
                  </p-button>
                    <p-menu
                    #more
                    [popup]="true"
                    [model]="actionAccessUserOwner"
                    appendTo="body"
                  ></p-menu>
                </div>
              </div>
            }
          } @else if (role() === 'editor') {
            @if(userAccess.role === 'owner') {
              <div class="flex flex-row items-center pb-3 pr-4">
                <span class="text-grey">{{getRoleName(userAccess.role!)}}</span>
              </div>
            } @else {
              @if (userAccess.id === userID){
                <div class="flex flex-row items-center">
                  <span class="text-grey">{{getRoleName(userAccess.role!)}}</span>
                  <div class="product-list-action">
                    <p-button
                      id="dropdown-project-list"
                      styleClass="p-button p-button-text"
                      icon="pi pi-angle-down"
                      (click)="more.toggle($event); setUser(userAccess)"
                    >
                    </p-button>
                      <p-menu
                      #more
                      [popup]="true"
                      [model]="actionAccessUserEditor"
                      appendTo="body"
                    ></p-menu>
                  </div>
                </div> 
              } @else {
                <div class="flex flex-row items-center pb-3 pr-4">
                  <span class="text-grey">{{getRoleName(userAccess.role!)}}</span>
                </div>
              }
            }
          } @else {
            <div class="flex flex-row items-center pb-3 pr-4">
              <span class="text-grey">{{getRoleName(userAccess.role!)}}</span>
            </div>
          }
        </div>
      }
    </div>

</p-dialog>
<fibr-block-ui [isBlocked]="isBlocked()" />