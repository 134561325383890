import { CollectionStyle } from "../generators/widgets/widget-builder.model";

interface DesignOptionType {
  label: string;
  name: keyof CollectionStyle;
  options: {
    label: string;
    value: string | number;
  }[];
  for: string[]
}

export const configDesignOptions: DesignOptionType[] = [
  {
    label: 'editor.pages.orientation',
    name: 'list_direction',
    options: [
      {
        label: 'editor.pages.orientation.vertical',
        value: 'vertical',
      },
      {
        label: 'editor.pages.orientation.horizontal',
        value: 'horizontal',
      },
    ],
    for: ['list'],
  },
  {
    label: 'editor.pages.column',
    name: 'grid_count',
    options: [
      {
        label: '1',
        value: 1,
      },
      {
        label: '2',
        value: 2,
      },
      {
        label: '3',
        value: 3,
      },
      {
        label: '4',
        value: 4,
      },
    ],
    for: ['grid'],
  },
  // {
  //   label: 'Border',
  //   name: 'border',
  //   options: [
  //     {
  //       label: 'editor.pages.border.float',
  //       value: 'float',
  //     },
  //     {
  //       label: 'editor.pages.border.outline',
  //       value: 'outline',
  //     },
  //     {
  //       label: 'editor.pages.border.none',
  //       value: 'none',
  //     },
  //   ],
  //   for: [],
  // },
  // {
  //   label: 'editor.pages.corner',
  //   name: 'corner',
  //   options: [
  //     {
  //       label: 'editor.pages.corner.sharp',
  //       value: 'sharp',
  //     },
  //     {
  //       label: 'editor.pages.corner.rounded',
  //       value: 'rounded',
  //     },
  //   ],
  //   for: [],
  // },
  {
    label: 'editor.pages.padding',
    name: 'padding',
    options: [
      {
        label: 'editor.pages.padding.loose',
        value: 'loose',
      },
      {
        label: 'editor.pages.padding.Tight',
        value: 'Tight',
      },
    ],
    for: [],
  },
];
