import { Injectable, inject } from '@angular/core';
import { ProjectsShardService } from '../../../core/projects-shard/projects-shard.service';
import { Timestamp, doc, getDoc } from '@angular/fire/firestore';
import { Voucher } from '../model/voucher.model';

@Injectable()
export class VoucherApi {
  private project: ProjectsShardService = inject(ProjectsShardService);

  private firestore = this.project.fireStore;

  checkAvailability(voucherCode: string): Promise<Voucher> {
    return new Promise((resolve, reject) => {
      const voucherRef = doc(this.firestore, `/vouchers/${voucherCode}`);

      getDoc(voucherRef)
        .then((doc) => {
          if (doc.exists()) {
            const currentDate = new Date();
            const validFrom = <Timestamp>doc.data()['valid_from'];
            const validUntil = <Timestamp>doc.data()['valid_until'];

            if (currentDate < validFrom.toDate()) {
              reject({ error: { code: 'invalid' } });
              return
            }

            if (currentDate > validUntil.toDate()) {
              reject({ error: { code: 'expired' } });
              return
            }

            resolve({
              id: doc.id,
              ...(doc.data() as Omit<Voucher, 'id'>),
            });
            return
          }

          reject({ error: { code: 'invalid' } });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
