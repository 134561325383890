<aside
  class="w-[4.5rem] flex flex-col bg-gray-50 border border-tertiary h-full items-center py-[.875rem]"
>
  <div class="flex flex-col space-y-7 justify-between">
    @for (action of mainActionList; track action) {
    <div
      class="flex items-center justify-center w-[2.625rem] h-[2.625rem] rounded-2xl"
      [ngClass]="{
        'bg-primary-500': DUMMY_INTERNAL_STATE.selectedMainAction === action,
        'hover:bg-primary-100':
          DUMMY_INTERNAL_STATE.selectedMainAction !== action
      }"
    >
      @switch (action) { @case ('add') {
      <fibr-add-icon
        (click)="handleVisibleFeature()"
        class="cursor-pointer"
      ></fibr-add-icon>
      } @case ('page-list') {
      <fibr-page-list-icon
        (click)="onClickShowPageList()"
        class="cursor-pointer"
      ></fibr-page-list-icon>
      } @case ('theme') {
      <fibr-theme-icon [ngClass]="getMainActionClass(action)"></fibr-theme-icon>
      } @case ('analytics') {
      <fibr-analytic-icon
        [ngClass]="getMainActionClass(action)"
      ></fibr-analytic-icon>
      } @case ('cms') {
      <fibr-cms-icon [ngClass]="getMainActionClass(action)"></fibr-cms-icon>
      } @case ('settings') {
      <fibr-settings-icon
        [ngClass]="getMainActionClass(action)"
      ></fibr-settings-icon>
      } }
    </div>
    }
  </div>
  <div class="flex flex-col space-y-7 justify-between mt-auto">
    <button class="sign-out-button" (click)="signOut()">
      <i data-feather="arrow-left" class="h-4 w-4 text-[#CB444A]"></i>
    </button>
  </div>
</aside>

@if(featureVisible){
<fibr-features class="flex flex-col border border-tertiary"></fibr-features>
}
