interface ContentOptionType {
  label: string;
  name: 'title' | 'description' | 'label' | 'image';
  for: string[];
  guide: {
    image: string;
    description: string;
  }
}

export const contentContentOptions: ContentOptionType[] = [
  {
    label: 'editor.pages.content.main_title',
    name: 'title',
    for: ['grid'],
    guide: {
      image: '/assets/images/overlay-info/oi_gmt.jpg',
      description: 'EDITOR.GUIDE_CONTENT.GRID.TITLE',
    },
  },
  {
    label: 'editor.pages.content.main_title',
    name: 'title',
    for: ['list'],
    guide: {
      image: '/assets/images/overlay-info/oi_lmt.jpg',
      description: 'editor.pages.content.main_title.tooltip',
    },
  },
  {
    label: 'editor.pages.content.description',
    name: 'description',
    for: ['grid'],
    guide: {
      image: '/assets/images/overlay-info/oi_gdes.jpg',
      description: 'EDITOR.GUIDE_CONTENT.GRID.DESCRIPTION',
    },
  },
  {
    label: 'editor.pages.content.description',
    name: 'description',
    for: ['list'],
    guide: {
      image: '/assets/images/overlay-info/oi_ldes.jpg',
      description: 'editor.pages.content.description.tooltip',
    },
  },
  {
    label: 'Label',
    name: 'label',
    for: ['grid', 'list'],
    guide: {
      image: '/assets/images/overlay-info/oi_gl.jpg',
      description: 'EDITOR.GUIDE_CONTENT.GRID.LABEL',
    },
  },
  {
    label: 'editor.pages.content.image',
    name: 'image',
    for: ['grid'],
    guide: {
      image: '/assets/images/overlay-info/oi_gimg.jpg',
      description: 'EDITOR.GUIDE_CONTENT.GRID.IMAGE',
    },
  },
  {
    label: 'editor.pages.content.image',
    name: 'image',
    for: ['list'],
    guide: {
      image: '/assets/images/overlay-info/oi_limg.jpg',
      description: 'editor.pages.content.image.tooltip',
    },
  },
];
