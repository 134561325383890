import {CommonModule} from '@angular/common';
import {Component, computed, inject, model} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'primeng/card';
import {DividerModule} from 'primeng/divider';
import {OrderListModule} from 'primeng/orderlist';
import {BodyItem} from '../../../../../model';
import {PageComponentStore} from "../../../../../store/page-component.store";
import {FibrPageComponent} from "../../../../page-component/page-component.component";
import {
  FibrPageComponentGridComponent
} from "../../../../page-component/page-component-types/page-component-grid/page-component-grid.component";
import {
  FibrPageComponentListComponent
} from "../../../../page-component/page-component-types/page-component-list/page-component-list.component";
import {TableStoreService} from "../../../../../../table/store/table-store.service";

@Component({
  selector: 'fibr-component-list',
  templateUrl: './component-list.component.html',
  standalone: true,
  imports: [
    CommonModule,
    OrderListModule,
    CardModule,
    ButtonModule,
    TranslateModule,
    DividerModule,
    FibrPageComponent,
    FibrPageComponentGridComponent,
    FibrPageComponentListComponent,
  ],
})
export class CustomPageListComponent {
  private pageComponentStore: PageComponentStore = inject(PageComponentStore);
  private tableStore: TableStoreService = inject(TableStoreService);

  private tables = computed(() => {
    return this.tableStore.tableList().data.reduce((acc: Record<string, string>, val) => {
      acc[val.id] = <string>val.label || val.id;
      return acc
    }, {})
  })

  pageComponents = model<BodyItem[]>([]);
  utils = model<{ visible: boolean, selected: BodyItem | null }>({
    visible: false,
    selected: null
  });

  getTableLabel(tableName: string) {
    return this.tables()[tableName];
  }

  addComponent() {
    this.utils.set({visible: true, selected: null});
    this.pageComponentStore.init();
  }

  openComponent(component: BodyItem) {
    this.utils.set({visible: true, selected: component});
    this.pageComponentStore.init(component);
  }

  deleteComponent(component: BodyItem) {
    this.pageComponents.update((val) => {
      return val.filter((x) => x.id !== component.id);
    })
  }

  editOrAddComponent(component: BodyItem) {
    const exist = this.pageComponents().findIndex((x) => x.id === component.id);
    this.pageComponents.update((val) => {
      if (exist !== -1) {
        val[exist] = component;
        return val;
      }
      return val.concat([component]);
    })
    this.utils.set({visible: false, selected: null});
  }

  cancelAddOrEditComponent() {
    this.utils.set({visible: false, selected: null});
    this.pageComponentStore.init();
  }
}
