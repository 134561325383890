import { Component, signal, OnInit, computed, inject, ViewChild } from '@angular/core';
import { HeaderV1Component } from '../../../../core/components/header-v1/header-v1.component';
import { MenuItem, type MenuItemCommandEvent } from 'primeng/api';
import { SubheaderComponent } from '../../../../core/components/subheader/subheader.component';
import { ConfirmationComponent } from '@shared/components/confirmation/confirmation.component';
import { NgClass } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TabMenuModule } from 'primeng/tabmenu';
import { OrderListModule } from 'primeng/orderlist';
import { DataSectionComponent } from '../data-section/data-section.component';
import { PreviewAndPageListSectionComponent } from '../preview-and-page-list-section/preview-and-page-list-section.component';
import { FooterComponent } from '../../../../core/components/footer/footer.component';
import { ActivatedRoute } from '@angular/router';
import { ProjectAPIService } from '../../../project/api/project-api.service';
import { ProjectStoreService } from '../../../project/store/project-store.service';
import { handleErrorTranslationToast } from '@shared/libs/error';
import { TableAPIService } from '../../../table/api/table-api.service';
import { TableStoreService } from '../../../table/store/table-store.service';
import { DialogService, type DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AddTableDialogComponent, type AddTableDialogProps } from '../../../table/ui/add-table-dialog/add-table-dialog.component';
import { EditorAPIService } from '../../api/editor-api.service';
import { BlockUIComponent } from '@shared/components/block-ui/block-ui.component';
import { setToast } from '@shared/components/toast/toast.component';
import { EditorStoreService } from '../../store/editor-store.service';
import { DialogInviteUserComponent } from '../dialog-invite-user/dialog-invite-user.component';
import { AuthStoreService } from '../../../auth/store/auth-store.service';

// the initial 2 item is title and add new table so the initial selected table is 2
export const INITIAL_SELECTED_TABLE_INDEX = 2;

@Component({
  selector: 'fibr-editor-page',
  standalone: true,
  imports: [TabMenuModule, NgClass, OrderListModule, TranslateModule, DataSectionComponent, PreviewAndPageListSectionComponent, HeaderV1Component, SubheaderComponent, ConfirmationComponent, FooterComponent, BlockUIComponent, DialogInviteUserComponent],
  templateUrl: './editor-page.component.html',
  styleUrl: './editor-page.component.scss'
})
export class EditorPageComponent implements OnInit {
  tableAPI = inject(TableAPIService);
  tableStore = inject(TableStoreService);
  dynamicDialog = inject(DialogService);

  selectedView = signal<'data' | 'preview'>('data')
  selectedTableIndex = signal(INITIAL_SELECTED_TABLE_INDEX)
  selectedTable = computed(() => this.tableList()[this.selectedTableIndex() - INITIAL_SELECTED_TABLE_INDEX])
  itemPages = signal<MenuItem[]>([]);
  isBlocked = signal(false);
  dialogInviteUser = signal(false);

  pageList = this.editorStore.pageList

  @ViewChild(SubheaderComponent) opPublishAppSub: SubheaderComponent;
  
  private tableList = computed(() => [...this.tableStore.tableList().data].sort((a, b) => {
    // sort by created_at if both have created_at, if not sort by label / name
    if (a.created_at && b.created_at) {
      return a.created_at.seconds - b.created_at.seconds;
    }
    if (!a.created_at && b.created_at) {
      return -1;
    }
    if (a.created_at && !b.created_at) {
      return 1;
    }
    return (a.label ?? a.id).localeCompare(b.label ?? b.id);
  }))

  private readonly initialSidebarItems: MenuItem[] = [
    {
      id: "_",
      label: "Data",
      styleClass: 'data-nav__header'
    },
    {
      label: this.translate.instant('EDITOR.ADD_DATA'),
      icon: "pi pi-fw pi-plus",
      styleClass: "add-button",
      command: () => {
        this.addNewTable();
      }
    }
  ];

  get sidebarItems() {
    return [...this.initialSidebarItems, ...this.tableList().map((table, index) => ({
      id: table.id,
      label: table.label ?? table.id,
      // TODO handle if table from GSheet
      icon: 'pi pi-fw pi-table',
      styleClass: "sidebar-item",
      command: (table: MenuItemCommandEvent) => {
        this.setSelectedTable(table, index + INITIAL_SELECTED_TABLE_INDEX);
      }
    }))]
  }

  projectID = this.route.snapshot.paramMap.get('id');

  projectDetail = computed(() => this.projectStore.projectDetail())
  isReleased = computed(() => this.projectStore.isReleased())
  planDetail = computed(() => this.projectStore.planDetail())
  role = computed(() => this.projectStore.role())
  tabs = [
    {
      label: "Data",
      icon: "pi pi-fw pi-table",
      command: () => {
        this.selectedView.set('data')
      }
    },
    {
      label: "Preview",
      icon: "pi pi-fw pi-tablet",
      command: () => {
        this.selectedView.set('preview')
      }
    }
  ]

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    private projectAPI: ProjectAPIService,
    private projectStore: ProjectStoreService,
    private editorAPI: EditorAPIService,
    private editorStore: EditorStoreService,
    private authStore: AuthStoreService
  ) { }

  ngOnInit(): void {
    if (!this.projectID) return;
    this.tableAPI.fetchTableList(this.projectID);
    this.projectAPI.getStatusProject(this.projectID);
    this.projectAPI.getRole(this.projectID, this.authStore.user()?.uid ?? "");
    this.projectAPI.fetchProjectDetail(this.projectID)
      .then((res) => {
        if (!res.isSuccess) handleErrorTranslationToast(this.translate)(res.error)
      })
      .catch(handleErrorTranslationToast(this.translate))
  }

  onResetSelectedTable = () => {
    this.selectedTableIndex.set(INITIAL_SELECTED_TABLE_INDEX);
  }

  private setSelectedTable = (table: MenuItemCommandEvent, index: number) => {
    this.selectedTableIndex.set(index)
  }

  private addNewTable() {
    if (!this.projectID) return;
    const dynamicDialogConfig: DynamicDialogConfig<AddTableDialogProps> = {
      header: this.translate.instant("TABLE.ADD_NEW_TABLE"),
      width: "500px",
      contentStyle: { "max-height": "500px", overflow: "auto" },
      styleClass: "dialog-no-footer create-new",
      dismissableMask: true,
      baseZIndex: 10000,
      showHeader: true,
      closable: true,
      data: {
        projectID: this.projectID
      }
    };
    this.dynamicDialog
      .open(AddTableDialogComponent, dynamicDialogConfig)
  }

  async publishApp(){

    if(this.pageList().length > 0){
      this.isBlocked.set(true);
      const result = await this.editorAPI.publishApp(this.projectID!);
      if(result.isSuccess){
        this.isBlocked.set(false);
        this.projectStore.setStatusProject(true);
        setToast(this.translate.instant('EDITOR.SUCCESS_PUBLISH_APP'), 'success');
        this.opPublishAppSub.callOpPublishApp();
      }
    } else {
      setToast(this.translate.instant('EDITOR.ERROR_PUBLISH_NO_PAGE'), 'error');
    }
  }

  save(){
    this.isBlocked.set(true);
    this.isBlocked.set(false);
  }

  onInvite(){
    this.dialogInviteUser.set(true);
  }

  onClose(){
    this.dialogInviteUser.set(false);
  }
}
