<button
  (click)="ref.toggle($event)"
  pButton
  pRipple
  type="button"
  class="p-element p-button-rounded p-button-primary mr-3 p-button-text p-button-icon-only p-button p-component"
>
  <img
    alt="icon"
    src="/assets/images/icon/icon_flag.svg"
    class="h-[20px]"
    *ngIf="selectedLanguage() === 'id'"
  />
  <img
    alt="icon"
    src="/assets/images/icon/icon_flag-us.svg"
    class="h-[20px]"
    *ngIf="selectedLanguage() === 'en'"
  />
</button>
<p-overlayPanel styleClass="p-overlaypanel-language" #ref>
  <ng-template pTemplate>
    <p-listbox
      [options]="items"
      [ngModel]="selectedLanguage()"
      (ngModelChange)="changeLanguage($event)"
    >
      <ng-template let-item pTemplate="item">
        <div class="flex align-items-center gap-2">
          <img class="h-[20px]" [src]="item.icon" alt="country" />
          <span class="text-xl font-bold ml-3">{{ item.label }}</span>
        </div>
      </ng-template>
    </p-listbox>
  </ng-template>
</p-overlayPanel>
