import { Component, OnInit, computed, inject, signal } from '@angular/core';
import { BaseComponent } from '../../../../core/base/base-component';
import { CommonModule } from '@angular/common';
import { HeaderV1Component } from '../../../../core/components/header-v1/header-v1.component';
import { DataViewModule } from 'primeng/dataview';
import { LabelInvoiceComponent } from '../label-invoice/label-invoice.component';
import { BillingApi } from '../../api/billing.api';
import { BillingStore } from '../../store/billing.store';
import { ButtonModule } from 'primeng/button';
import { BillingFilter, Invoice } from '../../model/billing.model';
import { DialogService } from 'primeng/dynamicdialog';
import { PopupInvoiceSubscriptionComponent } from '../popup-invoice/popup-invoice-subscription.component';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { PopupCheckoutSubscriptionComponent } from '../popup-checkout-subscription/popup-checkout-subscription.component';
import { ProjectsShardService } from '../../../../core/projects-shard/projects-shard.service';
import { TranslateModule } from '@ngx-translate/core';
import { BlockUIComponent } from '@shared/components/block-ui/block-ui.component';

@Component({
  selector: 'fibr-billing-page',
  templateUrl: './billing-page.component.html',
  styleUrls: ['./billing-page.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    HeaderV1Component,
    DataViewModule,
    LabelInvoiceComponent,
    DropdownModule,
    InputTextModule,
    CalendarModule,
    FormsModule,
    TranslateModule,
    BlockUIComponent,
  ],
  providers: [BillingApi],
})
export class BillingPageComponent extends BaseComponent implements OnInit {
  private dialog: DialogService = inject(DialogService);
  private confirm: ConfirmationService = inject(ConfirmationService);

  private billingApi: BillingApi = inject(BillingApi);
  private billingStore: BillingStore = inject(BillingStore);
  private projectShard: ProjectsShardService = inject(ProjectsShardService);

  constructor() {
    super('fibr-billing-page');
  }

  filters = signal<BillingFilter>({
    search: '',
    status: '',
    method: '',
    project_id: '',
    orderDate: null,
  });

  invoices = computed(() => this.billingStore.invoices());
  billingProjects = computed(() => this.billingStore.projects());
  billingMethod = computed(() => this.billingStore.methods());
  billingStatus = computed(() => this.billingStore.listStatus());
  needPayment = computed(() => this.billingStore.needPayment());
  totalUnpaid = computed(() => this.billingStore.totalUnpaid());

  openInvoice(invoice: Invoice) {
    this.setStateProcessing();
    this.projectShard.initializeFirebase(invoice.project_id).then(() => {
      this.setStateReady();
      this.dialog.open(PopupInvoiceSubscriptionComponent, {
        data: {
          invoice_id: invoice.id,
          project_id: invoice.project_id,
        },
      });
    });
  }

  private searchTimerId: NodeJS.Timeout | null;
  searchBilling(event: Event) {
    const target = event.target as HTMLInputElement;
    if (this.searchTimerId) {
      clearInterval(this.searchTimerId);
    }

    // eslint-disable-next-line functional/immutable-data
    this.searchTimerId = setTimeout(() => {
      this.billingStore.searchBilling(target.value);
      // eslint-disable-next-line functional/immutable-data
      this.searchTimerId = null;
    }, 400);
  }

  ngOnInit(): void {
    this.getInvoices();
  }

  getInvoices() {
    this.setStateLoading();

    this.billingApi.setupInvoices().then(() => {
      this.setStateReady();
    });
  }

  changeStatus(value: string) {
    this.filters.update((val) => ({
      ...val,
      status: value,
    }));

    this.billingStore.searchBilling(this.filters().search, {
      ...this.filters(),
      status: value,
    });
  }

  changeProject(value: string) {
    this.filters.update((val) => ({
      ...val,
      project_id: value,
    }));

    this.billingStore.searchBilling(this.filters().search, {
      ...this.filters(),
      project_id: this.filters().project_id,
    });
  }

  changeMethod(value: string) {
    this.filters.update((val) => ({
      ...val,
      method: value,
    }));

    this.billingStore.searchBilling(this.filters().search, {
      ...this.filters(),
      method: this.filters().method,
    });
  }

  changeDate(date: Date) {
    this.filters.update((val) => ({
      ...val,
      orderDate: date,
    }));

    this.billingStore.searchBilling(this.filters().search, {
      ...this.filters(),
      orderDate: date,
    });
  }

  extendPayment(invoice: Invoice) {
    this.confirm.confirm({
      header: this.translate('BILLING.EXTEND_PAYMENT.TITLE'),
      message: this.translate('BILLING.EXTEND_PAYMENT.MESSAGE', { project: invoice.project_name }),
      acceptLabel: this.translate('BILLING.EXTEND_PAYMENT.BUTTON'),
      rejectLabel: this.translate('COMMON.CANCEL'),
      rejectButtonStyleClass: 'p-button-secondary2',
      accept: () => {
        this.setStateProcessing();
        this.projectShard.initializeFirebase(invoice.project_id).then(() => {
          this.setStateReady();
          this.dialog.open(PopupCheckoutSubscriptionComponent, {
            data: {
              project_id: invoice.project_id,
              project_name: invoice.project_name,
              from: 'billing',
            },
          });
        });
      },
    });
  }

  renewPayment(invoice: Invoice) {
    this.setStateProcessing();
    this.projectShard.initializeFirebase(invoice.project_id).then(() => {
      this.setStateReady();
      this.dialog.open(PopupCheckoutSubscriptionComponent, {
        data: {
          project_id: invoice.project_id,
          project_name: invoice.project_name,
          from: 'billing',
        },
      });
    });
  }
}
