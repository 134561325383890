import { effect, signal, untracked } from "@angular/core";
import type { z } from "zod";

export const fieldSignal = <T>(field: T) => {
  const value = signal<T>(field);
  const error = signal<string>('');
  effect(() => {
    if (value() && untracked(error) !== '') {
      error.set('');
    }
  }, { allowSignalWrites: true })

  return { value, error };
}

export const getZodRegulerError = <T>(result: z.SafeParseError<T>, fieldName: string) =>
  result.error.errors[0].path[0] === fieldName ? result.error.errors[0].message : ''
