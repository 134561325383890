import { Component } from '@angular/core';

@Component({
  selector: 'fibr-logo-icon',
  standalone: true,
  imports: [],
  template: `
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="126"
    height="29"
    viewBox="0 0 126 29"
    fill="none"
  >
    <path
      d="M5.71042 0C6.46032 0 7.20288 0.13905 7.8957 0.40921C8.58852 0.67937 9.21803 1.07535 9.74829 1.57454C10.2786 2.07373 10.6992 2.66636 10.9862 3.31858C11.2731 3.97081 11.4208 4.66986 11.4208 5.37582V10.7516H5.71042C4.19592 10.7516 2.74346 10.1853 1.67254 9.1771C0.601634 8.16894 2.44162e-06 6.80158 2.44162e-06 5.37582C2.44162e-06 3.95006 0.601634 2.5827 1.67254 1.57454C2.74346 0.566379 4.19592 0 5.71042 0ZM5.71042 13.2814H11.4208V18.6573C11.4208 19.7205 11.0859 20.7599 10.4585 21.6439C9.83099 22.528 8.93914 23.217 7.8957 23.6239C6.85226 24.0307 5.70408 24.1372 4.59637 23.9298C3.48866 23.7224 2.47116 23.2104 1.67254 22.4585C0.873929 21.7067 0.330064 20.7488 0.109727 19.706C-0.110611 18.6632 0.00247423 17.5823 0.434682 16.6C0.86689 15.6177 1.59881 14.7781 2.53788 14.1874C3.47695 13.5967 4.581 13.2814 5.71042 13.2814ZM19.8185 0C21.333 0 22.7855 0.566379 23.8564 1.57454C24.9273 2.5827 25.5289 3.95006 25.5289 5.37582C25.5289 6.80158 24.9273 8.16894 23.8564 9.1771C22.7855 10.1853 21.333 10.7516 19.8185 10.7516H14.1081V5.37582C14.1081 3.95006 14.7097 2.5827 15.7806 1.57454C16.8515 0.566379 18.304 0 19.8185 0ZM14.1081 13.2814H19.8185C20.9479 13.2814 22.052 13.5967 22.991 14.1874C23.9301 14.7781 24.662 15.6177 25.0942 16.6C25.5264 17.5823 25.6395 18.6632 25.4192 19.706C25.1989 20.7488 24.655 21.7067 23.8564 22.4585C23.0578 23.2104 22.0403 23.7224 20.9326 23.9298C19.8248 24.1372 18.6767 24.0307 17.6332 23.6239C16.5898 23.217 15.6979 22.528 15.0705 21.6439C14.443 20.7599 14.1081 19.7205 14.1081 18.6573V13.2814Z"
      fill="#4394D8"
    />
    <path
      d="M30.3692 16.5099C30.3692 15.3711 30.5635 14.3318 30.9521 13.3919C31.3408 12.4519 31.8695 11.6431 32.5383 10.9652C33.2071 10.2964 33.9933 9.77675 34.8971 9.4062C35.7919 9.03565 36.7544 8.85038 37.7847 8.85038C38.806 8.85038 39.7685 9.04017 40.6723 9.41976C41.567 9.79934 42.3533 10.3281 43.0311 11.0059C43.7887 11.0059 44.7062 11.0059 45.2002 11.0059C45.2002 12.0262 45.2002 15.3892 45.2002 16.5099V24.1864H41.4857V22.6375C41.2868 22.8906 41.0473 23.1165 40.7672 23.3153C40.4779 23.5142 40.1707 23.6769 39.8453 23.8034C39.5109 23.9299 39.1675 24.0248 38.815 24.0881C38.4625 24.1604 38.1191 24.1965 37.7847 24.1965C36.7544 24.1965 35.7919 24.0022 34.8971 23.6136C33.9933 23.234 33.2071 22.7008 32.5383 22.0139C31.8695 21.327 31.3408 20.5136 30.9521 19.5737C30.5635 18.6428 30.3692 17.6215 30.3692 16.5099ZM34.0973 16.5099C34.0973 17.0702 34.1967 17.5899 34.3955 18.0689C34.5853 18.557 34.8474 18.9772 35.1818 19.3297C35.5162 19.6822 35.9094 19.9578 36.3612 20.1566C36.8041 20.3555 37.2786 20.4549 37.7847 20.4549C38.2908 20.4549 38.7698 20.3419 39.2217 20.116C39.6645 19.8991 40.0532 19.6053 40.3876 19.2348C40.722 18.8733 40.9841 18.453 41.1739 17.974C41.3637 17.5041 41.4585 17.016 41.4585 16.5099C41.4585 15.9496 41.3637 15.4299 41.1739 14.9509C40.9841 14.4719 40.722 14.0561 40.3876 13.7037C40.0532 13.3512 39.6645 13.071 39.2217 12.8632C38.7698 12.6643 38.2908 12.5649 37.7847 12.5649C37.2786 12.5649 36.8041 12.6643 36.3612 12.8632C35.9094 13.071 35.5162 13.3512 35.1818 13.7037C34.8474 14.0561 34.5853 14.4719 34.3955 14.9509C34.1967 15.4299 34.0973 15.9496 34.0973 16.5099Z"
      fill="#4394D8"
    />
    <path
      d="M30.3692 16.5367C30.3692 17.6755 30.5635 18.7148 30.9521 19.6548C31.3408 20.5947 31.8695 21.4036 32.5383 22.0814C33.2071 22.7502 33.9933 23.2699 34.8971 23.6404C35.7919 24.011 36.7544 24.1963 37.7847 24.1963C38.806 24.1963 39.7685 24.0065 40.6723 23.6269C41.567 23.2473 42.3533 22.7186 43.0311 22.0407C43.7887 22.0407 44.7062 22.0407 45.2002 22.0407C45.2002 21.0204 45.2002 17.6574 45.2002 16.5367V8.8602H41.4857V10.4091C41.2868 10.1561 41.0473 9.93011 40.7672 9.73128C40.4779 9.53245 40.1707 9.36977 39.8453 9.24324C39.5109 9.11671 39.1675 9.02182 38.815 8.95855C38.4625 8.88625 38.1191 8.8501 37.7847 8.8501C36.7544 8.8501 35.7919 9.04441 34.8971 9.43303C33.9933 9.81262 33.2071 10.3459 32.5383 11.0327C31.8695 11.7196 31.3408 12.533 30.9521 13.4729C30.5635 14.4038 30.3692 15.4251 30.3692 16.5367ZM34.0973 16.5367C34.0973 15.9764 34.1967 15.4567 34.3955 14.9777C34.5853 14.4897 34.8474 14.0694 35.1818 13.7169C35.5162 13.3645 35.9094 13.0888 36.3612 12.89C36.8041 12.6912 37.2786 12.5917 37.7847 12.5917C38.2908 12.5917 38.7698 12.7047 39.2217 12.9307C39.6645 13.1476 40.0532 13.4413 40.3876 13.8118C40.722 14.1734 40.9841 14.5936 41.1739 15.0726C41.3637 15.5426 41.4585 16.0306 41.4585 16.5367C41.4585 17.0971 41.3637 17.6167 41.1739 18.0957C40.9841 18.5748 40.722 18.9905 40.3876 19.343C40.0532 19.6954 39.6645 19.9756 39.2217 20.1835C38.7698 20.3823 38.2908 20.4817 37.7847 20.4817C37.2786 20.4817 36.8041 20.3823 36.3612 20.1835C35.9094 19.9756 35.5162 19.6954 35.1818 19.343C34.8474 18.9905 34.5853 18.5748 34.3955 18.0957C34.1967 17.6167 34.0973 17.0971 34.0973 16.5367Z"
      fill="#4394D8"
    />
    <path
      d="M61.7275 16.5092C61.7275 15.3705 61.5332 14.3311 61.1446 13.3912C60.7559 12.4513 60.2272 11.6424 59.5584 10.9646C58.8896 10.2958 58.1033 9.77609 57.1996 9.40554C56.3048 9.03499 55.3423 8.84972 54.312 8.84972C53.2907 8.84972 52.3282 9.03951 51.4244 9.4191C50.5297 9.79869 49.7434 10.3274 49.0656 11.0052C48.308 11.0052 47.3905 11.0052 46.8965 11.0052C46.8965 12.0256 46.8965 15.3886 46.8965 16.5092V28.9993H50.611V22.6369C50.8098 22.8899 51.0494 23.1159 51.3295 23.3147C51.6187 23.5135 51.926 23.6762 52.2514 23.8027C52.5858 23.9293 52.9292 24.0242 53.2817 24.0874C53.6342 24.1597 53.9776 24.1959 54.312 24.1959C55.3423 24.1959 56.3048 24.0016 57.1996 23.6129C58.1033 23.2334 58.8896 22.7001 59.5584 22.0132C60.2272 21.3264 60.7559 20.513 61.1446 19.573C61.5332 18.6422 61.7275 17.6209 61.7275 16.5092ZM57.9994 16.5092C57.9994 17.0696 57.9 17.5893 57.7012 18.0683C57.5114 18.5563 57.2493 18.9766 56.9149 19.329C56.5805 19.6815 56.1873 19.9572 55.7354 20.156C55.2926 20.3548 54.8181 20.4542 54.312 20.4542C53.8059 20.4542 53.3269 20.3413 52.875 20.1153C52.4321 19.8984 52.0435 19.6047 51.7091 19.2341C51.3747 18.8726 51.1126 18.4524 50.9228 17.9734C50.733 17.5034 50.6381 17.0154 50.6381 16.5092C50.6381 15.9489 50.733 15.4292 50.9228 14.9502C51.1126 14.4712 51.3747 14.0555 51.7091 13.703C52.0435 13.3505 52.4321 13.0704 52.875 12.8625C53.3269 12.6637 53.8059 12.5642 54.312 12.5642C54.8181 12.5642 55.2926 12.6637 55.7354 12.8625C56.1873 13.0704 56.5805 13.3505 56.9149 13.703C57.2493 14.0555 57.5114 14.4712 57.7012 14.9502C57.9 15.4292 57.9994 15.9489 57.9994 16.5092Z"
      fill="#4394D8"
    />
    <path
      d="M61.7275 16.5367C61.7275 17.6755 61.5332 18.7148 61.1446 19.6548C60.7559 20.5947 60.2272 21.4036 59.5584 22.0814C58.8896 22.7502 58.1033 23.2699 57.1996 23.6404C56.3048 24.011 55.3423 24.1963 54.312 24.1963C53.2907 24.1963 52.3282 24.0065 51.4244 23.6269C50.5297 23.2473 49.7434 22.7186 49.0656 22.0407C48.308 22.0407 47.3905 22.0407 46.8965 22.0407C46.8965 21.0204 46.8965 17.6574 46.8965 16.5367V8.8602H50.611V10.4091C50.8098 10.1561 51.0494 9.93011 51.3295 9.73128C51.6187 9.53245 51.926 9.36977 52.2514 9.24324C52.5858 9.11671 52.9292 9.02182 53.2817 8.95855C53.6342 8.88625 53.9776 8.8501 54.312 8.8501C55.3423 8.8501 56.3048 9.04441 57.1996 9.43303C58.1033 9.81262 58.8896 10.3459 59.5584 11.0327C60.2272 11.7196 60.7559 12.533 61.1446 13.4729C61.5332 14.4038 61.7275 15.4251 61.7275 16.5367ZM57.9994 16.5367C57.9994 15.9764 57.9 15.4567 57.7012 14.9777C57.5114 14.4897 57.2493 14.0694 56.9149 13.7169C56.5805 13.3645 56.1873 13.0888 55.7354 12.89C55.2926 12.6912 54.8181 12.5917 54.312 12.5917C53.8059 12.5917 53.3269 12.7047 52.875 12.9307C52.4321 13.1476 52.0435 13.4413 51.7091 13.8118C51.3747 14.1734 51.1126 14.5936 50.9228 15.0726C50.733 15.5426 50.6381 16.0306 50.6381 16.5367C50.6381 17.0971 50.733 17.6167 50.9228 18.0957C51.1126 18.5748 51.3747 18.9905 51.7091 19.343C52.0435 19.6954 52.4321 19.9756 52.875 20.1835C53.3269 20.3823 53.8059 20.4817 54.312 20.4817C54.8181 20.4817 55.2926 20.3823 55.7354 20.1835C56.1873 19.9756 56.5805 19.6954 56.9149 19.343C57.2493 18.9905 57.5114 18.5748 57.7012 18.0957C57.9 17.6167 57.9994 17.0971 57.9994 16.5367Z"
      fill="#4394D8"
    />
    <path
      d="M78.2304 16.5097C78.2304 15.371 78.0361 14.3316 77.6475 13.3917C77.2589 12.4518 76.7302 11.6429 76.0614 10.9651C75.3926 10.2963 74.6063 9.7766 73.7025 9.40605C72.8078 9.0355 71.8452 8.85023 70.8149 8.85023C69.7937 8.85023 68.8311 9.04002 67.9274 9.4196C67.0326 9.79919 66.2463 10.3279 65.5685 11.0057C64.8109 11.0057 63.8935 11.0057 63.3994 11.0057C63.3994 12.0261 63.3994 15.3891 63.3994 16.5097L63.3994 28.9998H67.1139L67.114 22.6374C67.3128 22.8904 67.5523 23.1164 67.8325 23.3152C68.1217 23.514 68.429 23.6767 68.7543 23.8032C69.0887 23.9298 69.4322 24.0247 69.7846 24.0879C70.1371 24.1602 70.4805 24.1964 70.8149 24.1964C71.8452 24.1964 72.8078 24.0021 73.7025 23.6134C74.6063 23.2339 75.3926 22.7006 76.0614 22.0138C76.7302 21.3269 77.2589 20.5135 77.6475 19.5736C78.0361 18.6427 78.2304 17.6214 78.2304 16.5097ZM74.5024 16.5097C74.5024 17.0701 74.4029 17.5898 74.2041 18.0688C74.0143 18.5568 73.7522 18.9771 73.4178 19.3295C73.0834 19.682 72.6903 19.9577 72.2384 20.1565C71.7955 20.3553 71.3211 20.4547 70.8149 20.4547C70.3088 20.4547 69.8298 20.3418 69.3779 20.1158C68.9351 19.8989 68.5465 19.6052 68.2121 19.2346C67.8777 18.8731 67.6156 18.4529 67.4258 17.9739C67.236 17.5039 67.1411 17.0159 67.1411 16.5097C67.1411 15.9494 67.236 15.4297 67.4258 14.9507C67.6156 14.4717 67.8777 14.056 68.2121 13.7035C68.5465 13.351 68.9351 13.0709 69.3779 12.863C69.8298 12.6642 70.3088 12.5648 70.8149 12.5648C71.3211 12.5648 71.7955 12.6642 72.2384 12.863C72.6903 13.0709 73.0834 13.351 73.4178 13.7035C73.7522 14.056 74.0143 14.4717 74.2041 14.9507C74.4029 15.4297 74.5024 15.9494 74.5024 16.5097Z"
      fill="#4394D8"
    />
    <path
      d="M78.2304 16.5367C78.2304 17.6755 78.0361 18.7148 77.6475 19.6548C77.2589 20.5947 76.7301 21.4036 76.0614 22.0814C75.3926 22.7502 74.6063 23.2699 73.7025 23.6404C72.8078 24.011 71.8452 24.1963 70.8149 24.1963C69.7936 24.1963 68.8311 24.0065 67.9273 23.6269C67.0326 23.2473 66.2463 22.7186 65.5685 22.0407C64.8109 22.0407 63.8934 22.0407 63.3994 22.0407C63.3994 21.0204 63.3994 17.6574 63.3994 16.5367V8.8602H67.1139V10.4091C67.3128 10.1561 67.5523 9.93011 67.8325 9.73128C68.1217 9.53245 68.4289 9.36977 68.7543 9.24324C69.0887 9.11671 69.4321 9.02182 69.7846 8.95855C70.1371 8.88625 70.4805 8.8501 70.8149 8.8501C71.8452 8.8501 72.8078 9.04441 73.7025 9.43303C74.6063 9.81262 75.3926 10.3459 76.0614 11.0327C76.7301 11.7196 77.2589 12.533 77.6475 13.4729C78.0361 14.4038 78.2304 15.4251 78.2304 16.5367ZM74.5023 16.5367C74.5023 15.9764 74.4029 15.4567 74.2041 14.9777C74.0143 14.4897 73.7522 14.0694 73.4178 13.7169C73.0834 13.3645 72.6903 13.0888 72.2384 12.89C71.7955 12.6912 71.321 12.5917 70.8149 12.5917C70.3088 12.5917 69.8298 12.7047 69.3779 12.9307C68.9351 13.1476 68.5464 13.4413 68.212 13.8118C67.8776 14.1734 67.6155 14.5936 67.4258 15.0726C67.236 15.5426 67.1411 16.0306 67.1411 16.5367C67.1411 17.0971 67.236 17.6167 67.4258 18.0957C67.6155 18.5748 67.8776 18.9905 68.212 19.343C68.5464 19.6954 68.9351 19.9756 69.3779 20.1835C69.8298 20.3823 70.3088 20.4817 70.8149 20.4817C71.321 20.4817 71.7955 20.3823 72.2384 20.1835C72.6903 19.9756 73.0834 19.6954 73.4178 19.343C73.7522 18.9905 74.0143 18.5748 74.2041 18.0957C74.4029 17.6167 74.5023 17.0971 74.5023 16.5367Z"
      fill="#4394D8"
    />
    <path
      d="M95.8484 24.1921H92.1083V10.2064H95.8484V24.1921Z"
      fill="#084B83"
    />
    <path
      d="M113.27 17.07C113.27 18.2125 113.075 19.2551 112.686 20.1981C112.296 21.1411 111.765 21.9525 111.094 22.6326C110.423 23.3035 109.635 23.8249 108.728 24.1966C107.83 24.5683 106.865 24.7542 105.831 24.7542C104.806 24.7542 103.841 24.5638 102.934 24.183C102.037 23.8022 101.248 23.2718 100.568 22.5918C99.8967 21.9117 99.3663 21.1048 98.9764 20.1709C98.5866 19.2279 98.3916 18.1943 98.3916 17.07V4.41265H102.118V10.9227C102.318 10.6688 102.558 10.4421 102.839 10.2427C103.129 10.0432 103.437 9.87999 103.764 9.75305C104.099 9.62611 104.444 9.53091 104.797 9.46744C105.151 9.39491 105.496 9.35864 105.831 9.35864C106.865 9.35864 107.83 9.55358 108.728 9.94346C109.635 10.3243 110.423 10.8592 111.094 11.5483C111.765 12.2374 112.296 13.0534 112.686 13.9964C113.075 14.9302 113.27 15.9548 113.27 17.07ZM109.53 17.07C109.53 16.5079 109.431 15.9865 109.231 15.506C109.041 15.0164 108.778 14.5948 108.442 14.2412C108.107 13.8876 107.712 13.611 107.259 13.4115C106.815 13.2121 106.339 13.1123 105.831 13.1123C105.323 13.1123 104.843 13.2257 104.389 13.4523C103.945 13.6699 103.555 13.9646 103.22 14.3364C102.884 14.699 102.621 15.1206 102.431 15.6012C102.241 16.0727 102.145 16.5623 102.145 17.07C102.145 17.6322 102.241 18.1535 102.431 18.6341C102.621 19.1146 102.884 19.5317 103.22 19.8853C103.555 20.2389 103.945 20.52 104.389 20.7285C104.843 20.928 105.323 21.0277 105.831 21.0277C106.339 21.0277 106.815 20.928 107.259 20.7285C107.712 20.52 108.107 20.2389 108.442 19.8853C108.778 19.5317 109.041 19.1146 109.231 18.6341C109.431 18.1535 109.53 17.6322 109.53 17.07Z"
      fill="#084B83"
    />
    <path
      d="M119.051 24.1921H115.338V9.33597H116.235H117.822C117.822 9.97519 118.837 9.97519 119.418 9.68505C119.998 9.39491 121.05 9.36317 121.866 9.36317H125.143V13.0625H121.866C121.476 13.0625 121.109 13.135 120.764 13.2801C120.42 13.4251 120.12 13.6246 119.867 13.8785C119.613 14.1324 119.413 14.4316 119.268 14.7761C119.123 15.1206 119.051 15.4879 119.051 15.8777V24.1921Z"
      fill="#084B83"
    />
    <path
      d="M84.3516 24.1785H80.6387L80.6387 14.6537L80.6387 10.9544L80.6387 10.2608C80.6387 9.36317 80.8064 8.51995 81.1419 7.73113C81.4864 6.94231 81.9534 6.25777 82.5427 5.67748C83.1411 5.08814 83.8348 4.62572 84.6236 4.29025C85.4124 3.94571 86.2556 3.77344 87.1532 3.77344H89.9413V7.47273H87.1532C86.7543 7.47273 86.3826 7.54526 86.038 7.69033C85.7025 7.82634 85.4079 8.02127 85.154 8.27515C84.9092 8.51995 84.7142 8.81463 84.5692 9.15917C84.4241 9.49464 84.3516 9.86185 84.3516 10.2608V10.9544H88.9077V14.6537H84.3516L84.3516 24.1785Z"
      fill="#084B83"
    />
    <path
      d="M96.3049 7.21432C96.4409 6.91511 96.5089 6.59324 96.5089 6.2487C96.5089 5.91322 96.4409 5.59588 96.3049 5.29667C96.178 4.9884 96.0012 4.72546 95.7745 4.50786C95.5478 4.28118 95.2803 4.10438 94.9721 3.97744C94.6729 3.84144 94.351 3.77344 94.0064 3.77344C93.6619 3.77344 93.3355 3.84144 93.0272 3.97744C92.728 4.10438 92.4651 4.28118 92.2384 4.50786C92.0208 4.72546 91.844 4.9884 91.708 5.29667C91.581 5.59588 91.5176 5.91322 91.5176 6.2487C91.5176 6.59324 91.581 6.91511 91.708 7.21432C91.844 7.51353 92.0208 7.77647 92.2384 8.00314C92.4651 8.22075 92.728 8.39755 93.0272 8.53355C93.3355 8.66049 93.6619 8.72396 94.0064 8.72396C94.351 8.72396 94.6729 8.66049 94.9721 8.53355C95.2803 8.39755 95.5478 8.22075 95.7745 8.00314C96.0012 7.77647 96.178 7.51353 96.3049 7.21432Z"
      fill="#F34819"
    />
  </svg>
  `,
  styles: ``
})
export class LogoIconComponent {

}
