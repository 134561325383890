import { NgClass } from '@angular/common';
import { Component, effect, signal } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastModule, type ToastPositionType } from 'primeng/toast';


export type ToastType = 'success' | 'error' | 'info';

const toast = signal({
  message: '',
  type: 'success' as ToastType,
  position: 'bottom-left' as ToastPositionType
});

// TODO move to service
type SetToast = (message: string, type: ToastType, position?: ToastPositionType ) => void;

export const setToast: SetToast = (message, type, position = 'bottom-left') => {
  toast.set({
    message, type, position
  });
}
@Component({
  selector: 'fibr-toast',
  standalone: true,
  imports: [NgClass, ToastModule],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
  providers: [MessageService]
})

export class ToastComponent {
  show = signal(false);

  constructor(private toastMessage: MessageService) {
    effect(() => {
      if (toast().message) {
        this.toastMessage.add({
          severity: toast().type,
          detail: toast().message,
          life: 5000,
          closable: true,
        })
      }
    }, { allowSignalWrites: true })
  }

  get position() {
    return toast().position
  }

  get message() {
    return toast().message;
  }

  get toastClass() {
    switch (toast().type) {
      case 'success':
        return 'bg-green-500';
      case 'error':
        return 'bg-red-500';
      case 'info':
        return 'bg-blue-500';
      default:
        return 'bg-gray-500';
    }
  }
}
