import { Component, AfterViewInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';

type InitializeEngineParams =
  { hostElement: HTMLElement, assetBase: string }

type AppRunner = {
  runApp: () => Promise<void>;
}

type EngineInitializer = {
  initializeEngine: ({ hostElement, assetBase }: InitializeEngineParams ) => AppRunner;
}

type LoadEntrypointParams = {
  entrypointUrl: string;
  onEntrypointLoaded: (engineInitializer: EngineInitializer) => void
}

export type FlutterState = {
  setCredentials: (credentials: string) => void;
  setPageId: (id: string) => void;
  getPageId: () => string;
  onPageChanged: (pageChanged: unknown) => void;
}

// The global flutterSetup namespace
declare const _flutter: {
  loader: {
    loadEntrypoint: (loadEntrypointParams: LoadEntrypointParams) => void
  }
};
declare const window: {
  _debug: unknown;
  __debug: unknown;
};

@Component({
  selector: 'fibr-ng-flutter',
  standalone: true,
  template: `
  <div #flutterTarget>
    <div class="spinner">

    </div>
  </div>
  `,
  styles: [`
    :host div {
      width: 100%;
      height: 100%;
    }
    .spinner {
      display: flex;
      justify-content: center;
      align-items: center;
    }`,
  ],
  imports: [],
})
export class NgFlutterComponent implements AfterViewInit {
  // The target that will host the Flutter app.
  @ViewChild('flutterTarget') flutterTarget!: ElementRef;

  @Output() appLoaded: EventEmitter<FlutterState> = new EventEmitter<FlutterState>();
  @Output() pageLoaded: EventEmitter<FlutterState> = new EventEmitter<FlutterState>();

  appVersion = '';
  flutterAsseturl = environment.flutterAssetUrl + environment.flutterBundleVersion + '/';

  constructor(private route:ActivatedRoute) {}

  ngAfterViewInit(): void {

    this.route.queryParams.subscribe(params => {
      this.appVersion = params['appVersion'];
    });

    if (this.appVersion !== undefined && this.appVersion !== ''){
      this.flutterAsseturl = environment.flutterAssetUrl + this.appVersion + '/';
    }
    const target: HTMLElement = this.flutterTarget.nativeElement;
    _flutter.loader.loadEntrypoint({
      entrypointUrl: this.flutterAsseturl + 'main.dart.js',
      onEntrypointLoaded: async (engineInitializer: EngineInitializer) => {
        const appRunner = await engineInitializer.initializeEngine({
          hostElement: target,
          assetBase: this.flutterAsseturl
        });
        await appRunner.runApp();
      }
    });

    target.addEventListener("flutter-initialized", (event: Event) => {
      const state = (event as CustomEvent).detail;
      window._debug = state;
      this.appLoaded.emit(state);
    }, {
      once: true,
    });
    target.addEventListener("flutter-home-page", (event: Event) => {
      const state = (event as CustomEvent).detail;
      window.__debug = state;
      this.pageLoaded.emit(state);
    }, {
      once: true,
    });
  }
}
