import { jwtDecode } from 'jwt-decode';
import type { MiliSecond, Second } from '../date-time';

export const isTokenExpired = (token:string) => {
  try {
    const decoded = jwtDecode(token);
    const expired: Second | undefined = decoded.exp;

    if (!decoded || !expired) return true;

    const dateNow = new Date();
    const tokenExpirationDate = new Date(expired * 1000 as MiliSecond);
    return tokenExpirationDate < dateNow;
  } catch (err) {
    return true;
  }
}
