<p-sidebar
  #sidebar
  styleClass="page-option"
  [visible]="visible"
  appendTo="body"
  [blockScroll]="true"
  [modal]="false"
  position="right"
>
    <p-card>
        <ng-template pTemplate="header">
            <div class="page-header">
                <p-card styleClass="card-details">
                <div class="grid align-items-center">
                    <b class="capitalize">Json Config</b>
                </div>
                </p-card>
            </div>
            <div class="right-action">
                <p-button
                type="button"
                label=""
                icon="pi pi-times"
                styleClass="p-button-secondary p-button-text"
                pTooltip="Cancel"
                tooltipPosition="bottom"
                (onClick)="onCancel()"
                ></p-button>
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div class="grid">
                <div class="col-12">
                    <p class="label-info">Body</p>
                    <json-editor [options]="editorOptions" [data]="component" (change)="changeLog($event)"></json-editor>
                    
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer" >
            <div class="grid">
                <div class="col-12 text-left">
                    <div class="flex flex-row gap-3">
                        <p-button
                            type="button"
                            label="Update"
                            styleClass="p-button-label"
                            (onClick)="onSave()"
                        ></p-button>
                        <p-button
                            type="button"
                            label="Cancel"
                            styleClass="p-button-secondary2"
                            (onClick)="cancelUpdate()"
                        ></p-button>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-card>
</p-sidebar>
<fibr-block-ui [isBlocked]="isBlocked()" />