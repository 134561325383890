import { Injectable, computed, signal } from '@angular/core';
import { Invoice } from '../model/billing.model';
import { AnyOrama, search, create, insertMultiple } from '@orama/orama';
import { formatDate } from '@angular/common';
import { removeEmptyObject } from '@shared/libs/remove-empty';

interface IBillingStore {
  invoice: Invoice | null;
  database: AnyOrama | null;
  raw_invoices: Invoice[];
  invoices: Invoice[];
  need_payment: Invoice[];
  total_unpaid: number;
  options: {
    projects: { label: string; value: string }[];
    methods: string[];
    status: { label: string; value: string }[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class BillingStore {
  private _store = signal<IBillingStore>({
    invoice: null,
    database: null,
    raw_invoices: [],
    invoices: [],
    need_payment: [],
    total_unpaid: 0,
    options: {
      projects: [],
      methods: [],
      status: [],
    },
  });

  constructor() {
    this.createDatabase();
  }

  invoice = computed(() => this._store().invoice);
  invoices = computed(() => this._store().invoices);

  projects = computed(() => this._store().options.projects);
  methods = computed(() => this._store().options.methods);
  listStatus = computed(() => this._store().options.status);
  needPayment = computed(() => this._store().need_payment);
  totalUnpaid = computed(() => this._store().total_unpaid);

  private async createDatabase() {
    const database = await create({
      schema: {
        id: 'string',
        project_id: 'string',
        amount: 'string',
        description: 'string',
        status: 'string',
        created_at: 'string',
        expired_at: 'string',
        paid_at: 'string',
        user: 'string',
      },
      language: 'indonesian',
    });

    this._store.update((val) => ({
      ...val,
      database,
    }));
  }

  setInvoice(invoice: Invoice) {
    this._store.update((val) => ({
      ...val,
      invoice,
    }));
  }

  setInvoices(invoices: Invoice[]) {
    insertMultiple(
      <AnyOrama>this._store().database,
      invoices.map((each) => ({
        id: each.id,
        project_id: each.project_id,
        amount: each.amount.toString(),
        description: each.description,
        status: each.status,
        created_at: formatDate(each.created_at, 'ddMMyyyy', 'en-US'),
        expired_at: each.expired_at ? formatDate(each.expired_at, 'dd/MM/yyyy', 'en-US') : '',
        // prettier-ignore
        paid_at: each.paid_at ? formatDate(each.paid_at, 'dd/MM/yyyy', 'en-US') : '',
        user: each.user,
      }))
    );

    this._store.update((val) => ({
      ...val,
      raw_invoices: invoices,
      invoices,
    }));
  }

  setBillingOptions(options: IBillingStore['options']) {
    this._store.update((val) => ({
      ...val,
      options,
    }));
  }

  setNeedPayment(invoices: Invoice[]) {
    this._store.update((val) => ({
      ...val,
      need_payment: invoices,
    }))
  }

  setTotalUnpaidBilling(total: number) {
    this._store.update((val) => ({
      ...val,
      total_unpaid: total,
    }))
  }

  async searchBilling(
    text: string,
    options?: {
      status: string;
      project_id: string;
      method: string;
      orderDate: Date | null;
    }
  ) {
    const billingWhere = {};

    if (options?.status) {
      Object.assign(billingWhere, {
        status: options?.status || null,
      });
    }

    if (options?.project_id) {
      Object.assign(billingWhere, {
        project_id: options?.project_id || null,
      });
    }

    
    if (options?.orderDate) {
      Object.assign(billingWhere, {
        created_at: options?.orderDate ? formatDate(<Date>options?.orderDate, 'ddMMyyyy', 'en-US') : null,
      });
    }

    const result = await search(<AnyOrama>this._store().database, {
      term: text,
      where: removeEmptyObject(billingWhere),
    });

    const resultIds = result.hits.map((hit) => {
      const selected = this._store().raw_invoices.filter((x) =>
        x.id.includes(hit.id)
      );

      return selected[0];
    });

    if (options?.status || options?.project_id || options?.orderDate || text) {
      this._store.update((val) => ({
        ...val,
        invoices: resultIds,
      }));
      return;
    }

    this._store.update((val) => ({
      ...val,
      invoices: val.raw_invoices,
    }));
  }
}
