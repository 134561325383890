import { CommonModule } from '@angular/common';
import { Component, computed, effect, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { FibrBaseComponentTypeComponent } from '../../../page-component/page-component-types/base-component-type.component';
import { DataSource } from '../../../../model';
import image from '@shared/libs/generators/widgets/image';
import { v4 } from 'uuid';
import { FibrCollapseComponent } from '@shared/components/collapse/collapse.component';

@Component({
  selector: 'fibr-component-image',
  templateUrl: './page-component-image.component.html',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    ReactiveFormsModule,
    FormsModule,
    InputTextModule,
    FibrCollapseComponent,
  ],
})
export class FibrPageComponentImageComponent extends FibrBaseComponentTypeComponent {
  override label: string = 'Image';
  override icon: string = '/assets/images/icon/details/icon_image.svg';
  override type: string = 'image';
  override category: string = 'content';

  columnImage = computed(() => {
    return this.pageComponentStore.tableColumns().filter(col => col.format === 'url');
  });

  tableSource = ['table'];
  dataSource = signal<DataSource>({
    type: 'table',
    value: '',
  });

  constructor() {
    super('fibr-component-image');

    effect(
      () => {
        const selected = this.pageComponentStore.dialogDetail().selected;
        if (selected) {
          this.dataSource.set(<DataSource>selected.data_source);
        }
      },
      { allowSignalWrites: true }
    );
  }

  init() {
    this.dataSource.set({
      value: '',
      type: 'table',
    });
  }

  onSourceChange(type: string) {
    if (type === 'custom') {
      this.dataSource.update((val) => ({
        ...val,
        value: '',
      }));
    }
  }

  reset() {
    setTimeout(() => {
      this.init();
      this.pageComponentStore.closeDetail();
    }, 200);
  }

  override apply(): void {
    const output = {
      ...image({
        style: {
          corner_radius: 16,
          fit: 'fit_width',
          aspect_ratio: '1:1',
          image_style: 'square',
        },
        tableConfig: this.dataSource(),
      }),
      id: this.pageComponentStore.dialogDetail().selected?.id || v4(),
    };

    this.pageComponentStore.addOrEditDetail(output);
    this.reset();
  }

  override cancel(): void {
    this.reset();
  }

  protected override onDestroy(): void {
    this.reset();
  }
}
