import { Component } from '@angular/core';

@Component({
  selector: 'fibr-booking-icon',
  standalone: true,
  imports: [],
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
    >
      <path
        d="M34.7126 2.72904H29.2545V1.36452C29.2545 1.00263 29.1108 0.655555 28.8549 0.399658C28.599 0.143761 28.2519 0 27.89 0C27.5281 0 27.181 0.143761 26.9251 0.399658C26.6692 0.655555 26.5255 1.00263 26.5255 1.36452V2.72904H12.8803V1.36452C12.8803 1.00263 12.7365 0.655555 12.4806 0.399658C12.2247 0.143761 11.8777 0 11.5158 0C11.1539 0 10.8068 0.143761 10.5509 0.399658C10.295 0.655555 10.1512 1.00263 10.1512 1.36452V2.72904H4.69317C3.60749 2.72904 2.56628 3.16032 1.79858 3.92801C1.03089 4.6957 0.599609 5.73692 0.599609 6.8226V34.113C0.599609 35.1987 1.03089 36.2399 1.79858 37.0076C2.56628 37.7753 3.60749 38.2065 4.69317 38.2065H34.7126C35.7983 38.2065 36.8395 37.7753 37.6072 37.0076C38.3749 36.2399 38.8061 35.1987 38.8061 34.113V6.8226C38.8061 5.73692 38.3749 4.6957 37.6072 3.92801C36.8395 3.16032 35.7983 2.72904 34.7126 2.72904ZM4.69317 5.45808H34.7126C35.0745 5.45808 35.4216 5.60184 35.6775 5.85774C35.9333 6.11363 36.0771 6.4607 36.0771 6.8226V10.9162H3.32865V6.8226C3.32865 6.4607 3.47241 6.11363 3.72831 5.85774C3.9842 5.60184 4.33127 5.45808 4.69317 5.45808ZM34.7126 35.4775H4.69317C4.33127 35.4775 3.9842 35.3337 3.72831 35.0778C3.47241 34.8219 3.32865 34.4749 3.32865 34.113V13.6452H36.0771V34.113C36.0771 34.4749 35.9333 34.8219 35.6775 35.0778C35.4216 35.3337 35.0745 35.4775 34.7126 35.4775Z"
        fill="#8A8A8A"
      />
      <path
        d="M25.6394 18.0672L17.0429 25.4356L13.8418 22.2358C13.5844 21.9872 13.2397 21.8497 12.882 21.8528C12.5242 21.8559 12.1819 21.9994 11.929 22.2524C11.676 22.5054 11.5325 22.8476 11.5293 23.2054C11.5262 23.5632 11.6638 23.9079 11.9123 24.1652L16.0059 28.2588C16.2489 28.5015 16.5746 28.6436 16.9178 28.6568C17.2611 28.6699 17.5966 28.5531 17.8575 28.3297L27.4092 20.1426C27.553 20.0286 27.6723 19.8867 27.76 19.7255C27.8477 19.5643 27.9019 19.387 27.9195 19.2044C27.9371 19.0217 27.9177 18.8373 27.8624 18.6624C27.8071 18.4874 27.7171 18.3253 27.5977 18.1859C27.4783 18.0465 27.3321 17.9327 27.1677 17.8511C27.0033 17.7696 26.8241 17.722 26.6409 17.7113C26.4577 17.7006 26.2742 17.727 26.1014 17.7888C25.9286 17.8507 25.7701 17.9467 25.6353 18.0713L25.6394 18.0672Z"
        fill="#8A8A8A"
      />
    </svg>
  `,
  styles: ``,
})
export class BookingIconComponent {}
