import { Component, OnInit, computed, inject, signal } from '@angular/core';
import { BaseComponent } from '../../../../core/base/base-component';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { PackageApi } from '../../api/packages.api';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ListboxModule } from 'primeng/listbox';
import { PackageStore } from '../../store/package.store';
import { FormsModule } from '@angular/forms';
import { PackageSubscription } from '../../model/package.model';
import { ProjectStoreService } from '../../../project/store/project-store.service';
import { DividerModule } from 'primeng/divider';
import { SummaryOrder } from '../../model/billing.model';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { VoucherApi } from '../../api/voucher.api';
import { HttpErrorResponse } from '@angular/common/http';
import { setToast } from '@shared/components/toast/toast.component';
import { BillingApi } from '../../api/billing.api';
import { PopupInvoiceSubscriptionComponent } from '../popup-invoice/popup-invoice-subscription.component';
import { TranslateModule } from '@ngx-translate/core';
import { BlockUIComponent } from '@shared/components/block-ui/block-ui.component';
import { ProjectAPIService } from '../../../project/api/project-api.service';

@Component({
  template: `
    <div class="w-full flex align-items-center justify-content-between">
      <div class="flex align-items-center">
        <img
          class="mr-2"
          src="/assets/images/icon/icon_project-lg.svg"
          alt="icon"
          [style]="{ width: 28 }"
        />
        <span class="text-[#084B83] text-3xl">
          {{ 'BILLING.CHECKOUT.TITLE' | translate }}
        </span>
      </div>

      <div class="absolute right-3 top-3">
        <p-button
          styleClass="p-button-secondary2"
          icon="pi pi-times"
          (onClick)="close()"
        ></p-button>
      </div>
    </div>
  `,
  standalone: true,
  imports: [TranslateModule, ButtonModule],
})
export class TitlePopupComponent {
  private dialog: DynamicDialogRef = inject(DynamicDialogRef);

  close() {
    this.dialog.close();
  }
}

@Component({
  selector: 'fibr-popup-checkout-subscription',
  templateUrl: './popup-checkout-subscription.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ListboxModule,
    FormsModule,
    DividerModule,
    ButtonModule,
    InputTextModule,
    BlockUIComponent,
  ],
  providers: [CurrencyPipe, PackageApi, VoucherApi, BillingApi],
  styles: [
    `
      ::ng-deep {
        .p-listbox {
          border: none;

          &:focus {
            outline: none;
            border: none;
          }
        }

        .p-listbox .p-listbox-list {
          padding: 0;
          @apply space-y-4;

          &:focus {
            outline: none;
            border: none;
          }
        }

        .p-listbox .p-listbox-list .p-listbox-item {
          border-radius: 6px;
          color: #8a8a8a;
          background: #f7f7f7;

          & h6 {
            color: #444444;
          }
        }

        .p-listbox .p-listbox-list .p-listbox-item .p-disabled {
          opacity: 1;
        }

        .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
          background: #e3effa;
          color: #8a8a8a;
          border: 1px solid var(--primary-color);

          & h6 {
            color: var(--primary-color);
          }
        }
      }
    `,
  ],
})
export class PopupCheckoutSubscriptionComponent
  extends BaseComponent
  implements OnInit
{
  private config: DynamicDialogConfig = inject(DynamicDialogConfig);
  private dialogRef: DynamicDialogRef = inject(DynamicDialogRef);
  private dialog: DialogService = inject(DialogService);
  private packageApi: PackageApi = inject(PackageApi);
  private voucherApi: VoucherApi = inject(VoucherApi);
  private billingApi: BillingApi = inject(BillingApi);
  private packageStore: PackageStore = inject(PackageStore);
  private projectApi: ProjectAPIService = inject(ProjectAPIService);
  private projectStore: ProjectStoreService = inject(ProjectStoreService);

  public currentPipe: CurrencyPipe = inject(CurrencyPipe);

  constructor() {
    super('fibr-popup-checkout-subscription');
    this.config.width = '500px';
    this.config.templates = {
      header: TitlePopupComponent,
    };
  }

  get projectName() {
    return this.config.data?.project_name || this.project()?.display_name;
  }

  summaryOrder = signal<SummaryOrder>({
    selected: null,
    voucherCode: '',
    tax: 0,
    discount: 0,
    total: 0,
  });
  selectedPackage = signal<PackageSubscription | null>(null);

  packages = computed(() => this.packageStore.packages().map((pack) => ({
    ...pack,
    disable: this.summaryOrder().selected === pack.id,
  })));
  project = computed(() => this.projectStore.projectDetail());

  ngOnInit(): void {
    if (!this.project()) {
      this.projectApi.fetchProjectDetail(this.config.data.projectId);
    }

    this.setStateLoading();
    this.packageApi.getPackages().then((packages) => {
      this.setStateReady();

      if (packages.length === 0) return;
      /**
       * set default option
       */
      this.onSelectedChange(packages[0].id);
    });
  }

  onSelectedChange(packageId: string) {
    this.summaryOrder.update((val) => ({
      ...val,
      selected: packageId,
    }));

    const filteredPackage = this.packages()
      .filter((x) => x.id === packageId)
      .at(0) as PackageSubscription;

    this.selectedPackage.set(filteredPackage);

    this.calculateTax(filteredPackage);
    this.calculateTotal();
  }

  calculateTax(packageSubscription: PackageSubscription) {
    // TODO: add to global variable
    const TAX_PERCENTAGE = 11;
    const taxPercetage = TAX_PERCENTAGE / 100;
    const rawTotal = Math.floor(packageSubscription.price - this.summaryOrder().discount);
    const total = Math.floor(rawTotal * taxPercetage);

    this.summaryOrder.update((val) => ({
      ...val,
      tax: total,
    }));
  }

  calculateTotal() {
    const packagePrice = <number>this.selectedPackage()?.price;
    const tax = this.summaryOrder().tax;
    const discount = this.summaryOrder().discount;
    const total = Math.floor((packagePrice - discount) + tax);

    this.summaryOrder.update((val) => ({
      ...val,
      total,
    }));
  }

  async applyVoucher() {
    this.setStateProcessing();

    try {
      const voucherCode = this.summaryOrder().voucherCode;
      const response = await this.voucherApi.checkAvailability(voucherCode);

      switch (response.discount_type) {
        case 'flat':
          this.summaryOrder.update((val) => ({
            ...val,
            discount: response.discount,
          }));
          break;
        case 'percentage':
          this.summaryOrder.update((val) => {
            const packagePrice = <number>this.selectedPackage()?.price;
            const discountPercentage = response.discount;
            const total = Math.floor(packagePrice * (discountPercentage / 100));

            return {
              ...val,
              discount: total,
            };
          });
          break;
      }

      this.setStateReady();
      this.calculateTax(this.selectedPackage() as PackageSubscription);
      this.calculateTotal();
    } catch (error: unknown) {
      if (error) {
        const errors = error as HttpErrorResponse;

        switch (errors.error.code) {
          case 'invalid':
            setToast('Voucher is invalid', 'error', 'top-center');
            break;
          case 'expired':
            setToast('Voucher is expired', 'error', 'top-center');
            break;
        }

        this.setStateReady();
      }
    }
  }

  async submit() {
    this.setStateLoading();

    try {
      const response = await this.billingApi.checkout({
        packageId: this.selectedPackage()!.id,
        projectId: this.config.data.projectId,
        voucherId: this.summaryOrder().voucherCode,
      });
      this.setStateReady();
      this.close();

      this.dialog.open(PopupInvoiceSubscriptionComponent, {
        data: {
          invoice_id: response.data.id,
          project_id: this.config.data.projectId
        },
      });
    } catch (error: unknown) {
      const errors = error as HttpErrorResponse;
      this.setStateReady();
      setToast(errors.error['message'] ?? 'Internal Server Error', 'error', 'top-center');
    }
  }

  close() {
    this.dialogRef.close();
  }
}
