import { Injectable } from '@angular/core';
import type { Plan, Project, Template } from '../model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { APIResponse } from '../../../shared/libs/http/response';
import {
  collection,
  collectionData,
  doc,
  getDocs,
} from '@angular/fire/firestore';
import { AuthStoreService } from '../../auth/store/auth-store.service';
import { ProjectStoreService } from '../store/project-store.service';
import { lastValueFrom } from 'rxjs';
import { failure, success } from '@shared/libs/error';
import { SUCCESS } from '@shared/libs/http/constants';
import { getDoc } from '@firebase/firestore';
import { ProjectsShardService } from '../../../core/projects-shard/projects-shard.service';
import { TableAPIService } from '../../table/api/table-api.service';
import { getAllDocumentIDs } from '@shared/libs/storage/firestore';

type CreateProjectPayload = {
  project_name: string;
  description: string;
  id_token: string
};

type CreateProjectUsingTemplatePayload = {
  project_name: string;
  template_id: string;
  id_token: string;
  import_data?: boolean;
};

type ProjectResp = APIResponse<Pick<Project, 'location'> & { project_id: string } & { custom_token: string }>;

@Injectable({
  providedIn: 'root',
})
export class ProjectAPIService {
  constructor(
    private http: HttpClient,
    private authStore: AuthStoreService,
    private projectStore: ProjectStoreService,
    private projectsShard: ProjectsShardService,
    private tableAPI: TableAPIService,
  ) { }

  async fetchProjectList() {
    const headers = new HttpHeaders().set('Authorization', this.authStore.userToken());
    try {
      const resp = await lastValueFrom(this.http.get<APIResponse<Project[]>>(`${environment.apiUrl}/listProject`, { headers }));
      this.projectStore.setProjectList(resp.data);
      return success(null);
    } catch (error) {
      return failure('PROJECT.FAILED_TO_FETCH_PROJECT_LIST');
    }
  }

  async fetchProjectDetail(projectID: string) {
    try {
      // prettier-ignore
      const appConfigRef = doc(this.projectsShard.fireStore, `/projects/${projectID}`);
      const globalConfigRef = doc(this.projectsShard.fireStore, `/projects/${projectID}/global_config/app`);

      const projectDetail = (await getDoc(appConfigRef)).data();
      const globalConfig = (await getDoc(globalConfigRef)).data();

      if (projectDetail?.['active_plans']) {
        this.getPlanDetail(projectID, projectDetail?.['active_plans'].slice(-1)[0]);
      }

      // prettier-ignore
      this.projectStore.setProjectDetail({ ...projectDetail, global_config: globalConfig } as Project);
      return success(null)
    }
    catch (error) {
      return failure('PROJECT.FAILED_TO_FETCH_PROJECT_DETAIL')
    }
  }

  async getStatusProject(projectID: string) {
    const projectRef = collection(
      this.projectsShard.fireStore,
      `/projects/${projectID}/versions`
    );

    const snapshot = await getDocs(projectRef);

    snapshot.empty ? this.projectStore.setStatusProject(false) : this.projectStore.setStatusProject(true);
  }

  async getRole(projectID: string, userID: string) {
    const projectRef = (await getDoc(
      doc(
        this.projectsShard.fireStore,
        `/projects/${projectID}/access/${userID}`
      )
    )).data();

    this.projectStore.setRole(projectRef?.['role']);
  }

  fetchTemplateList() {
    const templateRef = collection(
      this.projectsShard.fireStore,
      `/templates`
    );
    const templateList$ = collectionData(templateRef, { idField: 'id' }).subscribe((templates) => {
      this.projectStore.setTemplateList(templates as Template[]);
    });
    return templateList$.unsubscribe
  }

  async createProject(projectName: string, tableName: string) {
    try {
      const result = await lastValueFrom(this.http.post<ProjectResp>(`${environment.apiUrl}/createProject`, {
        project_name: projectName,
        id_token: this.authStore.userToken(),
        description: projectName,
      } as CreateProjectPayload));
      if (result.message === SUCCESS) {
        this.authStore.setProjectsShardConfigByProjectID(
          result.data.project_id, {
          customToken: result.data.custom_token,
          firebaseName: result.data.location ?? ''
        });
        await this.projectsShard.initializeFirebase(result.data.project_id);
        const createInitialTableResp = await this.tableAPI.createTable(result.data.project_id, tableName);
        if (!createInitialTableResp.isSuccess) {
          return success(createInitialTableResp.error);
        }
        return success(result.data.project_id)
      } else {
        return failure('PROJECT.FAILED_TO_CREATE_PROJECT')
      }
    } catch (error) {
      return failure('PROJECT.FAILED_TO_CREATE_PROJECT')
    }
  }

  async createProjectWithGoogleSheet(projectName: string) {

    try {
      const result = await lastValueFrom(this.http.post<ProjectResp>(`${environment.apiUrl}/createProject`, {
        project_name: projectName,
        id_token: this.authStore.userToken(),
        description: projectName
      } as CreateProjectPayload));
      if (result.message === SUCCESS) {
        this.authStore.setProjectsShardConfigByProjectID(
          result.data.project_id, {
          customToken: result.data.custom_token,
          firebaseName: result.data.location ?? ''
        });
        await this.projectsShard.initializeFirebase(result.data.project_id);
        return success(result.data.project_id)
      } else {
        return failure('PROJECT.FAILED_TO_CREATE_PROJECT')
      }
    } catch (error) {
      return failure('PROJECT.FAILED_TO_CREATE_PROJECT')
    }
  }

  async importGoogleSheet(projectID: string, sheetID: string, accessToken: string) {
    try {
      const result = await lastValueFrom(this.http.post(`${environment.apiProjectUrl}/importData`, {
        projectId: projectID,
        spreadsheetId: sheetID,
        accessToken: accessToken,
      })) as unknown as { status: string };
      
      if (result.status === "ok") {
        return success(null);
      } else {
        return failure('PROJECT.FAILED_TO_IMPORT_GOOGLE_SHEET');
      }
    } catch (error) {
      return failure('PROJECT.FAILED_TO_IMPORT_GOOGLE_SHEET');
    }
  }

  async createProjectUsingTemplate(projectName: string, templateID: string, withData = true) {
    const payload: CreateProjectUsingTemplatePayload = {
      project_name: projectName,
      template_id: templateID,
      id_token: this.authStore.userToken(),
      import_data: withData,
    }
    try {
      const result = await lastValueFrom(this.http.post<ProjectResp>(`${environment.apiUrl}/createProjectUsingTemplate`, payload ));
      if (result.message === SUCCESS) {
        this.authStore.setProjectsShardConfigByProjectID(
          result.data.project_id, {
          customToken: result.data.custom_token,
          firebaseName: result.data.location ?? ''
        });
        if (!withData) {
          const handleWithoutDataResp = await this.handleWithoutData(result.data.project_id);
          if (!handleWithoutDataResp.isSuccess) {
            return success(handleWithoutDataResp.error);
          }
        }
        return success(result.data.project_id)
      } else {
        return failure('PROJECT.FAILED_TO_CREATE_PROJECT')
      }
    } catch (error) {
      return failure('PROJECT.FAILED_TO_CREATE_PROJECT');
    }
  }

  private async handleWithoutData(projectID: string) {
    try {
      // TODO move to table api
      await this.projectsShard.initializeFirebase(projectID);
      const tablePath = this.tableAPI.getTablePath(projectID);
      const tableIDs = await getAllDocumentIDs(this.projectsShard.fireStore, tablePath);
      tableIDs.forEach(async (tableID) => {
        await this.tableAPI.addRow({ projectID, tableID, row: {} });
      })
      return success(null);
    } catch (error) {
      return failure('PROJECT.FAILED_TO_HANDLE_WITHOUT_DATA');
    }
  }

  async getPlanDetail(projectID: string, planID: string) {

    const planDetail = (await getDoc(
      doc(
        this.projectsShard.fireStore,
        `/projects/${projectID}/plans/${planID}`
      )
    )).data();

    const planDetailRef = {
      name: planDetail?.['name'] ?? '',
      end_at: planDetail?.['end_at']?.['seconds'] ?? '',
    }
    this.projectStore.setPlanDetail(planDetailRef as Plan);
  }

}
