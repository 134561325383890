<a
  href="javascript:void(0)"
  class="p-element flex align-items-center mt-2"
  (click)="menu.toggle($event)"
  pTooltip="Profile"
  tooltipPosition="bottom"
>
  <span class="text-dark-blue font-bold mr-2">{{ partnerName }}</span>
  <img
    alt="icon"
    src="/assets/images/icon/icon_user.svg"
    height="21"
    class="h-[21px]"
  />
</a>
<p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
