import { Component, type OnDestroy, type OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'primeng/accordion';
import { TooltipModule } from 'primeng/tooltip';
import { ProjectStoreService } from '../../store/project-store.service';
import { ProjectAPIService } from '../../api/project-api.service';
import type { Template } from '../../model';
import { DomSanitizer, type SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'fibr-project-template-list',
  standalone: true,
  imports: [TranslateModule, AccordionModule, TooltipModule],
  templateUrl: './project-template-list.component.html',
  styleUrl: './project-template-list.component.scss',
})

export class ProjectTemplateListComponent implements OnInit, OnDestroy {
  unsubscribe: () => void;
  templateList = this.projectStore.templateList;


  constructor(private projectStore: ProjectStoreService, private projectAPI: ProjectAPIService, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.unsubscribe = this.projectAPI.fetchTemplateList();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  openTemplateDialog(template: Template) {
    this.projectStore.setSelectedTemplate(template);
    this.projectStore.setActiveDialog('template');
  }

  getSafeUrl(unsafeUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(unsafeUrl);
  }
}
