<footer class="footer">
  <div class="p-footer grid">
    <div class="col-auto">
      <span
        >{{ "footer.version" | translate }} 1.3.23
        <a
          class="ml-2"
          href="https://appfibr.io/privacy-policy/"
          target="_blank"
          >{{ "footer.privacy.policy" | translate }}</a
        >
        |
        <a href="https://appfibr.io/syarat-ketentuan/" target="_blank">{{
          "footer.tos" | translate
        }}</a></span
      >
    </div>
    <div class="col-auto flex flex-row gap-1">
      <span>Copyright © {{currentYear}} </span>
      <img alt="fibr" [src]="'/assets/images/logo_fibr_footer.svg'" class="h-[14px]" />
    </div>
  </div>
</footer>
