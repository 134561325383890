import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { BaseComponent } from '../../../../../core/base/base-component';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangePasswordSchema } from '../../../model';
import { Input as InputValibot } from 'valibot';
import { AuthAPIService } from '../../../api/auth-api.service';
import { setToast } from '@shared/components/toast/toast.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'fibr-change-password',
  templateUrl: './change-password.component.html',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    DividerModule,
    ButtonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
  ],
})
export class ChangePasswordComponent extends BaseComponent {
  private authService = inject(AuthAPIService);

  constructor() {
    super('fibr-change-password');

    this.schema = ChangePasswordSchema;
  }

  formGroup = signal<InputValibot<typeof ChangePasswordSchema>>({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
  });

  async onSubmit() {
    const valid = this.validate(this.formGroup());

    if (valid.success) {
      const oldPassword = this.formGroup().oldPassword;
      const newPassword = this.formGroup().newPassword;

      this.setStateLoading();

      const changedPassword = await this.authService.changePassword(
        oldPassword,
        newPassword
      );

      if (changedPassword.isSuccess) {
        this.formGroup.set({
          oldPassword: '',
          newPassword: '',
          newPasswordConfirmation: '',
        });

        setToast(this.translate('AUTH.PASSWORD_CHANGED_SUCCESSFULLY'), 'success');
        this.setStateReady();
      }

      if (!changedPassword.isSuccess) {
        setToast(this.translate(changedPassword.error), 'error');
        this.setStateReady();
      }
    }
  }
}
