<fibr-header-v1 [srcImg]="'/assets/images/logo_appfibr.svg'"></fibr-header-v1>
<fibr-subheader [projectDetail] = "projectDetail()" [planDetail] = "planDetail()" [isReleased] = "isReleased()" (publishApp)="publishApp()" (save)="save()" (invite)="onInvite()"></fibr-subheader>

<div class="grid p-content fibr-portal">
    <div class="center-content">
        <div class="box h-100">
            <div class="layout-app">
                <p-tabMenu [model]="tabs" [activeItem]="tabs[0]" class="tab-editor lg:hidden"></p-tabMenu>
                <div class="app-container">
                    <fibr-data-section
                    [selectedTableIndex]="selectedTableIndex()"
                    [sidebarItems]="sidebarItems"
                    [isSelected]="selectedView() === 'data'"
                    [selectedTable]="selectedTable()"
                    [projectID]="projectID"
                    (resetSelectedTableEvent)="onResetSelectedTable()"
                    />
                    <fibr-preview-and-page-list-section
                    [isSelected]="selectedView() === 'preview'"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
<fibr-dialog-invite-user [dialogInviteUser]="dialogInviteUser()" (didClose)="onClose()" (role)="role()"></fibr-dialog-invite-user>
<fibr-footer></fibr-footer>
<fibr-block-ui [isBlocked]="isBlocked()" />
