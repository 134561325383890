import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import {
  AuthGuard,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import { LoginPageComponent } from './features/auth/ui/login-page/login-page.component';
import { ProjectListPageComponent } from './features/project/ui/project-list-page/project-list-page.component';
import { RegisterPageComponent } from './features/auth/ui/register-page/register-page.component';
import { ForgotPageComponent } from './features/auth/ui/forgot-page/forgot-page.component';
import { map, pipe } from 'rxjs';
import type { User } from '@angular/fire/auth';
import { ChangePasswordPageComponent } from './features/auth/ui/change-password-page/change-password-page.component';
import { EditorPageComponent } from './features/editor/ui/editor-page/editor-page.component';
import { projectsShardGuard } from './core/projects-shard/projects-shard.guard';
import { JoinAppComponent } from '@shared/components/join-app/join-app.component';
import { AccountPageComponent } from './features/auth/ui/account-page/account-page.component';
import { AppSettingsComponent } from './features/editor/ui/app-settings/app-settings.component';
import { BillingPageComponent } from './features/billing/ui/billing-page/billing-page.component';
import {
  FibrRedirectPaymentBillingComponent
} from "./features/billing/ui/redirect-payment-billing/redirect-payment-billing.component";
import { OnBoardingComponent } from './features/auth/ui/onboarding/onboarding.component';
import { onBoardingGuard } from './core/guards/onboarding.guard';

const redirectUnauthorize = () => redirectUnauthorizedTo(['sign-in']);
const redirectUnauthorizeSaveLastUrl = (route : ActivatedRouteSnapshot) => {
  const return_url = route.pathFromRoot.map(v => v.url.map(segment => segment.toString()).join('/')).join('/');
  const params = route.queryParams;

  return pipe(
    map((user: User | null) => {
      localStorage.setItem('lastUrl', JSON.stringify({ return_url, params }));
      if (!user || !user.emailVerified) {
        return true;
      }
      return 'join-app-logged-in';
    })
  )
}

const registerGuard = () =>
  pipe(
    map((user: User | null) => {
      if (!user) return true;
      if (!user.emailVerified) return 'sign-in';
      return 'projects';
    })
  );

const signInGuard = () =>
  pipe(
    map((user: User | null) => {
      if (!user || !user.emailVerified) return true;
      return 'projects';
    })
  );

const commonGuards = {
  canActivate: [AuthGuard],
  data: { authGuardPipe: redirectUnauthorize },
};

export const routes: Routes = [
  {
    path: 'sign-in',
    component: LoginPageComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: signInGuard },
  },
  {
    path: 'register',
    component: RegisterPageComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: registerGuard },
  },
  {
    path: 'forgot-password',
    component: ForgotPageComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: signInGuard },
  },
  {
    path: 'config/change-password',
    component: ChangePasswordPageComponent,
    ...commonGuards,
  },
  {
    path: 'profile',
    component: AccountPageComponent,
    ...commonGuards,
  },
  {
    path: 'onboarding',
    component: OnBoardingComponent,
    ...commonGuards,
  },
  {
    path: 'projects',
    component: ProjectListPageComponent,
    canActivate: [AuthGuard, onBoardingGuard],
    data: { authGuardPipe: redirectUnauthorize }
  },
  {
    path: 'projects/:id',
    canActivate: [AuthGuard, projectsShardGuard],
    data: { authGuardPipe: redirectUnauthorize },
    component: EditorPageComponent,
  },
  {
    path: 'projects/:id/app-settings',
    canActivate: [AuthGuard, projectsShardGuard],
    data: { authGuardPipe: redirectUnauthorize },
    component: AppSettingsComponent,
  },
  {
    path: 'billing',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorize },
    component: BillingPageComponent,
  },
  {
    path: 'finish-payment',
    component: FibrRedirectPaymentBillingComponent,
  },
  { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
  {
    path: 'join-app-logged-in',
    component: JoinAppComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorize },
  },
  {
    path: 'join-app',
    component: JoinAppComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizeSaveLastUrl },
  },
];
