import { Component } from '@angular/core';

@Component({
  selector: 'fibr-web-icon',
  standalone: true,
  imports: [],
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <rect x="0.5" y="0.5" width="16" height="16" rx="2.5" fill="#5FBEAC" fill-opacity="0.11" stroke="#5FBEAC"/>
      <path d="M8.5 4C7.60999 4 6.73996 4.26392 5.99994 4.75839C5.25991 5.25285 4.68314 5.95566 4.34254 6.77792C4.00195 7.60019 3.91283 8.50499 4.08647 9.37791C4.2601 10.2508 4.68868 11.0526 5.31802 11.682C5.94736 12.3113 6.74918 12.7399 7.62209 12.9135C8.49501 13.0872 9.39981 12.998 10.2221 12.6575C11.0443 12.3169 11.7471 11.7401 12.2416 11.0001C12.7361 10.26 13 9.39001 13 8.5C12.9987 7.30694 12.5241 6.16313 11.6805 5.31951C10.8369 4.47589 9.69306 4.00135 8.5 4ZM8.5 12.4C7.8805 12.4 7.2319 11.6014 6.91 10.3H10.09C9.7681 11.6014 9.1195 12.4 8.5 12.4ZM6.7882 9.7C6.6706 8.90432 6.6706 8.09568 6.7882 7.3H10.2118C10.2713 7.69722 10.3008 8.09835 10.3 8.5C10.3008 8.90165 10.2713 9.30278 10.2118 9.7H6.7882ZM4.6 8.5C4.60022 8.09246 4.66461 7.6875 4.7908 7.3H6.1858C6.0714 8.09591 6.0714 8.90409 6.1858 9.7H4.7908C4.66461 9.31249 4.60022 8.90753 4.6 8.5ZM8.5 4.6C9.1195 4.6 9.7681 5.3986 10.09 6.7H6.91C7.2319 5.3986 7.8805 4.6 8.5 4.6ZM10.8142 7.3H12.2092C12.4635 8.07979 12.4635 8.92021 12.2092 9.7H10.8142C10.8711 9.30253 10.8998 8.90152 10.9 8.5C10.8998 8.09847 10.8711 7.69747 10.8142 7.3ZM11.9563 6.7H10.7062C10.5727 6.04751 10.3109 5.42802 9.9361 4.8775C10.8055 5.22424 11.5222 5.87073 11.9563 6.7ZM7.0639 4.8775C6.68907 5.42802 6.42731 6.04751 6.2938 6.7H5.0437C5.47784 5.87073 6.19446 5.22424 7.0639 4.8775ZM5.0437 10.3H6.2938C6.42731 10.9525 6.68907 11.572 7.0639 12.1225C6.19446 11.7758 5.47784 11.1293 5.0437 10.3ZM9.9361 12.1225C10.3109 11.572 10.5727 10.9525 10.7062 10.3H11.9563C11.5222 11.1293 10.8055 11.7758 9.9361 12.1225Z" fill="#5FBEAC"/>
    </svg>
  `,
  styles: ``
})
export class WebIconComponent {

}
