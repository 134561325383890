import { Component, signal, AfterViewInit, type OnInit, type OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AddIconComponent } from './shared/components/icons/add/add-icon.component';
import { PageListIconComponent } from './shared/components/icons/page-list/page-list-icon.component';
import { ThemeIconComponent } from './shared/components/icons/theme/theme-icon.component';
import { AnalyticIconComponent } from './shared/components/icons/analytic/analytic-icon.component';
import { CMSIconComponent } from './shared/components/icons/cms/cms-icon.component';
import { SettingsIconComponent } from './shared/components/icons/settings/settings-icon.component';
import { ChevronLeftIconComponent } from './shared/components/icons/chevron-left/chevron-left-icon.component';
import * as feather from 'feather-icons';
import { HeaderComponent } from './core/components/header/header.component';
import { SidebarComponent } from './core/components/sidebar/sidebar.component';
import { ToastComponent, setToast } from './shared/components/toast/toast.component';
import { getLocalStorage } from './shared/libs/storage/localeStorage';
import { AuthAPIService } from './features/auth/api/auth-api.service';
import { PrimeNGConfig } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AuthStoreService } from './features/auth/store/auth-store.service';
import type { AuthStore } from './features/auth/model';
import { GlobalConfigService, type GlobalConfig, languageList } from './core/global-config/global-config.service';
import { P, match } from 'ts-pattern';

const mainActionList = [
  'add',
  'page-list',
  'theme',
  'analytics',
  'cms',
  'settings',
] as const;
type MainActionList = (typeof mainActionList)[number];

type InternalState = {
  selectedMainAction: MainActionList;
};

@Component({
  selector: 'fibr-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    TranslateModule,
    AddIconComponent,
    PageListIconComponent,
    ThemeIconComponent,
    AnalyticIconComponent,
    CMSIconComponent,
    SettingsIconComponent,
    ChevronLeftIconComponent,
    HeaderComponent,
    SidebarComponent,
    ToastComponent,
    ConfirmDialogModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
  private unsubscribe: () => void;

  constructor(
    private translate: TranslateService,
    private authAPI: AuthAPIService,
    private primengConfig: PrimeNGConfig,
    private authStore: AuthStoreService,
    private globalConfig: GlobalConfigService
  ) {
    translate.addLangs(Array.from(languageList));
    translate.setDefaultLang('id');

    const selectedLang = this.getInitialLanguage();
    this.globalConfig.setLanguage(selectedLang);
  }

  ngOnInit() {
    this.unsubscribe = this.authAPI.fetchLoggedInUser();
    window.addEventListener('offline', this.onOffline);
    this.primengConfig.ripple = true;

    const projectsShardConfig = getLocalStorage('PROJECTS_SHARD_CONFIG_BY_PROJECT_ID');
    if (projectsShardConfig) {
      this.authStore.restoreProjectsShardConfigByProjectID(
        JSON.parse(projectsShardConfig) as AuthStore['projectsShardConfigByProjectID']
      );
    }
  }

  ngAfterViewInit() {
    feather.replace();
  }

  ngOnDestroy() {
    this.unsubscribe();
    window.removeEventListener('offline', this.onOffline);
  }

  onOffline() {
    setToast('You are offline', 'error');
  }

  DUMMY_DATA = {
    app_title: 'Volunteer App',
    app_icon: null,
    published_platforms: ['web', 'android'],
    package: 'Basic',
  };

  DUMMY_INTERNAL_STATE: InternalState = {
    selectedMainAction: 'add',
  };

  showpage: boolean = false;
  handleShowPage() {
    this.showpage = !this.showpage;
  }
  showAddComponent = signal(false);

  onClickAddComponent = () => {
    this.showAddComponent.update((v) => !v);
  };

  mainActionList = mainActionList;

  title = 'appfibr-portal-v2';

  getMainActionClass(action: MainActionList) {
    return this.DUMMY_INTERNAL_STATE.selectedMainAction === action
      ? 'text-gray-50'
      : 'text-[#8A8A8A]';
  }

  private getInitialLanguage(): GlobalConfig['language'] {
    return match<string | null, GlobalConfig['language']>(getLocalStorage('FIBR-LANGUAGE'))
      .with(P.nullish, () => this.getBrowserLang())
      .with(P.union(...languageList), (lang) => lang)
      .with(P.string, () => 'id')
      .exhaustive();
  }

  private getBrowserLang() {
    return match<string | undefined, GlobalConfig['language']>(this.translate.getBrowserLang())
      .with(P.union(...languageList), (lang) => lang)
      .with(P.union(P.nullish, P.string), () => 'id')
      .exhaustive();
  }
}
