import { Component, OnInit, signal } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AuthAPIService } from '../../api/auth-api.service';
import { GoogleSignInComponent } from '../google-sign-in/google-sign-in.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { RegisterSchema, type Lang } from '../../model';
import { fieldSignal } from '../../../../shared/libs/form';
import { setToast } from '../../../../shared/components/toast/toast.component';
import { safeParse } from 'valibot';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { GlobalConfigService } from '../../../../core/global-config/global-config.service';

@Component({
  selector: 'fibr-register-page',
  standalone: true,
  imports: [
    TranslateModule,
    GoogleSignInComponent,
    CommonModule,
    FormsModule,
    RouterModule,
    ProgressSpinnerModule,
    CheckboxModule,
    DropdownModule,
    RecaptchaFormsModule,
    RecaptchaModule
  ],
  templateUrl: './register-page.component.html',
  styleUrl: './register-page.component.scss',
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
  ],
})
export class RegisterPageComponent implements OnInit {
  email = fieldSignal('');
  password = fieldSignal('');
  fullName = fieldSignal('');
  passwordConfirmation = fieldSignal('');
  termAndCondition = fieldSignal(false);
  captcha = fieldSignal('')
  selectedLanguage = signal<Lang | undefined>(undefined);
  isProgressSpinner = signal(false);

  returnUrl = signal('');
  errValidasi: string | undefined;

  languageList: Lang[] = [
    { name: 'English', code: 'en' },
    { name: 'Indonesia', code: 'id' },
  ];

  logo = environment.logo;
  image_register = environment.image_register;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private authService: AuthAPIService,
    private route: ActivatedRoute,
    private globalConfig: GlobalConfigService,
  ) {}

  ngOnInit(): void {
    const lastUrl = JSON.parse(localStorage.getItem('lastUrl') ?? '{}');

    if (Object.keys(lastUrl).length !== 0) {
      this.router.navigate(['register'], { queryParams: {return_url: 'join-app' } });
    }
    this.selectedLanguage.set(this.languageList.find(
      (x: Lang) => x.code === this.globalConfig.language()
    ));
    this.route.queryParams.subscribe((params) => {
      this.returnUrl.set(params['return_url'] ?? "/projects");
    });
  }

  onClickGoogle() {
    this.authService.googleSignIn().then((result) => {
      if (result.isSuccess) {
        this.router.navigateByUrl(this.returnUrl());
      } else {
        this.translate.get(result.error).subscribe((errorMessage) => {
          setToast(errorMessage, 'error');
        })
      }
    });
  }

  onLanguageChange() {
    const selectedLangCode = this.selectedLanguage()?.code ?? 'en';
    this.globalConfig.setLanguage(selectedLangCode);
  }

  onRegister() {
    const validatedFields = safeParse(RegisterSchema, {
      fullName: this.fullName.value(),
      email: this.email.value(),
      password: this.password.value(),
      passwordConfirmation: this.passwordConfirmation.value(),
      termAndCondition: this.termAndCondition.value(),
      captcha: this.captcha.value() ?? ''
    }, { abortEarly: true });
    if (validatedFields.success) {
      this.isProgressSpinner.set(true);
      this.authService.register(validatedFields.output)
        .then((result) => {
          if (result.isSuccess) {
            this.translate.get('AUTH.REGISTERED_SUCCESSFULLY').subscribe((successMessage) => {
              setToast(successMessage, 'success');
            })
            this.router.navigate(['/sign-in'])
          }
          else this.translate.get(result.error).subscribe((errorMessage) => {
            setToast(errorMessage, 'error');
          })
        })
        .catch(() => {
          this.translate.get('AUTH.USER_REGISTRATION_FAILED').subscribe((errorMessage) => {
            setToast(errorMessage, 'error');
          })
        })
        .finally(() => {
          this.isProgressSpinner.set(false);
        });
    } else {
      this.translate.get(validatedFields.issues[0].message).subscribe((errorMessage) => {
        setToast(errorMessage, 'error');
      })
    }
  }

}
