import { Component } from '@angular/core';

@Component({
  selector: 'fibr-page-list-icon',
  standalone: true,
  imports: [],
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="30" viewBox="0 0 24 30" fill="none">
      <path d="M21.5706 1H6.62634C5.83818 1 5.19701 1.67911 5.19701 2.51391V3.22107H4.52781C3.73965 3.22107 3.09848 3.90018 3.09848 4.73498V5.44208H2.42969C1.64132 5.44208 1 6.12108 1 6.95566V27.4864C1 28.321 1.64132 29 2.42969 29H17.3732C18.1616 29 18.8029 28.321 18.8029 27.4864V26.7789H19.4721C20.2603 26.7789 20.9015 26.0998 20.9015 25.265V24.5579H21.5707C22.3588 24.5579 23 23.8788 23 23.044V2.51391C22.9999 1.67911 22.3587 1 21.5706 1ZM17.3732 27.9061H2.42969C2.21077 27.9061 2.03264 27.7179 2.03264 27.4863V6.95555C2.03264 6.72406 2.21077 6.53572 2.42969 6.53572H13.9807V9.99967C13.9807 10.3017 14.2119 10.5465 14.497 10.5465H17.7703V27.4863C17.7702 27.7179 17.5922 27.9061 17.3732 27.9061ZM17.0394 9.45279H15.0133V7.3084L17.0394 9.45279ZM19.8688 25.265C19.8688 25.497 19.6912 25.6851 19.4721 25.6851H18.8029V10.0024C18.8029 10.0022 18.8027 9.99218 18.8025 9.97637C18.8002 9.84315 18.7485 9.716 18.6597 9.62161C18.6596 9.62156 18.6596 9.62156 18.6596 9.62156C18.6569 9.61861 14.8619 5.60199 14.8619 5.60199C14.8614 5.60144 14.8557 5.59581 14.8468 5.5869C14.7521 5.49305 14.6268 5.44104 14.4971 5.44197H14.497H4.13112V4.73487C4.13112 4.50283 4.30874 4.31471 4.52781 4.31471H19.4721C19.6912 4.31471 19.8688 4.50283 19.8688 4.73487V25.265ZM21.9673 23.044C21.9673 23.2756 21.7893 23.4642 21.5706 23.4642H20.9014V4.73492C20.9014 3.90012 20.2602 3.22101 19.472 3.22101H6.22965V2.51391C6.22965 2.28225 6.40763 2.09375 6.62634 2.09375H21.5706C21.7893 2.09375 21.9673 2.2822 21.9673 2.51391V23.044Z" fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
    </svg>
  `,
  styles: ``
})
export class PageListIconComponent {

}
