import { Component } from '@angular/core';

@Component({
  selector: 'fibr-android-icon',
  standalone: true,
  imports: [],
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <rect x="0.5" y="0.5" width="16" height="16" rx="2.5" fill="#5FBEAC" fill-opacity="0.11" stroke="#5FBEAC"/>
      <path d="M5.66406 6.38867H11.3726V6.86444H5.66406V6.38867Z" fill="#5FBEAC"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.53906 6.26367H11.4976V6.98944H5.53906V6.26367ZM5.78906 6.51367V6.73944H11.2476V6.51367H5.78906Z" fill="#5FBEAC"/>
      <path d="M4.95137 10.908C4.42595 10.908 4 10.482 4 9.95652V7.34013C4 6.81462 4.42595 6.38867 4.95137 6.38867C5.47687 6.38867 5.90282 6.81462 5.90282 7.34013V9.95652C5.90282 10.482 5.47687 10.908 4.95137 10.908ZM4.95137 6.86444C4.68866 6.86444 4.47568 7.07742 4.47568 7.34013V9.95652C4.47568 10.2192 4.68866 10.4323 4.95137 10.4323C5.21416 10.4323 5.42714 10.2192 5.42714 9.95652V7.34013C5.42714 7.07742 5.21416 6.86444 4.95137 6.86444Z" fill="#5FBEAC"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.95137 6.51367C4.49499 6.51367 4.125 6.88365 4.125 7.34013V9.95652C4.125 10.413 4.49499 10.783 4.95137 10.783C5.40784 10.783 5.77782 10.413 5.77782 9.95652V7.34013C5.77782 6.88365 5.40784 6.51367 4.95137 6.51367ZM3.875 7.34013C3.875 6.74559 4.3569 6.26367 4.95137 6.26367C5.54591 6.26367 6.02782 6.74558 6.02782 7.34013V9.95652C6.02782 10.5511 5.54591 11.033 4.95137 11.033C4.3569 11.033 3.875 10.5511 3.875 9.95652V7.34013ZM4.35068 7.34013C4.35068 7.00838 4.61962 6.73944 4.95137 6.73944C5.28319 6.73944 5.55214 7.00837 5.55214 7.34013V9.95652C5.55214 10.2882 5.28321 10.5573 4.95137 10.5573C4.61959 10.5573 4.35068 10.2882 4.35068 9.95652V7.34013ZM4.95137 6.98944C4.75769 6.98944 4.60068 7.14645 4.60068 7.34013V9.95652C4.60068 10.1502 4.75772 10.3073 4.95137 10.3073C5.14511 10.3073 5.30214 10.1502 5.30214 9.95652V7.34013C5.30214 7.14647 5.14514 6.98944 4.95137 6.98944Z" fill="#5FBEAC"/>
      <path d="M12.0861 10.908C11.5607 10.908 11.1348 10.482 11.1348 9.95652V7.34013C11.1348 6.81462 11.5607 6.38867 12.0861 6.38867C12.6116 6.38867 13.0376 6.81462 13.0376 7.34013V9.95652C13.0376 10.482 12.6116 10.908 12.0861 10.908ZM12.0861 6.86444C11.8234 6.86444 11.6104 7.07742 11.6104 7.34013V9.95652C11.6104 10.2192 11.8234 10.4323 12.0861 10.4323C12.3489 10.4323 12.5619 10.2192 12.5619 9.95652V7.34013C12.5619 7.07742 12.3489 6.86444 12.0861 6.86444Z" fill="#5FBEAC"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0861 6.51367C11.6298 6.51367 11.2598 6.88365 11.2598 7.34013V9.95652C11.2598 10.413 11.6298 10.783 12.0861 10.783C12.5426 10.783 12.9126 10.413 12.9126 9.95652V7.34013C12.9126 6.88365 12.5426 6.51367 12.0861 6.51367ZM11.0098 7.34013C11.0098 6.74559 11.4917 6.26367 12.0861 6.26367C12.6807 6.26367 13.1626 6.74558 13.1626 7.34013V9.95652C13.1626 10.5511 12.6807 11.033 12.0861 11.033C11.4917 11.033 11.0098 10.5511 11.0098 9.95652V7.34013ZM11.4854 7.34013C11.4854 7.00838 11.7544 6.73944 12.0861 6.73944C12.418 6.73944 12.6869 7.00837 12.6869 7.34013V9.95652C12.6869 10.2882 12.418 10.5573 12.0861 10.5573C11.7544 10.5573 11.4854 10.2882 11.4854 9.95652V7.34013ZM12.0861 6.98944C11.8925 6.98944 11.7354 7.14645 11.7354 7.34013V9.95652C11.7354 10.1502 11.8925 10.3073 12.0861 10.3073C12.2799 10.3073 12.4369 10.1502 12.4369 9.95652V7.34013C12.4369 7.14647 12.2799 6.98944 12.0861 6.98944Z" fill="#5FBEAC"/>
      <path d="M9.70719 13.9999C9.18169 13.9999 8.75574 13.5739 8.75574 13.0485V11.8592H8.28006V13.0485C8.28006 13.5739 7.85411 13.9999 7.3286 13.9999C6.80318 13.9999 6.37715 13.5739 6.37715 13.0485V11.8592H6.13935C5.7452 11.8592 5.42578 11.5397 5.42578 11.1456V6.62634C5.42578 4.91864 6.81015 3.53418 8.51785 3.53418C10.2256 3.53418 11.61 4.91864 11.61 6.62634V11.1456C11.61 11.5397 11.2905 11.8592 10.8964 11.8592H10.6586V13.0485C10.6586 13.5739 10.2326 13.9999 9.70719 13.9999ZM7.80429 11.3834H9.23142V13.0485C9.23142 13.3112 9.44448 13.5241 9.70719 13.5241C9.9699 13.5241 10.1829 13.3112 10.1829 13.0485V11.3834H10.8964C11.0278 11.3834 11.1343 11.277 11.1343 11.1456V6.62634C11.1343 5.18135 9.96294 4.00995 8.51785 4.00995C7.07286 4.00995 5.90146 5.18135 5.90146 6.62634V11.1456C5.90146 11.277 6.00799 11.3834 6.13935 11.3834H6.85292V13.0485C6.85292 13.3112 7.06589 13.5241 7.3286 13.5241C7.59131 13.5241 7.80429 13.3112 7.80429 13.0485V11.3834Z" fill="#5FBEAC"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.51785 3.65918C6.87919 3.65918 5.55078 4.98767 5.55078 6.62634V11.1456C5.55078 11.4707 5.81424 11.7342 6.13935 11.7342H6.50215V13.0485C6.50215 13.5048 6.87222 13.8749 7.3286 13.8749C7.78507 13.8749 8.15506 13.5049 8.15506 13.0485V11.7342H8.88074V13.0485C8.88074 13.5049 9.25073 13.8749 9.70719 13.8749C10.1636 13.8749 10.5336 13.5049 10.5336 13.0485V11.7342H10.8964C11.2215 11.7342 11.485 11.4707 11.485 11.1456V6.62634C11.485 4.98767 10.1566 3.65918 8.51785 3.65918ZM5.30078 6.62634C5.30078 4.8496 6.74111 3.40918 8.51785 3.40918C10.2947 3.40918 11.735 4.8496 11.735 6.62634V11.1456C11.735 11.6087 11.3595 11.9842 10.8964 11.9842H10.7836V13.0485C10.7836 13.6429 10.3017 14.1249 9.70719 14.1249C9.11264 14.1249 8.63074 13.6429 8.63074 13.0485V11.9842H8.40506V13.0485C8.40506 13.6429 7.92315 14.1249 7.3286 14.1249C6.73415 14.1249 6.25215 13.6429 6.25215 13.0485V11.9842H6.13935C5.67615 11.9842 5.30078 11.6087 5.30078 11.1456V6.62634ZM5.77646 6.62634C5.77646 5.11231 7.00382 3.88495 8.51785 3.88495C10.032 3.88495 11.2593 5.11231 11.2593 6.62634V11.1456C11.2593 11.3461 11.0968 11.5084 10.8964 11.5084H10.3079V13.0485C10.3079 13.3802 10.0389 13.6491 9.70719 13.6491C9.37548 13.6491 9.10642 13.3802 9.10642 13.0485V11.5084H7.92929V13.0485C7.92929 13.3802 7.66035 13.6491 7.3286 13.6491C6.99686 13.6491 6.72792 13.3802 6.72792 13.0485V11.5084H6.13935C5.93902 11.5084 5.77646 11.3461 5.77646 11.1456V6.62634ZM8.51785 4.13495C7.1419 4.13495 6.02646 5.25038 6.02646 6.62634V11.1456C6.02646 11.2079 6.07697 11.2584 6.13935 11.2584H6.97792V13.0485C6.97792 13.2421 7.13493 13.3991 7.3286 13.3991C7.52228 13.3991 7.67929 13.2421 7.67929 13.0485V11.2584H9.35642V13.0485C9.35642 13.2421 9.51349 13.3991 9.70719 13.3991C9.90087 13.3991 10.0579 13.2421 10.0579 13.0485V11.2584H10.8964C10.9588 11.2584 11.0093 11.2079 11.0093 11.1456V6.62634C11.0093 5.25038 9.8939 4.13495 8.51785 4.13495Z" fill="#5FBEAC"/>
      <path d="M7.56757 5.19922H7.0918V5.6749H7.56757V5.19922Z" fill="#5FBEAC"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.9668 5.07422H7.69257V5.7999H6.9668V5.07422ZM7.2168 5.32422V5.5499H7.44257V5.32422H7.2168Z" fill="#5FBEAC"/>
      <path d="M9.94639 5.19922H9.4707V5.6749H9.94639V5.19922Z" fill="#5FBEAC"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.3457 5.07422H10.0714V5.7999H9.3457V5.07422ZM9.5957 5.32422V5.5499H9.82139V5.32422H9.5957Z" fill="#5FBEAC"/>
      <path d="M6.30078 3.27216L6.69554 3.00684L7.35441 3.9873L6.95956 4.25262L6.30078 3.27216Z" fill="#5FBEAC"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.73115 2.83301L7.52947 4.02098L6.92711 4.42574L6.12891 3.23778L6.73115 2.83301ZM6.47582 3.30584L6.99518 4.0788L7.18252 3.95291L6.6631 3.17996L6.47582 3.30584Z" fill="#5FBEAC"/>
      <path d="M9.67773 3.97968L10.3361 3L10.7311 3.2655L10.0728 4.24517L9.67773 3.97968Z" fill="#5FBEAC"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3017 2.82617L10.9042 3.23112L10.1064 4.4183L9.50391 4.01335L10.3017 2.82617ZM10.3697 3.17313L9.85084 3.9453L10.0384 4.07135L10.5573 3.29917L10.3697 3.17313Z" fill="#5FBEAC"/>
    </svg>
  `,
  styles: ``
})
export class AndroidIconComponent {

}
