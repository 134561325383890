import { Component } from '@angular/core';
import { ChevronLeftIconComponent } from '../../../../shared/components/icons/chevron-left/chevron-left-icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { CustomPageIconComponent } from '../../../../shared/components/icons/custom-page/custom-page';
import { ChatIconComponent } from '../../../../shared/components/icons/chat/chat-icon';
import { CartIconComponent } from '../../../../shared/components/icons/cart/cart-icon';
import { VideoConferenceIconComponent } from '../../../../shared/components/icons/video-conference/video-icon';
import { BookingIconComponent } from '../../../../shared/components/icons/booking/booking-icon';
import { MapsIconComponent } from '../../../../shared/components/icons/maps/maps-icon';
import { ActivatedRoute, Router } from '@angular/router';
import { collection } from '@angular/fire/firestore';
import {
  doc,
  setDoc,
  getFirestore,
} from '@angular/fire/firestore';
import { getApp } from '@angular/fire/app';
import { PROJECTS_SHARD } from '@shared/libs/storage/firebase';

@Component({
  selector: 'fibr-features',
  standalone: true,
  imports: [
    ChevronLeftIconComponent,
    TranslateModule,
    CustomPageIconComponent,
    ChatIconComponent,
    CartIconComponent,
    VideoConferenceIconComponent,
    BookingIconComponent,
    MapsIconComponent,
  ],
  templateUrl: './features.component.html',
  styleUrl: './features.component.scss',
})
export class FeaturesComponent {
  project_id = this.route.snapshot.paramMap.get('id');
  private projectFireStore = getFirestore(getApp(PROJECTS_SHARD));
  constructor(private route: ActivatedRoute, private router: Router) {}

  async navigateToEditor() {
    const appConfigRef = collection(
      this.projectFireStore,
      `/projects/${this.project_id}/app_config`
    );

    const projectRef = doc(appConfigRef);

    await setDoc(projectRef, {
      data_source: { type: '', value: '' },
      index: 0,
      type: 'basic',
      name: 'Home Page',
      id: projectRef.id,
    });

    this.router.navigate([
      '/projects',
      this.project_id,
      'pages',
      projectRef.id,
    ]);
  }
}
