import { Component, OnInit, signal } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';
import { BadgeModule } from 'primeng/badge';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DividerModule } from 'primeng/divider';
import { DialogModule } from 'primeng/dialog';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'fibr-notifications',
  standalone: true,
  imports: [
    TooltipModule,
    BadgeModule,
    OverlayPanelModule,
    DividerModule,
    DialogModule,
    TranslateModule,
    CommonModule,
  ],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss',
})
export class NotificationsComponent implements OnInit {
  pathNotif = signal('');

  notifUser: {
    read: string;
    result: 'success' | 'error';
    title: string;
    content: string;
    type: 'build_app' | 'import_gsheet' | 'export_gsheet';
    created_date: Record<string, number>;
  }[] = [];
  countRead: number;

  constructor(public translate: TranslateService) {}

  ngOnInit(): void {
    this.showDataNotif();
  }

  showDataNotif() {
    this.countRead = 0;
    // TODO READ AGAIN AND CONFIRIM CODE IN V1, IT'S NOT CLEAR
    // this.pathNotif.set(`/users/${this.userProfile.uid}/notif_user`);
  }
}
