import { Component, OnInit, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { EditorAPIService } from '../../../features/editor/api/editor-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { setToast } from '../toast/toast.component';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AuthStoreService } from '../../../features/auth/store/auth-store.service';

@Component({
  selector: 'fibr-join-app',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './join-app.component.html',
  styleUrl: './join-app.component.scss'
})
export class JoinAppComponent implements OnInit {

  token = signal('')
  tokenLocalStorage = ''

  logo = environment.logo;
  labelAlt = environment.app_name;

  countdown = 3;

  error = signal<null | boolean>(null);
  errMessage = signal('');

  private authStore = inject(AuthStoreService);
  isBlocked = signal(false);

  constructor(
    private router: Router,
    private editorAPIService: EditorAPIService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {

    if (this.authStore.isLoggedIn()){
      const lastUrl = JSON.parse(localStorage.getItem('lastUrl') ?? '{}');
      this.isBlocked.set(true);
      const respRef = this.editorAPIService.validateInvitation(lastUrl.params.token);
      respRef.then((resp) => {
        this.isBlocked.set(false);
        if (resp.isSuccess) {
          this.error.set(false);
          setToast(this.translate.instant('PROJECT.SUCCESS_VALIDATE'), 'success', 'top-center');

          const intervalId = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown--;
            } else {
              clearInterval(intervalId);
              if (window.navigator.userAgent.indexOf("Android") != -1){
                window.location.href =  environment.play_store_app;
              } else if (window.navigator.userAgent.indexOf("iPhone") != -1){
                window.location.href = environment.app_store_app
              } else {
                window.location.href = '/projects'
              }
            }
          }, 1000);
        } else {
          this.error.set(true);
          this.errMessage.set((resp.error as HttpErrorResponse).error.message);
          setToast((resp.error as HttpErrorResponse).error.message, 'error', 'top-center');
        }
      });
    } else {
      this.router.navigate(['sign-in'], { queryParams: {return_url: 'join-app-logged-in' } });
    }
  }

}
