import { Component, Input } from '@angular/core';

@Component({
  selector: 'fibr-brand',
  standalone: true,
  imports: [],
  templateUrl: './brand.component.html',
  styleUrl: './brand.component.scss',
})
export class BrandComponent {
  @Input() srcImg: string = '/assets/images/logo_appfibr.svg';
}
