import { Component, Inject, OnDestroy, TemplateRef, ViewChild, inject } from "@angular/core";
import { PageComponentStore } from "../../../store/page-component.store";
import { TranslateService } from "@ngx-translate/core";

@Component({
  template: ''
})
export abstract class FibrBaseComponentTypeComponent implements OnDestroy {
  abstract label: string;
  abstract icon: string;
  abstract type: string;
  abstract category: string;

  public pageComponentStore: PageComponentStore;
  public _translate: TranslateService;
  
  constructor(@Inject(String) public moduleCode: string) {
    this.pageComponentStore = inject(PageComponentStore);
    this._translate = inject(TranslateService);
  }

  @ViewChild('dataTemplate') dataTemplate: TemplateRef<unknown>;
  @ViewChild('detailTemplate') detailTemplate: TemplateRef<unknown>;
  @ViewChild('formTemplate') formTemplate: TemplateRef<unknown>;
  @ViewChild('optionTemplate') optionTemplate: TemplateRef<unknown>;

  public translateInstant(key: string) {
    return this._translate.instant(key);
  }

  abstract apply(): void;
  abstract cancel(): void;

  protected onDestroy?(): void;

  ngOnDestroy(): void {
    if (!this.onDestroy) return
    this.onDestroy();
  }
}