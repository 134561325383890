import { inject } from '@angular/core';
import { CanActivateFn, type ActivatedRouteSnapshot } from '@angular/router';
import { ProjectsShardService } from './projects-shard.service';
import { signInWithCustomToken } from '@angular/fire/auth';
import { AuthStoreService } from '../../features/auth/store/auth-store.service';

export const projectsShardGuard: CanActivateFn = async (route: ActivatedRouteSnapshot) => {
  const projectsShard = inject(ProjectsShardService);
  const authStore = inject(AuthStoreService);
  try {
    const res = await projectsShard.initializeFirebase(route.params['id']);
    if (!res.isSuccess) return false;
    const userCredential = await signInWithCustomToken(projectsShard.auth, authStore.projectsShardConfigByID()[route.params['id']].customToken);
    await projectsShard.getTokenResult(userCredential)
    return true;
  } catch (error) {
    return false
  }
};
