import { Injectable, computed, effect, inject, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { setLocalStorage } from '@shared/libs/storage/localeStorage';

@Injectable({
  providedIn: 'root'
})
export class GlobalConfigService {
  translate = inject(TranslateService);

  private _store = signal<GlobalConfig>({
    language: 'id',
  })

  language = computed(() => this._store().language);

  constructor() {
    effect(() => {
      setLocalStorage('FIBR-LANGUAGE', this.language());
      this.translate.use(this.language());
    })
  }

  setLanguage(language: GlobalConfig['language']) {
    this._store.update(prev => ({ ...prev, language }));
    this.translate.use(language);
  }
}

export type GlobalConfig = {
  language: Language;
}

export const languageList = ['en', 'id'] as const;
export type Language = typeof languageList[number];
