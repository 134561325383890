import { Component, OnInit, computed, inject, signal } from '@angular/core';
import { FibrImagePickerComponent } from '@shared/components/image-picker/image-picker.component';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BaseComponent } from '../../../../../core/base/base-component';
import { ProjectStoreService } from '../../../../project/store/project-store.service';
import { ProjectAPIService } from '../../../../project/api/project-api.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Project } from '../../../../project/model';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DividerModule } from 'primeng/divider';
import { BlockUIComponent } from '@shared/components/block-ui/block-ui.component';
import { AppSettingService } from '../../../api/app-settings.service';
import { ConfirmationService } from 'primeng/api';
import {
  UpdateAppSetting,
  schemaUpdateSetting,
} from '../../../model/app-settings';
import { MissingImageDirective } from '@shared/directive/image-missing/image-missing.directive';
import { getPreviewImage } from '@shared/libs/get-preview-image';
import { TranslateModule } from '@ngx-translate/core';
import { setToast } from '@shared/components/toast/toast.component';

@Component({
  selector: 'fibr-app-identity',
  templateUrl: './app-identity.component.html',
  standalone: true,
  imports: [
    InputTextModule,
    InputTextareaModule,
    FibrImagePickerComponent,
    ButtonModule,
    ReactiveFormsModule,
    FormsModule,
    DividerModule,
    BlockUIComponent,
    RouterModule,
    MissingImageDirective,
    TranslateModule,
  ],
  providers: [AppSettingService],
})
export class AppIdentityComponent extends BaseComponent implements OnInit {
  route = inject(ActivatedRoute);
  router = inject(Router);
  projectStore = inject(ProjectStoreService);
  projectApi = inject(ProjectAPIService);
  appSetting = inject(AppSettingService);
  confirmService = inject(ConfirmationService);

  projectId = this.route.snapshot.paramMap.get('id');
  project = computed(() => this.projectStore.projectDetail());

  identity = signal<UpdateAppSetting>({
    app_name: '',
    app_description: '',
    app_icon: null,
  });

  appIconUrl = signal<string>('');

  constructor() {
    super('fibr-app-identity');

    this.schema = schemaUpdateSetting;
  }

  ngOnInit(): void {
    if (this.projectId) {
      this.setStateLoading();
      this.projectApi.fetchProjectDetail(this.projectId).then((response) => {
        if (response.isSuccess) {
          const app = <Project>this.project();
          this.identity.set({
            app_name: app.display_name,
            app_description: app.description,
            app_icon: app.icon,
          });

          this.appIconUrl.set(<string>this.identity().app_icon);
          this.setStateReady();
        }
      });
    }
  }

  async chooseIcon(event: FileList) {
    this.identity.update((val) => ({
      ...val,
      app_icon: event.item(0),
    }));

    this.appIconUrl.set(
      (await getPreviewImage(event.item(0) as File)) as string
    );
  }

  async removeIcon() {
    this.identity.update((val) => ({
      ...val,
      app_icon: null,
    }));
    this.appIconUrl.set('');
  }

  onSubmit() {
    this.setStateLoading();
    try {
      this.appSetting.updateAppIdentity({
        projectId: <string>this.projectId,
        app_name: this.identity().app_name,
        app_description: this.identity().app_description,
        app_icon: this.identity().app_icon,
      });
      this.setStateReady();
      setToast(this.translate('PROJECT.app_settings.form.alert.success'), 'success');
    } catch (error) {
      this.setStateReady();
      setToast(this.translate('PROJECT.app_settings.form.alert.error'), 'error');
      console.error(error);
    }
  }

  confirmDeleteProject() {
    this.confirmService.confirm({
      header: this.translate("PROJECT.app_settings.form.delete_title"),
      message: this.translate("PROJECT.app_settings.button_delete.alert.message"),
      rejectButtonStyleClass: 'p-button-secondary',
      dismissableMask: true,
      accept: async () => {
        this.setStateLoading();

        try {
          await this.appSetting.deleteProject(<string>this.projectId);
          this.setStateReady();
          this.router.navigate(['/projects'], { replaceUrl: true });
        } catch (error) {
          this.setStateReady();
          setToast(this.translate('PROJECT.app_settings.form.alert.delete_failed'), 'error');
          console.error(error);
        }
      },
    });
  }
}
