import { CommonModule } from '@angular/common';
import { Component, computed, effect, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import label from '@shared/libs/generators/widgets/label';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { FibrBaseComponentTypeComponent } from '../../../page-component/page-component-types/base-component-type.component';
import { DataSource } from '../../../../model';
import { v4 } from 'uuid';
import { FibrCollapseComponent } from '@shared/components/collapse/collapse.component';

@Component({
  selector: 'fibr-component-content',
  templateUrl: './page-component-content.component.html',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    ReactiveFormsModule,
    FormsModule,
    InputTextModule,
    FibrCollapseComponent,
  ],
})
export class FibrPageComponentContentComponent extends FibrBaseComponentTypeComponent {
  override label: string = 'Description';
  override icon: string = '/assets/images/icon/details/icon_content.svg';
  override type: string = 'content';
  override category: string = 'content';

  tableSource = ['table', 'custom'];
  dataSource = signal<DataSource>({
    type: 'table',
    value: '',
  });

  value = computed(() => this.dataSource().value);

  constructor() {
    super('fibr-component-content');

    this.init();

    effect(
      () => {
        const selected = this.pageComponentStore.dialogDetail().selected;
        if (selected) {
          this.dataSource.set(<DataSource>selected.data_source);
        }
      },
      { allowSignalWrites: true }
    );
  }

  init() {
    this.dataSource.set({
      value: '',
      type: 'table',
    });
  }

  onSourceChange(type: string) {
    if (type === 'custom') {
      this.dataSource.update((val) => ({
        ...val,
        value: '',
      }));
    }
  }

  changeValue(value: string) {
    this.dataSource.update((val) => ({
      ...val,
      value,
    }));
  }

  reset() {
    setTimeout(() => {
      this.init();
      this.pageComponentStore.closeDetail();
    }, 200);
  }

  override apply(): void {
    const output = {
      ...label({
        variant: 'content',
        tableConfig: this.dataSource(),
        style: {
          font_size: 14,
        },
      }),
      id: this.pageComponentStore.dialogDetail().selected?.id || v4(),
    };

    this.pageComponentStore.addOrEditDetail(output);
    this.reset();
  }

  override cancel(): void {
    this.reset();
  }

  protected override onDestroy(): void {
    this.reset();
  }
}
