import { NgClass, DatePipe } from '@angular/common';
import { Component, Input, Output, inject, EventEmitter, signal, effect, input, ViewChild, computed, ChangeDetectorRef } from '@angular/core';
import { InputGroupModule } from 'primeng/inputgroup';
import { ConfirmationService, type MenuItem } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';
import type { ColumnField, TableColumnFieldType, TableField } from '../../../table/model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { TableAPIService } from '../../../table/api/table-api.service';
import { setToast } from '@shared/components/toast/toast.component';
import { handleErrorTranslationToast } from '@shared/libs/error';
import { TableComponent, type Field, type Row, type RowValue, type FieldName, type FieldType } from '@shared/components/table/table.component';
import { TableStoreService } from '../../../table/store/table-store.service';
import { builderColTypeToColumnColType, convertToClientColType } from '../../../table/tools';
import { Timestamp } from '@angular/fire/firestore';
import { P, match } from 'ts-pattern';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { AddColumnDialogComponent } from '../../../table/ui/add-column-dialog/add-column-dialog.component';
import { EditColumnDialogComponent } from '../../../table/ui/edit-column-dialog/edit-column-dialog.component';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { UploadImageDialogComponent } from '../../../table/ui/upload-image-dialog/upload-image-dialog.component';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { escapeClicker } from '@shared/libs/escape-clicker';

const extraImageField = {
  imageHoverSize: 128,
  showHover: true,
  hoverWidth: 130,
  hoverHeight: 130,
}

const canEditColumn = (column: string) => !['id', 'created_at', 'updated_at'].some(col => col === column);

@Component({
  selector: 'fibr-data-section',
  standalone: true,
  imports: [NgClass, TabMenuModule, MenuModule, TranslateModule,
    ButtonModule, InputTextModule, InputGroupModule, FormsModule,
    TableComponent, DialogModule, EditColumnDialogComponent, DynamicDialogModule, CheckboxModule],
  templateUrl: './data-section.component.html',
  styleUrl: './data-section.component.scss',
})
export class DataSectionComponent {
  @ViewChild(TableComponent) tableComponent: TableComponent;

  @Input() selectedTableIndex = 0;
  @Input() sidebarItems: MenuItem[] = [];
  @Input() isSelected = false;
  selectedTable = input<TableField | null>(null);
  projectID = input<string | null>(null);
  @Output() resetSelectedTableEvent: EventEmitter<null> = new EventEmitter();

  translate = inject(TranslateService);
  confirmation = inject(ConfirmationService);
  tableAPI = inject(TableAPIService);
  tableStore = inject(TableStoreService);
  dynamicDialog = inject(DialogService);
  cdRef = inject(ChangeDetectorRef)
  pipe = new DatePipe('en-US');

  showUpdateColumnDialog = signal<boolean>(false);
  fields = signal<Field[]>([]);
  dataSelection = signal<Row[]>([]);
  selectedColumnNameToUpdate = signal<string | null>(null);
  showColumnsSettingDialog = signal<boolean>(false);
  columnsSetting = signal<ColumnField[]>([]);

  initialData = computed<Row[]>(() => {
    if (!this.projectID() || !this.selectedTable() || !this.selectedTableData) return [];
    return this.selectedTableData.reduce((acc, row: Row) => {
      if (!row) return acc;
      if (this.searchedText()) {
        const searchedText = this.searchedText().toLowerCase();
        if (!Object.values(row).some(value => match<RowValue, boolean>(value)
          .with(P.string, value => value.toLowerCase().includes(searchedText))
          .with(P.number, value => value.toString().toLowerCase().includes(searchedText))
          .with(P.boolean, () => false)
          .with(P._, () => false)
          .exhaustive())) {
          // return if no value match the searched text
          return acc;
        }
      }

      return [...acc, Object.entries(row).reduce(this.handleTimestampValue, {} as Row)]
    }, [] as Row[])
  });

  searchField = signal('');
  searchedText = signal('');

  readonly isGoogleFiles = false;
  readonly itemTable = [
    {
      label: this.translate.instant("TABLE.SHOW_HIDE_COLUMN"),
      icon: 'pi pi-fw pi-list',
      styleClass: "menu-blue",
      command: () => { this.showColumnsSettingDialog.set(true) }
    },
    {
      label: 'Download CSV',
      icon: 'pi pi-fw pi-download',
      styleClass: "menu-blue",
      command: () => { this.exportCSV() }
    },
    {
      label: `<img alt="icon" src="/assets/images/icon/file-export.svg" height="15" class="mr-2"> ${this.translate.instant('TABLE.EXPORT')}`,
      escape: false,
      styleClass: "menu-static",
      command: () => { }
    },
    {
      separator: true
    },
    {
      label: 'Delete Table',
      icon: 'pi pi-fw pi-trash',
      styleClass: "menu-magenta",
      command: this.deleteTable.bind(this)
    },
  ];

  constructor() {

    effect(() => {
      // TODO fill fields use computed instead of effect
      if (this.projectID() && this.selectedTable()) {
        this.tableAPI.fetchTableData(this.projectID() ?? '', this.selectedTable()?.id ?? '', this.selectedTable()?.is_external ?? false);
        const selectedTableColumns = this.tableStore.tableList().data.find(table => table.id === this.selectedTable()?.id)?.columns ?? {};
        this.fields.set([...Object.values(selectedTableColumns)].sort((a, b) => a.index - b.index).map((column) => {
          const showColumn = column.display ?? true;
          let typeConvert: FieldType = 'text';
          if(column.format === 'date'){
            typeConvert = 'date';
          } else if(column.format === 'date_time') {
            typeConvert = 'datetime';
          } else{
            typeConvert = convertToClientColType(column.type, column.format);
          }
          return {
            name: column.name,
            title: column.label,
            type: typeConvert,
            minWidth:75, 
            maxWidth:350,
            imageSize: convertToClientColType(column.type, column.format) === 'image' ? 50 : undefined,
            hidden: column.name === 'id' || !showColumn,
            primaryKey: column.name === 'id',
            canEdit: canEditColumn(column.name),
            ...(convertToClientColType(column.type, column.format) === 'image' ? extraImageField : {}),
            validateOnChange: true,
            validators: convertToClientColType(column.type, column.format) === 'float' ? [{
              type: 'isFloat',
              errorMessage: 'Must be a number'
            },
            ] : [],
            formatCellValue: function (value: unknown) {
              // TODO consistent to use clientColType or tablecolumnType,
              // and refactor redundant convertToClientColType(column.type, column.format)
              if (convertToClientColType(column.type, column.format) !== 'float') return value;
              if (isNaN(Number(value))) {
                return 0;
              }
              return value;
            }
          }
        }));
      }
    }, { allowSignalWrites: true })

    effect(() => {
      if (this.selectedTable()) {
        const { id: _id, ...otherColumns } = this.selectedTable()!.columns
        this.columnsSetting.set([...Object.values(otherColumns)]
          .sort((a, b) => a.label.localeCompare(b.label))
        )
      }
    }, { allowSignalWrites: true })

  }

  get selectedTableData() {
    return this.tableStore.tableDataByTableID().data[this.selectedTable()?.id ?? ''];
  }

  exportCSV() {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.selectedTableData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'data.xlsx');
  }

  onAddRow() {
    this.tableAPI.addRow({
      projectID: this.projectID() ?? '',
      tableID: this.selectedTable()?.id ?? '',
      row: {}
    }).then((result) => {
      if (result.isSuccess) {
        this.tableComponent.addNewRow({ id: result.value });
      } else {
        setToast(this.translate.instant(result.error), "error", "top-center");
      }
    }).catch(handleErrorTranslationToast(this.translate, 'top-center'));
  }

  onEditRow(values: Row) {
    if (!this.projectID() || !this.selectedTable()) return;
    if (values['id']) {
      this.tableAPI.editRow({
        projectID: this.projectID() ?? '',
        tableID: this.selectedTable()?.id ?? '',
        row: values
      }).then((result) => {
        if (!result.isSuccess) {
          setToast(this.translate.instant(result.error), "error", "top-center");
        }
      });
    }
  }

  onDeleteRow(values: Row) {
    if (!this.projectID || !this.selectedTable) return;
    this.tableAPI.deleteRow({
      projectID: this.projectID() ?? '',
      tableID: this.selectedTable()?.id ?? '',
      row: values
    });
  }

  onSelectionDataRow(values: Row[]) {
    this.dataSelection.set(values); 
  }

  removeDataColumn() {
    const dataSelected = this.dataSelection();
    if (!this.projectID || !this.selectedTable) return;
    dataSelected.map((values) => {
      this.tableAPI.deleteRow({
        projectID: this.projectID() ?? '',
        tableID: this.selectedTable()?.id ?? '',
        row: values
      });
    });
  }

  onClickCell(values: { record: Row, field: Field }) {
    if (values.field.type === 'image') {
      this.dynamicDialog.open(UploadImageDialogComponent, {
        header: this.translate.instant('TABLE.UPLOAD.UPLOAD_IMAGE'),
        width: '500px',
        data: {
          ...values,
          projectId: this.projectID() ?? '',
          tableId: this.selectedTable()?.id ?? '',
        },
      })
      escapeClicker();
    }
  }

  onSearchPressEnter() {
    this.searchedText.set(this.searchField());
  }

  onClickSearch() {
    this.searchedText.set(this.searchField());
  }

  // TODO
  // 1. handle card_transaction / payment cannot add column
  addNewColumn() {
    const dynamicDialogConfig = {
      header: this.translate.instant('TABLE.ADD_COLUMN'),
      width: '500px',
      contentStyle: { "max-height": "90%" },
      styleClass: 'dialog-no-footer create-new',
      dismissableMask: true,
      baseZIndex: 10000,
      showHeader: true,
      closable: true,
      // TODO handle google spreadsheet as add column props
    }
    this.dynamicDialog.open(AddColumnDialogComponent, dynamicDialogConfig)
      .onClose.subscribe(async (res: { columnName: string, columnType: TableColumnFieldType }) => {
        if (res) {
          const { columnName, columnType } = res;
          const resp = await this.tableAPI.createColumn({
            projectID: this.projectID()!,
            tableID: this.selectedTable()!.id,
            columnName,
            columnType
          })
          if (!resp.isSuccess) {
            setToast(this.translate.instant(resp.error), "error", "top-center");
          }
          await this.tableAPI.fetchTableList(this.projectID() ?? '');
        }
      });
  }


  onShowUpdateColumnDialog(columnName: string) {
    this.selectedColumnNameToUpdate.set(columnName);
    this.showUpdateColumnDialog.set(true);
    this.cdRef.detectChanges();
  }

  onCloseUpdateColumnDialog() {
    this.showUpdateColumnDialog.set(false);
    this.cdRef.detectChanges();
  }

  deleteTable() {
    this.confirmation.confirm({
      message: this.translate.instant('TABLE.DELETE_CONFIRMATION'),
      header: this.translate.instant('COMMON.CONFIRMATION'),
      acceptButtonStyleClass: 'p-button-danger',
      rejectButtonStyleClass: 'p-button-secondary',
      accept: () => {
        this.tableAPI.deleteTable(this.projectID() ?? '', this.selectedTable()?.id ?? '').then((result) => {
          if (result.isSuccess) {
            setToast(this.translate.instant('TABLE.SUCCESS_DELETE_TABLE'), "success", "top-center");
            this.resetSelectedTableEvent.emit();
          } else {
            setToast(this.translate.instant(result.error), "error", "top-center");
          }
        })
          .catch(handleErrorTranslationToast(this.translate, 'top-center'))
      },
      reject: () => { }
    });
  }

  getColumnType(column: ColumnField) {
    return builderColTypeToColumnColType(column)
  }

  toggleShowHideColumn(column: ColumnField) {
    const value = column.display ?? true;
    this.columnsSetting.set(this.columnsSetting().map(col => {
      if (col.name === column.name) {
        return {
          ...col,
          display: !value
        }
      }
      return col;
    }))
  }

  saveColumnsSetting() {
    const displaySomeColumns = this.columnsSetting().some(col => {
      const displayCol = col.display ?? true;
      return displayCol;
    })
    if (!displaySomeColumns) {
      setToast(this.translate.instant('TABLE.ERROR_AT_LEAST_ONE_COLUMN'), "error", "top-center");
      return;
    }
    this.tableAPI.setDisplayColumns({
      projectID: this.projectID()!,
      tableID: this.selectedTable()!.id,
      columns: this.columnsSetting()
    }).then((result) => {
      if (result.isSuccess) {
        this.tableAPI.fetchTableList(this.projectID()!);
        this.showColumnsSettingDialog.set(false);
      } else {
        setToast(this.translate.instant(result.error), "error", "top-center");
      }
    })
  }

  private handleTimestampValue(acc: Row, [key, value]: [FieldName, RowValue]) {
    return {
      ...acc,
      [key]: value instanceof Timestamp ? value.toDate() : value
    }
  }
}

