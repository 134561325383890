import {Component, computed, inject, model} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CheckboxModule} from "primeng/checkbox";
import {FormsModule} from "@angular/forms";
import {FibrTextEditorQuillComponent} from "@shared/components/text-editor/quill/quill.component";
import {FibrCollapseComponent} from "@shared/components/collapse/collapse.component";
import {TranslateModule} from "@ngx-translate/core";
import {DropdownModule} from "primeng/dropdown";
import {TableStoreService} from "../../../../../../table/store/table-store.service";
import {PageComponentStore} from "../../../../../store/page-component.store";

@Component({
  selector: 'fibr-form-settings',
  standalone: true,
  templateUrl: './form-settings.component.html',
  imports: [CommonModule, CheckboxModule, FormsModule, FibrTextEditorQuillComponent, FibrCollapseComponent, TranslateModule, DropdownModule]
})
export class FibrFormSettingsComponent {
  private tableStore: TableStoreService = inject(TableStoreService);
  private pageComponentStore: PageComponentStore = inject(PageComponentStore);

  settings = model({description: '', showAllSection: false})
  tables = computed(() => this.tableStore.tableList().data);
  tableSelected = this.pageComponentStore.tableSelected;

  changeSource(source: string) {
    this.pageComponentStore.changeTable(source);
  }
}
