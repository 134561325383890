import { CommonModule, NgClass, NgStyle } from '@angular/common';
import { Component, Input, ElementRef } from '@angular/core';
import { BlockableUI } from 'primeng/api';

@Component({
  selector: 'fibr-blockable',
  template: `
    <div [ngStyle]="style" [ngClass]="class">
      <ng-content></ng-content>
    </div>
  `,
  standalone: true,
  imports: [CommonModule]
})
export class BlockableComponent implements BlockableUI {
  @Input() style: NgStyle;
  @Input() class: NgClass;

  constructor(private el: ElementRef) {}

  getBlockableElement(): HTMLElement {
    return this.el.nativeElement.children[0];
  }
}
