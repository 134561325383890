import { Component, OnInit, AfterViewInit, Renderer2, signal } from '@angular/core';
import { MenuModule } from 'primeng/menu';
import { GalleriaModule, type GalleriaResponsiveOptions } from 'primeng/galleria';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { getLocalStorage } from '../../../libs/storage/localeStorage';
import { DomSanitizer, type SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'fibr-help',
  standalone: true,
  imports: [MenuModule, GalleriaModule, TranslateModule],
  templateUrl: './help.component.html',
  styleUrl: './help.component.scss',
})
export class HelpComponent implements OnInit, AfterViewInit {
  images = signal<{
    itemImageSrc: SafeResourceUrl;
    thumbnailImageSrc: string;
  }[]>([]);
  helpMenuItems: MenuItem[] = [];
  watchVideo: boolean = false;
  responsiveOptions: GalleriaResponsiveOptions[] = [
    {
      breakpoint: '1500px',
      numVisible: 5,
    },
    {
      breakpoint: '1024px',
      numVisible: 3,
    },
    {
      breakpoint: '768px',
      numVisible: 2,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
    },
  ];

  constructor(
    private translate: TranslateService,
    private domSanitizer: DomSanitizer,
    private renderer: Renderer2
  ) { }
  ngAfterViewInit(): void {
    this.translate
      .get(['help.how_to_use', 'help.watch_video', 'help.version.history'])
      .subscribe((trx) => {
        this.helpMenuItems = [
          {
            label: trx['help.how_to_use'],
            icon: 'pi pi-fw pi-question-circle',
            url: 'https://appfibr.io/learn/',
            target: '_blank',
          },
          {
            label: trx['help.watch_video'],
            icon: 'pi pi-fw pi-video',
            command: () => {
              this.watchVideo = true;

              const divElement = document.querySelector(
                '.sub-header .p-toolbar.p-component'
              );
              if (divElement != null)
                this.renderer.setStyle(divElement, 'z-index', 2);
            },
            // url: 'https://www.youtube.com/@appfibr',
            // target: '_blank'
          },
          {
            label: trx['help.version.history'],
            icon: 'pi pi-fw pi-list',
            url: 'https://appfibr.io/versi-rilis/',
            target: '_blank',
          },
        ];
      });
  }

  ngOnInit(): void {
    this.translate.use(getLocalStorage('FIBR-LANGUAGE') ?? '');
    this.images.set([
      {
        itemImageSrc: this.domSanitizer.bypassSecurityTrustResourceUrl(
          'https://www.youtube.com/embed/oTtmvGodPWY'
        ),
        thumbnailImageSrc:
          'https://i.ytimg.com/vi/oTtmvGodPWY/maxresdefault.jpg',
      },
      {
        itemImageSrc: this.domSanitizer.bypassSecurityTrustResourceUrl(
          'https://www.youtube.com/embed/pqu002lMa1s'
        ),
        thumbnailImageSrc:
          'https://i.ytimg.com/vi_webp/pqu002lMa1s/sddefault.webp',
      },
      {
        itemImageSrc: this.domSanitizer.bypassSecurityTrustResourceUrl(
          'https://www.youtube.com/embed/AMAr1_nU94k'
        ),
        thumbnailImageSrc:
          'https://i.ytimg.com/vi_webp/AMAr1_nU94k/sddefault.webp',
      },
      {
        itemImageSrc: this.domSanitizer.bypassSecurityTrustResourceUrl(
          'https://www.youtube.com/embed/fO6xdoXUG60'
        ),
        thumbnailImageSrc:
          'https://i.ytimg.com/vi_webp/fO6xdoXUG60/sddefault.webp',
      },
      {
        itemImageSrc: this.domSanitizer.bypassSecurityTrustResourceUrl(
          'https://www.youtube.com/embed/d3ex3_t0wEc'
        ),
        thumbnailImageSrc:
          'https://i.ytimg.com/vi_webp/d3ex3_t0wEc/sddefault.webp',
      },
      {
        itemImageSrc: this.domSanitizer.bypassSecurityTrustResourceUrl(
          'https://www.youtube.com/embed/LBE4iL7RHY4'
        ),
        thumbnailImageSrc:
          'https://i.ytimg.com/vi_webp/LBE4iL7RHY4/sddefault.webp',
      },
    ]);
  }
}
