<fibr-block-ui [isBlocked]="isLoading"></fibr-block-ui>

<div class="flex flex-column p-3">
  <div class="flex flex-column">
    <h4 class="text-xl font-semibold">
      {{ "BILLING.CHECKOUT.PRICING.TITLE" | translate }}
    </h4>

    <p-listbox
      optionLabel="description"
      optionValue="id"
      optionDisabled="disable"
      [options]="packages()"
      [ngModel]="summaryOrder().selected"
      (ngModelChange)="onSelectedChange($event)"
      [listStyle]="{'max-height': '450px'}"
    >
      <ng-template let-package pTemplate="item">
        <div class="py-2">
          <h6 class="text-lg font-semibold">
            {{
              "BILLING.CHECKOUT.PRICING.PACKAGE"
                | translate : { month: package.duration_days / 30 }
            }}
          </h6>

          <div>
            <p class="text-base text-[#8A8A8A]">
              {{
                "BILLING.CHECKOUT.PRICING.PER_PACKAGE"
                  | translate
                    : {
                        price: currentPipe.transform(
                          package.price / (package.duration_days / 30),
                          "Rp ",
                          "code",
                          "0.0-0"
                        )
                      }
              }}
            </p>
            <p class="text-base text-[#8A8A8A]">
              {{ "BILLING.CHECKOUT.PRICING.NOREFUND" | translate }}
            </p>
          </div>
        </div>
      </ng-template>
    </p-listbox>
  </div>

  @if (selectedPackage()?.duration_days) {
  <div class="flex flex-column mt-4">
    <h4 class="text-xl font-semibold">
      {{
        "BILLING.CHECKOUT.PRICING.PACKAGE"
          | translate : { month: selectedPackage()!.duration_days / 30 }
      }}
    </h4>

    <div class="px-4">
      <table class="w-full">
        <tr>
          <td class="w-1/2">
            {{
              "BILLING.CHECKOUT.PRICING.SELECTED"
                | translate
                  : {
                      app_name: projectName,
                      month: selectedPackage()!.duration_days / 30
                    }
            }}
          </td>
          <td class="w-1/2 text-right">
            {{
              selectedPackage()!.price / (selectedPackage()!.duration_days / 30)
                | currency : "Rp " : "code" : "0.0-0"
            }}
            x {{ selectedPackage()!.duration_days / 30 }}
          </td>
        </tr>
        <tr>
          <td colspan="2" class="text-right">
            {{ selectedPackage()!.price | currency : "Rp " : "code" : "0.0-0" }}
          </td>
        </tr>
      </table>
    </div>

    <p-divider></p-divider>

    <div class="px-4">
      <table class="w-full">
        <tr>
          <td class="w-1/2">
            {{ 'BILLING.CHECKOUT.TAX' | translate:{ percent: 11 } }}
          </td>
          <td class="w-1/2 text-right">
            {{ summaryOrder().tax | currency : "Rp " : "code" : "0.0-0" }}
          </td>
        </tr>
        <tr>
          <td colspan="2" class="h-3"></td>
        </tr>
        <tr>
          <td class="w-1/2">{{ 'BILLING.CHECKOUT.DISCOUNT' | translate }}</td>
          <td class="w-1/2 text-right">
            {{ summaryOrder().discount | currency : "Rp " : "code" : "0.0-0" }}
          </td>
        </tr>
      </table>

      <form class="mt-4" (ngSubmit)="applyVoucher()">
        <div class="flex align-items-center justify-content-around">
          <label for="voucher" class="text-[#444444]">
            {{ 'BILLING.CHECKOUT.VOUCHER.LABEL' | translate }}
          </label>
          <input
            id="voucher"
            name="voucher"
            class="mx-5"
            type="text"
            pInputText
            placeholder="{{ 'BILLING.CHECKOUT.VOUCHER.PLACEHOLDER' | translate }}"
            [(ngModel)]="summaryOrder().voucherCode"
          />
          <p-button
            type="submit"
            label="{{ 'COMMON.APPLY' | translate }}"
            [disabled]="!summaryOrder().voucherCode"
            [loading]="isProcessing"
          ></p-button>
        </div>
      </form>
    </div>

    <div
      class="px-4 py-3 mt-4 bg-[#E3EFFA] flex flex-row align-items-center justify-content-between"
    >
      <span class="text-base text-[#444444]">
        {{ 'BILLING.CHECKOUT.TOTAL' | translate }}
      </span>
      <span class="text-2xl text-primary font-semibold">
        {{ summaryOrder().total | currency : "Rp " : "code" : "0.0-0" }}
      </span>
    </div>
  </div>
  }

  <p-divider class="my-3"></p-divider>

  <div class="flex align-items-center justify-content-end gap-3">
    <p-button
      label="{{ 'COMMON.BACK' | translate }}"
      (onClick)="close()"
      styleClass="p-button-secondary2"
    ></p-button>
    <p-button
      label="{{ 'BILLING.CHECKOUT.SUBMIT' | translate }}"
      [loading]="isLoading"
      icon="pi pi-angle-right"
      iconPos="right"
      (onClick)="submit()"
    ></p-button>
  </div>
</div>
